import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { debounce } from 'lodash'
import { useHistory } from 'react-router-dom'

import { autoSaveClinicalNotesV1 } from '../../api/notes'
import { useNotesFeatures } from '../../containers/Patient/ClinicalNotes/helpers'
import { NotesEvents, trackNotesEvent } from '../../libs/freshpaint/notesEvents'
import { DateISO8601, NoteV1AuditLog } from '../../shared-types'
import {
  AutosaveTriggerCreateNoteKeyword,
  NoteType,
} from '../useClinicalNoteFields/utils'

type SaveQueue =
  | (NoteType & {
      noteId?: string
      shouldAuditLog?: boolean
    })
  | null

export type AutoSaveAuditLogs = {
  createdOnDate?: string
  createdOn?: string
  createdBy?: string
  editedOnDateBy: NoteV1AuditLog
}

type UseAutoSaveNoteV1Fn = (
  initialNoteData: NoteType,
  noteId?: string,
  canSaveBackup?: boolean,
  setCanSaveBackup?: Dispatch<SetStateAction<boolean>>
) => {
  isSaving: boolean
  isNoteCreated: boolean
  autosaveFailed: boolean
  lastSavedTimestamp?: DateISO8601
  auditLogs?: AutoSaveAuditLogs
  saveQueue: SaveQueue
  hasAnyPendingChanges: boolean
  setDataToAutosave: Dispatch<SetStateAction<NoteType | null>>
}

const useAutoSaveNoteV1: UseAutoSaveNoteV1Fn = (
  initialNoteData,
  noteId,
  canSaveBackup,
  setCanSaveBackup
) => {
  const { autosaveDebounceNotev1Seconds, useAutosaveWithNewNavFlow } =
    useNotesFeatures()
  const history = useHistory()

  const [idOfNote, setIdOfNote] = useState(noteId)
  const [dataToAutosave, setDataToAutosave] = useState<NoteType | null>(null)
  const [saveQueue, setSaveQueue] = useState<SaveQueue>(null)
  const [isSaving, setIsSaving] = useState(false)
  const [autosaveFailed, setAutosaveFailed] = useState(false)
  const [isNoteCreated, setIsNoteCreated] = useState(
    !location.pathname.includes(AutosaveTriggerCreateNoteKeyword)
  )
  const [isOnCreationMode, setIsOnCreationMode] = useState(false)
  const [auditLogs, setAuditLogs] = useState<AutoSaveAuditLogs>({
    createdOnDate: initialNoteData.CreatedOnDate,
    createdOn: initialNoteData.CreatedOn,
    createdBy: initialNoteData.CreatedBy,
    editedOnDateBy: initialNoteData.EditedOnDateBy ?? [],
  })
  const [lastSavedTimestamp, setLastSavedTimestamp] = useState<
    DateISO8601 | undefined
  >(undefined)

  // This won't run until X seconds of no new input on the note from the user (debounce delay uses autosaveDebounceNotev1Seconds)
  const debouncedAutoSave = debounce(() => {
    if (!dataToAutosave) return // Nothing to save
    if (!idOfNote && isSaving) return // Prevent duplicate creations

    setIsSaving(true)
    const dataWithAuditLogs: NoteType = {
      ...dataToAutosave,
      EditedOnDateBy: auditLogs.editedOnDateBy,
    } // Add auditLogs to the note body request

    let shouldAuditLog: boolean

    if (useAutosaveWithNewNavFlow) {
      // Don't audit log the edit if the note was just created < 20 seconds since edits made might still be counted towards note creation that occurred before navigating to the editor
      const noteWasJustCreated =
        new Date().getTime() -
          new Date(initialNoteData.CreatedOnDate).getTime() <
        20000
      shouldAuditLog =
        Boolean(lastSavedTimestamp === undefined) && !noteWasJustCreated
      if (noteWasJustCreated) {
        setLastSavedTimestamp(initialNoteData.CreatedOnDate)
      }
    } else {
      // Only audit log the very first auto-update that runs during the autosaving session (also block audit logs on create)
      shouldAuditLog =
        Boolean(lastSavedTimestamp === undefined) && !isOnCreationMode
    }

    // This saveQueue change triggers to call the the API
    setSaveQueue((previousSaveQueue) => ({
      ...(previousSaveQueue ?? {}),
      ...dataWithAuditLogs,
      noteId:
        idOfNote &&
        !location.pathname.includes(AutosaveTriggerCreateNoteKeyword)
          ? idOfNote
          : undefined,
      shouldAuditLog,
    }))

    setDataToAutosave(null) // Set to null so that we know to autosave again when an object is set
  }, (autosaveDebounceNotev1Seconds ?? 0) * 1000)

  // This initializes a debounced autosave
  useEffect(() => {
    if (!autosaveDebounceNotev1Seconds || !dataToAutosave?.NoteType) return

    debouncedAutoSave()

    // Every time the input on note changes, debounced call gets cleaned up and replaced with another one
    return () => {
      debouncedAutoSave.cancel()
    }
  }, [dataToAutosave])

  // This calls the autosave API once the saveQueue changes */
  useEffect(() => {
    if (!saveQueue) return

    const autoSaveRequest = async () => {
      try {
        const { noteId, shouldAuditLog, ...paylod } = saveQueue
        // This guards against any race conditions that may trigger creation (when !noteId) if the useAutosaveWithNewNavFlow is running
        if (useAutosaveWithNewNavFlow && !noteId) return

        // TODO: Clean up the autosave creation logic in this function after useAutosaveWithNewNavFlow is turned on for all users
        const response = await autoSaveClinicalNotesV1(
          paylod,
          noteId,
          shouldAuditLog
        )
        if (autosaveFailed) setAutosaveFailed(false)
        if (setCanSaveBackup && canSaveBackup) setCanSaveBackup(false)

        if (response.CreatedOn) {
          // The autosave created a new note
          trackNotesEvent(NotesEvents.AUTOSAVE_CREATED_NOTE, {
            noteId: response.NoteId,
            noteType: saveQueue.NoteType,
            patientId: saveQueue.PatientId,
          })
          setIsNoteCreated(true)
          setIdOfNote(response.NoteId)
          setAuditLogs((currentLogs) => ({
            ...currentLogs,
            createdBy: response.CreatedBy,
            createdOn: response.CreatedOn,
            createdOnDate: response.CreatedOnDate,
          }))
        }

        if (response.EditedOnDateBy) {
          // The autosave updated an existing note
          setAuditLogs((currentLogs) => ({
            ...currentLogs,
            editedOnDateBy: response.EditedOnDateBy,
          }))
        }

        if (shouldAuditLog) {
          // The first autosave triggered after opening note to edit
          trackNotesEvent(NotesEvents.AUTOSAVE_EDITED_NOTE, {
            noteId: saveQueue.noteId,
            noteType: saveQueue.NoteType,
            patientId: saveQueue.PatientId,
          })
        }

        setLastSavedTimestamp(response.lastSaved ?? new Date().toISOString())
      } catch (error) {
        setCanSaveBackup && setCanSaveBackup(true)
        setAutosaveFailed(true)
        console.error(`Failed to autosave note`, error)
        // Should leave the saveQueue state unchanged for the next autosave cadence
        trackNotesEvent(NotesEvents.AUTOSAVE_ERROR, {
          noteId: saveQueue.noteId,
          noteType: saveQueue.NoteType,
          patientId: saveQueue.PatientId,
        })
      } finally {
        setIsSaving(false)
      }
    }

    autoSaveRequest()
  }, [saveQueue])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (
      idOfNote &&
      location.pathname.includes(AutosaveTriggerCreateNoteKeyword) &&
      searchParams.get('saltNoteId') !== idOfNote
    ) {
      //Add noteId to url in the moment that the note is created
      history.replace(
        `${location.pathname.replace(
          AutosaveTriggerCreateNoteKeyword,
          idOfNote
        )}${location.search}`
      )
    }
  }, [idOfNote])

  useEffect(() => {
    // Prevents stale noteId
    if (noteId) {
      setIdOfNote(noteId)
    }
  }, [noteId])

  useEffect(() => {
    // Check keyworkd in URL to indicate that a new note is being created
    if (location.pathname.includes(AutosaveTriggerCreateNoteKeyword)) {
      setIsOnCreationMode(true)
    }
  }, [])

  useEffect(() => {
    // Prevents stale logs from existing note data
    setAuditLogs({
      createdOnDate: initialNoteData.CreatedOnDate,
      createdOn: initialNoteData.CreatedOn,
      createdBy: initialNoteData.CreatedBy,
      editedOnDateBy: initialNoteData.EditedOnDateBy ?? [],
    })
  }, [
    initialNoteData.CreatedOnDate,
    initialNoteData.CreatedOn,
    initialNoteData.CreatedBy,
    initialNoteData.EditedOnDateBy,
  ])

  return {
    isSaving,
    lastSavedTimestamp,
    auditLogs,
    isNoteCreated,
    autosaveFailed,
    saveQueue,
    hasAnyPendingChanges: !!dataToAutosave,
    setDataToAutosave,
  }
}

export default useAutoSaveNoteV1
