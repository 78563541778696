import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { Amplify, Auth } from 'aws-amplify'
import axios from 'axios'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'

import AntDConfigProvider from './AntDConfigProvider'
import App from './App'
import { defaultConfig, globalConfig, globalConfigUrl } from './config/config'
import { FeatureFlagProvider } from './libs/featureFlags'
import { initializeSentry } from './libs/sentry'
import * as serviceWorker from './serviceWorker'
import getCognitoUser from './shared/Helpers/getCognitoUser'

import './Index.scss'
import 'react-dropzone-uploader/dist/styles.css'

;(async () => {
  const customHeader = async () => {
    if (process.env.REACT_APP_STAGE !== 'local') {
      return {}
    }
    try {
      const currentSession = await Auth.currentSession()
      const jwt = currentSession.getAccessToken().getJwtToken()
      const id = currentSession.getIdToken().getJwtToken()

      return {
        Authorization: `Bearer ${jwt}`,
        AuthorizationId: id,
      }
    } catch (e) {
      return {}
    }
  }

  const endpoints = [
    'api',
    'billing',
    'caretaker',
    'clinical-notes',
    'core-api',
    'erx',
    'scheduling',
    'patient-intake',
    'patient-profile',
    'security',
    'providers',
    'labs',
    'insurance',
  ]

  const queryClient = new QueryClient()

  const persister = createSyncStoragePersister({
    storage: window.localStorage,
  })

  // we are reasonably confident that this is imported by an html with this root element so we can disable this lint rule
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(document.getElementById('root')!)

  try {
    const configResult = await axios.get(globalConfigUrl)
    if (typeof configResult.data === 'string') {
      // index.html is given if globalConfig.json cannot be found in production mode
      throw new Error('cannot find find globalConfig.json')
    }
    globalConfig.set(configResult.data)
    console.debug('Global config fetched: ', configResult.data)
  } catch (error) {
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.REACT_APP_STAGE === 'local'
    ) {
      console.error(
        `Failed to load global configuration from '${globalConfigUrl}', using the default configuration instead:`,
        defaultConfig
      )
      globalConfig.set(defaultConfig)
    } else {
      const errorMessage =
        'Error while fetching global config, the App wil not be rendered. (This is NOT a React error.)'
      console.error(
        errorMessage,
        `Have you provided the config file '${globalConfigUrl}'?`,
        error
      )
      root.render(
        <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>
      )
      return
    }
  }

  const configs = globalConfig.get()
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: configs.cognito.REGION,
      userPoolId: configs.cognito.USER_POOL_ID,
      identityPoolId: configs.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: configs.cognito.APP_CLIENT_ID,
    },
    Storage: {
      region: configs.s3.REGION,
      bucket: configs.s3.BUCKET,
      identityPoolId: configs.cognito.IDENTITY_POOL_ID,
    },
    API: {
      endpoints: endpoints.map((service) => ({
        name: service,
        endpoint: configs.apiGateway.API_GATEWAY_URL,
        region: configs.apiGateway.REGION,
        custom_header: customHeader,
      })),
    },
  })

  initializeSentry()

  let AppWithFeatureFlags
  try {
    // we are blindly getting the cognito user from the browser cache if possible; don't spam the error logs if there is no user
    const cachedAuthUser = await getCognitoUser({ logFailure: false })
    AppWithFeatureFlags = FeatureFlagProvider(cachedAuthUser, App)
  } catch (error) {
    AppWithFeatureFlags = FeatureFlagProvider(null, App)
  }

  root.render(
    <AntDConfigProvider>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister }}
      >
        <ReactQueryDevtools initialIsOpen={false} />
        <Router>
          <AppWithFeatureFlags />
        </Router>
      </PersistQueryClientProvider>
    </AntDConfigProvider>
  )
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister()
})()
