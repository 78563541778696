import { useCallback, useEffect, useRef, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import cx from 'classnames'
import { useHistory } from 'react-router-dom'

import ConfirmationModal from '../../../components/Other/ConfirmationModal'
import useClinicalNoteFields, {
  UpdateType,
} from '../../../hooks/useClinicalNoteFields'
import {
  LeaveUnsavedNoteMsg,
  NoteType,
  formatSALTNote,
} from '../../../hooks/useClinicalNoteFields/utils'
import { usePatientAllergies } from '../../../hooks/usePatientAllergies'
import { usePatientClinicalNotes } from '../../../hooks/usePatientClinicalNotes'
import { usePatientDiagnoses } from '../../../hooks/usePatientDiagnoses'
import { usePatientDemographics } from '../../../hooks/usePatientInfo'
import { usePatientMedications } from '../../../hooks/usePatientMedications'
import { useProfileUrlParams } from '../../../hooks/useProfileUrlParams'
import { useProviderDetails } from '../../../hooks/useProviderInfo'
import { useProviderSidePatientData } from '../../../hooks/useProviderSidePatientData'
import useQueryString from '../../../hooks/useQueryString'
import { useSetClinicalNoteBackup } from '../../../hooks/useSetClinicalNoteBackup'
import { QUERY_PARAMS } from '../../../libs/constants'
import { useFeatureFlags } from '../../../libs/featureFlags'
import { useNoteFocusTracking } from '../../../libs/freshpaint/notesEvents'
import { NoteTypes } from '../../../shared-types'
import { InfoPage, Skeleton } from '../../../stories/BaseComponents'
import { StandardSkeletonRows } from '../../../stories/BaseComponents/Skeleton'
import { PatientHeader } from '../PatientHeader'
import { patientDataTypes } from '../patient-data-types'
import { ClinicalNoteContext } from './ClinicalNoteContext'
import NavigationGuardModal from './ClinicalNotesComponents/GuardModals/NavigationGuardModal'
import { CreateNoteHeaderV1 } from './ClinicalNotesComponents/NoteHeaderV1'
import { NoteHeaderV2 } from './ClinicalNotesComponents/NoteHeaderV2'
import { SpravatoGeneralAlert } from './ClinicalNotesComponents/NoteType/Spravato/SpravatoAlerts'
import NewClinicalNoteForm from './NewClinicalNoteForm'
import { getClinicalNotesUrl } from './utils'

import styles from '../../_shared.module.scss'
import './CreateClinicalNotePage.scss'

/**
 * This is a repurposed version of deprecated NewClinicalNoteButton.js de-modaled into a "Create Note Page".
 * Previous button component moved to AddNoteButton.tsx
 */
export default function CreateClinicalNotePage({
  healthGorillaUserName,
}: {
  healthGorillaUserName: string
}) {
  const history = useHistory()
  const headerRef = useRef<HTMLDivElement>(null)
  const { backupCadenceNotev1, autosaveDebounceNotev1Seconds } =
    useFeatureFlags()
  const { urlParams } = useProfileUrlParams()
  const reactQuery = useQueryClient()
  const query = useQueryString()
  const patientId = query.get(QUERY_PARAMS.patientId) ?? ''
  const providerId = query.get(QUERY_PARAMS.providerId) ?? ''
  const saltNoteId = query.get(QUERY_PARAMS.saltNoteId) ?? ''
  const [changesMade, setChangesMade] = useState(0)
  const [touched, setFormTouched] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [saltNote, setSaltNote] = useState(false)
  const [isGeneratingREMS, setIsGeneratingREMS] = useState(false)
  const [noteId, setNoteId] = useState('')
  const [navguardOpen, setNavguardOpen] = useState(false)
  const [spravatoModalOpen, setSpravatoModalOpen] = useState(false)
  //UI interactions states
  const [collapsedHeader, setCollapsedHeader] = useState(false)

  // Note backup triggers
  const saltIdForBackup = saltNoteId ? `SALT:${saltNoteId}` : undefined
  const hasPendingChanges = changesMade > 2 // One for setting default fields and another for actual user input

  const {
    setBackupFields,
    setIsNoteBackupChecked,
    deleteCache,
    setBackupFound,
  } = useSetClinicalNoteBackup(patientId, hasPendingChanges, saltIdForBackup)

  const { data: patientMedications } = usePatientMedications(patientId ?? '')

  const {
    isLoading: patientDataIsLoading,
    isError: patientDataIsError,
    data: patient,
  } = usePatientDemographics(patientId ?? '')

  // Should optimize this usage by separating a list-clinical-notes endpoint
  const {
    isLoading: providerSidePatientIsLoading,
    isError: providerSidePatientIsError,
    data: providerSidePatientData,
  } = useProviderSidePatientData(patientId ?? '')

  const { data: patientClinicalNotes } = usePatientClinicalNotes(
    patientId ?? ''
  )

  const {
    isLoading: patientDiagnosesIsLoading,
    isError: patientDiagnosesIsError,
    data: patientDiagnosesData,
  } = usePatientDiagnoses(patientId ?? '')

  const {
    isLoading: patientAllergiesIsLoading,
    isError: patientAllergiesIsError,
    data: patientAllergiesData,
  } = usePatientAllergies(patientId ?? '')

  const {
    isLoading: providerDetailsIsLoading,
    isError: providerDetailsIsError,
    data: providerDetails,
  } = useProviderDetails()

  const notesListUrl = getClinicalNotesUrl({ urlParams })

  const goToNotesList = () => {
    history.push(notesListUrl)
  }
  const updateCache = () => {
    // Should optimize this usage by setting up a separate cache for clinical notes outside of providerSidePatientData
    reactQuery.invalidateQueries([
      patientDataTypes.ProviderSidePatientData,
      patientId,
    ])
    reactQuery.invalidateQueries([
      patientDataTypes.PatientMedications,
      patientId,
    ])
    reactQuery.invalidateQueries([patientDataTypes.PatientDiagnoses, patientId])
    reactQuery.invalidateQueries([patientDataTypes.PatientAllergies, patientId])
    reactQuery.invalidateQueries([
      patientDataTypes.PatientClinicalNotes,
      patientId,
    ])
  }

  const updateCacheAndNavToNotesList = () => {
    updateCache()
    goToNotesList()
  }

  const updateCacheAndNavToViewNote = (noteId: string) => {
    updateCache()
    history.push(
      getClinicalNotesUrl({
        urlParams,
        noteId,
      })
    )
  }

  const handleClose = () => {
    setSaltNote(false)
  }

  const clinicalNoteData = useClinicalNoteFields({
    note: saltNote,
    patient: patient ?? {},
    patientId,
    previousNotes: patientClinicalNotes ?? [],
    updateType: UpdateType.NEW,
    clinicData: providerDetails?.clinicData ?? {},
    patientPhysicalNotes: providerSidePatientData?.PhysicalNotes ?? {},
    handleClose,
    setIsGeneratingREMS,
  })

  const {
    // state
    fields,
    isValid,
    isSaving,
    showUnsignedAndInvalid,
    showSignedAndValid,
    showSignedAndInvalid,
    // state setters
    setFields,
    resetFields,
    setShowUnsignedAndInvalid,
    setShowSignedAndValid,
    setShowSignedAndInvalid,
    // async fetch
    handleSave,
    hasAnyPendingChanges,
  } = clinicalNoteData

  useNoteFocusTracking({
    noteId: noteId ?? '',
    noteType: fields.NoteType,
    patientId: patientId,
    autosaveTiming: autosaveDebounceNotev1Seconds, // This should always be 0 here because this paged is not used with this flag on
  })

  useEffect(() => {
    if (showSignedAndValid || showUnsignedAndInvalid || showSignedAndInvalid) {
      setSpravatoModalOpen(true)
    }
  }, [showSignedAndValid, showUnsignedAndInvalid, showSignedAndInvalid])

  useEffect(() => {
    if (!headerRef.current) return // wait for the elementRef to be available
    const resizeObserver = new ResizeObserver(() => {
      if (!headerRef.current) return
      if (backupCadenceNotev1) {
        if (headerRef.current?.clientWidth < 620 && !collapsedHeader) {
          setCollapsedHeader(true)
        } else {
          setCollapsedHeader(false)
        }
      }
    })
    resizeObserver.observe(headerRef.current)
    return () => resizeObserver.disconnect() // clean up
  }, [headerRef.current])

  useEffect(() => {
    // Pre-populate fields when salt note or clinic data is available
    if (saltNote || providerDetails?.clinicData) {
      resetFields()
    }
  }, [saltNote, providerDetails?.clinicData])

  useEffect(() => {
    if (!headerRef.current) return // wait for the elementRef to be available
    const resizeObserver = new ResizeObserver(() => {
      if (!headerRef.current) return
      if (backupCadenceNotev1) {
        if (headerRef.current?.clientWidth < 620 && !collapsedHeader) {
          setCollapsedHeader(true)
        } else {
          setCollapsedHeader(false)
        }
      }
    })
    resizeObserver.observe(headerRef.current)
    return () => resizeObserver.disconnect() // clean up
  }, [headerRef.current])

  useEffect(() => {
    // Find and set salt note when patientClinicalNotes is available
    if (!patientClinicalNotes) {
      return
    }
    const saltNoteFound = patientClinicalNotes.find(
      ({ NoteId }) => saltNoteId === NoteId
    )

    if (saltNoteFound) {
      const formattedSaltNote = formatSALTNote(saltNoteFound)
      setSaltNote(formattedSaltNote)
    }
  }, [saltNoteId, patientClinicalNotes])

  const handleSaveNote = async (navigate = true) => {
    try {
      const { NoteId, shouldNavAway } = await handleSave(false)
      deleteCache()
      setSubmitted(true)

      if (NoteId && backupCadenceNotev1 && navigate && shouldNavAway) {
        return updateCacheAndNavToViewNote(NoteId.toString())
      }
      if (backupCadenceNotev1 && !navigate) {
        updateCache()
      }
      if (!backupCadenceNotev1 && navigate && shouldNavAway) {
        return updateCacheAndNavToNotesList()
      }
      setNoteId(NoteId ?? '')
      return updateCache()
    } catch (err) {
      setSubmitted(false)
      console.error(`Save failed with error: ${err}`)
    }
  }

  const setFieldsAndFormState = useCallback(
    (newFields: NoteType) => {
      if (!touched) setFormTouched(true)
      setFields(newFields)
    },
    [setFields, setFormTouched, touched]
  )

  const confirmUnsignedSpravato = () => {
    setShowUnsignedAndInvalid(false)
    if (backupCadenceNotev1 && !navguardOpen) {
      updateCacheAndNavToViewNote(noteId.toString())
    } else if (backupCadenceNotev1 && spravatoModalOpen) {
      setSpravatoModalOpen(false)
    } else {
      updateCacheAndNavToNotesList()
    }
  }

  const confirmSignedSpravato = () => {
    setShowSignedAndValid(false)
    if (backupCadenceNotev1 && !navguardOpen) {
      updateCacheAndNavToViewNote(noteId.toString())
    } else if (backupCadenceNotev1 && spravatoModalOpen) {
      setSpravatoModalOpen(false)
    } else {
      updateCacheAndNavToNotesList()
    }
  }

  const isSaveButtonDisabled =
    isSaving || fields.NoteType === '' || !fields.NoteDate

  useEffect(() => {
    if (fields) {
      setChangesMade((prev) => prev + 1)
      setBackupFields(fields)
    }
  }, [fields])

  const queriesLoading =
    patientDataIsLoading ||
    providerSidePatientIsLoading ||
    providerDetailsIsLoading ||
    patientDiagnosesIsLoading ||
    patientAllergiesIsLoading

  const queriesError =
    patientDataIsError ||
    providerSidePatientIsError ||
    patientDiagnosesIsError ||
    patientAllergiesIsError ||
    providerDetailsIsError
  const noQueryData = !patient || !providerSidePatientData || !providerDetails

  if (queriesError || !patientId || !providerId) {
    return (
      <InfoPage
        status="warning"
        title="Sorry there was a problem loading this page"
        details="Oops something went wrong. Please contact your Osmind representative if this issue persists."
        redirectButtonText="Return to Patient List"
        redirectLink="/"
      />
    )
  }

  const { clinicalNotes = [] } = providerSidePatientData ?? {}

  const { activeMeds: activeMeds = [] } = patientMedications ?? {}

  return (
    <div className={styles.scroll}>
      <PatientHeader
        providerId={providerId}
        patientId={patientId}
        healthGorillaUserName={healthGorillaUserName}
      />
      {backupCadenceNotev1 > 0 ? (
        <NoteHeaderV2
          headerRef={headerRef}
          collapsedHeader={collapsedHeader}
          patientId={patientId}
          noteId={saltIdForBackup}
          inViewMode
          inCreationMode
          isSaveButtonDisabled={isSaveButtonDisabled}
          areActionButtonsDisabled
          fields={fields}
          handleSaveNote={handleSaveNote}
          setFields={setFields}
          setIsNoteBackupChecked={setIsNoteBackupChecked}
          setBackupFound={setBackupFound}
          goToNotesList={goToNotesList}
          hasPendingChanges={hasPendingChanges && Boolean(fields.NoteType)}
          hasAutosavePendingChanges={hasAnyPendingChanges}
        />
      ) : (
        <></>
      )}
      <SpravatoGeneralAlert
        isVisible={
          fields.NoteType === NoteTypes.SPRAVATO &&
          fields.Signatures.length === 0
        }
      />

      <div className={'createClinicalNotePageContainer'}>
        {queriesLoading || noQueryData ? (
          <Skeleton paragraph={{ rows: StandardSkeletonRows.fullPage }} />
        ) : (
          <div className="note-page">
            {!backupCadenceNotev1 && (
              <a className="back-to-notes" href={notesListUrl}>
                &#60; Back to notes list
              </a>
            )}
            <div
              className={cx(
                'note-page-container',
                backupCadenceNotev1 && 'note-page-container-no-padding'
              )}
            >
              <NavigationGuardModal
                when={
                  !submitted &&
                  (touched || Boolean(saltNoteId)) &&
                  Boolean(fields?.NoteType)
                }
                content={LeaveUnsavedNoteMsg}
                onConfirmCallback={() => {
                  deleteCache()
                }}
                onSaveClick={handleSaveNote}
                blockRefreshAndClosing
                setNavguardOpen={setNavguardOpen}
                shouldBlockNav={spravatoModalOpen}
              />
              <ConfirmationModal
                title="Spravato® REMS form submission"
                okText="View Form"
                body={
                  <>
                    <p>
                      The Spravato® REMS Patient Monitoring form has been
                      automatically generated and will be submitted on your
                      behalf.
                    </p>
                  </>
                }
                size="lg"
                show={showSignedAndValid}
                onCancel={confirmSignedSpravato}
                onConfirm={() =>
                  window
                    .open(
                      `/patient/documents?patientId=${patientId}&providerId=${providerId}`,
                      '_blank'
                    )
                    ?.focus()
                }
                showIcon={false}
              />
              <ConfirmationModal
                title="Submit your Spravato® REMS form"
                okText="Confirm"
                body={
                  <>
                    <p>
                      The Spravato® REMS Patient Monitoring form has NOT been
                      submitted yet. You can manually submit the required
                      patient monitoring form here:&nbsp;
                      <a
                        className="spravato-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://spravatoremsenroll.com/"
                      >
                        https://spravatoremsenroll.com/
                      </a>
                    </p>
                  </>
                }
                size="lg"
                show={showSignedAndInvalid}
                onConfirm={confirmUnsignedSpravato}
                showIcon={false}
                singleButton
              />
              <ConfirmationModal
                title="Note Saved"
                okText="Confirm"
                body={
                  <>
                    <p>
                      The Spravato® REMS Patient Monitoring form has NOT been
                      submitted yet. Once you sign the note, the form will
                      automatically be generated and submitted on your behalf.
                      OR, you can manually submit the required patient
                      monitoring form here:&nbsp;
                      <a
                        className="spravato-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://spravatoremsenroll.com/"
                      >
                        https://spravatoremsenroll.com/
                      </a>
                    </p>
                  </>
                }
                size="lg"
                show={showUnsignedAndInvalid}
                onConfirm={confirmUnsignedSpravato}
                showIcon={false}
                singleButton
              />
              {!backupCadenceNotev1 && (
                <CreateNoteHeaderV1
                  saltNote={saltNote}
                  isSaveButtonDisabled={isSaveButtonDisabled}
                  handleSaveNote={handleSaveNote}
                />
              )}
              <ClinicalNoteContext.Provider value={clinicalNoteData}>
                <NewClinicalNoteForm
                  salt={Boolean(saltNoteId)}
                  fields={fields}
                  clinicInfo={providerDetails}
                  Diagnosis={patientDiagnosesData}
                  activeMeds={activeMeds}
                  patient={patient}
                  Allergies={patientAllergiesData}
                  previousNotes={clinicalNotes}
                  isValid={isValid}
                  isSaving={isSaving}
                  isGeneratingREMS={isGeneratingREMS}
                  setShowSignedAndValid={setShowSignedAndValid}
                  setShowSignedAndInvalid={setShowSignedAndInvalid}
                  handleFieldChange={setFieldsAndFormState}
                  handleSave={handleSaveNote}
                  handleClose={handleClose}
                  updateCache={updateCache}
                />
              </ClinicalNoteContext.Provider>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
