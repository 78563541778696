import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { format } from 'date-fns'
import moment from 'moment'

import { useFeatureFlags } from '../../libs/featureFlags'
import { NotesEvents, trackNotesEvent } from '../../libs/freshpaint/notesEvents'
import { Modal } from '../../stories/BaseComponents'
import {
  AutosaveTriggerCreateNoteKeyword,
  NoteType,
} from '../useClinicalNoteFields/utils'

export const useGetClinicalNoteBackup = (
  patientId: string,
  setFields: (newFields: NoteType) => void,
  setIsNoteBackupChecked: Dispatch<SetStateAction<boolean>>,
  setBackupFound: Dispatch<SetStateAction<boolean>>,
  noteType: string,
  noteId?: string,
  lastBackupDate?: string
) => {
  const queryClient = useQueryClient()
  const { autosaveDebounceNotev1Seconds } = useFeatureFlags()
  const [modalShow, setModalShow] = useState(false)
  const [restoreNoteBackup, setRestoreNoteBackup] = useState(false)
  const [backupDate, setBackupDate] = useState('')
  const [noteBackup, setNoteBackup] = useState<NoteType | null>(null)
  const [backupQueryKey, setBackupQueryKey] = useState<string[] | null>(null)

  const handleModalClose = () => {
    trackNotesEvent(NotesEvents.DISCARD_NOTE_BACKUP, {
      patientId,
      noteId: noteId ?? '',
      noteType,
    })
    setModalShow(false)
    setIsNoteBackupChecked(true)
    if (autosaveDebounceNotev1Seconds)
      queryClient.removeQueries(backupQueryKey ?? []) // If autosave enabled remove backup after restoring or discard backup
  }

  const handleShowModal = (backup: NoteType, backupDate: string) => {
    trackNotesEvent(NotesEvents.NOTE_BACKUP_DETECTED, {
      patientId,
      noteId: noteId ?? '',
      noteType,
    })
    setBackupDate(backupDate)
    setBackupFound(true)
    setNoteBackup(backup)
    setModalShow(true)
  }

  const getBackupOfCreatedOrSALTNote = async (noteId: string) => {
    const queryKey = noteId.includes('SALT:')
      ? ['SALT', noteId.split(':')[1]] // Backup from note creation of SALT note (copy)
      : ['unsaved', noteId] // Backup from note editing
    const backup: any = await queryClient.getQueryData(queryKey)
    return { backup, queryKey }
  }

  const getBackupOfNewNote = async () => {
    const queryKey = ['draft', patientId, noteType]
    const backup: any = await queryClient.getQueryData([
      'draft',
      patientId,
      noteType,
    ])
    return { backup, queryKey }
  }

  const checkNoteBackup = async () => {
    if (noteId && noteId !== AutosaveTriggerCreateNoteKeyword) {
      const { backup, queryKey } = await getBackupOfCreatedOrSALTNote(noteId)
      const noteDate = new Date(lastBackupDate ?? '')
      const backupDate = new Date(backup?.backupDate ?? '')
      const isBackupDateValid = !lastBackupDate ? true : backupDate > noteDate
      if (backup && isBackupDateValid) {
        setBackupQueryKey(queryKey)
        handleShowModal(backup.noteData, backup.backupDate)
      } else {
        setIsNoteBackupChecked(true)
      }
    }
    if (!noteId || (noteId === AutosaveTriggerCreateNoteKeyword && noteType)) {
      const { backup, queryKey } = await getBackupOfNewNote()

      if (backup) {
        setBackupQueryKey(queryKey)
        handleShowModal(backup.noteData, backup.backupDate)
      } else {
        setIsNoteBackupChecked(true)
      }
    }
  }

  useEffect(() => {
    if (restoreNoteBackup) {
      if (noteBackup) {
        trackNotesEvent(NotesEvents.RECOVER_NOTE_BACKUP, {
          patientId,
          noteId: noteId ?? '',
          noteType,
        })

        setFields({
          ...noteBackup,
          // Dont override the date and time values of the note
          NoteDate: moment().hour(12).minute(0).second(0).millisecond(0),
          StartTime: moment().add(1, 'minutes').format('HH:mm'),
          EndTime: moment().format('HH:mm'),
        })
        if (autosaveDebounceNotev1Seconds)
          queryClient.removeQueries(backupQueryKey ?? []) // If autosave enabled remove backup after restoring or discard backup
      }
      setIsNoteBackupChecked(true)
      setModalShow(false)
      setRestoreNoteBackup(false)
    }
  }, [restoreNoteBackup])

  const formatBackupDate = () => {
    if (backupDate) {
      const result = format(new Date(backupDate), 'MMMM dd, yyyy')
      return result
    }
    return ''
  }

  const formatBackupTime = () => {
    if (backupDate) {
      const result = format(new Date(backupDate), 'hh:mm a')
      return result
    }
    return ''
  }

  const NoteBackupModal = useMemo(() => {
    let cancelText
    let modalTitle
    let modalDescription
    let modalQuestion

    if (noteId?.includes('SALT:')) {
      cancelText = 'Discard and start a new copy'
      modalTitle = `Unsaved ${noteType} copy found`
      modalDescription = `an unsaved ${noteType} copy created on`
      modalQuestion = 'Would you like to restore this copy?'
    } else if (noteId && noteId !== AutosaveTriggerCreateNoteKeyword) {
      cancelText = 'Discard changes'
      modalTitle = 'Unsaved changes found'
      modalDescription = 'unsaved changes made on'
      modalQuestion = 'Would you like to restore these changes?'
    } else {
      cancelText = 'Discard and start a new note'
      modalTitle = `Unsaved ${noteType} found`
      modalDescription = `an unsaved ${noteType} created on`
      modalQuestion = 'Would you like to restore it?'
    }

    return (
      <Modal
        okText={'Restore'}
        cancelText={cancelText}
        cancelButtonProps={{ danger: true }}
        title={modalTitle}
        visible={modalShow}
        closable={false}
        onOk={() => setRestoreNoteBackup(true)}
        onCancel={handleModalClose}
      >
        <p>
          We found {modalDescription} <b>{formatBackupDate()}</b> at{' '}
          <b>{formatBackupTime()}.</b>
        </p>
        <p>{modalQuestion}</p>
      </Modal>
    )
  }, [modalShow])

  return {
    NoteBackupModal,
    checkNoteBackup,
    modalShow,
  }
}
