import { Level } from './types'

export const NOTE_QUERY_KEY = 'NOTES_V2'
export const NOTE_BLOCK_QUERY_KEY = 'NOTE_BLOCK'
export const NOTE_DETAILS_QUERY_KEY = 'NOTE_DETAILS'

export const ALLOWED_HEADINGS: Level[] = [1, 2, 3]

export const TITLE_PLACEHOLDER_NAVIGATION = 'No title'

export const TITLE_PLACEHOLDER = 'Add note title...'
export const HISTORY_DEPTH = 1000
