import { API } from 'aws-amplify'
import moment from 'moment'
import qs from 'querystring'

import { queryParamsToQueryStringParams } from '../libs/utils'

const CUSTOM_BILLING_CODE_ROUTE = '/billing/codes/custom'

// ATTN
// If you want to use an error message supplied by the BE, use this wrapper
// see: https://stackoverflow.com/questions/49633463/how-to-handle-api-errors-using-aws-amplify
// params:
//   api: string, the name of the Amplify/API Gateway api
//   endpoint: string, the route, eg /patients
//   body: object, eg { body: { param: "value" }, headers: { ... } }
const post = async (api, endpoint, body) =>
  await API.post(api, endpoint, body).catch((error) => {
    return Promise.reject(error?.response?.data?.error || error)
  })

const put = async (api, endpoint, body) =>
  await API.put(api, endpoint, body).catch((error) => {
    return Promise.reject(error?.response?.data?.error || error)
  })

// *** API.get ***
export function loadProviderSidePatientData(patientId) {
  return API.get(
    'api',
    `/legacy-api/v1/provider-side-patient-data/${patientId}`
  )
}

export function loadPatientInfo(patientId) {
  return API.get('api', `/legacy-api/v1/patient-info/${patientId}`)
}

export function loadProviderInfo() {
  return API.get('api', '/legacy-api/v1/provider-homepage-data')
}

export function getAllPatients(usePreferredName) {
  return API.get('core-api', '/patients-list', {
    queryStringParameters: { usePreferredName },
  })
}

export function getPatientInsuranceCards(patientId) {
  return API.get('billing', `/billing/insurance/cards/${patientId}`)
}

export async function loadProvidersForPractice(practiceName) {
  if (!practiceName) return []
  return await API.post('core-api', `/provider/by-practice`, {
    body: { practiceName },
  })
}

export async function getStripePublishableKey() {
  return await API.get('billing', '/billing/stripe/public-key')
}

export async function createPaymentIntent(
  amountCents,
  patientId,
  paymentMethodUuid = null
) {
  const paymentIntentData = {
    amountCents,
    patientId,
    paymentMethodUuid,
  }
  return await post('billing', '/billing/stripe/payment-intent', {
    body: paymentIntentData,
  })
}

export async function updatePaymentIntent(paymentIntentId, data) {
  const body = {
    paymentIntentId,
    ...data,
  }

  return await put('billing', '/billing/stripe/payment-intent', { body })
}

export async function getPOSCodes() {
  return await API.get('billing', '/billing/codes/pos')
}

export function getGoogleOAuthUrl() {
  return API.get('scheduling', '/calendar/account/connect')
}

export function getIntegratedCalendarsForCurrentProvider() {
  return API.get('scheduling', '/calendar/integrated/provider')
}

export async function getRCopiaSSOUrl(patientRCopiaId) {
  const data = await API.get(
    'erx',
    `/patientSSO?patientRCopiaId=${patientRCopiaId}`
  )
  return data
}

export async function getErxNotificationCount() {
  const data = await API.get('erx', '/notifications')
  return data
}

export async function getCustomBillingCode(termToSearch) {
  const query = new URLSearchParams({ termToSearch })
  const route = `${CUSTOM_BILLING_CODE_ROUTE}/get?${query}`
  return await API.get('billing', route)
}

export async function getAllCustomBillingCodes() {
  const route = `${CUSTOM_BILLING_CODE_ROUTE}/getAll`
  return await API.get('billing', route)
}

export async function getStripeOnboardingLink() {
  return await API.get('billing', '/billing/stripe/authorize')
}

export async function getStripeLoginLink() {
  return await API.get('billing', '/billing/stripe/login')
}

export async function finalizeStripeOnboarding(stripeCode) {
  return await API.post('billing', '/billing/stripe/finalize', {
    body: { stripeCode },
  })
}

export async function getStripeAccountStatus() {
  return await API.get('billing', '/billing/stripe/status')
}

export async function getInvoices(patientId) {
  let url = '/billing/invoices'
  if (patientId) {
    url += `?patientId=${patientId}`
  }
  return await API.get('billing', url)
}

export async function getInvoice(invoiceUuid, queryParams = {}) {
  const url = `/billing/invoice/${invoiceUuid}`
  return await API.get('billing', url, {
    queryStringParameters: queryParamsToQueryStringParams(queryParams),
  })
}

export async function generateInvoicePDF(invoiceUuid) {
  const url = `/billing/invoice/${invoiceUuid}`
  return await API.post('billing', url)
}
export async function getInvoiceByNoteAndPatient(
  noteId,
  patientId,
  queryParams = {}
) {
  const url = `/billing/invoice-by-note/${noteId}/${patientId}`
  return await API.get('billing', url, {
    queryStringParameters: queryParamsToQueryStringParams(queryParams),
  })
}

export async function getNewInvoiceFormData(patientId, queryParams = {}) {
  const url = `/billing/invoices/new`
  return await API.get('billing', url, {
    queryStringParameters: {
      ...queryParamsToQueryStringParams(queryParams),
      patientId,
    },
  })
}

/**
 * @param {Object} params - The parameters for the function.
 * @param {string} [params.patientId] - The ID of the patient.
 * @param {string | null} [params.invoiceUuid] - The UUID of an invoice.
 * @param {string| null} [params.insuranceClaimUuid] - The UUID of an insurance claim.
 */
export async function getPaymentTransactions(params) {
  let url = '/billing/payment-transactions'
  if (params) {
    url += `?${qs.stringify(params)}`
  }
  return await API.get('billing', url)
}

export async function getClinicBillingSummaryData() {
  return await API.get('billing', '/billing/summary')
}

export async function getPaymentsSummary(start, end) {
  return await API.get('billing', '/billing/invoices/payments-summary', {
    queryStringParameters: { start, end },
  })
}

export async function getBillingPatientList(
  pageNumber,
  searchString,
  pageSize
) {
  return await API.get(
    'billing',
    `/billing/invoices/patients?pageNumber=${pageNumber}&pageSize=${pageSize}${
      searchString !== '' ? `&searchString=${searchString}` : ''
    }
    `
  )
}

export async function getPaymentMethods(patientId) {
  const url = `/billing/payment-methods?patientId=${patientId}`
  return await API.get('billing', url)
}

export async function createPaymentMethod(paymentMethodData) {
  return await API.post('billing', '/billing/payment-method', {
    body: paymentMethodData,
  })
}

/*
  params: {
    code: string
    description: string
    shortDescription: string
  }
*/
export async function createCustomBillingCode({ code, shortDescription }) {
  const data = {
    code,
    shortDescription,
    description: shortDescription,
  }
  const route = `${CUSTOM_BILLING_CODE_ROUTE}/create`
  return await post('billing', route, { body: data })
}

/*
  params:
    update: {
      code: string
      description: string
      shortDescription: string
    }
    codeToUpdate: string
*/
export async function updateCustomBillingCode({ update, codeToUpdate }) {
  const data = {
    ...update,
    codeToUpdate,
  }
  const route = `${CUSTOM_BILLING_CODE_ROUTE}/update`
  return await API.put('billing', route, { body: data })
}

export async function deleteCustomBillingCode(code) {
  const data = { code }
  const route = `${CUSTOM_BILLING_CODE_ROUTE}/del`
  return await API.put('billing', route, { body: data })
}

export async function searchBillingCodes(
  terms = '',
  code = '',
  description = ''
) {
  const params = {}

  if (terms.length) params.terms = terms
  if (code.length) params.code = code
  if (description.length) params.description = description

  const query = new URLSearchParams(params)
  return await API.get('billing', `/billing/codes/search?${query}`)
}

export async function getCaretakerData(shortPublicId, date) {
  const data = await API.get(
    'caretaker',
    `/getCaretakerData/average?publicId=${shortPublicId}&date=${date}`
  )
  return data
}

export async function fetchOrInitializeSuperbill(
  patientId,
  noteId,
  invoiceUuid
) {
  return await API.get(
    'billing',
    `/billing/superbill/${patientId}/${noteId}/${invoiceUuid}`
  )
}

export async function updateSuperbill(updatedSuperbill) {
  return await API.put('billing', `/billing/superbill`, {
    body: updatedSuperbill,
  })
}

// params: { id: superbillId, submitToAmd: boolean }
export async function finalizeSuperbill(params) {
  return await post('billing', '/billing/superbill/finalize', {
    body: params,
  })
}

// params: { id: cognitoId, billingNPI?: string }
export async function connectProviderToAmd(params) {
  return await post('billing', '/billing/advancedmd/connect-provider', {
    body: params,
  })
}

export async function updateInvoice(params) {
  return await API.put('billing', `/billing/invoices`, {
    body: params,
  })
}

export async function createInvoice(params, queryParams = {}) {
  return await API.post('billing', `/billing/invoices`, {
    body: params,
    queryStringParameters: queryParamsToQueryStringParams(queryParams),
  })
}

export async function getTeammateData() {
  return await API.get('core-api', `/users`, {})
}

export async function getPersonalSettings(userId) {
  const personalSettings = await API.get(
    'core-api',
    `/personalsettings/${userId}`
  )
  return personalSettings
}

export async function getAppointmentSettings() {
  return await API.get('scheduling', '/appointment/settings')
}

export async function getCalendarTimezone() {
  return await API.get('scheduling', '/calendar/timezone')
}

// *** API.post ***/

export function sendPatientInvite(data) {
  return API.post('api', '/legacy-api/v1/patient-invite', { body: data })
}

export async function createAppointmentType(data) {
  return API.post('scheduling', '/appointment/settings', { body: data })
}

export async function connectUserCalendar(body) {
  return API.post('scheduling', '/calendar/account/connect', { body })
}

export async function updateUserCalendarName(body) {
  return API.post('scheduling', '/calendar/name', { body })
}

export async function updateCalendarAccountSettings(body) {
  return API.post('scheduling', '/calendar/settings', { body })
}

export function updateProviderSurveySettings(data) {
  data.UpdateEnum = 'UpdateProviderSurveySettings'
  return API.post('api', '/legacy-api/v1/provider-questionnaire-settings', {
    body: data,
  })
}

export function putNewScore(data) {
  data.ProviderSide = true
  return API.put('api', `/scores/${data.PatientId}`, { body: data })
}

export function updateSurveySettings(body) {
  return API.put('core-api', '/practice/questionnaire_settings', { body })
}

export function putNewActiveMedication(data) {
  data.UpdateEnum = 'NewActive'
  return API.post('api', '/meds', { body: data })
}

export function putNewPastMedication(data) {
  data.UpdateEnum = 'NewPast'
  return API.post('api', '/meds', { body: data })
}

export function updateActiveMedication(data) {
  data.UpdateEnum = 'UpdateActive'
  return API.post('api', '/meds', { body: data })
}

export function putRetireActiveMed(data) {
  data.UpdateEnum = 'RetireActive'
  return API.post('api', '/meds', { body: data })
}

export function printPatientProgress(body) {
  return API.post('core-api', '/pdf/progress', { body })
}

// how was that working? Maybe because I hadn't removed it from core-api first?
export function submitNewOtherNotesRequest(body) {
  return API.post('patient-profile', '/profile/other', { body })
}

export function submitEditOtherNotesRequest(body) {
  return API.put('patient-profile', '/profile/other', { body })
}

export async function updatePersonalSettings(body) {
  return API.put('core-api', '/personalsettings', { body })
}

export function submitNewDevelopmentalHistoryRequest(body) {
  return API.post('patient-profile', '/profile/developmentalHistory', { body })
}

export function submitEditDevelopmentalHistoryRequest(body) {
  return API.put('patient-profile', '/profile/developmentalHistory', { body })
}

export function submitNewFamilyHistoryRequest(body) {
  return API.post('patient-profile', '/profile/familyHistory', { body })
}

export function submitEditFamilyHistoryRequest(body) {
  return API.put('patient-profile', '/profile/familyHistory', { body })
}

export function submitNewPhysicalNotesRequest(body) {
  return API.post('patient-profile', '/profile/physical', { body })
}

export function submitEditPhysicalNotesRequest(body) {
  return API.put('patient-profile', '/profile/physical', { body })
}

export function submitNewMedicalSurgicalHistoryRequest(body) {
  return API.post('patient-profile', '/profile/medicalSurgical', { body })
}

export function submitEditMedicalSurgicalHistoryRequest(body) {
  return API.put('patient-profile', '/profile/medicalSurgical', { body })
}

export function submitNewSocialHistoryNotesRequest(body) {
  return API.post('patient-profile', '/profile/socialHistory', { body })
}

export function submitEditSocialHistoryNotesRequest(body) {
  return API.put('patient-profile', '/profile/socialHistory', { body })
}

export function submitNewGeneralHistoryRequest(body) {
  return API.post('patient-profile', '/profile/demographics', { body })
}

export function submitNewImmunizationsRequest(body) {
  return API.post('patient-profile', '/profile/immunizations', { body })
}

export function submitEditImmunizationsRequest(body) {
  return API.put('patient-profile', '/profile/immunizations', { body })
}

export function submitUpdatePatientReportedDiagnosesRequest(patientId, body) {
  // since PatientReported diagnoses calls an upsert call in backend, call the create endpoint
  return API.post('patient-profile', `/patients/${patientId}/diagnoses`, {
    body,
  })
}

export function updateSendbirdChannelName(channelUrl, patientName) {
  const data = {
    patientName: patientName,
    channelUrl: channelUrl,
    updateSendbirdChannel: true,
  }
  return API.put('patient-profile', '/patients', { body: data })
}

export function updateChannelStatus(channelUrl, unread) {
  const body = {
    channelUrl,
    unread,
  }
  return API.post('core-api', '/chat/status', { body })
}

export async function sendPushNotifViaApi(patientId) {
  const data = { PatientId: patientId, NewMessage: 'true' }
  return API.post('api', '/device', { body: data })
}

export function deletePastMed(data) {
  data.UpdateEnum = 'DeletePast'
  return API.post('api', '/meds', { body: data })
}

export function putPatientSettings(data) {
  return API.post('api', '/legacy-api/v1/patient-questionnaire-settings', {
    body: data,
  })
}

export function deleteClinicalNote(data) {
  data.UpdateEnum = 'delete'
  return API.post('api', '/clinicalNote', { body: data })
}

// This function is used by DetailClinicalNoteForm
export function editClinicalNote(data) {
  data.NoteDate = moment(data.NoteDate)
  return API.post('api', '/clinicalNote', { body: data })
}

// This function is used by EditClinicalNoteForm
export function editClinicalNote2(data) {
  // TODO: merge function wiht editClinicalNote. ATTENTION with date format
  data.NoteDate = moment(data.NoteDate).format('ddd MMM DD YYYY')
  return API.post('api', '/clinicalNote', { body: data })
}

// This function is used by Addendums
export function saveNewAddendum(data) {
  data = { ...data, UpdateEnum: 'newAddendum' }
  return API.post('api', '/clinicalNote', { body: data })
}

export function saveNewSignatures(data) {
  data.UpdateEnum = 'newSignatures'
  return API.post('api', '/clinicalNote', { body: data })
}

export function generateRemsPDF(data) {
  return API.post('core-api', '/spravato/pdf', { body: data })
}

export function createNewClinicalNote(data) {
  data.NoteDate = moment(data.NoteDate)
  return API.post('api', '/clinicalNote', { body: data })
}

export function printClinicalNote(data) {
  data.UpdateEnum = 'print'
  return API.post('api', '/clinicalNote', { body: data })
}

export async function getProviderInformation() {
  const provInfo = await API.post('api', '/device', {
    body: { getProvider: true },
  })
  return provInfo
}

export async function changeEmailMessageEnable(providerEmail, isEnabled) {
  const data = {
    allowProviderEmailSetting: true,
    providerEmail: providerEmail,
    isEmailNotificationsEnabled: isEnabled,
  }
  const delivered = API.post('api', '/device', { body: data })
  return delivered
}

export function createLocation(data) {
  return API.post('scheduling', '/location', { body: data })
}

// this is actually get ALL locations for the provider
// should be changed to getLocations and /locations during osmo-http migration
export function getLocation() {
  return API.get('scheduling', '/location')
}

export function updateLocation(data) {
  return API.put('scheduling', '/location', { body: data })
}

export function createScheduledEvent(data) {
  return API.post('scheduling', '/scheduling', { body: data })
}

export async function getBillingTemplates(search) {
  return API.get('billing', '/billing/template', {
    queryStringParameters: {
      name: search,
    },
  })
}

export function createBillingProfile(data) {
  return API.post('billing', '/billing/profile', { body: data })
}

export async function addBillingTemplate(data) {
  return await API.post('billing', '/billing/template', { body: data })
}

export async function addBillingTemplateCode(data) {
  return await API.post('billing', '/billing/template/code', { body: data })
}

export function registerPatientForErx(data) {
  return API.post('erx', '/newErxPatient', { body: data })
}

export function updatePatientDemographicsErx(data) {
  return API.post('erx', '/updateErxPatient', { body: data })
}

export function sendFeatureEnablementEmail(data) {
  return API.post('core-api', '/feature/enable', { body: data })
}

export async function syncRCopiaAllergies(data) {
  return API.post('erx', '/syncAllergies', { body: data })
}

export async function syncRCopiaMeds(data) {
  return API.post('erx', '/syncMedications', { body: data })
}

export function updatePatientEmail(cognitoId, newEmail, oldEmail) {
  const data = {
    cognitoId,
    oldPatientEmail: oldEmail,
    newPatientEmail: newEmail,
  }
  return API.post('patient-profile', '/profile/demographics/email', {
    body: data,
  })
}

export async function getAmdConnection() {
  return API.get('billing', '/billing/advancedmd')
}

export async function connectAmd(values) {
  return put('billing', '/billing/advancedmd', { body: values })
}

export function markNoteAsBeingEdited(data) {
  const body = { ...data, UpdateEnum: 'beingEdited' }
  return API.post('api', '/clinicalNote', { body })
}

export function markNoteAsNotBeingEdited(data) {
  const body = { ...data, UpdateEnum: 'notBeingEdited' }
  return API.post('api', '/clinicalNote', { body })
}

export function getNoteData(data) {
  const body = { ...data, UpdateEnum: 'getData' }
  return API.post('api', '/clinicalNote', { body })
}

export function markForcedEndEditComplete(data) {
  return API.post('api', '/clinicalNote', { body: data })
}

export function saveApprovedJotform(data) {
  return API.post('api', '/jotform/save', { body: data })
}

export function updateJotformViewedStatus(data) {
  return API.post('api', '/jotform/update', { body: data })
}

export function sendNewTeammateInvite(data) {
  return API.post('core-api', '/teammate/invite', { body: data })
}

export function activateNewUser(data) {
  return API.post('core-api', '/teammate/active', { body: data })
}

export function verifyNewUserInvite(data) {
  return API.post('core-api', '/teammate/verify-invite', { body: data })
}

export function activateNewPractice(data) {
  return API.post('core-api', '/practice/activate', { body: data })
}

// *** API.put ***

export function emailMessage(data) {
  data.EmailPatientProgress = true
  return API.put('patient-profile', '/patients', { body: data })
}

export function editAllergy(allergyId, patientId, data) {
  return API.put(
    'patient-profile',
    `/patients/${patientId}/allergies/${allergyId}`,
    { body: data }
  )
}

export function createNewAllergy(patientId, data) {
  return API.post('patient-profile', `/patients/${patientId}/allergies`, {
    body: data,
  })
}

// TODO... do I want to keep this endpoint as is?
export function createNoKnownAllergies(noKnownAllergiesList, patientId) {
  const data = {}
  data.NoKnownAllergiesList = noKnownAllergiesList
  data.PatientId = patientId
  data.PatientAllergy = true
  data.UpdateEnum = 'newNoKnown'
  return API.put('patient-profile', '/patients', { body: data })
}

export function editPatientReportedAllergy(
  patientReportedAllergies,
  patientId,
  patientReportedAllergiesId
) {
  const body = {
    AllergyId: patientReportedAllergiesId,
    PatientId: patientId,
    Allergies: patientReportedAllergies,
    PatientReported: true,
  }
  return API.put(
    'patient-profile',
    `/patients/${patientId}/allergies/${patientReportedAllergiesId}`,
    { body }
  )
}

export function editPatientReportedMedication(data) {
  data.UpdateEnum = 'PatientReportedMedication'
  return API.put('patient-profile', '/patients', { body: data })
}

export async function updateAppointmentType(data) {
  return API.put('scheduling', '/appointment/settings', { body: data })
}

export function submitDeletePatientDocument(data) {
  data.DeletePatientDocument = true
  return API.put('patient-profile', '/patients', { body: data })
}

export function uploadSuperbillDoc(data) {
  return API.post('core-api', '/superbill/update', { body: data })
}

export function submitErxDemographics(data) {
  data.UpdateEnum = 'PatientDemographicsUpdate'
  // TODO: This isn't really working properly right now--should be replaced
  return API.put('patient-profile', '/patients', { body: data })
}

export function submitNewPatientDocument(data) {
  data.NewPatientDocument = true
  return API.put('patient-profile', '/patients', { body: data })
}

export function submitNewPsychHistoryRequest(data) {
  return API.post('patient-profile', '/psychHistory', { body: data })
}

export function submitEditPsychHistoryRequest(data) {
  return API.put('patient-profile', '/psychHistory', { body: data })
}

export function updateActiveStatus(data) {
  return API.put('patient-profile', '/patients', { body: data })
}

export function updateScheduledEvent(eventId, event) {
  return API.put('scheduling', `/scheduling/${eventId}`, { body: event })
}

export async function updateBillingTemplateCode(data) {
  return await API.put('billing', '/billing/template/code', { body: data })
}

export async function updateBillingTemplateName(data) {
  return await API.put('billing', '/billing/template', { body: data })
}

export function updateBillingProfile(data) {
  return API.put('billing', '/billing/profile', { body: data })
}

export function updatePracticeSettings(data) {
  return API.put('core-api', '/practice/settings', { body: data })
}

export function updatePracticeLogo(data) {
  return API.put('core-api', '/practice/logo', { body: data })
}

// TODO: deprecate? Or do we want to refactor our code to call backend instead?
export function updatePatientImage(data) {
  return API.put('core-api', '/patient/image', { body: data })
}

export async function deletePracticeLogo() {
  return await API.del('core-api', '/practice/logo')
}

export async function updateUserActivationStatus(body) {
  return API.put('core-api', '/users/status', { body })
}

export function updateCalendarTimezone(data) {
  return API.put('scheduling', '/calendar/timezone', { body: data })
}
export function updatePracticeTimezone(data) {
  return API.put('providers', '/practices/timezone', { body: data })
}

// *** API.delete ***

export function deleteAllergy(allergyId, patientId) {
  return API.del(
    'patient-profile',
    `/patients/${patientId}/allergies/${allergyId}`
  )
}

export function deleteScheduledEvent(
  eventId,
  changeEventActionType,
  startTime,
  internalEventDetailsId
) {
  return API.del(
    'scheduling',
    `/scheduling/${eventId}/${changeEventActionType}`,
    {
      body: {
        startTime,
        eventDetailsId: internalEventDetailsId,
      },
    }
  )
}

export function deleteBillingProfile(profileId) {
  return API.del('billing', `/billing/profile/${profileId}`)
}

export function deleteLocation(locationId) {
  return API.del('scheduling', `/location/${locationId}`)
}

export function deleteQuestionnaireSubmission(id, type) {
  return API.del('core-api', `/questionnaire/submission/${id}/type/${type}`)
}

export function deleteAppointmentType(appointmentTypeId) {
  return API.del('scheduling', `/appointment/settings/${appointmentTypeId}`)
}

export function deleteUserCalendarAccount(data) {
  return API.post('scheduling', '/calendar/account/delete', { body: data })
}

export async function deleteBillingTemplate(templateId) {
  return await API.del('billing', `/billing/template?templateId=${templateId}`)
}

export async function deleteBillingTemplateCode(codeId) {
  return await API.del(
    'billing',
    `/billing/template/code?billingTemplateCodeId=${codeId}`
  )
}

export async function saveCareTeam(data) {
  return API.post('core-api', '/care-team', { body: data })
}

export async function createPayment(data) {
  return post('billing', '/billing/payment', { body: data })
}

export async function applyPayment(data) {
  return post('billing', '/billing/invoice-payment', { body: data })
}

export async function getPaymentAttempt(paymentAttemptUuid, attemptNumber) {
  return API.get(
    'billing',
    `/billing/payment-attempt/${paymentAttemptUuid}?attemptNumber=${attemptNumber}`
  )
}

export async function deleteInvoice(invoiceUuid) {
  return await API.del('billing', `/billing/invoices/delete/${invoiceUuid}`)
}

export async function refundStripePayment(data) {
  return await API.post('billing', '/billing/stripe/refund-payment-intent', {
    body: data,
  })
}

export async function createInternalRefund(data) {
  return await API.post('billing', '/billing/refund', { body: data })
}

export async function getPaymentLockStatus(patientId) {
  return API.get('billing', `/billing/payment-lock?patientId=${patientId}`)
}

export async function setPaymentLockStatus(patientId, status, originPoint) {
  const data = {
    patientId,
    lockStatus: status,
    originPoint,
  }
  return API.post('billing', '/billing/payment-lock', { body: data })
}

export async function updateInsurance(
  primaryInsurance,
  secondaryInsurance,
  patientId
) {
  const data = {
    primaryInsurance,
    patientId,
  }
  if (Object.keys(secondaryInsurance).length) {
    data.secondaryInsurance = secondaryInsurance
  }
  return API.post('core-api', '/patient/insurance', { body: data })
}
