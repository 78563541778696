import React, { useEffect, useState } from 'react'

import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Form } from 'antd'

import { sendNewTeammateInvite } from '../../api/api-lib'
import { getRoles } from '../../api/permissions'
import { useHasPermissions } from '../../hooks/useHasPermissions'
import { onError } from '../../libs/errorLib'
import { useFeatureFlags } from '../../libs/featureFlags'
import { notification } from '../../libs/notificationLib'
import { RoleTitles } from '../../shared-types'
import {
  Button,
  Checkbox,
  Divider,
  Input,
  Modal,
  Row,
  Tooltip,
} from '../BaseComponents'
import ProviderInfoBox from './ProviderInfoBox'
import { WRITE_PERMISSIONS } from './_constants'

import styles from './TeammateModals.module.scss'

interface Props {
  masterProviderId: string
  users: any[]
  refresh(): any
  storybook?: boolean
}

const NewTeammateModal: React.FC<Props> = ({
  masterProviderId,
  users,
  refresh,
  storybook = false,
}) => {
  const [isInvitingTeammate, setIsInvitingTeammate] = useState<boolean>(false)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [roleOptions, setRoleOptions] = useState<RoleTitles[]>()
  const [requiredSelectedRoles, setRequiredSelectedRoles] = useState<number[]>(
    []
  )
  const [nonRequiredSelectedRoles, setNonRequiredSelectedRoles] = useState<
    number[]
  >([])
  const [providerRole, setProviderRole] = useState<boolean>(false)
  const [clinicalStaffRole, setClinicalStaffRole] = useState<boolean>(false)
  const [nonClinicalStaffRole, setNonClinicalStaffRole] =
    useState<boolean>(false)
  const [superAdminRole, setSuperAdminRole] = useState<boolean>(false)
  const [adminRole, setAdminRole] = useState<boolean>(false)
  const [form] = Form.useForm()

  const { userManagement } = useFeatureFlags()
  const hasAdminUserPermission = useHasPermissions({
    adminUsers: WRITE_PERMISSIONS,
  })

  useEffect(() => {
    async function getMainRoles() {
      const rolesFound = await getRoles()
      const rolesOptions = []
      for (const r of rolesFound) {
        rolesOptions.push({
          label: r.title as string,
          value: r.roleId as number,
        })
      }
      setRoleOptions(rolesOptions)
    }
    getMainRoles()
  }, [])

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    form.resetFields()
    setRequiredSelectedRoles([])
    setNonRequiredSelectedRoles([])
    setProviderRole(false)
    setClinicalStaffRole(false)
    setNonClinicalStaffRole(false)
    setSuperAdminRole(false)
    setAdminRole(false)
    setIsModalVisible(false)
  }

  async function handleInviteTeammate(
    newEmail: string,
    masterProviderId: string
  ) {
    const user: any = users.find(
      (user) => user.email === newEmail.toLowerCase()
    )
    let isResendInviteRequest = false
    let ProviderId = masterProviderId
    if (user) {
      if (user.isDeactivated || user.lastActive !== 'never_logged_in') {
        return
      }
      isResendInviteRequest = true
      const reInviteProvider = users.find((u) => u.email === newEmail)
      ProviderId = reInviteProvider.cognitoId
    } else if (!newEmail || newEmail.trim() === '') {
      return
    }
    const viewAllPatientsRole = roleOptions?.find(
      (role) => role.label === 'View all patients'
    )
    if (viewAllPatientsRole) {
      requiredSelectedRoles.push(viewAllPatientsRole.value)
    }

    setIsInvitingTeammate(true)
    try {
      const sendInviteRequest = {
        ProviderId: ProviderId,
        ProviderEmail: newEmail.toLowerCase(),
        RoleIds: requiredSelectedRoles.concat(nonRequiredSelectedRoles),
        isResendInviteRequest,
      }
      await sendNewTeammateInvite(sendInviteRequest)
      setIsModalVisible(false)
      notification(
        `Invite has successfully been sent to ${newEmail}.`,
        'success'
      )
      refresh()
    } catch (e) {
      onError(
        e,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
    } finally {
      setIsInvitingTeammate(false)
      handleCancel()
    }
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (
          values['email'] &&
          (!userManagement || requiredSelectedRoles.length)
        ) {
          if (storybook) return
          handleInviteTeammate(values['email'], masterProviderId)
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }

  const handleModalSubmitState = (e: any) => {
    e.preventDefault()
    if (e.target.value !== '') {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }

  function setChecks(check: boolean, role: string) {
    switch (role) {
      case 'Provider':
        setProviderRole(check)
        break
      case 'Clinical staff':
        setClinicalStaffRole(check)
        break
      case 'Non-clinical':
        setNonClinicalStaffRole(check)
        break
      case 'Super-Admin':
        setSuperAdminRole(check)
        break
      case 'Admin':
        setAdminRole(check)
        break
    }
  }

  function handleRequiredRoleChange(role: string) {
    const checkedRole = roleOptions?.find((rO) => rO.label === role)
    if (!checkedRole) return
    let shouldCheck = false
    if (requiredSelectedRoles.includes(checkedRole.value)) {
      const rolesToSave = requiredSelectedRoles.filter((sR) => {
        if (sR !== checkedRole.value) {
          return sR
        }
      })
      setRequiredSelectedRoles(rolesToSave)
    } else {
      setRequiredSelectedRoles([...requiredSelectedRoles, checkedRole.value])
      shouldCheck = true
    }
    setChecks(shouldCheck, role)
  }

  function handleNonRequiredRoleChange(role: string) {
    const checkedRole = roleOptions?.find((rO) => rO.label === role)
    if (!checkedRole) return
    let shouldCheck = false
    if (nonRequiredSelectedRoles.includes(checkedRole.value)) {
      const rolesToSave = nonRequiredSelectedRoles.filter((sR) => {
        if (sR !== checkedRole.value) {
          return sR
        }
      })
      setNonRequiredSelectedRoles(rolesToSave)
    } else {
      setNonRequiredSelectedRoles([
        ...nonRequiredSelectedRoles,
        checkedRole.value,
      ])
      shouldCheck = true
    }
    setChecks(shouldCheck, role)
  }

  return (
    <>
      {useHasPermissions({
        adminUsers: WRITE_PERMISSIONS,
        nonAdminUsers: WRITE_PERMISSIONS,
      }) ? (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={showModal}
          className="antd-center-button"
        >
          Add New
        </Button>
      ) : (
        <Tooltip
          placement="bottom"
          title="You do not have permissions to add/edit teammate"
        >
          <span style={{ cursor: 'not-allowed' }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              disabled
              onClick={showModal}
              className="antd-center-button"
              style={{ pointerEvents: 'none' }}
            >
              Add New
            </Button>
          </span>
        </Tooltip>
      )}
      <Modal
        title="Add user"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <div className="antd-modal-footer">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              id="inviteNewTeammate"
              type="primary"
              onClick={handleOk}
              loading={isInvitingTeammate}
              disabled={
                isDisabled || (!requiredSelectedRoles.length && userManagement)
              }
            >
              Send Invite
            </Button>
          </div>,
        ]}
      >
        <ProviderInfoBox isProvider={providerRole} />
        <Form form={form} layout="vertical" initialValues={{ email: '' }}>
          <span style={{ marginBottom: '10px' }}>
            The user's account will be created, and they will receive an email
            containing information to join Osmind.
          </span>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                type: 'email',
                message: 'Not a valid email.',
                required: true,
              },
              () => ({
                validator(_, value) {
                  const user = users.find((usr) => usr.email === value)
                  if (!user || user.lastActive === 'never_logged_in') {
                    return Promise.resolve()
                  } else if (user?.isDeactivated) {
                    return Promise.reject(
                      new Error(
                        'This user was already invited but deactivated. Reactivate to add user.'
                      )
                    )
                  } else {
                    return Promise.reject(
                      new Error(
                        'This user already exists and was invited/activated successfully.'
                      )
                    )
                  }
                },
              }),
            ]}
          >
            <Input
              placeholder={`Input new user's email address`}
              disabled={isInvitingTeammate}
              onChange={handleModalSubmitState}
            />
          </Form.Item>
          {userManagement ? (
            <>
              <Divider
                orientationMargin="0"
                style={{ marginTop: '26px', marginBottom: '26px' }}
              />
              <Form.Item
                required={!!userManagement}
                label={
                  <>
                    <span>Select user role(s) </span>
                    <a
                      href={
                        'https://support.osmind.org/en/articles/6446843-user-roles-permissions'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.linkText}
                    >
                      See role definitions
                    </a>
                  </>
                }
              >
                <Row
                  onClick={() => handleRequiredRoleChange('Provider')}
                  className={styles.row}
                >
                  <Checkbox checked={providerRole} />
                  <span className={styles.spanRow}>Provider</span>
                  <Tooltip
                    className="tooltipRow"
                    title="The Provider role has access to all standard Osmind EHR features, in addition to signing and finalizing a note and being listed as the rendering provider on a note and superbill."
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Row>
                <Row
                  onClick={() => handleRequiredRoleChange('Clinical staff')}
                  className={styles.row}
                >
                  <Checkbox checked={clinicalStaffRole} />
                  <span className={styles.spanRow}>Clinical Staff</span>
                  <Tooltip
                    className="tooltipRow"
                    title="The Clinical staff role, intended for non-provider staff that perform clinical workflows, has access to all standard Osmind EHR features."
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Row>
                <Row
                  onClick={() => handleRequiredRoleChange('Non-clinical')}
                  className={styles.row}
                >
                  <Checkbox checked={nonClinicalStaffRole} />
                  <span className={styles.spanRow}>Non Clinical Staff</span>
                  <Tooltip
                    className="tooltipRow"
                    title="The Non-clinical staff role, intended for staff that perform administrative workflows, has access to all standard Osmind EHR features."
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Row>
              </Form.Item>
              <Divider
                orientationMargin="0"
                style={{ marginTop: '26px', marginBottom: '26px' }}
              />
              <Form.Item
                label={
                  <>
                    <span>{'Additional permissions'}</span>
                    <span className={styles.optionalText}>{'(optional)'}</span>
                  </>
                }
              >
                {hasAdminUserPermission ? (
                  <Row
                    onClick={() => handleNonRequiredRoleChange('Super-Admin')}
                    className={styles.row}
                  >
                    <Checkbox checked={superAdminRole} />
                    <span className={styles.spanRow}>Super admin</span>
                    <Tooltip
                      className="tooltipRow"
                      title="The Super admin permission allows users to edit both Super admin and non-Super admin users. Note: This permission must be given in addition to a user role."
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Row>
                ) : (
                  ''
                )}
                <Row
                  onClick={() => handleNonRequiredRoleChange('Admin')}
                  className={styles.row}
                >
                  <Checkbox checked={adminRole} />
                  <span className={styles.spanRow}>Admin</span>
                  <Tooltip
                    className={styles.tooltipRow}
                    title="The admin permission allows users to edit non-Super admin users. Note: This permission must be given in addition to a user role."
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Row>
              </Form.Item>
            </>
          ) : (
            ''
          )}
        </Form>
      </Modal>
    </>
  )
}

export default NewTeammateModal
