import { ColumnType } from 'antd/lib/table'
import { omit } from 'lodash'

import { DiagnosisHistory } from '../../../../../../../shared-types'
import { renderValue } from '../../../elements'

import styles from '../../../styles.module.scss'

export type DiagnosisData = {
  id: string
  diagnosis: string
  diagnosis_code: string
  name: string
  diagnosis_date: string
  diagnosis_onset_date: string
  diagnosis_note_content?: string
}

export const serializeRowData = (
  dx: DiagnosisHistory[] | undefined
): DiagnosisData[] => {
  return (dx ?? []).map((d) => {
    return {
      id: d.DiagnosisId,
      key: d.DiagnosisId,
      diagnosis: d.Diagnosis,
      diagnosis_code: d.DiagnosisCode,
      name: `${d.Diagnosis} • ${d.DiagnosisCode}`,
      diagnosis_date: d.DiagnosisDate
        ? new Date(d.DiagnosisDate).toLocaleDateString()
        : '',
      diagnosis_onset_date: d.DiagnosisOnsetDate
        ? new Date(d.DiagnosisOnsetDate).toLocaleDateString()
        : '',
      diagnosis_note_content: d.DiagnosisNoteContent || undefined,
    }
  })
}

export type DiagnosisColumn = ColumnType<DiagnosisData> & {
  sorter?: (a: DiagnosisData, b: DiagnosisData) => number
}
export const getColumns = ({
  isSigned,
}: {
  isSigned: boolean
}): DiagnosisColumn[] => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a: DiagnosisData, b: DiagnosisData) =>
        a.name?.localeCompare(b.name),
      className: styles['primary-column-column-top-align'],
      render: (_text: any, record: DiagnosisData) => {
        return (
          <a>
            <a style={{ fontWeight: 'semibold' }}>{record.diagnosis}</a>
            <a className={styles.circle}> • </a>{' '}
            <a style={{ fontWeight: 'normal' }}>{record.diagnosis_code}</a>
          </a>
        )
      },
    },
    {
      className: styles['column-top-align'],
      title: 'Diagnosis Date',
      dataIndex: 'diagnosis_date',
      sorter: (a: DiagnosisData, b: DiagnosisData) =>
        a.diagnosis_date?.localeCompare(b.diagnosis_date),
      render: renderValue,
    },
    {
      className: styles['column-top-align'],
      title: 'Onset date',
      dataIndex: 'diagnosis_onset_date',
      sorter: (a: DiagnosisData, b: DiagnosisData) =>
        a.diagnosis_onset_date?.localeCompare(b.diagnosis_onset_date),
      render: renderValue,
    },
    {
      className: styles['column-top-align'],
      title: 'Comments',
      dataIndex: 'diagnosis_note_content',
      render: renderValue,
    },
  ].map((col) => {
    if (!isSigned) {
      return col
    }

    return omit(col, 'sorter')
  })
}
