import React, { useEffect, useMemo, useRef, useState } from 'react'

import { Form, Formik, FormikProps } from 'formik'
import { isEqual } from 'lodash'
import { useHistory } from 'react-router-dom'

import { PracticeDataType } from '../../hooks/usePatientProfile/shared-types'
import useQueryString from '../../hooks/useQueryString'
import { Spinner } from '../../stories/BaseComponents'
import {
  NotificationType,
  generateNotification,
} from '../../stories/BaseComponents/Notification'
import {
  useDeleteClaim,
  useGetClaimData,
  usePayers,
  useSaveClaim,
  useSaveClaimMemo,
} from './ClaimsV2/hooks'
import { toClaimData, toFormValues } from './ClaimsV2/mappers'
import { DeleteClaimModal, UnsavedChangesModal } from './ClaimsV2/modals'
import {
  ClaimHeader,
  Memo,
  PatientInfo,
  Payer,
  PrimaryInsurance,
  ReferringProvider,
  SecondaryInsurance,
} from './ClaimsV2/sections'
import { ClaimForm, ClaimFormStatus } from './ClaimsV2/types'
import {
  isErrorStatus,
  isFormDeleting,
  isFormInitialLoading,
  isLoadingStatus,
} from './ClaimsV2/utils'
import { PatientHeader } from './PatientHeader'

import styles from './ClaimCreationV2.module.scss'

export type ClaimCreationProps = {
  healthGorillaUserName: string
  practiceData: PracticeDataType
}

const ClaimCreation: React.FC<ClaimCreationProps> = ({
  healthGorillaUserName,
  // practiceData,
}) => {
  const history = useHistory()
  // get initial values from URL search params
  const query = useQueryString()
  const patientId = query.get('patientId') ?? ''
  const providerId = query.get('providerId') ?? ''
  const initialClaimId = query.get('claimId') ?? ''
  const initialNoteId = query.get('noteId') ?? ''
  const formRef = useRef<FormikProps<ClaimForm> | null>(null)

  const {
    data: claimData,
    error: claimError,
    status: claimFetchStatus,
  } = useGetClaimData({
    patientId,
    claimId: initialClaimId,
    noteId: initialNoteId,
  })

  const getClaimId = () => initialClaimId || claimData?.claimId

  const payers = usePayers()
  const { payersByName, status: payersStatus } = payers

  const { mutateAsync: doDeleteClaim, status: deleteClaimStatus } =
    useDeleteClaim({
      patientId,
    })

  const { mutateAsync: doSaveClaim, status: saveClaimStatus } = useSaveClaim({
    claimId: getClaimId(),
  })

  const { mutateAsync: doSaveClaimMemo, status: saveClaimMemoStatus } =
    useSaveClaimMemo({
      claimId: getClaimId(),
    })

  // states
  const [initialMemoValue, setInitialMemoValue] = useState<string | null>(null)
  const [initialFormValues, setInitialFormValues] = useState<ClaimForm | null>(
    null
  )

  const [isNewClaim, setIsNewClaim] = useState<boolean>(!initialClaimId)

  const [isShowDeleteClaimModal, setIsShowDeleteClaimModal] =
    useState<boolean>(false)
  const [isShowUnsavedChangesModal, setIsShowUnsavedChangesModal] =
    useState(false)

  // reusable functions
  const navigateToBilling = () => {
    history.push(
      `/patient/billing?patientId=${patientId}&providerId=${providerId}&tab=claims`
    )
  }

  const getCurrentFormValues = () => {
    return formRef.current?.values
  }

  const hasUnsaved = () => {
    // memo value may have been saved independently
    const initialFormValuesWithUpdatedMemo = {
      ...initialFormValues,
      claimMemo: initialMemoValue,
    }
    return (
      isNewClaim ||
      !isEqual(initialFormValuesWithUpdatedMemo, getCurrentFormValues())
    )
  }

  const saveClaim = async () => {
    const formValues = getCurrentFormValues()
    const claimId = getClaimId()
    const patientControlNumber = claimData?.patientControlNumber
    if (!formValues || !claimId || !patientControlNumber) return
    const body = toClaimData(patientId, patientControlNumber, formValues)
    try {
      await doSaveClaim({ claimId, body })
      generateNotification(
        'Successfully saved claim as draft.',
        NotificationType.SUCCESS
      )
    } catch (error) {
      generateNotification(
        'Failed to save claim as draft.',
        NotificationType.ERROR
      )
    }
  }

  const deleteClaim = async () => {
    const claimId = getClaimId()
    if (!claimId) return
    try {
      await doDeleteClaim(claimId)
      generateNotification(
        'Successfully deleted claim.',
        NotificationType.SUCCESS
      )
    } catch (error) {
      generateNotification('Failed to delete claim.', NotificationType.ERROR)
    }
  }

  const saveClaimMemo = async () => {
    const claimId = getClaimId()
    if (!claimId || !patientId) {
      return
    }
    const currentMemoValue = getCurrentFormValues()?.claimMemo ?? null
    try {
      await doSaveClaimMemo({
        claimId,
        memo: currentMemoValue,
      })

      setInitialMemoValue(currentMemoValue)
      setIsNewClaim(false)

      generateNotification(
        'Successfully saved claim memo.',
        NotificationType.SUCCESS
      )
    } catch (error) {
      generateNotification(
        'There was an error saving the memo.',
        NotificationType.ERROR
      )
    }
  }

  // handlers
  const handleOpenDeleteClaimModal = () => {
    setIsShowDeleteClaimModal(true)
  }

  const handleCloseDeleteClaimModal = () => {
    setIsShowDeleteClaimModal(false)
  }

  const handleOpenUnsavedChangesModal = () => {
    setIsShowUnsavedChangesModal(true)
  }

  const handleCloseUnsavedChangesModal = () => {
    setIsShowUnsavedChangesModal(false)
  }

  const handleGoBack = () => {
    if (hasUnsaved()) {
      handleOpenUnsavedChangesModal()
    } else {
      history.goBack()
    }
  }

  const handleConfirmDeleteClaim = async () => {
    await deleteClaim()
    handleCloseDeleteClaimModal()
    // TODO: discuss navigation behavior after deleting
    navigateToBilling()
  }

  const handleSaveClaim = async () => {
    await saveClaim()
    // TODO: discuss navigation behavior after saving
    navigateToBilling()
  }

  const handleSaveClaimMemo = async () => {
    await saveClaimMemo()
  }

  // todo
  const handleCancelClaim = () => {
    generateNotification('Cancel claim not working yet!', NotificationType.INFO)
  }
  // todo
  const handleEditPayments = () => {
    generateNotification('Edit claim not working yet!', NotificationType.INFO)
  }

  const handleSaveUnsavedChanges = async () => {
    await saveClaim()
    handleCloseUnsavedChangesModal()
    // TODO: discuss navigation behavior after saving from the warning modal
    navigateToBilling()
  }

  // For a existing claim, discard changes = close without saving new changes
  // For a newly created claim, discard changes = delete this claim
  const handleDiscardUnsavedChanges = async () => {
    if (isNewClaim) {
      await deleteClaim()
    }
    handleCloseUnsavedChangesModal()
    // TODO: discuss navigation behavior after discarding claim from the warning modal
    navigateToBilling()
  }

  useEffect(() => {
    if (!patientId) {
      throw new Error('There should be a patientId when creating claims')
    }
  }, [patientId])

  // Remove payersByName dependency once BE is fully prefilling all desired data on claim create (CARE-2349)
  useEffect(() => {
    if (!claimData || !payersByName) {
      return
    }
    setInitialMemoValue(claimData.claimMemo ?? null)
    setInitialFormValues(toFormValues(claimData, payersByName))
  }, [claimData, payersByName])

  // only one action can happen at a given time, we can consolidate these network states into one variable
  const formStatus: ClaimFormStatus = useMemo(() => {
    if (isLoadingStatus(payersStatus) || isLoadingStatus(claimFetchStatus)) {
      return 'INITIAL_LOADING'
    } else if (isLoadingStatus(saveClaimStatus)) {
      return 'SAVING_CLAIM'
    } else if (isLoadingStatus(deleteClaimStatus)) {
      return 'DELETING_CLAIM'
    } else if (isLoadingStatus(saveClaimMemoStatus)) {
      return 'SAVING_CLAIM_MEMO'
    }
    return null
  }, [
    claimFetchStatus,
    payersStatus,
    saveClaimStatus,
    deleteClaimStatus,
    saveClaimMemoStatus,
    payersStatus,
  ])

  return (
    <>
      <div className={styles.scroll}>
        <PatientHeader
          providerId={providerId}
          patientId={patientId}
          healthGorillaUserName={healthGorillaUserName}
        />
        <div className={styles.container}>
          {isFormInitialLoading(formStatus) && (
            <div
              data-testid="claim-loading-container"
              className={styles.loadingIndicator}
            >
              <Spinner />
            </div>
          )}
          {isErrorStatus(claimFetchStatus) && (
            <pre data-testid="claim-error-container">
              {JSON.stringify({ error: claimError }, undefined, 2)}
            </pre>
          )}
          {initialFormValues && (
            <div data-testid="claim-data-container">
              <Formik
                initialValues={initialFormValues}
                // onSubmit has undesired side effects (setting touched for all fields to true) that screws up dependent fields logic
                // pass in a dummy function since it's a required prop
                // eslint-disable-next-line
                onSubmit={() => {}}
                innerRef={formRef}
              >
                <>
                  <ClaimHeader
                    claim={claimData}
                    onGoBack={handleGoBack}
                    onDeleteClaim={handleOpenDeleteClaimModal}
                    onSaveClaim={handleSaveClaim}
                    onCancelClaim={handleCancelClaim}
                    onEditPayments={handleEditPayments}
                    formStatus={formStatus}
                  />
                  <Form>
                    <Memo
                      initialValue={initialMemoValue}
                      onSave={handleSaveClaimMemo}
                      formStatus={formStatus}
                    />
                    <PatientInfo />
                    <PrimaryInsurance payers={payers} />
                    <Payer payers={payers} />
                    <SecondaryInsurance payers={payers} />
                    <ReferringProvider />
                  </Form>
                </>
              </Formik>
            </div>
          )}
        </div>
      </div>
      <DeleteClaimModal
        visible={isShowDeleteClaimModal}
        onCancel={handleCloseDeleteClaimModal}
        onDeleteClaim={handleConfirmDeleteClaim}
        isDeleteClaimLoading={isFormDeleting(formStatus)}
      />
      <UnsavedChangesModal
        visible={isShowUnsavedChangesModal}
        onCancel={handleCloseUnsavedChangesModal}
        onSave={handleSaveUnsavedChanges}
        onDiscard={handleDiscardUnsavedChanges}
        formStatus={formStatus}
      />
    </>
  )
}

export default ClaimCreation
