// Import react
import React, { useEffect, useState } from 'react'

import { Button, Col } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

import {
  changeEmailMessageEnable,
  getProviderInformation,
} from '../../api/api-lib'
import LoaderButton from '../../components/Buttons/LoaderButton'

import './Settings.scss'

export default function Settings(_props) {
  const [providerEmail, setProviderEmail] = useState('')
  const [enableEmailMessage, setEnableEmailMessage] = useState(false)

  useEffect(() => {
    async function onLoad() {
      const provInfo = await getProviderInformation()
      setProviderEmail(provInfo.providerInfo.ProviderEmail)
      setEnableEmailMessage(
        provInfo.providerInfo.IsEmailNotificationsEnabled || false
      )
    }

    onLoad()
  }, [])

  const personalSettingsButton = (
    <>
      <LinkContainer to="/settings/personal">
        <LoaderButton>
          <i className="fa fa-user" aria-hidden="true" />
          &nbsp;Personal Settings
        </LoaderButton>
      </LinkContainer>
      <br />
    </>
  )

  return (
    <Col className="Settings">
      <LinkContainer to="/settings/team">
        <LoaderButton icon={<i className="fa fa-lock" aria-hidden="true" />}>
          Team Settings
        </LoaderButton>
      </LinkContainer>
      <br />
      {personalSettingsButton}
      <LinkContainer to="/settings/password">
        <LoaderButton icon={<i className="fa fa-lock" aria-hidden="true" />}>
          <i className="fa fa-key" aria-hidden="true" />
          &nbsp;Change Password
        </LoaderButton>
      </LinkContainer>
      <br />
      <LinkContainer to="/settings/location">
        <LoaderButton
          icon={<i className="fa fa-location-arrow" aria-hidden="true" />}
        >
          <i className="fa fa-location-arrow" aria-hidden="true" />
          &nbsp;Locations
        </LoaderButton>
      </LinkContainer>
      <br />
      <LinkContainer to="/settings/appointment-settings">
        <LoaderButton
          icon={<i className="fa fa-calendar" aria-hidden="true" />}
        >
          <i className="fa fa-calendar" aria-hidden="true" />
          &nbsp;Appointment Types
        </LoaderButton>
      </LinkContainer>
      <br />
      <LinkContainer to="/settings/practice-settings">
        <LoaderButton icon={<i className="fa fa-cogs" aria-hidden="true" />}>
          <i className="fa fa-cogs" aria-hidden="true" />
          &nbsp;Practice Settings
        </LoaderButton>
      </LinkContainer>
      <br />
      <Button
        id="secureMessageNotifications"
        onClick={() => {
          changeEmailMessageEnable(providerEmail, enableEmailMessage)
          setEnableEmailMessage(!enableEmailMessage)
        }}
        bsPrefix="button-block center"
      >
        <i className="fa fa-bell-o" aria-hidden="true" />
        &nbsp; Receive email notification <br /> for new secure messages: &nbsp;
        <span id="secureMessageNotificationsState">
          {enableEmailMessage ? 'Enabled' : 'Disabled'}
        </span>
      </Button>
    </Col>
  )
}
