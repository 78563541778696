import { useQuery } from '@tanstack/react-query'

import { fetchNoteBlock } from '../../api'
import { NOTE_BLOCK_QUERY_KEY } from '../../constants'

export const getNoteBlockQueryKey = (
  noteUuid: string | undefined,
  blockUuid: string | undefined
) => {
  return [NOTE_BLOCK_QUERY_KEY, noteUuid, blockUuid]
}

export const useFetchNoteBlock = (
  noteUuid: string | undefined,
  blockUuid: string | undefined
) => {
  const queryKey = getNoteBlockQueryKey(noteUuid, blockUuid)
  return useQuery(
    queryKey,
    () => {
      if (!noteUuid || !blockUuid) {
        return undefined
      }
      return fetchNoteBlock({ noteUuid, blockUuid })
    },
    {
      enabled: !!(noteUuid || blockUuid),
    }
  )
}
