import { Tooltip } from '../../../stories/BaseComponents'
import { DeleteNoteButton } from './DeleteNoteButton'

// Import styles
import './DeleteClinicalNoteButton.scss'

interface DeleteClinicalNoteButtonModalTopProps {
  isDisabled: boolean
  hasSuperbill: boolean
  modalComponent: React.ReactNode
  handleModalShow: () => void
}

export default function DeleteClinicalNoteButtonModalTop({
  isDisabled,
  hasSuperbill,
  handleModalShow,
  modalComponent,
}: DeleteClinicalNoteButtonModalTopProps) {
  return (
    <>
      {modalComponent}
      {!hasSuperbill ? (
        <DeleteNoteButton onClick={handleModalShow} isDisabled={isDisabled} />
      ) : (
        <Tooltip
          title="Cannot delete a note after a superbill has been created."
          placement="bottom"
        >
          <div className={'deleteButtonContainer'}>
            <DeleteNoteButton isDisabled />
          </div>
        </Tooltip>
      )}
    </>
  )
}
