import { UseMutationResult } from '@tanstack/react-query'

import {
  LegalSex,
  SpecificEthnicity,
} from '../../containers/Patient/General/types'
import { DateISO8601, DemographicsRequestOrigin } from '../../shared-types'

/**
 * This enum contains all the id's of the form fields in the Demographics section
 * of the Patient Profile Sidepanel
 */
export enum DemographicsSection {
  firstName = 'firstName',
  middleName = 'middleName',
  lastName = 'lastName',
  firstNameToUse = 'firstNameToUse',
  middleNameToUse = 'middleNameToUse',
  lastNameToUse = 'lastNameToUse',
  formerFirstName = 'formerFirstName',
  formerMiddleName = 'formerMiddleName',
  formerLastName = 'formerLastName',
  email = 'email', // currently not displayed
  DateOfBirth = 'DateOfBirth',
  birthSex = 'birthSex',
  legalSex = 'legalSex',
  pronouns = 'pronouns',
  genderIdentity = 'genderIdentity',
  race = 'race',
  raceSubcategory = 'raceSubcategory',
  ethnicity = 'ethnicity',
  specificEthnicity = 'specificEthnicity',
  notes = 'notes',
  summary = 'summary',
}

/**
 * This enum contains all the id's of the form fields in the Insurance section
 * of the Patient Profile Sidepanel
 */

export const InsurancePrimary = {
  patientInsuranceId: 'patientInsuranceId',
  insuranceName: 'insuranceName',
  insuranceMemberId: 'insuranceMemberId',
  insuranceGroupId: 'insuranceGroupId',
  informationRelease: 'informationRelease',
  paymentAutorization: 'paymentAutorization',
  claimFilingCode: 'claimFilingCode',
}

export const InsurancePrimaryHolder = {
  primaryRelationship: 'primaryRelationship',
  primaryFirstName: 'primaryFirstName',
  primaryLastName: 'primaryLastName',
  dateOfBirth: 'dateOfBirth',
  legalSex: 'legalSex',
  email: 'email',
  phoneNumber: 'phoneNumber',
  sameAddressAsPatient: 'sameAddressAsPatient',
  address: 'address',
  unit: 'unit',
}

export const InsuranceSecondary = {
  secondaryInsuranceName: 'secondaryInsuranceName',
  secondaryInsuranceMemberId: 'secondaryInsuranceMemberId',
  secondaryInsuranceGroupId: 'secondaryInsuranceGroupId',
  secondaryInformationRelease: 'secondaryInformationRelease',
  secondaryPaymentAuthorization: 'secondaryPaymentAuthorization',
  secondaryClaimFillingCode: 'secondaryClaimFillingCode',
}

export const InsuranceSecondaryHolder = {
  secondaryFirstName: 'secondaryFirstName',
  secondaryLastName: 'secondaryLastName',
  secondaryDateOfBirth: 'secondaryDateOfBirth',
  secondaryRelationship: 'secondaryRelationship',
}

/**
 * This is the general union type that we can expand on to include all the different
 * fields for each form section on the Patient Profile Sidepanel
 */
export type PatientProfilePanelSections = DemographicsSection

export type PatientProfileRequestObject = {
  patientId: string
}

export type UpdateInsuranceRequest = {
  patientId: string
}

export type GetPaymentRequest = {
  claimId: string
  patientId: string
  isEnabled?: boolean
}

export type GetTeammateRequest = {
  providerId: string
}

export type CoreIdentifiersMutation = UseMutationResult<
  CoreIdentifiers,
  unknown,
  {
    summary?: string
  }
>

export type DemographicsContactInfoMutationParams = {
  formData: DemographicsContactInfo
  patientInfo?: PatientInfo
  newValueId: string
  newValueLabel?: string
}

export type DemographicsContactInfoMutation = UseMutationResult<
  DemographicsContactInfo,
  unknown,
  DemographicsContactInfoMutationParams
>

export type PatientTag = {
  patientId: string
  tagId: number
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  tag: {
    id: number
    label: string
  }
}

/**
 * This is a the object used to display data on the patient's CoreIdentifiers UI
 */
export type CoreIdentifiers = {
  summary?: string
  patientFirstName?: string
  patientLastName?: string
  patientName?: string
  patientNameToUse?: string
  displayName?: string
  birthOfDate?: string
  age?: string
  suicidalIntention?: boolean
  pronouns?: string[]
  genderIdentity?: string
  tags?: Array<PatientTag>
  birthdateUpdated?: boolean // This is used to listen to any birthdate changes from correctPatientBirthdate
}

/**
 * This is the current type for the payload coming from loadPatientInfo API request.
 * It contains a combination of data from both PatientInfo (legacy) and Patients (newer) tables.
 *
 * Note: Should update this type with data migration fixes/changes to represent the payload sent from the backend
 */
export interface PatientInfo extends DemographicsContactInfo {
  PatientId?: string
  patientTags?: PatientTag[]
  createdAt?: string
  CreatedAt?: string
  CognitoId?: string
  PublicId?: string
  ChannelUrl?: string
  PatientRCopiaId?: string
  HasRecentSuicidalThoughts?: boolean
  AddressLine1?: string
  AddressLine2?: string
  City?: string
  State?: string
  zipcode?: string
}

/**
 * This is a subset of the PatientInfo type that is displayed on the Demographics UI
 *
 * Note: Should update this type with data migration fixes/changes to represent what is shown and updated from Demographics UI
 */
// TODO:  combine this interface with PatientInfo and remove mapping
export type DemographicsContactInfo = {
  patientId?: string
  firstName?: string
  middleName?: string
  lastName?: string
  PatientName?: string // backwards compatible for loading profile image
  firstNameToUse?: string
  middleNameToUse?: string
  lastNameToUse?: string
  formerFirstName?: string
  formerMiddleName?: string
  formerLastName?: string
  birthSex?: string
  legalSex?: LegalSex
  pronouns?: string[]
  genderIdentity?: string | null
  race?: string[]
  ethnicity?: string | null
  specificEthnicity?: SpecificEthnicity[]
  cellPhone?: string
  homePhone?: string
  workPhone?: string
  email?: string
  notes?: string
  summary?: string
  DateOfBirth?: DateISO8601
  addressLine1?: string
  addressLine2?: string
  city?: string
  state?: string
  zipCode?: string
  raceSubcategory?: string // fix this type, this is string[]
  updateOrigin?: DemographicsRequestOrigin
}

export type PatientInsurance = {
  patientInsuranceId: number | null
  insuranceName: string | null
  insuranceMemberId: string | null
  insuranceGroupId: string | null
  insuranceCompany: string | null
  insuranceCompanyAddress1: string | null
  insuranceCompanyAddress2: string | null
  insuranceCompanyCity: string | null
  insuranceCompanyState: string | null
  insuranceCompanyZipCode: string | null
  insuranceCardBack: string | null
  insuranceCardFront: string | null
  isPrimaryInsuranceHolder: boolean | null
  hasInsuranceCardSaved: boolean | null
  isPaymentAuthorized: boolean | null
  isInformationReleaseAuthorized: boolean | null
  claimFilingCode?: string | null
  insuranceTypeCode?: string | null
}

export type InsuranceHolder = {
  address1: string | null
  address2: string | null
  zipCode: string | null
  city: string | null
  state: string | null
  country: string | null
  dateOfBirth: string | null
  email: string | null
  firstName: string | null
  lastName: string | null
  phoneNumber: string | null
  relationship: string | null
  legalSex: string | null
  patientInsuranceId?: number
  hasSameAddressAsPatient?: boolean | null
}

export type PatientInsuranceWithOptionalInsuranceHolder = {
  patientInsurance: PatientInsurance
  insuranceHolder?: InsuranceHolder
}

export type PatientConditionRelation = {
  employmentAccident: boolean | null
  autoAccident: boolean | null
  accidentState: string | null
  otherAccident: boolean | null
}

export type PatientInfoType = {
  firstName: string | null
  lastName: string | null
  address1: string | null
  address2: string | null
  zipCode: string | null
  city: string | null
  state: string | null
  country: string | null
  dateOfBirth: string | null
  legalSex: string | null
}

export type ReferringProviderType = {
  firstName: string | null
  lastName: string | null
  providerEin: string | null
  providerNpi: string | null
}

export type SupervisingProviderType = {
  providerId: string | null
  providerEin: string | null
  providerNpi: string | null
}

export type RenderingProviderType = {
  providerId: string | null
  providerEin: string | null
  providerNpi: string | null
  providerSsn: string | null
  providerTaxonomyCode?: string | null
  taxType: 'EIN' | 'SSN' | null
  providerSSNfirstFive: string | null
  providerSSNlastFour: string | null
}

export type BillingInfo = {
  type: 'Provider' | 'Practice' | null
  name: string | null
  locationName: string | null
  locationId: string | null
  address1: string | null
  address2: string | null
  zipCode: string | null
  city: string | null
  state: string | null
  country: string | null
  phone: string | null
  EIN: string | null
  NPI: string | null
  SSN: string | null
  taxType: 'EIN' | 'SSN' | null
  SSNfirstFive: string | null
  SSNlastFour: string | null
  taxonomyCode?: string | null
}

export type AppointmentInfo = {
  /**
   * @deprecated use noteIdV2 instead
   */
  noteId: string | null
  noteIdV2: string | null
  startDate: string | null
  endDate: string | null
  practice: string | null
  locationId: string | null
  address1: string | null
  address2: string | null
  state: string | null
  city: string | null
  country: string | null
  zipCode: string | null
  posCode: string | null
  practiceNpi: string | null
  isSigned: boolean | null
  signedDate?: Date | null
}

export interface ClaimDetails {
  type: string
  patientControlNumber: string
  created: string
  submittedStatus: string
  status: string
  submittedDate?: string
  canceledAt?: string
  payerControlNumber?: string
}

export type GetClaimInfo = {
  patientId: string
  noteId?: string
  claimId?: string
}

export type PracticeDataType = {
  PracticeName?: string
  ProviderPhone?: string
  ProviderPracticeEIN?: string
  ProviderPracticeBillingNPI?: string
  CreatedAt: string
  OsmindUrl?: string
  PaidSeats?: number
  ProviderCredential?: string
  ProviderEmail?: string
  ProviderFirstName?: string
  ProviderLastName?: string
  ProviderPracticeAddress?: string
  ProviderPracticeCity?: string
  ProviderPracticeCountry?: string
  ProviderPracticeState?: string
  ProviderPracticeZipcode?: string
  stripeAccountId?: string
}
