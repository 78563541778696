import { useHistory } from 'react-router-dom'

export const useNavigate = () => {
  const history = useHistory()

  const navigate = (path?: string, search?: string): void => {
    if (path) {
      history.push(`${path}?${search}`)
    }
  }

  return navigate
}
