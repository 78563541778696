import React, { useCallback, useEffect, useState } from 'react'

import { useHasPermissions } from '../../hooks/useHasPermissions'
import { useTagsData } from '../../hooks/useTags'
import { isPatientActive } from '../../libs/utils'
import { ProviderHomePageData, Teammate } from '../../shared-types'
import PatientInvite from '../../stories/Patients/PatientInvite'
import { UnreadMessageCountsContext } from '../../v2/messaging/UnreadMessageCounts'
import { Checkbox, Input, PageHeader } from '../BaseComponents'
import Table, { GenericTableProps } from '../BaseComponents/Table'
import { PatientTableData } from './_types'
import patientTableConstructor from './patientTableConstructor'

import './_shared.scss'

interface PatientTableProps extends GenericTableProps {
  dataSource: PatientTableData[]
  handleAddPatient: () => void
  updateCareTeam: Function
  providerData: Pick<ProviderHomePageData, 'providerId' | 'loggedInProviderId'>
  teammates: Teammate[]
  storybook?: boolean
}

const PatientTable: React.FC<PatientTableProps> = ({
  dataSource,
  providerData,
  handleAddPatient,
  updateCareTeam,
  loading = false,
  teammates,
  storybook = false,
}) => {
  const { data: allTags = [] } = useTagsData()
  const [filteredPatients, setFilteredPatients] =
    useState<PatientTableData[]>(dataSource)
  const [checked, setChecked] = useState<boolean>(false)
  const [searchedPatients, setSearchedPatients] = useState<
    PatientTableData[] | undefined
  >(undefined)
  const [searchFilter, setSearchFilter] = useState<string>('')
  const { providerId, loggedInProviderId } = providerData
  const { isLoading: messagesLoading } = React.useContext(
    UnreadMessageCountsContext
  )

  const viewAllPatients = useHasPermissions({
    canSeeAllPatients: 'READ',
  })

  const [ownPatients, setOwnPatients] = useState<boolean>(!viewAllPatients)
  const [disabled, setDisabled] = useState<boolean>(!viewAllPatients)

  useEffect(() => {
    setDisabled(!viewAllPatients)
    setOwnPatients(!viewAllPatients)
  }, [viewAllPatients])

  useEffect(() => {
    if (checked && ownPatients) {
      const deactivatedPatients = dataSource.filter(
        (patient) => !patient.isActive
      )

      setFilteredPatients(
        deactivatedPatients.filter((patient) => {
          if (!patient.careTeam) {
            return patient.clinicId === loggedInProviderId
          }
          return !!patient.careTeam.find((teamMember) => {
            return teamMember.providerId === loggedInProviderId
          })
        })
      )
    } else if (checked) {
      setFilteredPatients(dataSource.filter((patient) => !patient.isActive))
    } else if (ownPatients) {
      const activePatients = dataSource.filter((patient) => patient.isActive)
      setFilteredPatients(
        activePatients.filter((patient) => {
          if (!patient.careTeam) {
            return patient.clinicId === loggedInProviderId
          }

          return !!patient.careTeam.find((teamMember) => {
            return teamMember.providerId === loggedInProviderId
          })
        })
      )
    } else {
      // check for undefined because if field doesn't exist, patient account is still active
      setFilteredPatients(
        dataSource.filter((patient) => isPatientActive(patient.isActive))
      )
    }
  }, [dataSource, checked, ownPatients])

  useEffect(() => {
    if (searchFilter === '') {
      setSearchedPatients(undefined)
    } else {
      setSearchedPatients(
        filteredPatients.filter((patient) => {
          const searchMatchesName =
            // First M. Last
            patient.name.toLowerCase().includes(searchFilter.toLowerCase()) ||
            // First M Last
            patient.name
              .replace('.', '')
              .toLowerCase()
              .includes(searchFilter.toLowerCase()) ||
            // First Middle Last
            patient.fullName
              .toLowerCase()
              .includes(searchFilter.toLowerCase()) ||
            // First Last
            patient.firstLast.toLowerCase().includes(searchFilter.toLowerCase())

          return patient.fullName !== undefined && searchMatchesName
        })
      )
    }
  }, [searchFilter, filteredPatients])

  const setActivePatients = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchFilter(event.target.value)
    },
    []
  )

  const onChange = useCallback((e: any) => {
    setChecked(e.target.checked)
  }, [])

  const myPatients = useCallback((e: any) => {
    setOwnPatients(e.target.checked)
  }, [])

  const { columns } = patientTableConstructor({
    allTags,
    dataSource,
    providerId,
    teammates,
    updateCareTeam,
  }).table

  const headerComponent = [
    <Checkbox
      key="patientlist-careTeam-patients-checkbox"
      checked={ownPatients}
      onChange={myPatients}
      disabled={disabled}
    >
      My patients only
    </Checkbox>,
    <Checkbox
      key="patientlist-deactivated-patients-checkbox"
      onChange={onChange}
    >
      Show deactivated patients only
    </Checkbox>,
    <Input
      key="patientlist-patient-searchbox"
      placeholder="Search Patients"
      allowClear
      onChange={setActivePatients}
      style={{ width: 227 }}
    />,
    <PatientInvite
      key="patientlist-invite-patient-button"
      providerData={providerData}
      handleUpdatePatientList={handleAddPatient}
      storybook={storybook}
    />,
  ]

  return (
    <>
      <PageHeader
        className="header"
        title="Patients"
        extra={headerComponent}
        description={
          messagesLoading ? 'Warning: Unread messages still loading...' : ''
        }
        descriptionStyle={{ color: 'orange' }}
      />
      <div className="patient-margin">
        <Table
          className="component-container icon-adjustment"
          columns={columns}
          dataSource={searchedPatients || filteredPatients}
          pageSize={50}
          loading={loading}
        />
      </div>
    </>
  )
}

export default PatientTable
