import React, { useCallback, useMemo, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { FormInstance } from 'antd'
import Form from 'antd/lib/form'
import { RadioChangeEvent } from 'antd/lib/radio'

import {
  ChangePayersList,
  getChangePayersList,
} from '../../../../src/api/intakeForms'
import {
  LegalRecordedSexDropDownValues,
  insuranceHolderRelationshipDropDownValues,
} from '../../../shared/Demographics'
import {
  Col,
  DateInput,
  Divider,
  ImageUpload,
  Input,
  PhoneNumberInput,
  Radio,
  Row,
  Select,
} from '../../BaseComponents'
import { email, normalizeText, optionalFreeText } from '../validation-rules'
import Address from './PatientIntakeAddress'
import {
  DividerWithSpacer,
  FULL_SPAN,
  HALF_SPAN,
  Header,
  LargeSubText,
  SectionHeader,
  mapCodesetToListView,
} from './helpers'
import { InsuranceQuestionKeys } from './question-keys'

import styles from './_shared.module.scss'

interface Props {
  form: FormInstance
  reviewMode?: boolean
}

const expandedInsuranceFields = [
  InsuranceQuestionKeys.PRIMARY_INSURED_ADDRESS,
  InsuranceQuestionKeys.PRIMARY_INSURED_RELATIONSHIP,
  InsuranceQuestionKeys.PRIMARY_INSURED_FIRST_NAME,
  InsuranceQuestionKeys.PRIMARY_INSURED_LAST_NAME,
  InsuranceQuestionKeys.PRIMARY_INSURED_DATE_OF_BIRTH,
  InsuranceQuestionKeys.PRIMARY_INSURED_LEGAL_SEX,
  InsuranceQuestionKeys.PRIMARY_INSURED_EMAIL,
  InsuranceQuestionKeys.PRIMARY_INSURED_PHONE,
  InsuranceQuestionKeys.PRIMARY_INSURED_ADDRESS,
  InsuranceQuestionKeys.PRIMARY_INSURED_ADDRESS_UNIT_NUMBER,
  InsuranceQuestionKeys.PRIMARY_INSURED_ADDRESS_OBJECT,
]

const secondaryInsuranceFields = [
  InsuranceQuestionKeys.SECONDARY_INSURANCE_NAME,
  InsuranceQuestionKeys.SECONDARY_INSURANCE_NAME,
  InsuranceQuestionKeys.SECONDARY_INSURANCE_MEMBER_ID,
  InsuranceQuestionKeys.SECONDARY_INSURANCE_GROUP_ID,
  InsuranceQuestionKeys.IS_SECONDARY_INSURANCE_SUBSCRIBER,
  InsuranceQuestionKeys.SECONDARY_INSURANCE_SUBSCRIBER_RELATIONSHIP,
  InsuranceQuestionKeys.SECONDARY_INSURANCE_SUBSCRIBER_FIRST_NAME,
  InsuranceQuestionKeys.SECONDARY_INSURANCE_SUBSCRIBER_LAST_NAME,
  InsuranceQuestionKeys.SECONDARY_INSURANCE_SUBSCRIBER_DATE_OF_BIRTH,
  InsuranceQuestionKeys.IS_SECONDARY_PAYMENT_AUTHORIZED,
  InsuranceQuestionKeys.IS_SECONDARY_INFORMATION_RELEASE_AUTHORIZED,
]

const secondaryInsuranceSubscriberFields = [
  InsuranceQuestionKeys.SECONDARY_INSURANCE_SUBSCRIBER_RELATIONSHIP,
  InsuranceQuestionKeys.SECONDARY_INSURANCE_SUBSCRIBER_FIRST_NAME,
  InsuranceQuestionKeys.SECONDARY_INSURANCE_SUBSCRIBER_LAST_NAME,
  InsuranceQuestionKeys.SECONDARY_INSURANCE_SUBSCRIBER_DATE_OF_BIRTH,
]

const Insurance = ({ form, reviewMode }: Props) => {
  const [isPrimaryInsuranceHolder, setIsPrimaryInsuranceHolder] = useState<
    boolean | null
  >(null)

  const [hasSecondaryInsurance, setHasSecondaryInsurance] = useState<
    boolean | null
  >(null)

  const [isSecondaryInsuranceSubscriber, setIsSecondaryInsuranceSubscriber] =
    useState<boolean | null>(null)

  const [hideAddress, setHideAddress] = useState<boolean>(true)
  const { data: payers, isLoading: isLoading } = useQuery(
    ['payers'],
    async () => {
      const payersList: ChangePayersList[] = await getChangePayersList()
      return payersList.map((p) => ({
        value: p.organizationName,
        label: p.organizationName,
      }))
    }
  )

  const {
    data: insuranceFrontImage,
    isFetching: isFrontLoading,
    refetch: refetchFrontImage,
  } = useQuery(['insuranceFrontImage'], () =>
    form.getFieldValue(InsuranceQuestionKeys.INSURANCE_CARD_IMAGE_FRONT)
  )

  const {
    data: insuranceBackImage,
    isFetching: isBackLoading,
    refetch: refetchBackImage,
  } = useQuery(['insuranceBackImage'], () =>
    form.getFieldValue(InsuranceQuestionKeys.INSURANCE_CARD_IMAGE_BACK)
  )

  const legalRecordedSex = useMemo(() => {
    return LegalRecordedSexDropDownValues.map(mapCodesetToListView)
  }, [])

  const relationshipOptions = useMemo(() => {
    return insuranceHolderRelationshipDropDownValues.map(mapCodesetToListView)
  }, [])

  const handleIsPrimaryInsuranceHolder = useCallback(
    (event: RadioChangeEvent) => {
      setIsPrimaryInsuranceHolder(event.target.value)

      // Clear expanded fields if isPrimaryInsuranceHolder checked 'Yes'
      if (event.target.value === true) {
        form.resetFields(expandedInsuranceFields)
      }
    },
    [form]
  )

  const handleHasSecondaryInsurance = useCallback(
    (event: RadioChangeEvent) => {
      setHasSecondaryInsurance(event.target.value)

      // Clear expanded fields if isPrimaryInsuranceHolder checked 'Yes'
      if (event.target.value === true) {
        form.resetFields(secondaryInsuranceFields)
      }
    },
    [form]
  )

  const handleIsSecondaryInsuranceSubscriber = useCallback(
    (event: RadioChangeEvent) => {
      setIsSecondaryInsuranceSubscriber(event.target.value)

      // Clear expanded fields if isPrimaryInsuranceHolder checked 'Yes'
      if (event.target.value === true) {
        form.resetFields(secondaryInsuranceSubscriberFields)
      }
    },
    [form]
  )

  const setImage = (base64Image: string, side: 'FRONT' | 'BACK') => {
    form.setFieldValue(
      [InsuranceQuestionKeys[`INSURANCE_CARD_IMAGE_${side}`]],
      base64Image
    )
    if (side === 'FRONT') return refetchFrontImage()
    refetchBackImage()
  }

  const imageUploadProps = {
    className: 'insurance-card-uploader',
    cropAspectRatio: 1.5446428,
    shouldKeepFileType: true,
    quality: 0.8,
    removeButtonClassName: 'insurance-card-remove-button',
    uploadedClassName: 'insurance-card-uploaded',
  }

  return (
    <>
      <SectionHeader
        sectionTitle="Insurance"
        description="Please list the details of any active health insurance coverage you have, even if you do not plan to use it for services here."
      />
      <Divider style={{ marginBottom: '5px' }} />
      <Row gutter={16}>
        <Col {...FULL_SPAN}>
          <Form.Item
            label="Insurance company name"
            name={InsuranceQuestionKeys.INSURANCE_NAME}
            rules={[
              optionalFreeText('Please input valid insurance company name'),
            ]}
            normalize={normalizeText}
          >
            <Select
              className={styles.fullWidth}
              loading={isLoading}
              size="large"
              placeholder="Select one"
              options={payers}
              filterOption={false}
              allowClear
              showSearch
              wrapOptions
            />
          </Form.Item>
        </Col>
        <Col {...FULL_SPAN}>
          <Form.Item
            label="Insurance member ID"
            name={InsuranceQuestionKeys.INSURANCE_MEMBER_ID}
            rules={[optionalFreeText('Please input valid member id')]}
            normalize={normalizeText}
          >
            <Input disabled={reviewMode} size="large" />
          </Form.Item>
        </Col>
        <Col {...FULL_SPAN}>
          <Form.Item
            label="Insurance group ID"
            name={InsuranceQuestionKeys.INSURANCE_GROUP_ID}
            rules={[optionalFreeText('Please input valid group id')]}
            normalize={normalizeText}
          >
            <Input disabled={reviewMode} size="large" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: '15px' }}>
        <Col {...HALF_SPAN}>
          <Form.Item
            label="Insurance Card - Front"
            name={InsuranceQuestionKeys.INSURANCE_CARD_IMAGE_FRONT}
          >
            <ImageUpload
              {...imageUploadProps}
              croppedImageBase64={insuranceFrontImage}
              imageAlt="Uploaded front of insurance card"
              isLoading={isFrontLoading}
              setCroppedImageBase64={(image: string) =>
                setImage(image, 'FRONT')
              }
            />
          </Form.Item>
        </Col>
        <Col {...HALF_SPAN}>
          <Form.Item
            label="Insurance Card - Back"
            name={InsuranceQuestionKeys.INSURANCE_CARD_IMAGE_BACK}
          >
            <ImageUpload
              {...imageUploadProps}
              croppedImageBase64={insuranceBackImage}
              imageAlt="Uploaded back of insurance card"
              isLoading={isBackLoading}
              setCroppedImageBase64={(image: string) => setImage(image, 'BACK')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...FULL_SPAN}>
          <Form.Item
            label="Are you the subscriber of the insurance listed above?"
            name={InsuranceQuestionKeys.IS_PRIMARY_INSURANCE_HOLDER}
          >
            <Radio.Group
              buttonStyle="solid"
              disabled={reviewMode}
              size="large"
              onChange={handleIsPrimaryInsuranceHolder}
            >
              <Radio.Button value={true}>Yes</Radio.Button>
              <Radio.Button value={false}>No</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      {(isPrimaryInsuranceHolder === false || reviewMode) && (
        <Row gutter={10}>
          <Col {...FULL_SPAN} style={{ marginTop: '20px' }}>
            <Header title="Insurance subscriber info" />
            <LargeSubText
              paragraphText="Fill in the following information for the subscriber of your
                             insurance plan."
            />
            <Form.Item
              label="Relationship with insurance subscriber"
              name={InsuranceQuestionKeys.PRIMARY_INSURED_RELATIONSHIP}
            >
              <Select
                className={styles.fullWidth}
                disabled={reviewMode}
                size="large"
                placeholder="Select one"
                options={relationshipOptions}
                allowClear
                showSearch
              />
              {/* TODO: We need to convert this to a dropdown and figure out values */}
            </Form.Item>
          </Col>
          <Col {...FULL_SPAN}>
            <Form.Item
              label="Insurance subscriber’s first name"
              name={InsuranceQuestionKeys.PRIMARY_INSURED_FIRST_NAME}
              rules={[optionalFreeText('Please input valid first name')]}
              normalize={normalizeText}
            >
              <Input disabled={reviewMode} size="large" />
            </Form.Item>
          </Col>
          <Col {...FULL_SPAN}>
            <Form.Item
              label="Insurance subscriber’s last name"
              name={InsuranceQuestionKeys.PRIMARY_INSURED_LAST_NAME}
              rules={[optionalFreeText('Please input valid last name')]}
              normalize={normalizeText}
            >
              <Input disabled={reviewMode} size="large" />
            </Form.Item>
          </Col>
          <Col {...FULL_SPAN}>
            <DateInput
              label="Insurance subscriber’s date of birth"
              name={InsuranceQuestionKeys.PRIMARY_INSURED_DATE_OF_BIRTH}
              disabled={reviewMode}
              form={form}
            />
          </Col>
          <Col {...FULL_SPAN}>
            <Form.Item
              label="Insurance subscriber’s legal sex per the insurance plan"
              name={InsuranceQuestionKeys.PRIMARY_INSURED_LEGAL_SEX}
            >
              <Radio.Group
                buttonStyle="solid"
                disabled={reviewMode}
                size="large"
              >
                {legalRecordedSex.map(({ label, value }) => (
                  <Radio.Button children={label} value={value} />
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col {...FULL_SPAN}>
            <Form.Item
              label="Insurance subscriber’s email"
              name={InsuranceQuestionKeys.PRIMARY_INSURED_EMAIL}
              rules={[email('Invalid email address')]}
            >
              <Input disabled={reviewMode} size="large" type="email" />
            </Form.Item>
          </Col>
          <Col {...FULL_SPAN}>
            <PhoneNumberInput
              disabled={reviewMode}
              form={form}
              name={InsuranceQuestionKeys.PRIMARY_INSURED_PHONE}
              formItemProps={{ label: 'Insurance subscriber’s phone' }}
              inputProps={{ size: 'large' }}
            />
          </Col>
          <Col {...FULL_SPAN}>
            <Form.Item
              label="Do you and the insurance subscriber share the same address?"
              name={InsuranceQuestionKeys.PRIMARY_INSURED_HAS_SAME_ADDRESS}
            >
              <Radio.Group
                buttonStyle="solid"
                size="large"
                onChange={(event) => setHideAddress(event.target.value)}
              >
                <Radio.Button value={true}>Yes</Radio.Button>
                <Radio.Button value={false}>No</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          {!hideAddress && (
            <Col {...FULL_SPAN}>
              {/* would need to save under PrimaryInsuredAddress on submit */}
              <Address
                disabled={reviewMode}
                form={form}
                label="Insurance subscriber’s address"
                labelUnit="Insurance subscriber's unit number"
                name={InsuranceQuestionKeys.PRIMARY_INSURED_ADDRESS}
                size="large"
              />
            </Col>
          )}
        </Row>
      )}
      <DividerWithSpacer />
      <Header title="Secondary insurance" />
      <LargeSubText
        paragraphText="A separate plan that offers additional benefits to your primary
                       insurance is called secondary insurance."
      />
      <Row gutter={16}>
        <Col {...FULL_SPAN}>
          <Form.Item
            label="Do you have a secondary insurance you would like to use with your primary insurance?"
            name={InsuranceQuestionKeys.HAS_SECONDARY_INSURANCE}
          >
            <Radio.Group
              buttonStyle="solid"
              disabled={reviewMode}
              size="large"
              onChange={handleHasSecondaryInsurance}
            >
              <Radio.Button value={true}>Yes</Radio.Button>
              <Radio.Button value={false}>No</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      {(hasSecondaryInsurance === true || reviewMode) && (
        <>
          <Row gutter={16}>
            <Col {...FULL_SPAN}>
              <Form.Item
                label="Secondary insurance company name"
                name={InsuranceQuestionKeys.SECONDARY_INSURANCE_NAME}
                rules={[
                  optionalFreeText('Please input valid insurance company name'),
                ]}
                normalize={normalizeText}
              >
                <Select
                  className={styles.fullWidth}
                  loading={isLoading}
                  size="large"
                  placeholder="Select one"
                  options={payers}
                  allowClear
                  showSearch
                />
              </Form.Item>
            </Col>
            <Col {...FULL_SPAN}>
              <Form.Item
                label="Secondary insurance member ID"
                name={InsuranceQuestionKeys.SECONDARY_INSURANCE_MEMBER_ID}
                rules={[optionalFreeText('Please input valid member id')]}
                normalize={normalizeText}
              >
                <Input disabled={reviewMode} size="large" />
              </Form.Item>
            </Col>
            <Col {...FULL_SPAN}>
              <Form.Item
                label="Secondary insurance group ID"
                name={InsuranceQuestionKeys.SECONDARY_INSURANCE_GROUP_ID}
                rules={[optionalFreeText('Please input valid group id')]}
                normalize={normalizeText}
              >
                <Input disabled={reviewMode} size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col {...FULL_SPAN}>
              <Form.Item
                label="Are you the subscriber of the secondary insurance listed above?"
                name={InsuranceQuestionKeys.IS_SECONDARY_INSURANCE_SUBSCRIBER}
              >
                <Radio.Group
                  buttonStyle="solid"
                  disabled={reviewMode}
                  size="large"
                  onChange={handleIsSecondaryInsuranceSubscriber}
                >
                  <Radio.Button value={true}>Yes</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          {(isSecondaryInsuranceSubscriber === false || reviewMode) && (
            <>
              <Row style={{ marginTop: '20px' }}>
                <Header
                  title="Secondary insurance subscriber info"
                  shouldAddSpacing={true}
                />
              </Row>
              <Col {...FULL_SPAN}>
                <LargeSubText paragraphText="Fill in the following information for the subscriber of your secondary insurance plan." />
                <Form.Item
                  label="Relationship with secondary insurance subscriber"
                  name={
                    InsuranceQuestionKeys.SECONDARY_INSURANCE_SUBSCRIBER_RELATIONSHIP
                  }
                >
                  <Select
                    className={styles.fullWidth}
                    disabled={reviewMode}
                    size="large"
                    placeholder="Select one"
                    options={relationshipOptions}
                    allowClear
                    showSearch
                  />
                </Form.Item>
              </Col>
              <Col {...FULL_SPAN}>
                <Form.Item
                  label="Secondary insurance subscriber’s first name"
                  name={
                    InsuranceQuestionKeys.SECONDARY_INSURANCE_SUBSCRIBER_FIRST_NAME
                  }
                  rules={[optionalFreeText('Please input valid first name')]}
                  normalize={normalizeText}
                >
                  <Input disabled={reviewMode} size="large" />
                </Form.Item>
              </Col>
              <Col {...FULL_SPAN}>
                <Form.Item
                  label="Secondary insurance subscriber’s last name"
                  name={
                    InsuranceQuestionKeys.SECONDARY_INSURANCE_SUBSCRIBER_LAST_NAME
                  }
                  rules={[optionalFreeText('Please input valid last name')]}
                  normalize={normalizeText}
                >
                  <Input disabled={reviewMode} size="large" />
                </Form.Item>
              </Col>
              <Col {...FULL_SPAN}>
                <DateInput
                  label="Secondary insurance subscriber’s date of birth"
                  name={
                    InsuranceQuestionKeys.SECONDARY_INSURANCE_SUBSCRIBER_DATE_OF_BIRTH
                  }
                  disabled={reviewMode}
                  form={form}
                />
              </Col>
            </>
          )}
        </>
      )}
      <Row>
        <DividerWithSpacer />
        <Col>
          <Header title="Authorization" />
          <LargeSubText
            paragraphText="We need your permission in order to process relevant in-network
            insurance claims. Without it, we won’t be able to submit insurance
            claims on your behalf."
          />
          <Header
            title="Primary insurance information release"
            shouldAddSpacing={true}
            levelSize={5}
          />
          <Form.Item
            label="Do you authorize the release of any medical or other information necessary to process insurance claims?"
            name={
              InsuranceQuestionKeys.IS_PRIMARY_INFORMATION_RELEASE_AUTHORIZED
            }
            className={styles.authorizationItem}
          >
            <Radio.Group buttonStyle="solid" disabled={reviewMode} size="large">
              <Radio.Button value={true}>Yes</Radio.Button>
              <Radio.Button value={false}>No</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col {...FULL_SPAN}>
          <Header title="Primary insurance payment release" levelSize={5} />
          <Form.Item
            label="Does the primary insurance subscriber authorize payment of medical benefits to the
                   undersigned physician or supplier for any in-network, claimed services?"
            name={InsuranceQuestionKeys.IS_PRIMARY_PAYMENT_AUTHORIZED}
            className={styles.authorizationItem}
          >
            <Radio.Group buttonStyle="solid" disabled={reviewMode} size="large">
              <Radio.Button value={true}>Yes</Radio.Button>
              <Radio.Button value={false}>No</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
        {(hasSecondaryInsurance || reviewMode) && (
          <Col {...FULL_SPAN}>
            <Header
              title="Secondary insurance information release"
              shouldAddSpacing={false}
              levelSize={5}
            />
            <Form.Item
              label="Do you authorize the release of any medical or other information necessary to process insurance claims?"
              name={
                InsuranceQuestionKeys.IS_SECONDARY_INFORMATION_RELEASE_AUTHORIZED
              }
              className={styles.authorizationItem}
            >
              <Radio.Group
                buttonStyle="solid"
                disabled={reviewMode}
                size="large"
              >
                <Radio.Button value={true}>Yes</Radio.Button>
                <Radio.Button value={false}>No</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Header title="Secondary insurance payment release" levelSize={5} />
            <Form.Item
              label="Does the secondary insurance subscriber authorize payment of medical benefits to the undersigned physician
                     or supplier for any in-network, claimed services?"
              name={InsuranceQuestionKeys.IS_SECONDARY_PAYMENT_AUTHORIZED}
              className={styles.authorizationItem}
            >
              <Radio.Group
                buttonStyle="solid"
                disabled={reviewMode}
                size="large"
              >
                <Radio.Button value={true}>Yes</Radio.Button>
                <Radio.Button value={false}>No</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        )}
      </Row>
      <Divider style={{ marginTop: '5px', marginBottom: '30px' }} />
    </>
  )
}

export default Insurance
