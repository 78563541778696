import { useMutation, useQueryClient } from '@tanstack/react-query'

import { notification } from '../../../../libs/notificationLib'
import {
  DynamicNoteBlock,
  UpdateDynamicBlockOptions,
} from '../../Components/Blocks/Core/Block/DynamicBlockTypes'
import { updateNoteBlock } from '../../api'
import { getNoteBlockQueryKey } from './useFetchNoteBlock'

type useCreateNoteProps = {
  onSuccess: (noteBlock: DynamicNoteBlock) => void
  onError?: (error: Error) => void
}

export const useUpdateNoteBlock = ({
  onSuccess,
  onError,
}: useCreateNoteProps) => {
  const defaultOnError = (error: Error) => {
    console.error(error)
    notification(
      'There was a problem creating the note block. Please contact your Osmind administrator.',
      'error'
    )
  }
  const queryClient = useQueryClient()
  const {
    mutate: update,
    isLoading,
    isSuccess,
    isError,
  } = useMutation(updateNoteBlock, {
    onSuccess: (noteBlock, opts) => {
      queryClient.invalidateQueries(
        getNoteBlockQueryKey(opts.noteUuid, opts.blockUuid)
      )
      onSuccess(noteBlock)
    },
    onError: onError ? onError : defaultOnError,
  })

  const callUpdate = (opts: UpdateDynamicBlockOptions) => {
    update(opts)
  }

  return {
    updateNoteBlock: callUpdate,
    isLoading,
    isSuccess,
    isError,
  }
}
