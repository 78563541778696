import React from 'react'

import { useField } from 'formik'

import { Switch } from '../../../stories/BaseComponents'

import styles from './SectionToggle.module.scss'

type SectionToggleProps = {
  name: string
  label: string
}

const SectionToggle: React.FC<SectionToggleProps> = ({ name, label }) => {
  const [{ value }, _, { setValue, setTouched }] = useField({ name })

  const handleToggle = () => {
    setTouched(true)
    setValue(!value)
  }

  return (
    <Switch
      label={label}
      onToggle={handleToggle}
      checked={value}
      className={styles.toggle}
      testId={name}
    />
  )
}

export default SectionToggle
