import React from 'react'

import Icon, { RedoOutlined } from '@ant-design/icons'
import { Button } from 'antd'

import { generateCoreIdDisplayName } from '../../../../../../../hooks/usePatientProfile/utils'
import { useProfileUrlParams } from '../../../../../../../hooks/useProfileUrlParams'
import { useAppContext } from '../../../../../../../libs/contextLib'
import { ActiveMedicationHistory } from '../../../../../../../shared-types'
import { Typography } from '../../../../../../../stories/BaseComponents'
import { ExternalLink } from '../../../../Icons/ExternalLink'
import { DynamicBlockRenderOptions } from '../../../Core/Block/DynamicBlockTypes'
import { DynamicBlockHeader } from '../shared/TableHeader'
import { MedicationsBlockTable } from './MedicationsBlockTable'

export const MedicationsBlock = ({
  block,
  note,
  onBlockUpdate,
}: DynamicBlockRenderOptions) => {
  const { Link } = Typography
  const { appSettings } = useAppContext() as unknown as {
    appSettings?: { erx: boolean }
  }

  const { urlParams } = useProfileUrlParams()
  const options = {
    isDrFirst: !!appSettings?.erx,
    urlParams,
  }

  const actionButtons = [
    <Link
      key={`${block.uuid}_refresh`}
      data-testid={`${block.uuid}_refresh`}
      onClick={() => {
        onBlockUpdate({
          blockConfig: {
            options: {
              limit: Date.now(),
            },
          },
          blockUuid: block.uuid,
          noteUuid: note.uuid,
        })
      }}
    >
      <RedoOutlined /> Refresh medications
    </Link>,
    <Button
      key={`${block.uuid}_add`}
      data-testid={`${block.uuid}_add`}
      icon={<Icon component={() => <ExternalLink />} />}
      onClick={() => {
        if (!options || !options.urlParams) return
        window.open(
          `/patient/medication?${options.urlParams}`,
          '_blank',
          'noreferrer'
        )
      }}
    >
      {options.isDrFirst ? 'Edit medications in Dr. First' : 'Edit medications'}
    </Button>,
  ]

  return (
    <>
      <DynamicBlockHeader
        title={'Active Medications'}
        buttons={actionButtons}
        isSigned={!!note?.firstSignedAt}
      />
      <div>
        <MedicationsBlockTable
          isSigned={!!note?.firstSignedAt}
          patientName={
            note?.patient && generateCoreIdDisplayName(note?.patient)
          }
          isDrFirst={options.isDrFirst}
          blockData={block.data as ActiveMedicationHistory[]}
          isLoading={false}
        />
      </div>
    </>
  )
}
