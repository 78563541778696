import React from 'react'

import Icon, { RedoOutlined } from '@ant-design/icons'
import { Button } from 'antd'

import { generateCoreIdDisplayName } from '../../../../../../../hooks/usePatientProfile/utils'
import { useProfileUrlParams } from '../../../../../../../hooks/useProfileUrlParams'
import { useAppContext } from '../../../../../../../libs/contextLib'
import { AllergyHistory } from '../../../../../../../shared-types'
import { Typography } from '../../../../../../../stories/BaseComponents'
import { ExternalLink } from '../../../../Icons/ExternalLink'
import {
  DynamicBlockRenderOptions,
  MedicationsBlockOptions,
} from '../../../Core/Block/DynamicBlockTypes'
import { DynamicBlockHeader } from '../shared/TableHeader'
import { AllergiesBlockTable } from './AllergiesBlockTable'

export const AllergiesBlock = ({
  block,
  note,
  onBlockUpdate,
}: DynamicBlockRenderOptions) => {
  const { Link } = Typography
  const { appSettings } = useAppContext() as unknown as {
    appSettings?: { erx: boolean }
  }
  const { urlParams } = useProfileUrlParams()

  const options = {
    isDrFirst: !!appSettings?.erx,
    urlParams,
  }

  const actionButtons = [
    <Link
      key={`${block.uuid}_refresh`}
      data-testid={`${block.uuid}_refresh`}
      onClick={() => {
        const update: MedicationsBlockOptions = {
          limit: Date.now(),
        }
        onBlockUpdate({
          blockConfig: { options: update },
          blockUuid: block.uuid,
          noteUuid: note.uuid,
        })
      }}
    >
      <RedoOutlined /> Refresh allergies
    </Link>,
    <Button
      icon={<Icon component={() => <ExternalLink />} />}
      key={`${block.uuid}_add`}
      data-testid={`${block.uuid}_add`}
      onClick={() => {
        if (!options) return

        if (options.isDrFirst) {
          window.open(
            `/patient/medication?${options.urlParams}`,
            '_blank',
            'noreferrer'
          )
        } else {
          window.open(
            `/patient/profile?${options.urlParams}&patientProfile=allergies`,
            '_blank',
            'noreferrer'
          )
        }
      }}
    >
      {options.isDrFirst ? 'Edit allergies in Dr. First' : 'Edit allergies'}
    </Button>,
  ]
  return (
    <>
      <DynamicBlockHeader
        title={'Active Allergies'}
        buttons={actionButtons}
        isSigned={!!note?.firstSignedAt}
      />
      <div>
        <AllergiesBlockTable
          isSigned={!!note?.firstSignedAt}
          blockData={block.data ? (block.data as AllergyHistory[]) : undefined}
          patientName={
            note?.patient && generateCoreIdDisplayName(note?.patient)
          }
          isLoading={false}
        />
      </div>
    </>
  )
}
