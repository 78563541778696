// FE data model
export type NdcItem = {
  key: string
  uuid?: string
  billingCodeId: number | null
  idQualifier: NdcIdQualifier | null
  ndc: string | null
  ndcLabel: string | null
  numberOfUnits: number | null
  unitType: UnitMeasurementType | null
  prescriptionNumber: string | null
}

// BE data model
export type InsuranceClaimNdc = {
  uuid?: string
  billingCodeId: number | null
  ndcIdQualifier: NdcIdQualifier | null
  ndcEntered: string | null
  ndcLabel: string | null
  numberOfUnits: number | null
  unitMeasurementType: UnitMeasurementType | null
  prescriptionNumber: string | null
}

export enum NdcIdQualifier {
  NDC_IN_5_4_2_FORMAT = 'NDC_IN_5_4_2_FORMAT',
  EAN_UCC_13 = 'EAN_UCC_13',
  EAN_UCC_8 = 'EAN_UCC_8',
  HEALTH_INDUSTRY_BAR_CODE_SUPPLIER_LABELING = 'HEALTH_INDUSTRY_BAR_CODE_SUPPLIER_LABELING',
  CUSTOMER_ORDER_NUMBER = 'CUSTOMER_ORDER_NUMBER',
  GTIN_14 = 'GTIN_14',
  UCC_12 = 'UCC_12',
}

export enum UnitMeasurementType {
  INTERNATIONAL_UNIT = 'INTERNATIONAL_UNIT',
  GRAM = 'GRAM',
  MILLIGRAM = 'MILLIGRAM',
  MILLILITER = 'MILLILITER',
  UNIT = 'UNIT',
}
