import { useMemo } from 'react'

// Import only the necessary functions from date-fns library, which is more modern and tree-shakable
import { format } from 'date-fns-tz'

import { Addendum, Signature } from '../../types'
import { Typography } from './../../../../stories/BaseComponents'
import { Addendums } from './Addendums'

export type SignaturesProps = {
  signatures: Signature[]
  addendums: Addendum[]
}

export const SignaturesPrintMode = ({
  signatures,
  addendums,
}: SignaturesProps) => {
  const { Text } = Typography

  // Memoize the rendering logic for signatures to prevent unnecessary re-renders
  const renderedSignatures = useMemo(() => {
    if (!Array.isArray(signatures) || signatures.length === 0) {
      return null
    }

    return signatures.map((signature, i) => {
      if (!signature || !signature.signedAt || !signature.providerName) {
        return null
      }

      const date = format(
        new Date(signature.signedAt),
        'EEEE, MMMM d, yyyy h:mm a'
      )

      return (
        <div
          key={`signature-${i}`}
          style={{
            paddingBottom: '10px',
            display: 'flex',
            flexDirection: 'column',
            fontSize: '11pt',
          }}
        >
          <Text>
            <b style={{ paddingRight: '1ch', margin: '2px 0' }}>Signee: </b>
            {`Electronically signed by ${signature.providerName}`}
          </Text>
          <Text style={{ margin: '2px 0' }}>{date}</Text>
          <Text
            style={{
              display: 'flex',
              margin: '2px 0',
            }}
          >
            <b style={{ paddingRight: '1ch' }}>Comments: </b>
            {signature.comments}
          </Text>
        </div>
      )
    })
  }, [signatures])

  if (!renderedSignatures) {
    return null
  }

  return (
    <div>
      <h1>Signatures</h1>
      {renderedSignatures}
      {addendums.length > 0 && <Addendums addendums={addendums} />}
    </div>
  )
}
