import React, { useCallback, useMemo, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { orderBy } from 'lodash'
import { Accordion, Button, Card } from 'react-bootstrap'

import AddScoreModal from '../../components/Forms/AddScoreModal'
import ScoreCard from '../../components/Other/ScoreCard'
import { usePatientClinicalNotes } from '../../hooks/usePatientClinicalNotes'
import { usePatientDemographics } from '../../hooks/usePatientInfo'
import { QueryKeys } from '../../hooks/usePatientProfile'
import { usePatientSettings } from '../../hooks/usePatientSettings'
import { useProviderDetails } from '../../hooks/useProviderInfo'
import { useProviderSidePatientData } from '../../hooks/useProviderSidePatientData'
import useQueryString from '../../hooks/useQueryString'
import { RenderChart } from '../../libs/chartsLib'
import { Col, InfoPage, Row, Skeleton } from '../../stories/BaseComponents'
import { StandardSkeletonRows } from '../../stories/BaseComponents/Skeleton'
import { PatientHeader } from './PatientHeader'
import { patientDataTypes } from './patient-data-types'

import styles from '../_shared.module.scss'
import './Patient.scss'

const ErrorView = (
  <InfoPage
    status="warning"
    title="Sorry there was a problem loading this page"
    details="Oops something went wrong. Please contact your Osmind representative if this issue persists."
    redirectButtonText="Return to Patient List"
    redirectLink="/"
  />
)

export default function PatientChartsPage({
  healthGorillaUserName,
}: {
  healthGorillaUserName: string
}) {
  const query = useQueryString()
  const queryClient = useQueryClient()
  const patientId = query.get('patientId')
  const providerId = query.get('providerId')
  const [showAddScore, setShowAddScore] = useState(false)

  const {
    isLoading: patientDataIsLoading,
    isError: patientDataIsError,
    data: patient,
  } = usePatientDemographics(patientId ?? '')

  const {
    isLoading: providerSidePatientIsLoading,
    isError: providerSidePatientIsError,
    data: providerSidePatientData,
  } = useProviderSidePatientData(patientId ?? '')

  const { data: patientClinicalNotes } = usePatientClinicalNotes(
    patientId ?? ''
  )

  const {
    isLoading: patientSettingsIsLoading,
    isError: patientSettingsIsError,
    data: patientSettingsData,
  } = usePatientSettings(patientId ?? '')

  const {
    isLoading: providerDetailsIsLoading,
    isError: providerDetailsIsError,
    data: providerDetails,
  } = useProviderDetails()

  const providerPatientData = useMemo(() => {
    if (providerSidePatientData) {
      return {
        PatientId: patientId,
        ...providerSidePatientData,
      }
    } else {
      return undefined
    }
  }, [providerSidePatientData])

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries([
      patientDataTypes.ProviderSidePatientData,
      patientId,
    ])
    queryClient.invalidateQueries([patientDataTypes.PatientSettings, patientId])
    queryClient.invalidateQueries([QueryKeys.CORE_IDENTIFIERS])
  }, [queryClient])

  const handleShowAddScore = useCallback(() => {
    setShowAddScore(true)
  }, [])

  const handleCloseAddScore = useCallback(() => {
    setShowAddScore(false)
  }, [])

  const queriesLoading =
    patientDataIsLoading ||
    patientSettingsIsLoading ||
    providerSidePatientIsLoading ||
    providerDetailsIsLoading

  const noQueryData =
    !patient ||
    !providerDetails ||
    !patientSettingsData ||
    !providerSidePatientData ||
    !providerPatientData

  const queriesError =
    patientDataIsError ||
    patientSettingsIsError ||
    providerSidePatientIsError ||
    providerDetailsIsError

  const oneTimeSurveys = useMemo(() => {
    if (!providerDetails?.surveyData) {
      return []
    } else {
      return orderBy(
        providerDetails.surveyData.filter((survey: any) => !survey.IsRecurring),
        (survey: any) => {
          return survey.Id
        },
        ['asc']
      )
    }
  }, [providerDetails?.surveyData])

  const recurringSurveys = useMemo(() => {
    if (!patientSettingsData?.questionnaires) {
      return []
    }
    return patientSettingsData?.questionnaires
      .filter((survey) => survey.IsRecurring)
      .sort((a, b) => String(a.Name).localeCompare(String(b.Name)))
  }, [patientSettingsData?.questionnaires])

  if (queriesError || !patientId || !providerId) {
    return ErrorView
  }

  return (
    <div className={styles.scroll}>
      <PatientHeader
        patientId={patientId}
        providerId={providerId}
        healthGorillaUserName={healthGorillaUserName}
      />
      {queriesLoading || noQueryData ? (
        <Skeleton paragraph={{ rows: StandardSkeletonRows.fullPage }} />
      ) : (
        <div className={styles.patientProgressContainer}>
          <div className={`${styles.centeredHeader} side-note extra-large`}>
            <i
              style={{ fontSize: '26px' }}
              className="fa fa-comment-o"
              aria-hidden="true"
            />
            &nbsp; Double click on a node in
            <span className="emphasis"> any</span> chart to view individual
            patient responses (<span className="emphasis">except</span>
            &nbsp;the&nbsp;
            <span className="emphasis">mood</span>&nbsp;and&nbsp;
            <span className="emphasis">pain</span>&nbsp;charts).
          </div>
          <Row className="justify-content-center">
            <Col xs={12} sm={12} md={24} lg={24} xl={24}>
              <Button
                id="addScoreManuallyBotton"
                className={`button center ${styles.addScoreButton}`}
                style={{ width: '203px' }}
                onClick={handleShowAddScore}
              >
                <i className="fa fa-plus-circle" aria-hidden="true" />
                &nbsp; Administer Clinician Rated Measure or Add Score Manually
              </Button>
            </Col>
            <AddScoreModal
              handleApiChange={invalidateQuery}
              handleClose={handleCloseAddScore}
              show={showAddScore}
              PatientId={patient.PatientId}
              surveyData={providerDetails.surveyData}
              patientSurveySettingsInfo={patientSettingsData.surveySettings}
            />
          </Row>
          <br />
          <div className="chart-container">
            {recurringSurveys.map((survey) => {
              const submissions =
                patientSettingsData.surveySubmissions[survey.ScoreTypeEnum]
              return (
                <div key={`${survey.ScoreTypeEnum}--chart-accordion`}>
                  {submissions?.length > 0 ? (
                    <Accordion bsPrefix="plain-accordion">
                      <Accordion.Toggle
                        bsPrefix="align-center accordion-header accordion-toggle patient-card very-large"
                        as={Card.Header}
                        variant="link"
                        eventKey="1"
                      >
                        {survey.Name}
                      </Accordion.Toggle>
                      <Accordion.Collapse
                        eventKey="1"
                        style={{ backgroundColor: 'white' }}
                      >
                        <RenderChart
                          loggedInProviderId={
                            providerDetails.loggedInProviderId
                          }
                          handleApiChange={invalidateQuery}
                          type={survey.ScoreTypeEnum}
                          clinicalNotes={patientClinicalNotes ?? []}
                          survey={survey}
                          submissions={submissions}
                        />
                      </Accordion.Collapse>
                    </Accordion>
                  ) : null}
                </div>
              )
            })}
            <div id="single-scores-cards">
              {oneTimeSurveys.map((survey) => {
                const submissions =
                  patientSettingsData.surveySubmissions[survey.ScoreTypeEnum]

                return submissions?.length > 0 ? (
                  <ScoreCard
                    loggedInProviderId={providerDetails.loggedInProviderId}
                    handleApiChange={invalidateQuery}
                    key={survey.ScoreTypeEnum}
                    type={survey.ScoreTypeEnum}
                    survey={survey}
                    submissions={submissions}
                  />
                ) : null
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
