import React, { ChangeEvent, useEffect, useRef, useState } from 'react'

import { Button, Skeleton } from 'antd'
import { format } from 'date-fns'
import { cloneDeep, isBoolean } from 'lodash'

import { updateInsurance } from '../../api/api-lib'
import {
  ChangePayersList,
  getInsuranceCardUploadUrl,
} from '../../api/intakeForms'
import SkeletonLoadingTransition from '../../components/Animation/SkeletonLoadingTransition'
import {
  useChangePayers,
  useInsuranceInfo,
} from '../../hooks/usePatientProfile'
import {
  InsuranceHolder,
  InsurancePrimary,
  InsurancePrimaryHolder,
  InsuranceSecondary,
  InsuranceSecondaryHolder,
  PatientInsurance,
  PatientInsuranceWithOptionalInsuranceHolder,
} from '../../hooks/usePatientProfile/shared-types'
import useQueryString from '../../hooks/useQueryString'
import { notification } from '../../libs/notificationLib'
import { emailRegEx, tenDigitPhoneNumberRegEx } from '../../libs/regex'
import { formatDate } from '../../libs/utils'
import { Popconfirm } from '../BaseComponents'
import Fields, { FieldGroupProps, Item } from './Fields'
import {
  InsuranceSubscriberItems,
  insuranceEmptyObject,
  insuranceHolderEmptyObject,
  primaryInsuranceComponents,
  secondaryInsuranceComponents,
} from './Insurance-Helper'
import InsuranceCards from './InsuranceCards'

import styles from './Insurance.module.scss'

const INSURANCE_CARD_FRONT = '#INSURANCE_CARD#FRONT'
const INSURANCE_CARD_BACK = '#INSURANCE_CARD#BACK'

export interface InsuranceProps {
  loading?: boolean
  testId?: string
}

const Loading = () => (
  <div className={styles.skeletonContainer}>
    <Skeleton
      active
      title={{ width: 85 }}
      paragraph={{
        rows: 3,
        width: '100%',
        className: styles.skeletonParagraph,
      }}
    />
  </div>
)

const Insurance: React.FC<InsuranceProps> = ({ testId }) => {
  const query = useQueryString()
  const patientId = query.get('patientId') ?? ''
  const containerRef: React.Ref<HTMLDivElement> = useRef(null)
  const [compact, setCompact] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [primaryInsurance, setPrimaryInsurance] = useState<
    null | PatientInsurance | undefined
  >(insuranceEmptyObject)
  const [primaryHolder, setPrimaryHolder] = useState<
    null | InsuranceHolder | undefined
  >(null)
  const [secondaryInsurance, setSecondaryInsurance] = useState<
    null | PatientInsurance | undefined
  >(null)
  const [secondaryHolder, setSecondaryHolder] = useState<
    null | InsuranceHolder | undefined
  >(null)
  const [imageUrlFront, setImageUrlFront] = useState<null | string>(null)
  const [showFrontCard, setShowFrontCard] = useState<boolean>(false)
  const [showBackCard, setShowBackCard] = useState<boolean>(false)
  const [imageUrlBack, setImageUrlBack] = useState<null | string>(null)
  const [loadingImages, setLoadingImages] = useState(false)

  const { data: changeList, isFetching: loadingChangeList } = useChangePayers()

  const [primary, setPrimary] = useState<FieldGroupProps[]>(
    primaryInsuranceComponents
  )

  const [secondary, setSecondary] = useState<FieldGroupProps[]>(
    secondaryInsuranceComponents
  )

  const handleResize = () => {
    const shouldBeCompact = (containerRef.current?.clientWidth || 301) < 320
    setCompact(shouldBeCompact)
  }

  const { data, isFetching, refetch, isError } = useInsuranceInfo({ patientId })

  async function handlePatientCards() {
    setImageUrlFront(data.presignedUrlCardFront || null)
    setImageUrlBack(data.presignedUrlCardBack || null)
  }

  async function fetchPatientInsurance() {
    if (data?.insurances) {
      const insurances = cloneDeep(data.insurances)
      const [primaryI] = insurances.filter((dC: any) => dC.isPrimaryInsurance)
      setPrimaryHolder(primaryI?.InsuranceHolder || null)
      const primaryIClone = cloneDeep(primaryI)
      if (primaryIClone?.InsuranceHolder) delete primaryIClone.InsuranceHolder
      setPrimaryInsurance({
        ...insuranceEmptyObject,
        ...primaryIClone,
        ...{ isPrimaryInsurance: true },
      })
      const [secondaryI] = insurances.filter(
        (dC: any) => !dC.isPrimaryInsurance
      )
      setSecondaryHolder(secondaryI?.InsuranceHolder || null)
      const secondaryIClone = cloneDeep(secondaryI)
      if (secondaryIClone?.InsuranceHolder)
        delete secondaryIClone.InsuranceHolder
      setSecondaryInsurance(secondaryIClone || null)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (data && !isFetching) {
      fetchPatientInsurance()
      handlePatientCards()
    } else if (isError) {
      setLoading(false)
    }
  }, [data, isFetching])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (loading || loadingChangeList || !changeList) return
    const orgNameArray: string[] = []
    const changeOptions: {
      value: string
      label: string
      disabled?: boolean
    }[] = changeList.map((cL: ChangePayersList) => {
      orgNameArray.push(cL.organizationName)
      return {
        value: cL.organizationName,
        label: cL.organizationName,
      }
    })
    const itemsCopy: FieldGroupProps[] = cloneDeep<FieldGroupProps[]>(
      primaryInsuranceComponents
    )
    if (primaryInsurance) {
      if (
        primaryInsurance.insuranceName &&
        !orgNameArray.includes(primaryInsurance.insuranceName)
      ) {
        changeOptions.unshift({
          value: primaryInsurance.insuranceName,
          label: primaryInsurance.insuranceName,
          disabled: true,
        })
      }

      if (primaryHolder && primaryHolder?.hasSameAddressAsPatient) {
        const isSameAddressPatient = itemsCopy[1].items.slice(0, 8)
        itemsCopy[1].items = isSameAddressPatient
      } else if (primaryHolder?.hasSameAddressAsPatient === false) {
        itemsCopy[1].items = cloneDeep<Item[]>(InsuranceSubscriberItems)
      }

      if (!primaryHolder || !primaryHolder?.relationship) {
        const selfRelation = itemsCopy[1].items.slice(0, 1)
        itemsCopy[1].items = selfRelation
      } else if (
        primaryHolder &&
        primaryHolder.relationship &&
        itemsCopy[1].items.length === 1
      ) {
        itemsCopy[1].items = cloneDeep<Item[]>(InsuranceSubscriberItems)
      }

      itemsCopy.forEach(({ items }: { items: Item[] }) => {
        items.forEach((i: Item) => {
          switch (i.id) {
            case InsurancePrimary.insuranceName:
              i.value = primaryInsurance.insuranceName
              break
            case InsurancePrimary.insuranceGroupId:
              i.value = primaryInsurance.insuranceGroupId
              break
            case InsurancePrimary.insuranceMemberId:
              i.value = primaryInsurance.insuranceMemberId
              break
            case InsurancePrimary.informationRelease:
              if (primaryInsurance.isInformationReleaseAuthorized !== null) {
                i.value = primaryInsurance.isInformationReleaseAuthorized
                  ? 'Yes'
                  : 'No'
              } else {
                i.value = null
              }

              break
            case InsurancePrimary.paymentAutorization:
              if (primaryInsurance.isPaymentAuthorized !== null) {
                i.value = primaryInsurance.isPaymentAuthorized ? 'Yes' : 'No'
              } else {
                i.value = null
              }
              break
            case InsurancePrimaryHolder.primaryRelationship:
              if (
                primaryInsurance.isPrimaryInsuranceHolder === null &&
                !primaryHolder
              ) {
                i.value = null
              } else if (
                primaryInsurance.isPrimaryInsuranceHolder &&
                !primaryHolder
              ) {
                i.value = 'Self'
              } else {
                i.value = primaryHolder?.relationship || ''
              }
              break
            case InsurancePrimaryHolder.primaryFirstName:
              i.value = primaryHolder?.firstName || ''
              break
            case InsurancePrimaryHolder.primaryLastName:
              i.value = primaryHolder?.lastName || ''
              break
            case InsurancePrimaryHolder.dateOfBirth:
              i.value = primaryHolder?.dateOfBirth || ''
              break
            case InsurancePrimaryHolder.legalSex:
              if (
                primaryHolder?.legalSex &&
                (primaryHolder.legalSex === 'M' ||
                  primaryHolder.legalSex === 'F' ||
                  primaryHolder.legalSex === 'X' ||
                  primaryHolder.legalSex === 'Unknown')
              ) {
                i.value = primaryHolder?.legalSex || ''
              } else {
                i.value = null
              }
              break
            case InsurancePrimaryHolder.email:
              i.value = primaryHolder?.email || ''
              break
            case InsurancePrimaryHolder.phoneNumber:
              i.value = primaryHolder?.phoneNumber || ''
              break
            case InsurancePrimaryHolder.sameAddressAsPatient:
              if (primaryHolder?.hasSameAddressAsPatient === null) {
                i.value = null
              } else {
                i.value =
                  primaryHolder?.hasSameAddressAsPatient === true ? 'Yes' : 'No'
              }
              break
            case InsurancePrimaryHolder.address:
              i.value = primaryHolder?.address1
                ? `${primaryHolder.address1}${
                    primaryHolder.city ? `, ${primaryHolder.city}` : ''
                  }${primaryHolder.state ? `, ${primaryHolder.state}` : ''}${
                    primaryHolder.zipCode ? `, ${primaryHolder.zipCode}` : ''
                  }`
                : ''
              break
            case InsurancePrimaryHolder.unit:
              i.value = primaryHolder?.address2 || ''
              break
          }
        })
      })
    }
    itemsCopy[0].items[0].options = changeOptions
    setPrimary(itemsCopy)
  }, [loading, primaryInsurance, primaryHolder, loadingChangeList])

  useEffect(() => {
    if (loading || loadingChangeList || !changeList) return
    let secondaryInsComponentsCopy: FieldGroupProps[] = cloneDeep<
      FieldGroupProps[]
    >(secondaryInsuranceComponents)
    if (secondaryInsurance) {
      const orgNameArray: string[] = []
      const changeOptions: {
        value: string
        label: string
        disabled?: boolean
      }[] = changeList.map((cL: ChangePayersList) => {
        orgNameArray.push(cL.organizationName)
        return {
          value: cL.organizationName,
          label: cL.organizationName,
        }
      })
      if (
        secondaryInsurance.insuranceName &&
        !orgNameArray.includes(secondaryInsurance.insuranceName)
      ) {
        changeOptions.unshift({
          value: secondaryInsurance.insuranceName,
          label: secondaryInsurance.insuranceName,
          disabled: true,
        })
      }
      secondaryInsComponentsCopy[0].items[0].options = changeOptions
      if (
        secondaryInsurance.isPrimaryInsuranceHolder ||
        !secondaryHolder ||
        !secondaryHolder.relationship
      ) {
        const selfRelation = secondaryInsComponentsCopy.slice(0, 1)
        secondaryInsComponentsCopy = selfRelation
      }
      secondaryInsComponentsCopy.forEach(({ items }: { items: Item[] }) => {
        items.forEach((i: Item) => {
          switch (i.id) {
            case InsuranceSecondary.secondaryInsuranceName:
              i.value = secondaryInsurance.insuranceName
              break
            case InsuranceSecondary.secondaryInsuranceGroupId:
              i.value = secondaryInsurance.insuranceGroupId
              break
            case InsuranceSecondary.secondaryInsuranceMemberId:
              i.value = secondaryInsurance.insuranceMemberId
              break
            case InsuranceSecondary.secondaryInformationRelease:
              if (secondaryInsurance.isInformationReleaseAuthorized !== null) {
                i.value = secondaryInsurance.isInformationReleaseAuthorized
                  ? 'Yes'
                  : 'No'
              } else {
                i.value = null
              }
              break
            case InsuranceSecondary.secondaryPaymentAuthorization:
              if (secondaryInsurance.isPaymentAuthorized !== null) {
                i.value = secondaryInsurance.isPaymentAuthorized ? 'Yes' : 'No'
              } else {
                i.value = null
              }
              break
            case InsuranceSecondaryHolder.secondaryFirstName:
              i.value = secondaryHolder?.firstName || ''
              break
            case InsuranceSecondaryHolder.secondaryLastName:
              i.value = secondaryHolder?.lastName || ''
              break
            case InsuranceSecondaryHolder.secondaryDateOfBirth:
              i.value = secondaryHolder?.dateOfBirth || ''
              break
            case InsuranceSecondaryHolder.secondaryRelationship:
              if (
                secondaryInsurance.isPrimaryInsuranceHolder === null &&
                !secondaryHolder
              ) {
                i.value = null
                break
              } else if (
                secondaryInsurance.isPrimaryInsuranceHolder ||
                !secondaryHolder
              ) {
                i.value = 'Self'
                break
              } else {
                i.value = secondaryHolder?.relationship || null
              }
              break
          }
        })
      })
      setSecondary(secondaryInsComponentsCopy)
    } else {
      const items = [{ groupName: 'Secondary insurance', items: [] }]
      setSecondary(items)
    }
  }, [loading, secondaryInsurance, secondaryHolder, loadingChangeList])

  async function updateInsuranceInformation(
    primaryInsurance: PatientInsuranceWithOptionalInsuranceHolder,
    secondaryInsurance: PatientInsuranceWithOptionalInsuranceHolder | {}
  ) {
    try {
      await updateInsurance(primaryInsurance, secondaryInsurance, patientId)
      await refetch()
    } catch (e) {
      console.error('There was an error saving insurnace information:: ', e)
      notification(
        'There was an error saving the patient insurance information.',
        'failure'
      )
    } finally {
      setLoading(false)
      setLoadingImages(false)
    }
  }

  const handleSave = (
    newValue: any,
    id: string,
    _groupName: string,
    _label?: string
  ) => {
    let newVal: any
    if (typeof newValue === 'string') {
      newVal = newValue.trim()
    } else {
      newVal = newValue
    }
    if (id === 'addSecondary') {
      setSecondaryInsurance(insuranceEmptyObject)
      return
    }
    let primaryInsuranceObject: PatientInsurance | null = null
    let primaryInsuranceHolderObject: InsuranceHolder | null = null
    let secondaryInsuranceObject: PatientInsurance | null = null
    let secondaryInsuranceHolderObject: InsuranceHolder | null = null

    let primaryInsuranceItem: any = {}
    let secondaryInsuranceItem: any = {}

    if (primaryInsurance || Object.values(InsurancePrimary).includes(id)) {
      const primaryInsuranceCopy = { ...primaryInsurance }
      primaryInsuranceObject = {
        ...insuranceEmptyObject,
        ...primaryInsuranceCopy,
      }
      primaryInsuranceItem = {
        patientInsurance: {
          insuranceName: primaryInsuranceObject.insuranceName || null,
          insuranceMemberId: primaryInsuranceObject.insuranceMemberId || null,
          insuranceGroupId: primaryInsuranceObject.insuranceGroupId || null,
          insuranceCompany: primaryInsuranceObject.insuranceCompany || null,
          insuranceCompanyAddress1:
            primaryInsuranceObject.insuranceCompanyAddress1 || null,
          insuranceCompanyAddress2:
            primaryInsuranceObject.insuranceCompanyAddress2 || null,
          insuranceCompanyCity:
            primaryInsuranceObject.insuranceCompanyCity || null,
          insuranceCompanyState:
            primaryInsuranceObject.insuranceCompanyState || null,
          insuranceCompanyZipCode:
            primaryInsuranceObject.insuranceCompanyZipCode || null,
          insuranceCardBack: primaryInsuranceObject.insuranceCardBack || null,
          insuranceCardFront: primaryInsuranceObject.insuranceCardFront || null,
          isPrimaryInsuranceHolder: true,
          hasInsuranceCardSaved:
            primaryInsuranceObject.hasInsuranceCardSaved ||
            (isBoolean(primaryInsuranceObject?.hasInsuranceCardSaved)
              ? false
              : null),
          isPaymentAuthorized:
            primaryInsuranceObject.isPaymentAuthorized ||
            (isBoolean(primaryInsuranceObject?.isPaymentAuthorized)
              ? false
              : null),
          isInformationReleaseAuthorized:
            primaryInsuranceObject.isInformationReleaseAuthorized ||
            (isBoolean(primaryInsuranceObject?.isInformationReleaseAuthorized)
              ? false
              : null),
        },
      }
      if (primaryHolder || Object.values(InsurancePrimaryHolder).includes(id)) {
        primaryInsuranceHolderObject = {
          ...insuranceHolderEmptyObject,
          ...primaryHolder,
        }
        primaryInsuranceItem.patientInsurance.isPrimaryInsuranceHolder = false
        primaryInsuranceItem.insuranceHolder = {
          address1: primaryInsuranceHolderObject.address1 || null,
          address2: primaryInsuranceHolderObject.address2 || null,
          zipCode: primaryInsuranceHolderObject.zipCode || null,
          city: primaryInsuranceHolderObject.city || null,
          state: primaryInsuranceHolderObject.state || null,
          country: primaryInsuranceHolderObject.country || null,
          dateOfBirth: primaryInsuranceHolderObject.dateOfBirth || null,
          email: primaryInsuranceHolderObject.email || null,
          firstName: primaryInsuranceHolderObject.firstName || null,
          lastName: primaryInsuranceHolderObject.lastName || null,
          phoneNumber: primaryInsuranceHolderObject.phoneNumber || null,
          relationship: primaryInsuranceHolderObject.relationship || null,
          legalSex: primaryInsuranceHolderObject.legalSex || null,
          hasSameAddressAsPatient:
            primaryInsuranceHolderObject.hasSameAddressAsPatient ||
            (isBoolean(primaryInsuranceHolderObject?.hasSameAddressAsPatient)
              ? false
              : null),
        }
      }
    }
    if (secondaryInsurance || Object.values(InsuranceSecondary).includes(id)) {
      const secondaryInsuranceCopy = { ...secondaryInsurance }
      secondaryInsuranceObject = {
        ...insuranceEmptyObject,
        ...secondaryInsuranceCopy,
      }
      secondaryInsuranceItem = {
        patientInsurance: {
          insuranceName: secondaryInsuranceObject.insuranceName || null,
          insuranceMemberId: secondaryInsuranceObject.insuranceMemberId || null,
          insuranceGroupId: secondaryInsuranceObject.insuranceGroupId || null,
          insuranceCompany: secondaryInsuranceObject.insuranceCompany || null,
          insuranceCompanyAddress1:
            secondaryInsuranceObject.insuranceCompanyAddress1 || null,
          insuranceCompanyAddress2:
            secondaryInsuranceObject.insuranceCompanyAddress2 || null,
          insuranceCompanyCity:
            secondaryInsuranceObject.insuranceCompanyCity || null,
          insuranceCompanyState:
            secondaryInsuranceObject.insuranceCompanyState || null,
          insuranceCompanyZipCode:
            secondaryInsuranceObject.insuranceCompanyZipCode || null,
          insuranceCardBack: secondaryInsuranceObject.insuranceCardBack || null,
          insuranceCardFront:
            secondaryInsuranceObject.insuranceCardFront || null,
          isPrimaryInsuranceHolder: true,
          hasInsuranceCardSaved:
            secondaryInsuranceObject.hasInsuranceCardSaved ||
            (isBoolean(secondaryInsuranceObject?.hasInsuranceCardSaved)
              ? false
              : null),
          isPaymentAuthorized:
            secondaryInsuranceObject.isPaymentAuthorized ||
            (isBoolean(secondaryInsuranceObject?.isPaymentAuthorized)
              ? false
              : null),
          isInformationReleaseAuthorized:
            secondaryInsuranceObject.isInformationReleaseAuthorized ||
            (isBoolean(secondaryInsuranceObject?.isInformationReleaseAuthorized)
              ? false
              : null),
        },
      }
      if (
        secondaryHolder ||
        Object.values(InsuranceSecondaryHolder).includes(id)
      ) {
        secondaryInsuranceHolderObject = {
          ...insuranceHolderEmptyObject,
          ...secondaryHolder,
        }
        secondaryInsuranceItem.patientInsurance.isPrimaryInsuranceHolder = false
        secondaryInsuranceItem.insuranceHolder = {
          address1: secondaryInsuranceHolderObject.address1 || null,
          address2: secondaryInsuranceHolderObject.address2 || null,
          zipCode: secondaryInsuranceHolderObject.zipCode || null,
          city: secondaryInsuranceHolderObject.city || null,
          state: secondaryInsuranceHolderObject.state || null,
          country: secondaryInsuranceHolderObject.country || null,
          dateOfBirth: secondaryInsuranceHolderObject.dateOfBirth || null,
          email: secondaryInsuranceHolderObject.email || null,
          firstName: secondaryInsuranceHolderObject.firstName || null,
          lastName: secondaryInsuranceHolderObject.lastName || null,
          phoneNumber: secondaryInsuranceHolderObject.phoneNumber || null,
          relationship: secondaryInsuranceHolderObject.relationship || null,
          legalSex: secondaryInsuranceHolderObject.legalSex || null,
        }
      }
    }
    let shouldSave = true
    switch (id) {
      case InsurancePrimaryHolder.sameAddressAsPatient:
        if (newVal === 'Yes') {
          primaryInsuranceItem.insuranceHolder.hasSameAddressAsPatient = true
          primaryInsuranceItem.insuranceHolder.address1 = null
          primaryInsuranceItem.insuranceHolder.address2 = null
          primaryInsuranceItem.insuranceHolder.city = null
          primaryInsuranceItem.insuranceHolder.zipCode = null
          primaryInsuranceItem.insuranceHolder.state = null
          primaryInsuranceItem.insuranceHolder.country = null
        } else {
          primaryInsuranceItem.insuranceHolder.hasSameAddressAsPatient = false
        }
        break
      case InsurancePrimary.insuranceName:
        primaryInsuranceItem.patientInsurance[id] = newVal || null
        break
      case InsurancePrimary.insuranceMemberId:
        primaryInsuranceItem.patientInsurance[
          InsurancePrimary.insuranceMemberId
        ] = newVal || null
        break
      case InsurancePrimary.insuranceGroupId:
        primaryInsuranceItem.patientInsurance[
          InsurancePrimary.insuranceGroupId
        ] = newVal || null
        break
      case InsurancePrimary.informationRelease:
        primaryInsuranceItem.patientInsurance.isInformationReleaseAuthorized =
          newVal === 'Yes'
        break
      case InsurancePrimary.paymentAutorization:
        primaryInsuranceItem.patientInsurance.isPaymentAuthorized =
          newVal === 'Yes'
        break
      case InsurancePrimaryHolder.address:
        primaryInsuranceItem.insuranceHolder.hasSameAddressAsPatient = false
        primaryInsuranceItem.insuranceHolder.address1 =
          newVal.Address1?.trim() || null
        primaryInsuranceItem.insuranceHolder.city = newVal.City?.trim() || null
        primaryInsuranceItem.insuranceHolder.zipCode =
          newVal.Zipcode?.trim() || null
        primaryInsuranceItem.insuranceHolder.state =
          newVal.State?.trim() || null
        primaryInsuranceItem.insuranceHolder.country = 'USA'
        break
      case InsurancePrimaryHolder.unit:
        primaryInsuranceItem.insuranceHolder.address2 = newVal || null
        break
      case InsurancePrimaryHolder.primaryRelationship:
        if (!newVal) {
          primaryInsuranceItem.patientInsurance.isPrimaryInsuranceHolder = false
          primaryInsuranceItem.insuranceHolder.relationship = null
        } else if (newVal === 'Self') {
          primaryInsuranceItem.insuranceHolder.hasSameAddressAsPatient = true
          delete primaryInsuranceItem.insuranceHolder
          primaryInsuranceItem.patientInsurance.isPrimaryInsuranceHolder = true
        } else {
          primaryInsuranceItem.patientInsurance.isPrimaryInsuranceHolder = false
          primaryInsuranceItem.insuranceHolder.relationship = newVal || null
        }
        break
      case InsurancePrimaryHolder.primaryFirstName:
        primaryInsuranceItem.insuranceHolder.firstName = newVal || null
        break
      case InsurancePrimaryHolder.primaryLastName:
        primaryInsuranceItem.insuranceHolder.lastName = newVal || null
        break
      case InsurancePrimaryHolder.dateOfBirth:
        primaryInsuranceItem.insuranceHolder.dateOfBirth = newVal
          ? format(new Date(newVal), 'MM/dd/yyyy')
          : null
        break
      case InsurancePrimaryHolder.legalSex:
        primaryInsuranceItem.insuranceHolder.legalSex = newVal || null
        break
      case InsurancePrimaryHolder.email:
        if (!newVal || emailRegEx.test(newVal)) {
          primaryInsuranceItem.insuranceHolder.email = newVal || null
        } else {
          shouldSave = false
          notification('Please input a valid email.', 'failure')
        }
        break
      case InsurancePrimaryHolder.phoneNumber:
        if (!newVal || tenDigitPhoneNumberRegEx.test(newVal)) {
          primaryInsuranceItem.insuranceHolder.phoneNumber = newVal || null
          break
        }
        shouldSave = false
        notification('Please input a valid 10 digit phone number.', 'failure')
        break
      case 'removeSecondary':
        secondaryInsuranceItem = {}
        break
      case InsuranceSecondary.secondaryInsuranceName:
        secondaryInsuranceItem.patientInsurance.insuranceName = newVal || null
        break
      case InsuranceSecondary.secondaryInsuranceMemberId:
        secondaryInsuranceItem.patientInsurance.insuranceMemberId =
          newVal || null
        break
      case InsuranceSecondary.secondaryInsuranceGroupId:
        secondaryInsuranceItem.patientInsurance.insuranceGroupId =
          newVal || null
        break
      case InsuranceSecondary.secondaryInformationRelease:
        secondaryInsuranceItem.patientInsurance.isInformationReleaseAuthorized =
          newVal === 'Yes'
        break
      case InsuranceSecondary.secondaryPaymentAuthorization:
        secondaryInsuranceItem.patientInsurance.isPaymentAuthorized =
          newVal === 'Yes'
        break
      case InsuranceSecondaryHolder.secondaryRelationship:
        if (!newVal) {
          secondaryInsuranceItem.patientInsurance.isPrimaryInsuranceHolder =
            false
          secondaryInsuranceItem.insuranceHolder.relationship = null
        } else if (newVal === 'Self') {
          delete secondaryInsuranceItem.insuranceHolder
          secondaryInsuranceItem.patientInsurance.isPrimaryInsuranceHolder =
            true
        } else {
          secondaryInsuranceItem.patientInsurance.isPrimaryInsuranceHolder =
            false
          secondaryInsuranceItem.insuranceHolder.relationship = newVal || null
        }
        break
      case InsuranceSecondaryHolder.secondaryFirstName:
        secondaryInsuranceItem.insuranceHolder.firstName = newVal || null
        break
      case InsuranceSecondaryHolder.secondaryLastName:
        secondaryInsuranceItem.insuranceHolder.lastName = newVal || null
        break
      case InsuranceSecondaryHolder.secondaryDateOfBirth:
        secondaryInsuranceItem.insuranceHolder.dateOfBirth = newVal
          ? format(new Date(newVal), 'MM/dd/yyyy')
          : null
        break
      case 'removeBackCard':
        primaryInsuranceItem.patientInsurance.insuranceCardBack = newVal
        break
      case 'removeBackFront':
        primaryInsuranceItem.patientInsurance.insuranceCardFront = newVal
        break
      case 'insuranceCardFront':
        primaryInsuranceItem.patientInsurance.insuranceCardFront = newVal
        break
      case 'insuranceCardBack':
        primaryInsuranceItem.patientInsurance.insuranceCardBack = newVal
    }
    if (shouldSave) {
      setLoading(true)
      updateInsuranceInformation(primaryInsuranceItem, secondaryInsuranceItem)
    } else {
      setLoadingImages(false)
    }
  }

  async function handleUploadFile(
    e: ChangeEvent<HTMLInputElement>,
    side: string
  ) {
    e.preventDefault()
    setLoadingImages(true)
    const files = e.target.files
    if (!files || !files[0]) {
      notification('Please, select one file.', 'failure')
      return null
    }
    const file = files[0]
    if (file.size / 1024 / 1024 > 56) {
      notification('File must be smaller than 56MB.', 'failure')
      return null
    }
    const contentType = file.type
    const createdAt = formatDate({
      value: new Date(),
      format: 'yyyy-MM-dd_hhmm_aa',
      shouldLocalize: true,
    })
    const suffix = side === 'FRONT' ? INSURANCE_CARD_FRONT : INSURANCE_CARD_BACK
    const key = `${createdAt}${suffix}`
    try {
      const uploadUrl = await getInsuranceCardUploadUrl(
        patientId,
        key,
        contentType
      )
      const arrayBuffer = await file.arrayBuffer()
      await fetch(uploadUrl, {
        method: 'PUT',
        headers: new Headers({ 'Content-Type': contentType }),
        body: arrayBuffer,
      })
      handleSave(
        key,
        side === 'FRONT' ? 'insuranceCardFront' : 'insuranceCardBack',
        'insuranceCards'
      )
    } catch (e) {
      notification(
        'There was an error uploading the file, please try again.',
        'failure'
      )
    }
  }

  return (
    <SkeletonLoadingTransition
      isLoading={loading || loadingChangeList}
      skeletonComponent={
        <>
          <Loading />
          <Loading />
          <Loading />
          <Loading />
          <Loading />
        </>
      }
      loadedComponent={
        <div className={styles.topMargin} ref={containerRef}>
          <Fields
            testId={testId}
            items={primary}
            compact={compact}
            handleSave={handleSave}
          />
          <InsuranceCards
            testId={'insuranceCards-testId'}
            imageUrlFront={imageUrlFront}
            imageUrlBack={imageUrlBack}
            setShowBackCard={setShowBackCard}
            setShowFrontCard={setShowFrontCard}
            showFrontCard={showFrontCard}
            showBackCard={showBackCard}
            handleUploadFile={handleUploadFile}
            loadingImages={loadingImages}
            handleSave={handleSave}
          />
          <Fields
            testId={testId}
            items={secondary}
            compact={compact}
            handleSave={handleSave}
          />
          {!secondaryInsurance ? (
            <div className={styles.addSecondaryButtonContainer}>
              <Button
                className={styles.addSecondaryButton}
                onClick={() => handleSave(null, 'addSecondary', '')}
              >
                Add secondary insurance
              </Button>
            </div>
          ) : (
            <Popconfirm
              title="Are you sure you want to remove secondary insurance?"
              onConfirm={() => handleSave(null, 'removeSecondary', '')}
            >
              <div className={styles.addSecondaryButtonContainer}>
                <Button className={styles.addSecondaryButton}>
                  Remove secondary insurance
                </Button>
              </div>
            </Popconfirm>
          )}
        </div>
      }
    />
  )
}

export default Insurance
