import React, { useState } from 'react'

import { Form } from 'antd'
import { Auth } from 'aws-amplify'

import { useAppContext } from '../../libs/contextLib'
import { onError } from '../../libs/errorLib'
import { setFreshpaintUserAndGroupProperties } from '../../libs/freshpaint'
import Sentry from '../../libs/sentry'
import LoginCard from '../../stories/Authentication/LoginCard'

export default function Login() {
  const [form] = Form.useForm()
  const { userHasAuthenticated, setIsAuthenticating } = useAppContext()
  const [isLoading, setIsLoading] = useState(false)

  async function handleSubmit() {
    const fields = form.getFieldsValue()
    setIsLoading(true)

    try {
      await Auth.signIn(fields.email.toLowerCase(), fields.password)
      setIsAuthenticating(true)
      userHasAuthenticated(true)
      // Allow the user to login even if metrics fails
      setFreshpaintUserAndGroupProperties().catch((e) => {
        console.error('Freshpaint integration failed')
        Sentry.captureException(e)
      })
    } catch (e) {
      let errorMessage =
        'Invalid login credentials. Please try again or contact your administrator.'
      if (e.message === 'User is disabled.') {
        errorMessage =
          'Your account has been disabled by the primary Provider account. Please contact your administrator.'
      }
      onError(e, 401, errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <LoginCard form={form} handleSubmit={handleSubmit} isLoading={isLoading} />
  )
}
