import { datadogRum } from '@datadog/browser-rum'
import '@sendbird/chat/groupChannel'
// eslint-disable-next-line import/no-unresolved
import { SBUEventHandlers } from '@sendbird/uikit-react/types/lib/types'

import { updateChannelStatus } from '../../api/api-lib'
import Sentry from '../sentry'

/**
 * Capture Sendbird connection errors
 */
export const getSendbirdProviderEventHandlers = (
  /**
   * NOTE: sendbirdUserId references ClinicID aka ProviderPrimaryID
   */
  sendbirdUserId: string
) =>
  ({
    connection: {
      onFailed: (e) => {
        // no need for error messages if the sendbird user isn't provided
        if (!sendbirdUserId) {
          return
        }

        console.error('Received error when initializing sendbird', e)
        const regexForWsConnections = new RegExp(
          /Sendbird.*Too many Websocket connections/i
        )
        if (regexForWsConnections.exec(e.toString()) !== null) {
          /**
           * This is very similar to SendbirdInitializationError
           * - However, we need to be able to have a clear DD Monitor for this case
           * - Therefore we need a separate action
           *   since we can't easily filter DD Monitors on Custom Action properties
           */
          datadogRum.addAction(
            'SendbirdWSConnectionLimitExceeded.OsmindPatientMessaging',
            {
              sendbirdUserId: sendbirdUserId,
              error: e,
            }
          )
        }

        datadogRum.addAction(
          'SendbirdInitializationError.OsmindPatientMessaging',
          {
            sendbirdUserId: sendbirdUserId,
            error: e,
          }
        )
        Sentry.captureException(e, {
          tags: { sendbirdUser: sendbirdUserId },
        })
      },
    },
  } as SBUEventHandlers)

export type UnreadMessages = {
  count: number
  markedUnread: boolean
}

export async function persistMessagesAsUnread(channelUrl: string) {
  await updateChannelStatus(channelUrl, true)
  datadogRum.addAction('SetMessagesAsUnread.OsmindPatientMessaging', {
    channelUrl,
  })
}

export async function persistMessagesAsRead(channelUrl: string) {
  await updateChannelStatus(channelUrl, false)
  datadogRum.addAction('SetMessagesAsRead.OsmindPatientMessaging', {
    channelUrl,
  })
}
