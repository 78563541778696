import React, { useEffect, useState } from 'react'

// Import components/containers
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap'

import { submitUpdatePatientReportedDiagnosesRequest } from '../../api/api-lib'
import {
  createNewDiagnosis,
  createRCopiaDiagnosis,
} from '../../api/api-lib-typed'
import { DiagnosisModal } from '../../components/Forms/DiagnosisModal'
import { QueryKeys } from '../../hooks/usePatientInfo'
import DiagnosesList from './DiagnosesList'
import { patientDataTypes } from './patient-data-types'

import './PatientProfile.scss'

function isPatientReported(diagnosisObject) {
  return diagnosisObject.isPatientReported
}
function isClinicianReported(diagnosisObject) {
  return !isPatientReported(diagnosisObject)
}
function isNoStatusDiagnosis(diagnosisObject) {
  return (
    isClinicianReported(diagnosisObject) && !diagnosisObject.DiagnosisStatus
  )
}

export default function Diagnoses(props) {
  const clinicianReportedDiagnoses = props.Diagnoses.filter(isClinicianReported)
  const patientReportedDiagnosisObject = props.Diagnoses.find(isPatientReported) // get the first one

  const [editMedHistory, setEditMedHistory] = useState(false)

  const [patientDataChange, setPatientDataChange] = useState(0)

  const [patientReportedDiagnoses, setPatientReportedDiagnoses] = useState(
    patientReportedDiagnosisObject?.Diagnosis || ''
  )

  const [activeDiagnoses, setActiveDiagnoses] = useState(
    props.Diagnoses.filter((d) => d.DiagnosisStatus === 'Active')
  )
  const [inactiveDiagnoses, setInactiveDiagnoses] = useState(
    props.Diagnoses.filter((d) => d.DiagnosisStatus === 'Inactive')
  )
  const [resolvedDiagnoses, setResolvedDiagnoses] = useState(
    props.Diagnoses.filter((d) => d.DiagnosisStatus === 'Resolved')
  )
  const [noStatusDiagnoses, setNoStatusDiagnoses] = useState(
    props.Diagnoses.filter((d) => isNoStatusDiagnosis(d))
  )
  const [showNewDiagnosis, setShowNewDiagnosis] = useState(false)

  //  useEffect + handlePatientDataChange checks frequently for a state changes so that diagnosesDisplay actually updates.
  useEffect(() => {
    const newActiveDiagnoses = clinicianReportedDiagnoses.filter(
      (d) => d.DiagnosisStatus === 'Active'
    )
    const newInactiveDiagnoses = clinicianReportedDiagnoses.filter(
      (d) => d.DiagnosisStatus === 'Inactive'
    )
    const newResolvedDiagnoses = clinicianReportedDiagnoses.filter(
      (d) => d.DiagnosisStatus === 'Resolved'
    )
    const newNoStatusDiagnoses = clinicianReportedDiagnoses.filter((d) =>
      isNoStatusDiagnosis(d)
    )
    // Do not perform the noStatus hack here. This is looking for changes.
    setNoStatusDiagnoses(newNoStatusDiagnoses)
    setActiveDiagnoses(newActiveDiagnoses)
    setInactiveDiagnoses(newInactiveDiagnoses)
    setResolvedDiagnoses(newResolvedDiagnoses)
  }, [props.Diagnoses, editMedHistory, patientDataChange, showNewDiagnosis])

  function handlePatientDataChange() {
    const patientId = props.Patient.PatientId
    props.handleApiChange([QueryKeys.DEMOGRAPHICS, patientId])
    props.handleApiChange([patientDataTypes.ProviderSidePatientData, patientId])
    props.handleApiChange([patientDataTypes.PatientDiagnoses, patientId])
    setEditMedHistory(true)
    setPatientDataChange(patientDataChange + 1)
  }

  async function handlePatientReportedChange(e) {
    // this handler can fire with every keystroke
    // Patient-reported diagnoses are string, not array in the data model.
    setPatientReportedDiagnoses(e.target.value)
  }

  async function handleSaveDiagnoses() {
    const patientId = props.Patient.PatientId ?? ''
    const body = {
      patientReportedDiagnoses: patientReportedDiagnoses ?? '',
    }
    await submitUpdatePatientReportedDiagnosesRequest(patientId, body)
    setPatientReportedDiagnoses(patientReportedDiagnoses)
    setEditMedHistory(false) // return to read-only view after saving
    props.handleApiChange([patientDataTypes.PatientDiagnoses, patientId])
  }

  /**
   * Handles the submission of a new diagnosis
   * @param {Object} newDiagnosis New diagnosis to be added to the patient's diagnoses
   * @param {string} newDiagnosis.icdCode ICD code of the new diagnosis
   * @param {string} newDiagnosis.icdDescription Description of the ICD Code
   * @param {string} newDiagnosis.diagnosisDate Diagnosis date
   * @param {'Active' | 'Inactive' | 'Resolved'} newDiagnosis.diagnosisStatus Diagnosis status
   * @param {string} newDiagnosis.onsetDate Diagnosis onset date
   * @param {string} newDiagnosis.comments Comments on the diagnosis
   * @returns
   */
  const handleSubmitNewDiagnosis = async (newDiagnosis) => {
    const createdDiagnosis = await createNewDiagnosis(props.Patient.PatientId, {
      Diagnosis: newDiagnosis.icdDescription,
      DiagnosisCode: newDiagnosis.icdCode,
      DiagnosisDate: newDiagnosis.diagnosisDate,
      DiagnosisNoteContent: newDiagnosis.comments,
      DiagnosisOnsetDate: newDiagnosis.onsetDate,
      DiagnosisOnsetDateFormat: 'MM/DD/YYYY',
      DiagnosisStatus: newDiagnosis.diagnosisStatus,
      PatientId: props.Patient.PatientId,
    })

    if (props.Patient.PatientRCopiaId) {
      await createRCopiaDiagnosis({
        Diagnosis: newDiagnosis.icdDescription,
        DiagnosisCode: newDiagnosis.icdCode,
        DiagnosisDate: newDiagnosis.diagnosisDate,
        DiagnosisNoteContent: newDiagnosis.comments,
        DiagnosisOnsetDate: newDiagnosis.onsetDate,
        DiagnosisOnsetDateFormat: 'MM/DD/YYYY',
        DiagnosisStatus: newDiagnosis.diagnosisStatus,
        PatientId: props.Patient.PatientId,
        PatientRCopiaId: props.Patient.PatientRCopiaId,
        DiagnosisId: createdDiagnosis.DiagnosisId,
      })
    }
    handlePatientDataChange()
    setShowNewDiagnosis(false)
  }

  const [patientDataCopy, setPatientDataCopy] = useState(
    patientReportedDiagnoses
  )

  useEffect(() => {
    setPatientDataCopy(patientReportedDiagnoses)
  }, [editMedHistory])

  const revert = () => {
    setPatientReportedDiagnoses(patientDataCopy)
  }

  const savedTable = (
    <Container>
      <br />
      <Row>
        <Col>
          <Row className="indent">
            <Col className="bottom-spacing">
              <Row>
                <Col>
                  <Form.Label bsPrefix="subtitle subsection">Active</Form.Label>
                  {activeDiagnoses.length ? (
                    <DiagnosesList
                      status="Active"
                      diagnoses={activeDiagnoses}
                      editMedHistory={editMedHistory}
                      handlePatientDataChange={handlePatientDataChange}
                      handleApiChange={props.handleApiChange}
                      patient={props.Patient}
                    />
                  ) : (
                    <div className="diagnoses-list text-muted">
                      No active diagnoses recorded
                    </div>
                  )}
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Label bsPrefix="subtitle subsection">
                    Inactive
                  </Form.Label>
                  {inactiveDiagnoses.length ? (
                    <DiagnosesList
                      status="Inactive"
                      diagnoses={inactiveDiagnoses}
                      editMedHistory={editMedHistory}
                      handlePatientDataChange={handlePatientDataChange}
                      handleApiChange={props.handleApiChange}
                      patient={props.Patient}
                    />
                  ) : (
                    <div className="diagnoses-list text-muted">
                      No inactive diagnoses recorded
                    </div>
                  )}
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Label bsPrefix="subtitle subsection">
                    Resolved
                  </Form.Label>
                  {resolvedDiagnoses.length ? (
                    <DiagnosesList
                      status="Resolved"
                      diagnoses={resolvedDiagnoses}
                      editMedHistory={editMedHistory}
                      handlePatientDataChange={handlePatientDataChange}
                      handleApiChange={props.handleApiChange}
                      patient={props.Patient}
                    />
                  ) : (
                    <div className="diagnoses-list text-muted">
                      No resolved diagnoses recorded
                    </div>
                  )}
                </Col>
              </Row>

              {noStatusDiagnoses.length > 0 && (
                <Row>
                  <Col>
                    <Form.Label bsPrefix="subtitle subsection">
                      No status defined
                    </Form.Label>
                    <DiagnosesList
                      status="NoStatus"
                      diagnoses={noStatusDiagnoses}
                      editMedHistory={editMedHistory}
                      handlePatientDataChange={handlePatientDataChange}
                      handleApiChange={props.handleApiChange}
                      patient={props.Patient}
                    />
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <Form.Label bsPrefix="subtitle subsection">
                    Patient-reported
                  </Form.Label>
                  <div className="diagnoses-list text-muted">
                    {patientReportedDiagnoses === ''
                      ? 'No patient-reported diagnoses recorded'
                      : patientReportedDiagnoses}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <br />
      {/* EDIT BUTTON */}
      <Row>
        <Col id="editDiagnosisButton">
          <Button
            bsPrefix="button-block icon-button"
            onClick={() => setEditMedHistory(true)}
          >
            <i className="fa fa-pencil-square-o" aria-hidden="true" />
            &nbsp; Edit diagnoses
          </Button>
          <br />
        </Col>
      </Row>
    </Container>
  )

  const editableTable = (
    <Form as={Container} noValidate style={{ padding: '16px' }}>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Row bsPrefix="indent">
            <Form.Label className="patient topic">
              Diagnoses&nbsp;
              <Button
                id="editDiagnosisSection"
                bsPrefix="button-add"
                onClick={() => setShowNewDiagnosis(true)}
              >
                <i className="fa fa-plus" aria-hidden="true">
                  {' '}
                </i>
              </Button>
            </Form.Label>

            <Row>
              <Col>
                <Form.Label bsPrefix="info subsection">Active</Form.Label>
                {activeDiagnoses.length ? (
                  <DiagnosesList
                    status="Active"
                    diagnoses={activeDiagnoses}
                    editMedHistory={editMedHistory}
                    handlePatientDataChange={handlePatientDataChange}
                    handleApiChange={props.handleApiChange}
                    patient={props.Patient}
                  />
                ) : (
                  <div className="text-muted">No active diagnoses recorded</div>
                )}
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Label bsPrefix="info subsection">Inactive</Form.Label>
                {inactiveDiagnoses.length ? (
                  <DiagnosesList
                    status="Inactive"
                    diagnoses={inactiveDiagnoses}
                    editMedHistory={editMedHistory}
                    handlePatientDataChange={handlePatientDataChange}
                    handleApiChange={props.handleApiChange}
                    patient={props.Patient}
                  />
                ) : (
                  <div className="text-muted">
                    No inactive diagnoses recorded
                  </div>
                )}
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Label bsPrefix="info subsection">Resolved</Form.Label>
                {resolvedDiagnoses.length ? (
                  <DiagnosesList
                    status="Resolved"
                    diagnoses={resolvedDiagnoses}
                    editMedHistory={editMedHistory}
                    handlePatientDataChange={handlePatientDataChange}
                    handleApiChange={props.handleApiChange}
                    patient={props.Patient}
                  />
                ) : (
                  <div className="text-muted">
                    No resolved diagnoses recorded
                  </div>
                )}
              </Col>
            </Row>

            {noStatusDiagnoses.length > 0 && (
              <Row>
                <Col>
                  <Form.Label bsPrefix="subtitle subsection">
                    No status defined
                  </Form.Label>
                  <DiagnosesList
                    status="NoStatus"
                    diagnoses={noStatusDiagnoses}
                    editMedHistory={editMedHistory}
                    handlePatientDataChange={handlePatientDataChange}
                    handleApiChange={props.handleApiChange}
                    patient={props.Patient}
                  />
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <Form.Label bsPrefix="info subsection">
                  Patient-reported
                </Form.Label>
                <Form.Group>
                  <Form.Control
                    value={patientReportedDiagnoses}
                    type="text"
                    as="textarea"
                    rows={5}
                    bsPrefix="form-input"
                    placeholder="Enter patient-reported diagnoses..."
                    onChange={handlePatientReportedChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <DiagnosisModal
              title="Add new diagnosis"
              isVisible={showNewDiagnosis}
              onCancel={() => setShowNewDiagnosis(false)}
              onAddDiagnosis={handleSubmitNewDiagnosis}
            />
          </Form.Row>
          <br />
        </Form.Group>
      </Form.Row>

      {/* SAVE BUTTON */}
      <Form.Row className="justify-contents-center">
        <Form.Group sm={12} md={5} lg={{ span: 4, offset: 2 }} as={Col}>
          <Button
            bsPrefix="button-block icon-button"
            onClick={() => {
              handleSaveDiagnoses()
            }}
          >
            <i className="fa fa-floppy-o" aria-hidden="true" />
            &nbsp; Save diagnoses
          </Button>
        </Form.Group>
        <Form.Group sm={12} md={5} as={Col}>
          <Button
            id="cancelEditDiagnosisButton"
            bsPrefix="button-close icon-button"
            onClick={() => {
              revert()
              setEditMedHistory(false)
            }}
          >
            <i className="fa fa-times" aria-hidden="true" />
            &nbsp; Cancel
          </Button>
        </Form.Group>
      </Form.Row>
    </Form>
  )

  const diagnosesDisplay = (
    <Accordion defaultActiveKey="0">
      <Accordion.Toggle
        bsPrefix="accordion-header very-large accordion-toggle"
        as={Card.Header}
        eventKey="1"
      >
        <div style={{ cursor: 'pointer' }}>Diagnoses</div>
      </Accordion.Toggle>

      {!editMedHistory ? (
        <Accordion.Collapse eventKey="1">{savedTable}</Accordion.Collapse>
      ) : (
        <Accordion.Collapse eventKey="1">{editableTable}</Accordion.Collapse>
      )}
    </Accordion>
  )

  return <>{diagnosesDisplay}</>
}
