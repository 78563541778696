import { AnyExtension } from '@tiptap/react'

import { BlockWrapper } from '../Components/Blocks/Core/BlockWrapper'
import { CoreNode } from '../Components/Blocks/Core/CoreNode'
import { BlockConfig } from '../Components/Blocks/types'

// This probably doesn't need to be a hook right now.
// But we may start adding more hook-like behavior as we add blocks.
export const useBlockExtensions = (
  configList: BlockConfig[],
  isSigned: boolean
) => {
  return configList.map((config) =>
    CoreNode({
      BlockComponent: BlockWrapper(config, isSigned),
      name: config.name,
      tag: config.tag,
    })
  ) as AnyExtension[]
}
