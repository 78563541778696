import moment from 'moment'
import 'moment-timezone'

import {
  ClinicDataType,
  ClinicalNote,
  DateISO8601,
  InsuranceClaim,
  Invoice,
  NoteTypes,
  NoteV1AuditLog,
} from '../../shared-types'

export enum UpdateType {
  EDIT = 'edit',
  NEW = 'new',
}

export const AutosaveTriggerCreateNoteKeyword = 'newAutosave'

export const LeaveUnsavedNoteMsg =
  'Would you like to save your work before leaving?'

export type Signature = {
  Notes: string
  SignedBy: string
  SignedByProviderId: string
  CreatedAt: DateISO8601
}

export type Entries = {
  Date: moment.Moment | null
  Description: string
  Resolution: string
  Type: string
}

export type Medications = {
  Dose: string
  Name: string
  Time: moment.Moment | null
  Comments: string
}

type VitalSignType = {
  Time: string
  BP: string
  HR: string
  RR: string
  SPO2: string
  TemperatureUnits: string
  Temp: string
  Comments: string
  EKG: string
  RASSscore: string
  BpArm?: string
}

enum BillingTypeEnum {
  IN_NETWORK = 'IN_NETWORK',
  PRIVATE_PAY = 'PRIVATE_PAY',
}

const getDefaultFields = () => ({
  NoteType: '',
  ClinicalNoteContent: '',
  ReceivedTreatment: false,
  MedicationName: '',
  Administration: '',
  OtherAdministration: '',
  IvPlacement: '',
  Dosage: '',
  TreatmentDurationInMins: '',
  PatientWeight: '',
  IsTreatmentConcurrentPsychotherapy: false,
  PsychotherapyNotes: '',
  NoteDate: moment().hour(12).minute(0).second(0).millisecond(0),
  BP: '',
  BpArm: '',
  HR: '',
  RR: '',
  SPO2: '',
  Temperature: '',
  TemperatureUnits: '',
  Comments: '',
  ProviderInitials: '',
  AdditionalMedicationName: '',
  AdditionalMedicationAdministrationRoute: '',
  AdditionalMedicationOtherAdministrationRoute: '',
  AdditionalMedicationIvPlacement: '',
  AdditionalMedicationDosage: 0,
  AdditionalMedicationPatientWeight: '',
  Title: '',
  FacilityRoom: '',
  Staff: '',
  SuicidalIdeation: null,
  SuicidalFrequency: '',
  SuicidalPlan: null,
  SuicidalIntent: null,
  SuicidalDetails: '',
  BladderSx: null,
  BladderExplanation: '',
  PregnantStatus: 'N/A',
  ROS: '',
  Exam: '',
  AdditionalInformation: '',
  NPOhours: 0,
  OtherAdditionalInformation: '',
  Catheter: '',
  CatheterOther: '',
  NumberOfAttempts: 1,
  InfusionLocation: '',
  IVNotes: '',
  KetamineDose: '',
  KetamineUnits: 'kg',
  KetamineNotes: '',
  KetamineTotalDose: '',
  TreatmentPlan: '',
  SpontaneousVentilation: true,
  UneventfulInfusionAndRecovery: true,
  PatientAlertAndResponsive: true,
  PatientMetalStatus: true,
  PatientDeniesSuicidalIdeation: true,
  Nausea: false,
  VitalsStable: true,
  Ambulation: false,
  DischargeInstruccionsReviewed: true,
  ResponsibleAdultEscort: true,
  DischargeNotes: '',
  TreatmentNotes: '',
  MonitorOtherText: '',
  BpPlacement: '',
  AdditionalMedicationOther: '',
  AdditionalMedicationUnits: 'mg',
  AdditionalMedicationUnitsOther: '',
  EKG: '',
  RASSscore: '',
  VitalSignsType: '',
  DischargeBP: '',
  DischargeBpArm: '',
  DischargeHR: '',
  DischargeRR: '',
  DischargeSPO2: '',
  DischargeComments: '',
  DischargeVitalSignsType: '',
  DischargeEKG: '',
  DischargeRASSscore: '',
  IVType: '',
  TreatmentType: '',
  InductionNumber: '',
  PatientEndorsment: false,
  EndorsmentNotes: '',
  MentalStatusExam: '',
  PsychiatricROS: '',
  KRAnxiety: false,
  KRAnxietyText: '',
  KRVisualChanges: false,
  KRVisualChangesText: '',
  KRDizziness: false,
  KRDizzinessText: '',
  KRSedation: false,
  KRSedationText: '',
  KRNausea: false,
  KRNauseaText: '',
  KRVomiting: false,
  KRVomitingText: '',
  KRFlushing: false,
  KRFlushingText: '',
  KRNumbness: false,
  KRNumbnessText: '',
  KRPalpitations: false,
  KRPalpitationsText: '',
  KRSalivation: false,
  KRSalivationText: '',
  KRDryMouth: false,
  KRDryMouthText: '',
  KRDissociation: false,
  KRDissociationText: '',
  KRMoodElevation: false,
  KRMoodElevationText: '',
  KROther: false,
  KROtherText: '',
  PatientResponseToExperience: '',
  SavedVitalSigns: [] as Array<VitalSignType>,
  SavedAdditionalMedications: [],
  SavedAdditionalMedicationsDissociation: [],
  SavedAdditionalMedicationsSedation: [],
  StartTime: moment().add(1, 'minutes').format('HH:mm'),
  EndTime: moment().format('HH:mm'),
  PatientTreatmentConsent: false,
  MedicalHistoyPhysicalExam: false,
  NPO: false,
  RisksAndQuestions: false,
  EquipmentCheck: false,
  InfusionStartTime: moment().format('HH:mm'),
  InfusionEndTime: moment().format('HH:mm'),
  SpravatoStartTime: '',
  SpravatoEndTime: '',
  DischargeTime: moment().format('HH:mm'),
  MonitorEKG: false,
  MonitorNIBP: false,
  MonitorSpO2: false,
  MonitorOther: false,
  SavedDischargeVitalSigns: {},
  SavedEntries: [],
  SavedAdditionalEntries: [],
  Signed: false,
  SignedBy: '',
  SignedDate: '',
  CreatedOnDate: '',
  CreatedBy: '',
  EditedOnDateBy: [] as NoteV1AuditLog,
  Addendums: [],
  Signatures: [],
  NewSignature: { Notes: '', SignedBy: '', SignedByProviderId: '' },
  LabRecords: '',
  Imaging: '',
  DifferentialDiagnosis: '',
  CGISeverity: '',
  CGIImprovment: '',
  PsychCare: '',
  OtherSafety: '',
  RiskLevel: '',
  LinkID: '',

  SpravatoSedation: null,
  SpravatoSedationOnset: '',
  SpravatoSedationResolution: null,
  SpravatoSedationResolutionTime: '',
  SpravatoSedationMedication: null,
  Benzodiazepines: null,
  NonBenzodiazepine: null,
  Psychostimulants: null,
  MAOIs: null,
  SpravatoDissociation: null,
  SpravatoDissociationOnset: '',
  SpravatoDissociationResolution: null,
  SpravatoDissociationResolutionTime: '',
  SpravatoDissociationMedication: null,
  SpravatoAdverseEventDuring: null,
  SpravatoAdverseEventBetween: null,
  SpravatoSessionRange: null,
  SpravatoAdministrationRange: null,
  StartSpravatoHR: '',
  StartSpravatoBP: '',
  StartSpravatoVitals: {
    Time: null,
    BP: '',
    HR: '',
    RR: '',
    Temp: '',
    Comments: '',
  },
  FortyMinSpravatoVitals: {
    Time: null,
    BP: '',
    HR: '',
    RR: '',
    Temp: '',
    Comments: '',
  },
  EndSpravatoVitals: {
    Time: null,
    BP: '',
    HR: '',
    RR: '',
    Temp: '',
    Comments: '',
  },
  SpravatoDose: '',
  SpravatoDoseOther: '',
  SpravatoManufacture: '',
  SessionTotalTime: '',
  SessionMonitoringTime: '',
  SessionPersonnelNotes: '',
  SessionAdditionalNotes: '',
  SpravatoProviderFirstName: '',
  LotNumber: '',
  SpravatoProviderLastName: '',
  SpravatoProviderPhone: '',
  SpravatoProviderEmail: '',
  SpravatoClinicName: '',
  SpravatoClinicAddress1: '',
  SpravatoClinicAddress2: '',
  SpravatoClinicCity: '',
  SpravatoClinicState: '',
  SpravatoClinicZip: '',
  SpravatoClinicAdditionalNotes: '',
  FaceTimeNotes: '',
  SupportDocumentationNotes: '',
  SpravatoDischargeNotes: '',
  FollowUpNotes: '',
  ComprehensiveMedicalROS: {},
  MSE: {},
  PhysicalExam: {},
  KetamineSpecificROS: {} as {} | null,
  Rooms: '',
  RenderingProvider: '',
  invoice: {} as Invoice,
  InsuranceClaim: [] as Array<InsuranceClaim>,
  importedIntakeFormId: null,
  BillingType: '' as BillingTypeEnum | string,
})

export type NoteType = ReturnType<typeof getDefaultFields> & {
  PatientId: string
  UpdateEnum: UpdateType
  CreatedOn?: string
  EditedOnDateBy?: NoteV1AuditLog
}

function merge(a: { [k: string]: unknown }, b?: { [k: string]: unknown }) {
  return !b
    ? a
    : Object.fromEntries(
        Object.entries(a).map(([key, value]) => [
          key,
          b[key] === undefined ? value : b[key],
        ])
      )
}

export const getFields = (
  updateType: UpdateType,
  patientId: string,
  note?: Record<string, any>,
  clinicData?: ClinicDataType,
  patientPhysicalNotes?: any
) => {
  const fields = {
    ...merge(getDefaultFields(), note),
    BillingType: note?.BillingType ?? '',
    PatientId: patientId,
    UpdateEnum: updateType,
  } as NoteType

  if (updateType === UpdateType.EDIT) {
    fields.BpPlacement =
      note?.BpPlacement || note?.SavedVitalSigns?.[0]?.BpArm || ''
    fields.NoteDate = note?.NoteDate
      ? moment.utc(note?.NoteDate)
      : moment.utc(note?.CreatedOn)
    fields.NoteType = note?.NoteType || NoteTypes.CLINICAL_NOTE
  }

  if (updateType === UpdateType.NEW && clinicData) {
    fields.SpravatoProviderPhone = clinicData.ProviderPhone
    fields.SpravatoProviderEmail = clinicData.ProviderEmail
    fields.SpravatoClinicName = clinicData.PracticeName
    fields.SpravatoClinicAddress1 = clinicData.ProviderPracticeAddress
    fields.SpravatoClinicAddress2 = clinicData.ProviderPracticeAddressExtn
    fields.SpravatoClinicCity = clinicData.ProviderPracticeCity
    fields.SpravatoClinicState = clinicData.ProviderPracticeState
    fields.SpravatoClinicZip = clinicData.ProviderPracticeZipcode
  }
  if (
    updateType === UpdateType.NEW &&
    patientPhysicalNotes?.weight &&
    !note?.PatientWeight
  ) {
    fields.PatientWeight = String(patientPhysicalNotes.weight)
    fields.AdditionalMedicationPatientWeight = String(
      patientPhysicalNotes.weight
    )
    // weight unit is recorded as 'lb' in db but KetamineUnit value expects 'lbs'
    fields.KetamineUnits =
      patientPhysicalNotes.weightUnit === 'kg' ? 'kg' : 'lbs'
  }

  return fields
}

// function to include '(Copy)' in SALT note titles, remove all time related fields, all vitals and all signature data for SALT notes
export function formatSALTNote(note: ClinicalNote) {
  const newNote = Object.assign({}, note) as any
  const match = note?.Title?.match(` [0-9]+$`)
  if (match) {
    const copyNumber = Number(match[0].trim())
    const newCopyNumber = copyNumber + 1
    newNote.Title = `${note?.Title?.substring(0, match.index)} ${newCopyNumber}`
  } else {
    newNote.Title = `${note?.Title} 1`
  }

  const fieldsToRemove = [
    'Addendums',
    'DischargeTime',
    'EndTime',
    'InfusionEndTime',
    'InfusionStartTime',
    'MedicationAgentTime',
    'StartTime',
    'SessionMonitoringTime',
    'SessionTotalTime',
    'SpravatoDissociationResolutionTime',
    'SpravatoEndTime',
    'SpravatoSedationResolutionTime',
    'SpravatoStartTime',
    'Signatures',
    'VitalsStable',
    'StartSpravatoVitals',
    'SavedVitalSigns',
    'SavedDischargeVitalSigns',
    'EndSpravatoVitals',
    'FortyMinSpravatoVitals',
    'CreatedBy',
    'CreatedOn',
    'CreatedOnDate',
    'EditedOnDateBy',
  ]
  fieldsToRemove.forEach((field) => delete newNote[field])

  if (newNote.SavedAdditionalMedications) {
    for (const medication of newNote.SavedAdditionalMedications) {
      delete medication.AdditionalMedicationTime
    }
  }

  if (newNote.SavedEntries) {
    for (const medication of newNote.SavedEntries) {
      delete medication.Time
    }
  }

  return newNote
}
