import {
  BOOL_OPTIONS,
  CLAIM_FILING_CODE_OPTIONS,
  INSURANCE_TYPE_CODE_OPTIONS,
  LEGAL_SEX_OPTIONS,
  RELATIONSHIP_OPTIONS,
} from './constants'
import { Ein, Npi, PhoneNumber } from './schemas'
import { formatEin, formatPhoneNumber, validatorFactory } from './utils'

export const PATIENT_INFO = [
  {
    name: 'patient.firstName',
    label: 'First name',
    placeholder: 'Add',
    type: 'text',
    isRequired: true,
  },
  {
    name: 'patient.lastName',
    label: 'Last name',
    placeholder: 'Add',
    type: 'text',
    isRequired: true,
  },
  {
    name: 'patient.dateOfBirth',
    label: 'Date of birth',
    placeholder: 'Add',
    type: 'date',
    isRequired: true,
  },
  {
    name: 'patient.address',
    label: 'Address',
    placeholder: 'Select',
    type: 'address',
    isRequired: true,
  },
  {
    name: 'patient.address2',
    label: 'Address 2',
    placeholder: 'Add',
    type: 'text',
  },
  {
    name: 'patient.legalSex',
    label: 'Legal sex',
    placeholder: 'Select',
    type: 'select',
    isRequired: true,
    options: LEGAL_SEX_OPTIONS,
  },
]

export const PRIMARY_INSURANCE = {
  INSURANCE: [
    {
      name: 'primaryInsurance.name',
      label: 'Insurance company',
      placeholder: 'Select',
      type: 'select',
      isRequired: true,
      options: [],
    },
    {
      name: 'primaryInsurance.claimFilingCode',
      label: 'Claim filing code',
      placeholder: 'Select',
      type: 'select',
      isRequired: true,
      options: CLAIM_FILING_CODE_OPTIONS,
    },
    {
      name: 'primaryInsurance.memberId',
      label: 'Member ID',
      placeholder: 'Add',
      type: 'text',
      isRequired: true,
    },
    {
      name: 'primaryInsurance.groupId',
      label: 'Group ID',
      placeholder: 'Add',
      type: 'text',
    },
    {
      name: 'primaryInsurance.informationReleaseAuthorized',
      label: 'Information release',
      placeholder: 'Select',
      type: 'select',
      isRequired: true,
      options: BOOL_OPTIONS,
    },
    {
      name: 'primaryInsurance.paymentAuthorized',
      label: 'Payment authorization',
      placeholder: 'Select',
      type: 'select',
      isRequired: true,
      options: BOOL_OPTIONS,
    },
    {
      name: 'primaryInsurance.subscriberRelationship',
      label: `Patient's relationship to subscriber`,
      placeholder: 'Select',
      type: 'select',
      isRequired: true,
      options: RELATIONSHIP_OPTIONS,
    },
  ],
  SUBSCRIBER: [
    {
      name: 'primaryInsurance.subscriberFirstName',
      label: 'First name',
      placeholder: 'Add',
      type: 'text',
      isRequired: true,
    },
    {
      name: 'primaryInsurance.subscriberLastName',
      label: 'Last name',
      placeholder: 'Add',
      type: 'text',
      isRequired: true,
    },
    {
      name: 'primaryInsurance.subscriberDateOfBirth',
      label: 'Date of birth',
      placeholder: 'Add',
      type: 'date',
      isRequired: true,
    },
    {
      name: 'primaryInsurance.subscriberLegalSex',
      label: 'Legal sex',
      placeholder: 'Select',
      type: 'select',
      options: LEGAL_SEX_OPTIONS,
    },
    {
      name: 'primaryInsurance.subscriberEmail',
      label: 'Email',
      placeholder: 'Add',
      type: 'text',
    },
    {
      name: 'primaryInsurance.subscriberPhoneNumber',
      label: 'Phone',
      placeholder: 'Add',
      type: 'text',
      validator: validatorFactory(PhoneNumber),
      formatter: formatPhoneNumber,
    },
    {
      name: 'primaryInsurance.subscriberAddressSameAsPatient',
      label: 'Same address as patient?',
      placeholder: 'Select',
      type: 'select',
      options: BOOL_OPTIONS,
    },
  ],
  SUBSCRIBER_ADDRESS: [
    {
      name: 'primaryInsurance.subscriberAddress',
      label: 'Address',
      placeholder: 'Select',
      type: 'address',
    },
    {
      name: 'primaryInsurance.subscriberAddress2',
      label: 'Address 2',
      placeholder: 'Add',
      type: 'text',
    },
  ],
}

export const SECONDARY_INSURANCE = {
  TOGGLE: {
    name: 'secondaryInsurance.isIncluded',
    label: `Include patient's secondary insurance on this claim`,
  },
  INSURANCE: [
    {
      name: 'secondaryInsurance.name',
      label: 'Insurance company',
      placeholder: 'Select',
      type: 'select',
      isRequired: true,
      options: [],
    },
    {
      name: 'secondaryInsurance.claimFilingCode',
      label: 'Claim filing code',
      placeholder: 'Select',
      type: 'select',
      isRequired: true,
      options: CLAIM_FILING_CODE_OPTIONS,
    },
    {
      name: 'secondaryInsurance.insuranceTypeCode',
      label: 'Medicare secondary insurance type code',
      placeholder: 'Select',
      type: 'select',
      isRequired: true,
      options: INSURANCE_TYPE_CODE_OPTIONS,
    },
    {
      name: 'secondaryInsurance.memberId',
      label: 'Member ID',
      placeholder: 'Add',
      type: 'text',
      isRequired: true,
    },
    {
      name: 'secondaryInsurance.groupId',
      label: 'Group ID',
      placeholder: 'Add',
      type: 'text',
    },
    {
      name: 'secondaryInsurance.informationReleaseAuthorized',
      label: 'Information release',
      placeholder: 'Select',
      type: 'select',
      isRequired: true,
      options: BOOL_OPTIONS,
    },
    {
      name: 'secondaryInsurance.paymentAuthorized',
      label: 'Payment authorization',
      placeholder: 'Select',
      type: 'select',
      isRequired: true,
      options: BOOL_OPTIONS,
    },
    {
      name: 'secondaryInsurance.subscriberRelationship',
      label: `Relationship to subscriber`,
      placeholder: 'Select',
      type: 'select',
      isRequired: true,
      options: RELATIONSHIP_OPTIONS,
    },
  ],
  SUBSCRIBER: [
    {
      name: 'secondaryInsurance.subscriberFirstName',
      label: 'First name',
      placeholder: 'Add',
      type: 'text',
      isRequired: true,
    },
    {
      name: 'secondaryInsurance.subscriberLastName',
      label: 'Last name',
      placeholder: 'Add',
      type: 'text',
      isRequired: true,
    },
    {
      name: 'secondaryInsurance.subscriberDateOfBirth',
      label: 'Date of birth',
      placeholder: 'Add',
      type: 'date',
      isRequired: true,
    },
  ],
}

export const REFERRING_PROVIDER = {
  TOGGLE: {
    name: 'referringProvider.isIncluded',
    label: `Include patient's referring provider`,
  },
  PROVIDER: [
    {
      name: 'referringProvider.providerFirstName',
      label: 'First name',
      placeholder: 'Add',
      type: 'text',
    },
    {
      name: 'referringProvider.providerLastName',
      label: 'Last name',
      placeholder: 'Add',
      type: 'text',
    },
    {
      name: 'referringProvider.providerEin',
      label: 'EIN',
      placeholder: 'Add',
      type: 'text',
      isRequired: true,
      validator: validatorFactory(Ein),
      formatter: formatEin,
    },
    {
      name: 'referringProvider.providerNpi',
      label: 'NPI',
      placeholder: 'Add',
      type: 'text',
      isRequired: true,
      validator: validatorFactory(Npi),
    },
  ],
}
