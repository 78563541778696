import { useMemo } from 'react'

import cx from 'classnames'
import moment from 'moment'

import { useFeatureFlags } from '../../../../../libs/featureFlags'
import { Location, Teammate } from '../../../../../shared-types'
import {
  convertTime24to12,
  convertTime24toISO,
  convertTimeISOto24,
  getTimeFromISO,
} from '../../../../../shared/Helpers/utils'
import {
  InlineEditSelect,
  Link,
  Skeleton,
} from '../../../../../stories/BaseComponents'
import InlineEditDate from '../../../../../stories/BaseComponents/InlineEditFields/InlineEditDate'
import InlineEditTime from '../../../../../stories/BaseComponents/InlineEditFields/InlineEditTime'
import { Note } from '../../../types'
import { LoadingSkeleton } from './LoadingSkeleton'
import { Row } from './Row'

import styles from './CoreData.module.scss'

// TODO: Remove filtering of the providers from the render,
//          why would we ever return disabled providers?
export const CoreData = ({
  note,
  onChange,
  teamOptions,
  locationOptions,
  isLoading,
  isSigned = false,
}: {
  note?: Note
  onChange: (val: Partial<Note>) => void
  teamOptions: Array<Teammate>
  locationOptions: Array<Location>
  isLoading: boolean
  isSigned?: boolean
}) => {
  const { claims } = useFeatureFlags()
  const startTimeParts = useMemo(() => {
    return getTimeFromISO(note?.startTime ?? null)
  }, [note?.startTime])

  const generateTimeText = () => {
    const { hours, minutes, modifier } = convertTime24to12(
      convertTimeISOto24(note?.startTime ?? null)
    )
    const timezone = !note?.startTime
      ? ''
      : new Date(note?.startTime)
          .toLocaleString('en', { timeZoneName: 'short' })
          .split(' ')
          .pop()

    return `${hours}:${minutes} ${modifier} ${timezone}`
  }

  return (
    <div className={styles.container} id="note-core-data">
      <div className={cx(styles.tableWrapper, 'hide-on-print')}>
        <Row
          label="Patient name"
          content={
            isLoading ? (
              <LoadingSkeleton />
            ) : (
              <div className={styles.nonEditableField}>{note?.patientName}</div>
            )
          }
          fieldName="patientName"
          isEditable={false}
        />
        <Row
          label="Date of visit"
          content={
            isLoading ? (
              <LoadingSkeleton />
            ) : isSigned ? (
              <div className={styles.nonEditableField}>
                {moment(note?.appointmentDate).format('MM/DD/YYYY')}
              </div>
            ) : (
              <InlineEditDate
                isLocal
                value={moment(note?.appointmentDate).format('MM/DD/YYYY')}
                onSave={(value) => {
                  const change: Partial<Note> = {}
                  const appointmentDate = new Date(value)
                  // cast to noon so that most UTC offsets will be accounted for
                  // TODO: save this as a string instead, since it's not meant to change across timezones
                  // https://osmind.atlassian.net/browse/CC-2438
                  appointmentDate.setHours(12)
                  // Also correct the start datetime
                  change.appointmentDate = appointmentDate.toISOString()
                  if (startTimeParts) {
                    const startDatetime = new Date(appointmentDate)
                    startDatetime.setHours(
                      startTimeParts.hours,
                      startTimeParts.minutes
                    )
                    change.startTime = startDatetime.toISOString()
                  }
                  onChange(change)
                }}
                className={cx(styles.rightPadding, styles.rowPostion)}
                padded={true}
                after={moment().add(100, 'year')}
              />
            )
          }
          fieldName="appointmentDate"
          isEditable={false}
        />

        <Row
          label="Treatment start time"
          content={
            isLoading ? (
              <LoadingSkeleton />
            ) : isSigned ? (
              <div className={styles.nonEditableField}>
                {generateTimeText()}
              </div>
            ) : (
              <InlineEditTime
                value={note?.startTime}
                onSave={(value: string) => {
                  const iso = convertTime24toISO(note?.appointmentDate, value)
                  onChange({ startTime: iso })
                }}
                className={cx(styles.rightPadding, styles.rowPostion)}
                padded={true}
                placeholder="Select"
              />
            )
          }
          fieldName="startTime"
          isEditable={false}
        />

        <Row
          label="Location"
          content={
            isLoading ? (
              <LoadingSkeleton />
            ) : locationOptions.length === 0 ? (
              <div className={styles.nonEditableField}>
                No locations found •&nbsp;
                <Link to={'/settings/location'} target="_blank">
                  Add one
                </Link>
              </div>
            ) : isSigned ? (
              <div className={styles.nonEditableField}>
                {note?.locationName}
              </div>
            ) : (
              <InlineEditSelect
                value={note?.locationId ?? undefined}
                options={locationOptions?.map((location: Location) => {
                  return {
                    value: location.Uuid ?? '',
                    label: location.LocationName ?? '',
                  }
                })}
                className={cx(styles.rightPadding, styles.rowPostion)}
                padded={true}
                onSave={(value) => {
                  onChange({
                    locationId: value,
                  })
                }}
                placeholder="Select"
              />
            )
          }
          fieldName="location"
          isEditable={false}
        />
        <Row
          label="Rendering provider"
          content={
            isLoading ? (
              <LoadingSkeleton />
            ) : isSigned ? (
              <div className={styles.nonEditableField}>
                {note?.renderingProviderName}
              </div>
            ) : (
              <InlineEditSelect
                value={note?.renderingProviderId ?? undefined}
                options={teamOptions?.map((team: any) => {
                  if (team.isDeactivated) {
                    return {
                      value: team.cognitoId,
                      label: team.name,
                      disabled: true,
                    }
                  } else {
                    return { value: team.cognitoId, label: team.name }
                  }
                })}
                onSave={(value) => {
                  onChange({
                    renderingProviderId: value,
                  })
                }}
                className={cx(styles.rightPadding, styles.rowPostion)}
                padded={true}
                placeholder="Select"
              />
            )
          }
          fieldName="renderingProvider"
          isEditable={false}
        />
        {claims && (
          <Row
            label="Billing type"
            fieldName="billingType"
            isEditable={false}
            content={
              isLoading ? (
                <LoadingSkeleton />
              ) : isSigned ? (
                <div className={styles.nonEditableField}>
                  {note?.billingType}
                </div>
              ) : (
                <InlineEditSelect
                  value={note?.billingType ?? undefined}
                  options={[
                    { value: 'Select', label: 'Select' },
                    { value: 'In-network', label: 'In-network' },
                    { value: 'Private pay', label: 'Private pay' },
                  ]}
                  onSave={(value) => {
                    onChange({
                      billingType: value === 'Select' ? null : value,
                    })
                  }}
                  className={cx(styles.rightPadding, styles.rowPostion)}
                  padded={true}
                  placeholder="Select"
                />
              )
            }
          />
        )}
      </div>
      {isLoading && (
        <Skeleton
          title={{
            className: cx(styles.skeletonTitle, styles.skeletonBlock),
          }}
          paragraph={{
            rows: 3,
            className: cx(styles.skeletonParagraph, styles.skeletonBlock),
          }}
        />
      )}
    </div>
  )
}
