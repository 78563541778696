import { useEffect, useState } from 'react'

import { useFeatureFlags } from '../../libs/featureFlags'
import {
  AutosaveTriggerCreateNoteKeyword,
  NoteType,
} from '../useClinicalNoteFields/utils'
import { useQueryStorage } from '../useQueryStorage'

const ONE_WEEK = 1000 * 60 * 60 * 24 * 7

export const useSetClinicalNoteBackup = (
  patientId: string,
  hasPendingChanges?: boolean,
  noteId?: string,
  canSaveBackup = true
) => {
  const [backupFields, setBackupFields] = useState<NoteType | null>(null)
  const [triggerUpdate, setTriggerUpdate] = useState(false)
  const [isNoteBackupChecked, setIsNoteBackupChecked] = useState(false)
  const [backupFound, setBackupFound] = useState(false)

  // Build query key for noteType or specific note backup
  const buildQueryKey = () => {
    if (!canSaveBackup) return [] // With autosave enabled it can only save when autosave request failed with autosave flag disabled the default is true so backup saves constantly
    if (noteId?.includes('SALT:')) return ['SALT', noteId.split(':')[1]]
    if (noteId && noteId !== AutosaveTriggerCreateNoteKeyword)
      return ['unsaved', noteId]
    if (backupFields?.NoteType)
      return ['draft', patientId, backupFields?.NoteType]
    return []
  }
  const { upsertCache, deleteCache } = useQueryStorage<Record<string, any>>(
    buildQueryKey(),
    ONE_WEEK
  )
  const { backupCadenceNotev1 } = useFeatureFlags()

  // Hook that saves the data on query localstorage, using useEffect to avoid outdated states issues
  useEffect(() => {
    if (triggerUpdate) {
      upsertCache({
        noteData: { ...backupFields, PatientId: patientId },
        backupDate: new Date().toISOString(),
      })
      setTriggerUpdate(false)
    }
  }, [triggerUpdate])

  // Interval is always running but not always saving
  useEffect(() => {
    const interval = setInterval(() => {
      // Wait until note matches all the required parameters to start saving the backup
      if (
        backupFields?.NoteType &&
        !triggerUpdate &&
        isNoteBackupChecked &&
        hasPendingChanges
      ) {
        setTriggerUpdate(true)
      }
    }, backupCadenceNotev1 * 1000)
    return () => clearInterval(interval)
  }, [backupFields, isNoteBackupChecked, hasPendingChanges])

  return {
    setBackupFields,
    setIsNoteBackupChecked,
    isNoteBackupChecked,
    deleteCache,
    setBackupFound,
    backupFound,
  }
}
