import React from 'react'

import { Node } from '@tiptap/core'
import { ReactNodeViewRenderer, mergeAttributes } from '@tiptap/react'

import { errorNotification } from '../../../../../libs/notificationLib'

type CoreNodeProps = {
  BlockComponent: (props: any) => React.ReactNode
  name: string
  tag: string
}

export const CoreNode = ({ BlockComponent, name, tag }: CoreNodeProps) =>
  Node.create({
    name,
    group: 'block',
    draggable: true,
    selectable: false,
    addNodeView() {
      return ReactNodeViewRenderer(BlockComponent)
    },

    addOptions() {
      return {
        removeBlock: () => {
          errorNotification(
            'DEVELOPER: Careful! You probably do not actually want to be modifying removeBlock.'
          )
        },
      }
    },

    addAttributes() {
      return {
        id: {
          default: null,
          renderHTML: (attributes) => {
            return {
              id: attributes.id,
            }
          },
        },
      }
    },

    parseHTML() {
      return [{ tag }]
    },

    renderHTML({ HTMLAttributes }) {
      return [
        tag,
        mergeAttributes(HTMLAttributes, {
          id: this.options?.id,
        }),
      ]
    },
  })
