import { useState } from 'react'

import moment from 'moment'

import {
  createRCopiaDiagnosis,
  deleteDiagnosis,
  deleteRCopiaDiagnosis,
  editDiagnosis,
  editRCopiaDiagnosis,
} from '../../../api/api-lib-typed'
import { patientDataTypes } from '../../../containers/Patient/patient-data-types'
import { Button } from '../../../stories/BaseComponents'
import { DiagnosisModal } from '../../Forms/DiagnosisModal'

export const EditDiagnosisButton = ({
  diagnosis,
  handleApiChange,
  handlePatientDataChange,
  patient,
  status,
}: {
  diagnosis: {
    DiagnosisId: string
    Diagnosis: string
    DiagnosisCode: string
    DiagnosisDate: string
    DiagnosisNoteContent: string
    DiagnosisOnsetDate: string
    DiagnosisStatus: 'Active' | 'Inactive' | 'Resolved'
    IsUploaded: boolean
  }
  patient: {
    PatientId: string
    PatientRCopiaId?: string
  }
  handleApiChange: (args: [string, string]) => void
  handlePatientDataChange: () => void
  status: string
}) => {
  const [show, setShow] = useState(false)

  function toggleShow() {
    setShow((curr) => !curr)
  }

  const handleCancelClick = () => {
    setShow(false)
  }

  const handleSubmitEditDiagnosis = async (editedDiagnosis: {
    icdCode: string
    icdDescription: string
    diagnosisDate: string
    diagnosisStatus: 'Active' | 'Inactive' | 'Resolved'
    onsetDate: string
    comments: string
  }) => {
    await editDiagnosis(diagnosis.DiagnosisId, patient.PatientId, {
      Diagnosis: editedDiagnosis.icdDescription,
      DiagnosisCode: editedDiagnosis.icdCode,
      DiagnosisDate: moment(editedDiagnosis.diagnosisDate),
      DiagnosisNoteContent: editedDiagnosis.comments,
      DiagnosisOnsetDate: moment(editedDiagnosis.onsetDate),
      DiagnosisOnsetDateFormat: 'MM/DD/YYYY',
      DiagnosisStatus: editedDiagnosis.diagnosisStatus,
      PatientId: patient.PatientId,
    })

    if (patient?.PatientRCopiaId) {
      if (diagnosis.IsUploaded) {
        await editRCopiaDiagnosis({
          Diagnosis: editedDiagnosis.icdDescription,
          DiagnosisCode: editedDiagnosis.icdCode,
          DiagnosisDate: moment(editedDiagnosis.diagnosisDate),
          DiagnosisNoteContent: editedDiagnosis.comments,
          DiagnosisOnsetDate: moment(editedDiagnosis.onsetDate),
          DiagnosisOnsetDateFormat: 'MM/DD/YYYY',
          DiagnosisStatus: editedDiagnosis.diagnosisStatus,
          PatientId: patient.PatientId,
          PatientRCopiaId: patient.PatientRCopiaId,
        })
      } else {
        await createRCopiaDiagnosis({
          Diagnosis: editedDiagnosis.icdDescription,
          DiagnosisCode: editedDiagnosis.icdCode,
          DiagnosisDate: moment(editedDiagnosis.diagnosisDate),
          DiagnosisNoteContent: editedDiagnosis.comments,
          DiagnosisOnsetDate: moment(editedDiagnosis.onsetDate),
          DiagnosisOnsetDateFormat: 'MM/DD/YYYY',
          DiagnosisStatus: editedDiagnosis.diagnosisStatus,
          PatientId: patient.PatientId,
          PatientRCopiaId: patient.PatientRCopiaId,
          DiagnosisId: diagnosis.DiagnosisId,
        })
      }
    }
    handlePatientDataChange()
    setShow(false)
  }

  const handleDeleteDiagnosis = async () => {
    await deleteDiagnosis(diagnosis.DiagnosisId, patient.PatientId)
    if (patient?.PatientRCopiaId) {
      await deleteRCopiaDiagnosis({
        DiagnosisId: diagnosis.DiagnosisId,
        PatientRCopiaId: patient.PatientRCopiaId,
        Diagnosis: {
          Diagnosis: diagnosis.Diagnosis,
          DiagnosisCode: diagnosis.DiagnosisCode,
          DiagnosisDate: moment(diagnosis.DiagnosisDate),
          DiagnosisNoteContent: diagnosis.DiagnosisNoteContent,
          DiagnosisOnsetDate: moment(diagnosis.DiagnosisOnsetDate),
          DiagnosisOnsetDateFormat: 'MM/DD/YYYY',
          DiagnosisStatus: diagnosis.DiagnosisStatus,
          PatientId: patient.PatientId,
        },
        DiagnosisCode: diagnosis.DiagnosisCode,
        PatientId: patient.PatientId,
      })
    }
    handleApiChange([
      patientDataTypes.PatientDiagnoses,
      patient.PatientId ?? '',
    ])
    handleApiChange([
      patientDataTypes.ProviderSidePatientData,
      patient.PatientId ?? '',
    ])
    setShow(false)
  }

  return (
    <>
      <Button
        id={'editDiagnosisButton' + status}
        onClick={toggleShow}
        type="text"
        style={{ backgroundColor: 'transparent' }}
      >
        <i className="fa fa-pencil" aria-hidden="true">
          {' '}
        </i>
      </Button>
      <DiagnosisModal
        isVisible={show}
        onCancel={handleCancelClick}
        title="Edit diagnosis"
        initialIcdCode={{
          code: diagnosis.DiagnosisCode,
          description: diagnosis.Diagnosis,
        }}
        initialDiagnosisDate={diagnosis.DiagnosisDate}
        initialComments={diagnosis.DiagnosisNoteContent}
        initialDiagnosisStatus={diagnosis.DiagnosisStatus}
        initialOnsetDate={diagnosis.DiagnosisOnsetDate}
        onDeleteDiagnosis={handleDeleteDiagnosis}
        onSaveDiagnosis={handleSubmitEditDiagnosis}
      />
    </>
  )
}
