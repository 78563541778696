import { useMemo } from 'react'

import { format } from 'date-fns-tz'

import { Addendum, Signature } from '../../types'
import { Typography } from './../../../../stories/BaseComponents'
import { Row } from './CoreData/Row'

import styles from './SignatureHeader.module.scss'

export type SignatureHeaderProps = {
  signatures: Signature[]
  addendums: Addendum[]
  scrollToAddendums?: () => void
  scrollToSignatures?: () => void
  printMode?: boolean
}

export const SignatureHeader = ({
  signatures,
  addendums,
  scrollToSignatures,
  scrollToAddendums,
  printMode = false,
}: SignatureHeaderProps) => {
  const { Text, Link } = Typography
  // Memoize the rendering logic for signatures to prevent unnecessary re-renders
  const renderedSignatures = useMemo(() => {
    if (!Array.isArray(signatures) || signatures.length === 0) {
      return null
    }

    if (printMode) {
      return signatures.map((signature, i) => {
        const date = format(new Date(signature.signedAt), 'MM/dd/yyyy')
        return (
          <div key={`signature-content-${i}`} className={styles.signature}>
            <Text>
              {signature.providerName} {date}
            </Text>
          </div>
        )
      })
    }

    return signatures.map((signature, i) => {
      const date = format(new Date(signature.signedAt), 'MMMM dd, yyyy p z')
      return (
        <div key={`signature-content-${i}`} className={styles.signature}>
          <Link onClick={scrollToSignatures}>{signature.providerName}</Link>
          <br />
          <Text className={styles.text}>{date}</Text>
        </div>
      )
    })
  }, [signatures])

  if (!signatures || signatures.length === 0) {
    return <></>
  }

  return (
    <div className={styles.signaturesHeader}>
      <div className={styles.tableWrapper}>
        <Row
          label="Electronically signed by"
          content={
            <div className={styles.nonEditableField}>{renderedSignatures}</div>
          }
          fieldName="signed"
          isEditable={false}
        />
        {addendums.length > 0 && (
          <Row
            label="Addendums added"
            content={
              <div className={styles.nonEditableField}>
                <Link onClick={scrollToAddendums}>
                  {addendums.length}{' '}
                  {addendums.length === 1 ? 'addendum' : 'addendums'}
                </Link>
              </div>
            }
            fieldName="addendum"
          />
        )}
      </div>
    </div>
  )
}
