import React, { useEffect } from 'react'

import { useFormikContext } from 'formik'

import { Card } from '../../../../stories/BaseComponents'
import InlineField from '../InlineField'
import { PATIENT_INFO } from '../field-constants'
import { ClaimForm } from '../types'

import styles from './shared.module.scss'

export const testIds = {
  container: 'claim-patient-info',
}

const PatientInfo: React.FC = () => {
  // current form values
  const {
    values: {
      patient: { address },
    },
    setFieldValue,
    touched,
  } = useFormikContext<ClaimForm>()

  const addressTouched = touched.patient?.address

  // dependent fields logic
  useEffect(() => {
    if (!addressTouched) {
      return
    }
    const newAddress2 = address?.Address2 ?? null
    setFieldValue('patient.address2', newAddress2)
  }, [address, addressTouched])

  return (
    <Card
      testId={testIds.container}
      style={{ marginTop: 24 }}
      bodyStyle={{ paddingBottom: 16 }}
    >
      <div className={styles.section}>
        <div className={styles.header}>
          <span className={styles.title}>{'Patient information'}</span>
        </div>
        <div>
          {PATIENT_INFO.map((item) => (
            <InlineField {...item} key={item.name} />
          ))}
        </div>
      </div>
    </Card>
  )
}

export default PatientInfo
