import { PlusCircleTwoTone } from '@ant-design/icons'
import cx from 'classnames'

import styles from './NoteButton.module.scss'

export const CreateNoteButton = () => {
  return (
    <div className={cx([styles.createNoteButtonContainer, styles.noteButton])}>
      <div>
        <PlusCircleTwoTone twoToneColor="#900ba6" className={styles.icon} />
      </div>
      <div>Beta Note</div>
    </div>
  )
}
