import { useCallback, useMemo, useState } from 'react'

import CalendarOutlined from '@ant-design/icons/CalendarOutlined'
import DollarCircleOutlined from '@ant-design/icons/DollarCircleOutlined'
import DownOutlined from '@ant-design/icons/DownOutlined'
import LineChartOutlined from '@ant-design/icons/LineChartOutlined'
import LogoutOutlined from '@ant-design/icons/LogoutOutlined'
import SettingOutlined from '@ant-design/icons/SettingOutlined'
import TeamOutlined from '@ant-design/icons/TeamOutlined'
import UserOutlined from '@ant-design/icons/UserOutlined'
import {
  Affix,
  Avatar,
  Button,
  Dropdown,
  Layout,
  Menu,
  Space,
  Tooltip,
} from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import classNames from 'classnames'
import { Link, useLocation } from 'react-router-dom'

import { useLocalStorage } from '../../hooks/useLocalStorage'
import { useSchedulingNotifications } from '../../hooks/useSchedulingNotifications'
import { useUnsignedSpravatoNotifications } from '../../hooks/useUnsignedSpravatoNotifications'
import ERxOutlined from '../../images/Icons/ERxOutlined'
import SidebarFilled from '../../images/Icons/SidebarFilled'
import SidebarOutlined from '../../images/Icons/SidebarOutlined'
import OsmindLogo from '../../images/OsmindLogo'
import { useFeatureFlags } from '../../libs/featureFlags'
import { getProviderInitials } from '../../libs/utils'
import { useLogoutAcrossTabsComponent } from '../../v2/logoutAcrossTabs/useLogoutAcrossTabsComponent'
import { CSSPropertiesWithVariables } from '../types'
import { IndicatorWrapper } from './IndicatorWrapper'
import { OnLogoutCallback } from './types'

import styles from './VerticalMainNavigation.module.scss'

const { Sider } = Layout

export enum RoutesWithoutNavbar {
  FORM = 'form',
  ORDER = 'laborder',
}

/**
 * the dropdown will naturally fill the sidebar width (defaults to 200px)
 * but we want it to be inset by 16px on each side so it has some breathing room.
 */
const DROPDOWN_MENU_WIDTH = '168px'

export const IS_COLLAPSED_LOCAL_STORAGE_KEY = 'left-nav-collapsed'

/**
 * Pulled this out to a hook so that it's easier to test (difficult to mock window resizing)
 * Refactor into a util if ever incentivized to reuse?
 * But this feels sufficiently specific to keep local to the navbar itself
 */
export const useResponsiveCollapsedState = () => {
  const [userCachedCollapsedState, setUserCachedCollapsedState] =
    useLocalStorage<boolean>(IS_COLLAPSED_LOCAL_STORAGE_KEY, false)

  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    userCachedCollapsedState
  )

  const toggleUserCollapsed = useCallback(() => {
    setUserCachedCollapsedState<boolean>(!isCollapsed)
    setIsCollapsed(!isCollapsed)
  }, [isCollapsed])

  const onResponsiveCollapsed = useCallback(
    (collapsed: boolean) => {
      if (collapsed) {
        // when narrowing the window (uncollapsed -> collapsed), always let the responsiveness setting override implicit user preference
        setIsCollapsed(true)
      } else {
        // when expanding the window (collapsed -> uncollapsed), cede the state to what the user last selected
        setIsCollapsed(userCachedCollapsedState)
      }
    },
    [userCachedCollapsedState]
  )

  return {
    isCollapsed,
    toggleUserCollapsed,
    onResponsiveCollapsed,
  }
}

interface VerticalMainNavigationProps {
  notificationCount?: number
  providerName?: string
  onLogoutCallback: OnLogoutCallback
}

export default function VerticalMainNavigation({
  notificationCount = 0,
  providerName,
  onLogoutCallback,
}: VerticalMainNavigationProps) {
  const { totalUnreadSpravatoNotes } = useUnsignedSpravatoNotifications()
  const { totalNotifications: totalSchedulingNotifications } =
    useSchedulingNotifications({
      appointmentStatuses: ['PENDING'],
    })
  const { pathname } = useLocation()
  const { initiateLogoutWithConfirmation, LogoutConfirmationModal } =
    useLogoutAcrossTabsComponent({ onLogoutCallback })
  const { isCollapsed, toggleUserCollapsed, onResponsiveCollapsed } =
    useResponsiveCollapsedState()

  const { community } = useFeatureFlags()

  const mainNavigationOptions: ItemType[] = useMemo(() => {
    const base = [
      {
        icon: <UserOutlined />,
        label: <Link to="/">Patients</Link>,
        title: 'Patients',
        key: 'patients',
      },
      {
        icon: (
          <IndicatorWrapper
            icon={
              <Link to="/scheduling">
                <CalendarOutlined style={{ fontSize: '14px' }} />
              </Link>
            }
            hasIndicator={totalSchedulingNotifications > 0}
            indicatorTestId="scheduling-notifications-indicator-icon"
            isCollapsed={isCollapsed}
          />
        ),
        label: (
          <IndicatorWrapper
            label={<Link to="/scheduling">Schedule</Link>}
            hasIndicator={totalSchedulingNotifications > 0}
            indicatorTestId="scheduling-notifications-indicator-label"
            isCollapsed={isCollapsed}
          />
        ),
        title: 'Schedule',
        key: 'scheduling',
      },
      {
        icon: (
          <IndicatorWrapper
            icon={
              <Link to="/reports">
                {<LineChartOutlined style={{ fontSize: '14px' }} />}
              </Link>
            }
            hasIndicator={totalUnreadSpravatoNotes > 0}
            isCollapsed={isCollapsed}
            indicatorTestId="reports-notifications-indicator-icon"
          />
        ),
        label: (
          <IndicatorWrapper
            label={<Link to="/reports/notes">Reports</Link>}
            hasIndicator={totalUnreadSpravatoNotes > 0}
            indicatorTestId="reports-notifications-indicator-label"
            isCollapsed={isCollapsed}
          />
        ),
        title: 'Reports',
        key: 'reports',
      },
      {
        icon: <DollarCircleOutlined />,
        label: <Link to="/billing/clinic">Billing</Link>,
        title: 'Billing',
        key: 'billing',
      },
      {
        icon: (
          <IndicatorWrapper
            icon={
              <Link to="/erx">
                <ERxOutlined />
              </Link>
            }
            hasIndicator={notificationCount > 0}
            isCollapsed={isCollapsed}
            indicatorTestId="erx-notifications-indicator-icon"
          />
        ),
        label: (
          <IndicatorWrapper
            label={<Link to="/erx">e-Prescribe</Link>}
            hasIndicator={notificationCount > 0}
            indicatorTestId="erx-notifications-indicator-label"
            isCollapsed={isCollapsed}
          />
        ),
        title: 'e-Prescribe',
        key: 'erx',
      },
    ]
    if (community) {
      base.push({
        icon: <TeamOutlined />,
        label: (
          <a
            href="https://community.osmind.org/join?invitation_token=f3469ff563132d4c6ab5360dcd8b33d19a1aeecf-9b97a65b-8bbb-4d06-b049-dacdc2d39dd1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Community
          </a>
        ),
        title: 'Community',
        key: 'community',
      })
    }
    return base
  }, [
    community,
    totalUnreadSpravatoNotes,
    totalSchedulingNotifications,
    isCollapsed,
    notificationCount,
  ])

  const bottomNavigationOptions = useMemo(() => {
    return [
      {
        icon: <SettingOutlined />,
        title: 'Settings',
        key: 'settings',
        label: <Link to="/settings/personal">Settings</Link>,
        selected: pathname.startsWith('/settings'),
      },
    ]
  }, [])

  const providerInitials = useMemo(() => {
    return getProviderInitials(providerName)
  }, [providerName])

  const activeKeys = useMemo(() => {
    const knownKeys = mainNavigationOptions
      .map((o) => o?.key)
      .concat(bottomNavigationOptions.map((o) => o.key))
    let derivedKey: string = pathname.split('/')[1] ?? ''
    if (derivedKey === '') {
      derivedKey = 'patients'
    }
    return knownKeys.includes(derivedKey) ? [derivedKey] : undefined
  }, [pathname, mainNavigationOptions, bottomNavigationOptions])

  return (
    <Sider
      aria-label="Main Navigation"
      role="menu"
      className={styles.wrapper}
      collapsedWidth="56px"
      breakpoint="md"
      collapsed={isCollapsed}
      onCollapse={onResponsiveCollapsed}
      style={
        {
          '--dropdown-menu-width': DROPDOWN_MENU_WIDTH,
        } as CSSPropertiesWithVariables
      }
    >
      <LogoutConfirmationModal />
      <div
        className={classNames(
          styles.header,
          isCollapsed ? styles.collapsed : undefined
        )}
      >
        {!isCollapsed && (
          <div className={styles.logoContainer}>
            <Link to="/">
              <OsmindLogo className={styles.logo} />
            </Link>
          </div>
        )}
        <Button
          aria-label="Hide/Show"
          className={styles.collapseButton}
          type="text"
          onClick={toggleUserCollapsed}
          data-testid="vertical-main-navigation-hide-show-button"
        >
          {isCollapsed ? <SidebarOutlined /> : <SidebarFilled />}
        </Button>
      </div>
      <Menu
        className={styles.topMenu}
        mode="vertical"
        items={mainNavigationOptions}
        selectedKeys={activeKeys}
        triggerSubMenuAction="click"
      />
      <Menu
        mode="vertical"
        items={bottomNavigationOptions}
        selectedKeys={activeKeys}
        triggerSubMenuAction="click"
      />
      <Affix offsetBottom={0}>
        <Dropdown
          overlay={
            <Menu
              className={classNames(
                styles.dropdownMenu,
                isCollapsed ? styles.collapsed : null
              )}
              mode="vertical"
              items={[
                {
                  icon: <LogoutOutlined />,
                  onClick: initiateLogoutWithConfirmation,
                  key: 'Log out',
                  label: 'Log out',
                  title: '',
                },
              ]}
            />
          }
          placement="top"
          trigger={['click']}
        >
          <Tooltip title={isCollapsed ? 'Profile' : null} placement="right">
            <Button aria-label="Profile Menu" className={styles.profileButton}>
              <Space align="center" size="small">
                <Avatar
                  shape="circle"
                  size={16}
                  icon={providerInitials ? null : <UserOutlined />}
                >
                  {providerInitials}
                </Avatar>
                {!isCollapsed && providerName?.split(' ')[0]}
              </Space>
              {!isCollapsed && (
                <DownOutlined className={styles.profileButtonIndicator} />
              )}
            </Button>
          </Tooltip>
        </Dropdown>
      </Affix>
    </Sider>
  )
}
