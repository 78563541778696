import React, { useEffect, useState } from 'react'

import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Form } from 'antd'

import { getEmailTemplate, updateEmailTemplate } from '../../api/api-lib-typed'
import { onError } from '../../libs/errorLib'
import { notification } from '../../libs/notificationLib'
import { Button, Card, TextArea } from '../../stories/BaseComponents'

import '../Provider/Settings.scss'
import styles from '../_shared.module.scss'

export const PROVIDER_EMAIL_TEMPLATE_QUERY_KEY = 'PROVIDER_EMAIL_TEMPLATE'

export const DEFAULT_EMAIL_TEMPLATE =
  "Dear Ms/Mr/Mx, \n\nIncluded with patient's ketamine infusion therapy is a progress monitoring program called Osmind. With this program, we can see the patient's daily self-assessed mood score from 1-10.\n\nEvery couple of weeks, or when we deem it necessary, the patient will also complete the more in-depth PHQ-9 to benchmark and monitor overall depression symptoms.\n\nAttached you will find a graph of patient's daily mood scores, plus any additional scores. The black lines indicate when treatments occurred. Please do not hesitate to contact me if you have any questions at all.\n\nSincerely,\n\n[provider]"

export const getDefaultEmailBody = (emailTemplate: string | undefined) => {
  return emailTemplate && emailTemplate.length > 0
    ? emailTemplate
    : DEFAULT_EMAIL_TEMPLATE
}

interface FormValues {
  template: string
}

const EmailTemplate: React.FC = () => {
  const queryClient = useQueryClient()
  const [form] = Form.useForm()
  const { data: emailTemplate } = useQuery<string>(
    [PROVIDER_EMAIL_TEMPLATE_QUERY_KEY],
    {
      queryFn: () => getEmailTemplate(),
      retry: false,
    }
  )
  const [isLoading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const initialValues: FormValues = {
      template: getDefaultEmailBody(emailTemplate),
    }
    form.setFieldsValue(initialValues)
  }, [emailTemplate])

  const handleSubmit = async (values: FormValues) => {
    setLoading(true)

    try {
      await updateEmailTemplate(values.template)
      notification('Successfully updated the email template.', 'success')
      queryClient.invalidateQueries([PROVIDER_EMAIL_TEMPLATE_QUERY_KEY])
    } catch (e) {
      onError(
        e,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className={styles.scroll}>
      <div className={styles.spacedContainer}>
        <Card bordered={false}>
          <div className="headerWrapper headerWrapper_title">
            Email Template
          </div>

          <div className="headerWrapper_subtitle">
            This template will be used as the starting text in the message body
            of Share Patient Progress (located in Patient Settings).
          </div>

          <Form layout="vertical" form={form} onFinish={handleSubmit}>
            <Form.Item
              id="template"
              name="template"
              rules={[
                {
                  required: true,
                  message: 'Please input Email Template',
                  type: 'string',
                  whitespace: true,
                },
              ]}
            >
              <TextArea
                data-testid={'email-template-contents'}
                rows={14}
                placeholder="Email Template"
              />
            </Form.Item>
            <Form.Item id="submit">
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  )
}

export default EmailTemplate
