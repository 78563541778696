import { useEffect, useState } from 'react'

import cx from 'classnames'
import { Dropdown, NavItem, NavLink } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import { useProfileUrlParams } from '../../../hooks/useProfileUrlParams'
import { useFeatureFlags } from '../../../libs/featureFlags'
import { ClinicalNote, NoteTypes } from '../../../shared-types'
import { CreateNoteButton } from '../../../v2/notes/Components/CreateNoteButton'
import { getClinicalNotesUrl } from './utils'

import NoteButtonStyles from '../../../v2/notes/Components/CreateNoteButton/NoteButton.module.scss'

interface AddNoteButtonProps {
  notes: ClinicalNote[]
  newNoteButtonIsDisabled: boolean
  enableNewNoteButton: () => void
}

/**
 * Repurposed button component taken from deprecated NewClinicalNoteButton.js and used to navigate to new
 * demodalized pages: create note, salt note and edit note
 */
export default function AddNoteButton({
  notes,
  newNoteButtonIsDisabled,
  enableNewNoteButton,
}: AddNoteButtonProps) {
  const history = useHistory()
  const { urlParams } = useProfileUrlParams()

  const { hasEnabledCustomNotes, autosaveDebounceNotev1Seconds } =
    useFeatureFlags()

  const [mostRecentNoteTypes, setMostRecentNoteTypes] = useState<
    Record<string, ClinicalNote>
  >({})

  const goToCreateNote = () => {
    if (!autosaveDebounceNotev1Seconds) {
      history.push(getClinicalNotesUrl({ urlParams, isNew: true }))
    } else {
      history.push(
        getClinicalNotesUrl({ urlParams, autoSaveEnabled: true, isNew: true })
      )
    }
  }

  const goToCreateNoteV2 = () => {
    hasEnabledCustomNotes
      ? history.push(
          getClinicalNotesUrl({ isV2: true, isNew: true, urlParams })
        )
      : goToCreateNote()
  }

  const goToSaltNote = (noteId: string) => {
    if (!autosaveDebounceNotev1Seconds) {
      history.push(getClinicalNotesUrl({ urlParams, isNew: true, noteId }))
    } else {
      history.push(
        getClinicalNotesUrl({
          urlParams,
          autoSaveEnabled: true,
          isNew: true,
          noteId,
        })
      )
    }
  }

  const findNewerNote = (note1: ClinicalNote, note2: ClinicalNote) => {
    const creationTime1 = new Date(note1.CreatedOn)
    const creationTime2 = new Date(note2.CreatedOn)
    return creationTime1 > creationTime2 ? note1 : note2
  }

  useEffect(() => {
    if (notes) {
      const mostRecentNotes: Record<string, ClinicalNote> = {}
      notes
        .filter((note) => note.NoteType !== NoteTypes.NOTE_V2)
        .forEach((note) => {
          if (!(note.NoteType in mostRecentNotes)) {
            mostRecentNotes[note.NoteType] = note
          } else {
            const newerNote = findNewerNote(
              note,
              mostRecentNotes[note.NoteType as string]
            )
            mostRecentNotes[note.NoteType] = newerNote
          }
        })
      setMostRecentNoteTypes(mostRecentNotes)
    }

    enableNewNoteButton()
  }, [notes])

  return (
    <Dropdown as={NavItem}>
      <Dropdown.Toggle
        as={NavLink}
        bsPrefix="nav-items"
        style={{ fontSize: 20, verticalAlign: 'sub' }}
        disabled={newNoteButtonIsDisabled}
        data-testid="toggle-add-note"
      >
        <svg
          style={{ verticalAlign: 'top' }}
          width="1.5em"
          height="1.5em"
          viewBox="0 0 16 16"
          className="bi bi-file-earmark-plus"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9 1H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h5v-1H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h5v2.5A1.5 1.5 0 0 0 10.5 6H13v2h1V6L9 1z" />
          <path
            fillRule="evenodd"
            d="M13.5 10a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z"
          />
          <path
            fillRule="evenodd"
            d="M13 12.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0v-2z"
          />
        </svg>
        <span style={{ fontSize: 20, verticalAlign: 'sub' }}>
          &nbsp;&nbsp;New Note
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu bsPrefix="dropdown-menu-simple">
        <Dropdown.Item
          bsPrefix="nav-items"
          onClick={goToCreateNote}
          data-testid="new-note-button"
        >
          <div
            className={cx([
              'align-left',
              NoteButtonStyles.createNoteButtonContainer,
            ])}
          >
            <div>
              <i className="fa fa-plus-circle" aria-hidden="true" />
            </div>
            &nbsp;New Note
          </div>
        </Dropdown.Item>
        {hasEnabledCustomNotes ? (
          <Dropdown.Item
            bsPrefix="nav-items"
            onClick={goToCreateNoteV2}
            data-testid="new-note-v2-button"
          >
            <CreateNoteButton />
          </Dropdown.Item>
        ) : (
          ''
        )}

        {Object.keys(mostRecentNoteTypes).length ? (
          <hr style={{ margin: 5, borderTop: '3px solid #2765c1' }} />
        ) : null}
        {mostRecentNoteTypes &&
          Object.keys(mostRecentNoteTypes).map((noteType) => {
            return (
              <Dropdown.Item
                bsPrefix="nav-items"
                onClick={() =>
                  goToSaltNote(mostRecentNoteTypes[noteType]?.NoteId ?? null)
                }
                key={noteType}
                data-testid="salt-note-button"
              >
                <div
                  className={cx([
                    'align-left',
                    NoteButtonStyles.createNoteButtonContainer,
                  ])}
                >
                  <div>
                    <i className="fa fa-clock-o" aria-hidden="true" />
                  </div>
                  &nbsp;From Last {noteType}
                </div>
              </Dropdown.Item>
            )
          })}
      </Dropdown.Menu>
    </Dropdown>
  )
}
