import { useEffect, useMemo } from 'react'

import {
  MutationStatus,
  QueryStatus,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { keyBy } from 'lodash'

import {
  createClaimFromPatientInfo,
  deleteClaim,
  getClaimById,
  updateClaim,
  updateClaimMemo,
} from '../../../api/insuranceClaimsV2'
import { QueryKeys as InsuranceClaimQueryKeys } from '../../../hooks/useInsuranceClaims'
import { useChangePayers } from '../../../hooks/usePatientProfile'
import { ClaimData } from './types'

const QUERY_KEYS = {
  CLAIM: 'CLAIM',
} as const

type UseGetClaimDataInput = {
  patientId: string
  claimId?: string
  noteId?: string
}

type UseGetClaimDataOutput = {
  data?: ClaimData
  error: unknown
  status: QueryStatus | MutationStatus
}

export const useGetClaimData = ({
  patientId,
  claimId,
  noteId,
}: UseGetClaimDataInput): UseGetClaimDataOutput => {
  const queryClient = useQueryClient()
  const {
    data: existingClaimData,
    error: existingClaimError,
    status: existingClaimFetchStatus,
  } = useQuery(
    [QUERY_KEYS.CLAIM, claimId],
    () => getClaimById(claimId as string),
    {
      enabled: !!claimId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 0,
    }
  )

  const {
    mutate: doCreateClaim,
    data: newClaimData,
    error: newClaimError,
    status: newClaimFetchStatus,
  } = useMutation({
    mutationFn: createClaimFromPatientInfo,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [InsuranceClaimQueryKeys.INSURANCE_CLAIMS, patientId],
      })
    },
  })

  useEffect(() => {
    if (!claimId) {
      doCreateClaim({ patientId, noteId })
    }
  }, [claimId, patientId, noteId])

  // use useMemo to avoid creating a new object on every render, critical for preventing extra rendering when we include the returned "data" as a dependency in a useEffect
  const existingClaimDataWithClaimId = useMemo(
    () =>
      existingClaimData ? { ...existingClaimData, claimId } : existingClaimData,
    [existingClaimData]
  )

  if (claimId) {
    return {
      data: existingClaimDataWithClaimId,
      error: existingClaimError,
      status: existingClaimFetchStatus,
    }
  } else {
    return {
      data: newClaimData,
      error: newClaimError,
      status: newClaimFetchStatus,
    }
  }
}

export const useDeleteClaim = ({ patientId }: { patientId: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteClaim,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [InsuranceClaimQueryKeys.INSURANCE_CLAIMS, patientId],
      })
    },
  })
}

export const useSaveClaim = ({ claimId }: { claimId?: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateClaim,
    onSuccess: () => {
      // invalidate getClaimById query after claim update.
      // Otherwise useGetClaimData will serve stale data when the same claim loads for the second time, which is not a problem if we set enableReinitialize prop to true for <Formik/>
      // However, that causes extra re-initialization and flash of old claim data.
      if (claimId) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.CLAIM, claimId],
        })
      }
    },
  })
}

export const useSaveClaimMemo = ({ claimId }: { claimId?: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateClaimMemo,
    onSuccess: () => {
      if (claimId) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.CLAIM, claimId],
        })
      }
    },
  })
}

export const usePayers = () => {
  const { data, status } = useChangePayers()

  const payersByName = useMemo(() => {
    if (data?.length) {
      return keyBy(data, 'organizationName')
    }
    return undefined
  }, [data])

  return {
    status,
    options:
      data?.map((payer) => ({
        label: payer.organizationName,
        value: payer.organizationName,
      })) ?? [],
    payersByName,
  }
}

export type UsePayersResult = ReturnType<typeof usePayers>
