import { useMutation } from '@tanstack/react-query'

import { notification } from '../../../../libs/notificationLib'
import {
  CreateDynamicBlockOptions,
  DynamicNoteBlock,
} from '../../Components/Blocks/Core/Block/DynamicBlockTypes'
import { createNoteBlock } from '../../api'

type useCreateNoteProps = {
  onSuccess: (noteBlock: DynamicNoteBlock) => void
  onError?: (error: Error) => void
}

export const useCreateNoteBlock = ({
  onSuccess,
  onError,
}: useCreateNoteProps) => {
  const defaultOnError = (error: Error) => {
    console.error(error)
    notification(
      'There was a problem creating the note block. Please contact your Osmind administrator.',
      'error'
    )
  }

  const {
    mutate: create,
    isLoading,
    isSuccess,
    isError,
  } = useMutation(createNoteBlock, {
    onSuccess: (noteBlock) => {
      onSuccess(noteBlock)
    },
    onError: onError ? onError : defaultOnError,
  })

  const callCreate = ({ noteUuid, blockConfig }: CreateDynamicBlockOptions) => {
    create({ noteUuid, blockConfig })
  }

  return {
    createNewNoteBlock: callCreate,
    isLoading,
    isSuccess,
    isError,
  }
}
