import React from 'react'

import Card from './Card'

import './SignaturePreview.scss'

export enum FontOptions {
  ALLURA = 'Allura',
  DANCING_SCRIPT = 'Dancing Script',
  GREAT_VIBES = 'Great Vibes',
  LICORICE = 'Licorice',
  SACRAMENTO = 'Sacramento',
}

interface SignaturePreviewProps {
  font: FontOptions | ''
  name: string
}

const SignaturePreview: React.FC<SignaturePreviewProps> = ({
  font, // default is sacramento if none is given
  name,
}) => {
  return (
    <Card id="signature-preview">
      <span
        style={{
          fontFamily: font || FontOptions.SACRAMENTO,
        }}
      >
        {name}
      </span>
    </Card>
  )
}

export default SignaturePreview
