import React, { JSX } from 'react'

import cx from 'classnames'

import styles from './TableHeader.module.scss'

export const DynamicBlockHeader = (args: {
  title: string
  isSigned: boolean
  buttons: JSX.Element[]
}): JSX.Element | null => {
  return (
    <div className={styles.container}>
      <h2 className={styles.label + ' block-header'}>{args.title}</h2>
      {!args.isSigned && args.buttons && (
        <div className={cx('block-buttons', styles.blockButtons)}>
          {args.buttons}
        </div>
      )}
    </div>
  )
}
