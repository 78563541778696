import { intervalToDuration, isBefore } from 'date-fns'
import moment, { Moment } from 'moment'

import { mmyyyydash, mmyyyyslash } from '../../libs/regex'
import { parseDateOrNull } from '../../libs/utils'
import { DateISO8601 } from '../../shared-types'

/**
 * Takes any possibly value that a Clinical Note date could be and returns it as a
 * Date if it is valid or a null if invalid
 *
 * @param noteDate any
 * @returns Date | null
 */
export function determineNoteDate(noteDate: any) {
  if (moment.isMoment(noteDate)) {
    return parseDateOrNull(noteDate.toDate(), true)
  }
  return parseDateOrNull(noteDate, true)
}

export function determineNoteDateWithTime(
  noteDate: Moment | Date | null,
  startTime: string | null
): Date | null {
  let outputDate

  if (moment.isMoment(noteDate)) {
    outputDate = parseDateOrNull(noteDate.toDate(), true)
  } else {
    outputDate = parseDateOrNull(noteDate, true)
  }

  // If note's startTime is not available, then just use what we have from the noteDate
  if (!startTime || !outputDate) {
    return outputDate
  }

  const timeComponents = startTime.split(':')

  // If note's startTime is invalid format, then just default time from noteDate also
  if (
    timeComponents.length !== 2 ||
    isNaN(Number(timeComponents[0])) ||
    isNaN(Number(timeComponents[1])) ||
    Number(timeComponents[0]) < 0 ||
    (Number(timeComponents[0]) >= 24 && Number(timeComponents[1]) > 0)
  ) {
    return outputDate
  }

  const [hour, minute] = timeComponents.map(Number)

  // Return the noteDate modified with startTime if all works out
  const dateWithModifiedTime = new Date(outputDate.getTime())
  dateWithModifiedTime.setHours(hour, minute)
  return dateWithModifiedTime
}

export function isBeforeOrOn(date1: string | Date, date2: string | Date) {
  const dateObj1 = new Date(date1)
  const dateObj2 = new Date(date2)
  const areDatesEqual =
    dateObj1.getDate() === dateObj2.getDate() &&
    dateObj1.getMonth() === dateObj2.getMonth() &&
    dateObj1.getFullYear() === dateObj2.getFullYear()
  return areDatesEqual || isBefore(dateObj1, dateObj2)
}

/**
 * Takes an array of possible date fields, iterates through them
 * and tries to find the first usable date.
 *
 * NOTE: The ordering of the array items is the priority order for which date to use
 * */
export const getUsableDate = (
  datesToCheck: (null | undefined | DateISO8601 | string)[]
): Date | null => {
  const datesToIterate = [...datesToCheck]
  let dateToUse = null

  while (datesToIterate.length && dateToUse === null) {
    dateToUse = datesToIterate.find((date) => date)

    if (!dateToUse) continue

    if (dateToUse.match(mmyyyyslash)) {
      const datesplit = dateToUse.split('/')
      dateToUse = `${datesplit[0]}/01/${datesplit[1]}`
    }

    if (dateToUse.match(mmyyyydash)) {
      const datesplit = dateToUse.split('-')
      dateToUse = `${datesplit[0]}/01/${datesplit[1]}`
    }

    datesToIterate.shift()
    dateToUse = parseDateOrNull(dateToUse, true)
  }

  if (dateToUse instanceof Date || dateToUse === null) {
    return dateToUse
  }
  return parseDateOrNull(dateToUse, true)
}

export const getAgeWithMonths = (birthDate: string) => {
  if (!birthDate) return ''
  let date = new Date(birthDate)
  if (isNaN(date.getTime())) {
    date = new Date(parseFloat(birthDate))
  }
  const interval = intervalToDuration({
    start: date,
    end: new Date(),
  })
  const years = interval.years
  const months = interval.months
  const monthsText = months && months > 0 ? `${months}m` : ``
  return `${years}y ${monthsText}`
}
/**
 * @returns string: The full name of the patient displayed as "First M. Last"
 */
export const getNameToDisplay = ({
  firstName,
  middleName,
  lastName,
}: {
  firstName: string
  lastName: string
  middleName?: string
}): string => {
  return `${firstName} ${
    middleName ? `${middleName.charAt(0)}. ` : ''
  }${lastName}`
}

/**
 * @returns string: return hour pased in 12 hours to 24 hours format example: 04:32 pm to 16:32
 */
export const convertTime12to24 = (time12h: string) => {
  if (!time12h || time12h === '') return ''
  const [time, modifier] = time12h.split(' ')

  // eslint-disable-next-line prefer-const
  let [hours, minutes] = time.split(':')

  if (hours === '12' && modifier?.toLowerCase() === 'am') {
    hours = '00'
  }
  if (hours !== '12' && modifier?.toLowerCase() === 'pm') {
    hours = (parseInt(hours, 10) + 12).toString()
  }

  return `${hours}:${minutes}`
}

export const convertTimeISOto24 = (iso: string | null) => {
  if (iso == null) {
    return ''
  } else {
    const date = new Date(iso)
    return `${date.getHours().toString().padStart(2, '0')}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`
  }
}

export const getTimeFromISO = (iso: string | null) => {
  if (iso == null) {
    return null
  } else {
    const date = new Date(iso)
    return { hours: date.getHours(), minutes: date.getMinutes() }
  }
}

export const convertTime24toISO = (
  date: string | undefined | null,
  time24h: string
) => {
  if (!date || time24h === '') {
    return null
  } else {
    const [hour, minutes] = time24h.split(':')
    const fullDate = new Date(date)
    fullDate.setHours(+hour)
    fullDate.setMinutes(+minutes)
    return fullDate.toISOString()
  }
}

export const convertTime24to12 = (time24h: string) => {
  if (!time24h) {
    return { hours: 'hh', minutes: 'mm', modifier: 'aa' }
  }
  // eslint-disable-next-line prefer-const
  let [hours, minutes] = time24h.split(':')
  const modifier = +hours >= 12 ? 'PM' : 'AM'
  hours = +hours % 12 === 0 ? '12' : (+hours % 12).toString()
  hours = hours.length === 1 ? '0' + hours : hours
  return { hours, minutes, modifier }
}

export const validateZipCode = (value: string) => {
  const regexByCountry = {
    US: /^\d{5}(?:[- ]?\d{4})?$/,
    CA: /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
  }
  const regexes = Object.values(regexByCountry)
  return regexes.some((regex) => regex.test(value))
}

// BEN: This intentionally allows `undefined` providerId. Appointment link
//   to notes page causes an error if url has `providerId=` (empty string)
//   but previous code would result in `providerId=undefined`, which seems
//   to work for some reason (truthiness?), so we allow `undefined` to be
//   able to maintain previous behavior there.
/** @returns string: patient url redirect */
export const getPatientUrl = (patientId: string, providerId?: string) =>
  `/patient/clinical-notes?patientId=${patientId}&providerId=${providerId}`

/**
 * @returns string: given a durationInMinutes from an appointmentType, it returns duration in hours
 */
export const transformDurationToHoursAndMinutes = (
  durationInMinutes: number
) => {
  const originalDuration =
    typeof durationInMinutes === 'string'
      ? parseInt(durationInMinutes)
      : durationInMinutes
  const hourValue = Math.floor(originalDuration / 60)
  const minuteValue = originalDuration % 60
  let durationValue
  if (hourValue !== 0) {
    if (minuteValue !== 0) {
      durationValue = `${hourValue} hr ${minuteValue} min`
    } else {
      durationValue = `${hourValue} hr`
    }
  } else {
    durationValue = `${minuteValue} min`
  }
  return durationValue
}

/**
 * @returns string: phone number formated with (XXX) XXX - XXXX mask
 */
export const formatPhoneNumber = (input?: string): string => {
  if (!input || !input.length) {
    return input || ''
  }
  let clean = input.replace(/\D/g, '')
  if (clean.length > 10) {
    clean = clean.replace(/^\+?1|\|1|\D/, '') // clean us country code
    clean = clean.substring(0, 10) // limit to 10 numbers
  }
  if (clean.length === 10) {
    return clean.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
  } else if (clean.length > 6) {
    return clean.replace(/(\d{3})(\d{3})(\d)/, '($1) $2-$3')
  } else if (clean.length > 3) {
    return clean.replace(/(\d{3})(\d)/, '($1) $2')
  } else {
    return clean
  }
}
/**
 * Restrict decimal on key press event for input element with type number;
 * Example: <input type="number" onKeyPress={restrictDecimalOnKeyPressInputEvent} />
 * @param e event
 */
export const restrictDecimalOnKeyPressInputEvent = (e: any) => {
  const { value } = e.target
  const { key } = e

  // Allowed non-numeric keys
  const allowedNonNumericKeys = new Set([
    'Backspace',
    'Delete',
    'ArrowLeft',
    'ArrowRight',
    'Tab',
    'Enter',
    '.',
  ])
  // Check for non-numeric keys and disallow if not in the allowed set
  if (isNaN(key) && !allowedNonNumericKeys.has(key)) {
    e.preventDefault()
    return
  }

  // Check if text is selected
  if (e.target.selectionStart !== e.target.selectionEnd) {
    return
  }

  // Check if a decimal point is already present
  if (key === '.' && value.includes('.')) {
    e.preventDefault()
    return
  }

  //If the coursor is on the integer part of the number, allow only numbers
  if (e.target.selectionStart <= value.indexOf('.')) {
    return
  }

  // Check for more than two decimal places
  const decimalIndex = value.indexOf('.')
  if (decimalIndex !== -1 && key !== '.' && value.length - decimalIndex > 2) {
    e.preventDefault()
    return
  }
}
