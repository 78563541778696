import React, { useEffect, useMemo, useRef, useState } from 'react'

// NOTE: do not change this to a base component---this needs to pull directly
// from antd for now
import { DatePicker } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import moment, { Moment } from 'moment'
import { useHistory } from 'react-router-dom'

import { formatDate, parseDateOrNull } from '../../../libs/utils'
import InlineEditBase, { focusRef } from './InlineEditBase'
import {
  ActiveComponentProps,
  BaseInterface,
  OnSave,
  PickerMode,
  Validator,
} from './shared-types'

import styles from './_shared.module.scss'

export interface Props extends BaseInterface {
  // format has to appease both date-fns and dayjs
  // (latter is converted to upper case)
  format?: string
  onSave?: OnSave<string>
  rows?: number
  size?: SizeType
  mode?: PickerMode
  value?: Date | string | null
  Validators?: Validator<Date | string | null>
  allowClear?: boolean
  before?: Moment
  after?: Moment
  // Whether or not the passed value should stay in local time or be cast to UTC
  isLocal?: boolean
}

/**
  Editable inline date
*/
const InlineEditDate = ({
  format = 'MM/dd/yyyy',
  placeholder,
  requireConfirmation = false,
  mode,
  size,
  testId,
  onSave,
  value: initialValue,
  autoFocus,
  allowClear = false,
  before,
  after,
  disabled,
  isLocal,
  ...props
}: Props) => {
  const history = useHistory()
  const dateRef: React.Ref<any> = useRef(null)
  const dateContainerRef: React.Ref<HTMLDivElement> = useRef(null)
  const [open, setOpen] = useState(false)
  const [rendered, setRendered] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRendered(true)
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const ActiveDatePicker = useMemo(
    () =>
      ({
        handleChange,
        toggleCancel,
        toggleSave,
        value,
      }: ActiveComponentProps) => {
        const givenDate = useMemo(() => {
          if (!moment(formatDate({ format, value })).isValid()) return undefined
          return moment(parseDateOrNull(value, isLocal))
        }, [format, value])

        const initialDate = useMemo(() => {
          if (!moment(formatDate({ format, value: initialValue })).isValid())
            return undefined
          return moment(parseDateOrNull(initialValue, isLocal))
        }, [format, initialValue])

        return (
          <DatePicker
            className={`${styles.activeSelect} ${!value && styles.noValue}`}
            data-testid={`inline-${testId}`}
            format={format.toUpperCase()}
            onKeyDown={async (event) => {
              if (event.key === 'Escape') return toggleCancel()
              if (event.key === 'Enter')
                await toggleSave(event.currentTarget.value)
            }}
            onChange={(_date, dateString) => {
              handleChange(dateString)
              setOpen(false)
            }}
            onClick={() => setOpen(true)}
            open={open}
            placement="topLeft"
            picker={mode}
            ref={dateRef}
            size={size}
            disabledDate={(current: Moment) =>
              current.isBefore(
                before != null ? before : moment().subtract(100, 'year')
              ) || current.isAfter(after != null ? after : moment())
            }
            value={givenDate}
            defaultValue={initialDate}
          />
        )
      },
    [open, mode, initialValue]
  )

  return (
    <div className={styles.refContainer} ref={dateContainerRef}>
      <InlineEditBase
        {...props}
        activeComponent={(props) => <ActiveDatePicker {...props} />}
        value={initialValue}
        onActive={() =>
          focusRef(autoFocus, dateContainerRef, dateRef, rendered, () => {
            setOpen(true)
            const searchParams = new URLSearchParams(location.search)
            if (searchParams.toString().indexOf('field') !== -1) {
              history.push({
                search: location.search.replace(
                  searchParams
                    .toString()
                    .slice(searchParams.toString().indexOf('field') - 1),
                  ''
                ),
              })
            }
          })
        }
        autoSave={!requireConfirmation}
        toggleActive={autoFocus}
        onSave={onSave}
        size={size}
        placeholder={placeholder}
        testId={testId}
        showActions={requireConfirmation}
        allowClear={allowClear}
        disabled={disabled}
      />
    </div>
  )
}

export default InlineEditDate
