import React, { useEffect, useMemo, useState } from 'react'

import { UserOutlined } from '@ant-design/icons'
import { Avatar, Badge, Dropdown, Layout } from 'antd'
import { Link } from 'react-router-dom'

import Menu from '../../components/Menu/Menu'
import { Option } from '../../components/Menu/types'
import { useReadyForReviewLabs } from '../../hooks/useReadyToReviewLabs'
import OsmindLogo from '../../images/OsmindLogo'
import { useFeatureFlags } from '../../libs/featureFlags'
import { useLogoutAcrossTabsComponent } from '../../v2/logoutAcrossTabs/useLogoutAcrossTabsComponent'
import { OnLogoutCallback } from './types'

import './Navigation.scss'

const { Header } = Layout

export enum RoutesWithoutNavbar {
  FORM = 'form',
  ORDER = 'laborder',
}

interface NavigationBarProps {
  notificationCount?: number
  hasNotification?: boolean
  providerName?: string
  onLogoutCallback: OnLogoutCallback
}

export default function NavigationBar({
  providerName = '',
  notificationCount = 0,
  onLogoutCallback,
}: NavigationBarProps) {
  const { initiateLogoutWithConfirmation, LogoutConfirmationModal } =
    useLogoutAcrossTabsComponent({ onLogoutCallback })

  const [practiceLogo, setPracticeLogo] = useState<React.ReactElement | null>(
    null
  )
  const [menuOptionSelected, setMenuOptionSelected] = useState<Option | null>(
    null
  )
  const hasLabsReportReview = useReadyForReviewLabs().data
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(
    window.matchMedia('only screen and (max-width: 678px)').matches
  )
  const { community } = useFeatureFlags()

  const hasNotification = notificationCount > 0

  let fullPath = location.pathname

  if (!fullPath.includes('/reports')) {
    fullPath = '/reports/notes'
  }

  const headerAvatarOptions: Option[] = [
    {
      notificationCount,
      icon: 'ERxOutlined',
      link: '/erx',
      title: 'e-Prescribing',
    },
    {
      icon: 'SettingOutlined',
      link: '/settings/personal',
      title: 'Settings',
    },
    {
      isDivider: true,
    },
    {
      icon: 'LogoutOutlined',
      onClick: initiateLogoutWithConfirmation,
      title: 'Log out',
    },
  ]
  const navigationOptions: Option[] = useMemo(
    () => [
      { icon: 'UserOutlined', link: '/', title: 'Patients', id: 'patient' },
      {
        icon: 'CalendarOutlined',
        link: '/scheduling',
        title: 'Schedule',
        id: 'scheduling',
      },
      {
        icon: 'LineChartOutlined',
        link: fullPath,
        title: 'Reports',
        id: 'reports',
      },
      {
        icon: 'DollarCircleOutlined',
        link: '/billing/clinic',
        title: 'Billing',
        id: 'billing',
      },
    ],
    [hasLabsReportReview]
  )

  useEffect(() => {
    const handleSetIsSmallScreen = (mediaQueryListEvent: MediaQueryListEvent) =>
      setIsSmallScreen(mediaQueryListEvent.matches)
    const mediaQuery = window.matchMedia('only screen and (max-width: 678px)')
    /// Due to some old browser incompatibility we have to use deprecated function.
    /// Once we define a minimum browser version, please change it to most recent function.
    /// mediaQuery.addEventListener('change', handleSetIsSmallScreen)
    mediaQuery.addListener(handleSetIsSmallScreen)
    return () => {
      /// Same here, please update in the future.
      // return mediaQuery.removeEventListener('change', handleSetIsSmallScreen)
      return mediaQuery.removeListener(handleSetIsSmallScreen)
    }
  }, [])

  const sortedNavigationOptions: Option[] = useMemo(() => {
    if (!isSmallScreen) {
      return navigationOptions
    }

    if (!menuOptionSelected) {
      return []
    }

    return [
      {
        ...menuOptionSelected,
        isSelected: true,
        subMenu: navigationOptions
          .filter((a) => a.id !== menuOptionSelected.id)
          .map((a) => ({ ...a, isSelected: false })),
      },
    ]
  }, [menuOptionSelected, isSmallScreen])

  const findSelectedMenuItem = (optionSelected: string) =>
    navigationOptions.find((a) => a.id === optionSelected) || null

  useEffect(() => {
    const [, currentMenuOption] = window.location.pathname.split('/')
    if (!currentMenuOption) {
      setMenuOptionSelected(navigationOptions[0])
    } else {
      setMenuOptionSelected(findSelectedMenuItem(currentMenuOption))
    }
  }, [window.location.pathname])
  const communityNavigationOptions = {
    icon: 'TeamOutlined',
    link: 'https://community.osmind.org/join?invitation_token=f3469ff563132d4c6ab5360dcd8b33d19a1aeecf-9b97a65b-8bbb-4d06-b049-dacdc2d39dd1',
    title: 'Community',
    id: 'community',
    isSelected: false,
    isExternalLink: true,
  }

  // had to add this or the Community button would duplicate on each refresh of the DOM
  let hasCommunity = false
  for (const nO of navigationOptions) {
    if (nO.title === 'Community') hasCommunity = true
  }
  if (community && !hasCommunity) {
    navigationOptions.push(communityNavigationOptions)
  }

  useEffect(() => {
    const loadLogo = () => {
      if (!providerName || providerName === 'Secondary Account (N/A)') {
        setPracticeLogo(<Avatar shape="square" icon={<UserOutlined />} />)
        return
      }

      const name = providerName.split(' ')
      const letters = []
      const length = name.length

      if (!length) {
        setPracticeLogo(<Avatar shape="square" icon={<UserOutlined />} />)
        return
      }

      const firstLetter = name[0][0]
      letters.push(firstLetter)

      if (length >= 2) {
        const secondLetter = name[length - 1][0]
        letters.push(secondLetter)
      }

      const logo = (
        <Avatar
          shape="square"
          icon={
            <>
              {letters.map((letter, index) => {
                return (
                  <span
                    id={`providerInitials${letter}${index}`}
                    className="logo-initial"
                    key={`${letter}-${index}`}
                  >
                    {letter}
                  </span>
                )
              })}
            </>
          }
        />
      )

      setPracticeLogo(logo)
    }

    loadLogo()
  }, [providerName])

  const handleMenuOptionSelect = ({ key }: { key: string }) => {
    const [, selectedOption] = key.split('-')
    const menuItem = findSelectedMenuItem(selectedOption)
    if (!menuItem) {
      setMenuOptionSelected(null)
      return
    }
    menuItem.key = key
    setMenuOptionSelected(menuItem)
  }

  const menuItems = (
    <Menu
      selectedItem={menuOptionSelected}
      id="ant-header-navbar-menu"
      mode="horizontal"
      onClick={handleMenuOptionSelect}
      options={sortedNavigationOptions}
    />
  )

  const avatarDropdown = (
    <Menu id="ant-header-navbar-avatar-menu" options={headerAvatarOptions} />
  )

  const avatarWithBadge = (
    <Badge dot className="notification-dot">
      {practiceLogo}
    </Badge>
  )

  // if using a keyboard dependent-setup, might have issues getting the settings
  // to toggle on (ie, hard to find when sifting through components using tab)
  // might want to revisit later
  const avatarArea = (
    <Dropdown overlay={avatarDropdown}>
      <div
        id="avatar-dropdown"
        data-testid="avatar-dropdown"
        aria-label="Avatar and settings menu"
        className="provider-avatar"
        role="tab"
      >
        {hasNotification ? avatarWithBadge : practiceLogo}
      </div>
    </Dropdown>
  )

  const navigationBar = (
    <Header id="ant-header-navbar">
      <LogoutConfirmationModal />
      <Link to="/">
        <OsmindLogo className="header-logo" />
      </Link>
      {menuItems}
      {avatarArea}
    </Header>
  )

  return navigationBar
}
