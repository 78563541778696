import generatePicker, {
  PickerProps,
  RangePickerProps,
} from 'antd/lib/date-picker/generatePicker'
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'

const { TimePicker, RangePicker } = generatePicker<Date>(dateFnsGenerateConfig)

export default {
  /* Basic picker with a singe time field */
  Basic: (props: PickerProps<Date>) => (
    <TimePicker
      {...props}
      getPopupContainer={(trigger) => trigger?.parentElement || document.body}
    />
  ),
  /* Range picker with separate "start" and "end" fields */
  Range: (props: RangePickerProps<Date>) => (
    <RangePicker {...props} picker="time" />
  ),
}
