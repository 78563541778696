import React from 'react'

import { ArrowLeftOutlined, DownOutlined } from '@ant-design/icons'
import { format } from 'date-fns'

import { calculateClaimAndPatientOutstanding } from '../../../../libs/billing'
import { Button, Card, Dropdown } from '../../../../stories/BaseComponents'
import { ClaimData, ClaimFormStatus, ClaimStatus } from '../types'
import {
  getClaimStatusDisplay,
  isClaimCancellable,
  isClaimPresubmit,
  isFormBusy,
  isFormSaving,
} from '../utils'

import styles from './ClaimHeader.module.scss'

export const MISSING_VALUE = '—'

export const formatDate = (dateString: string) =>
  format(new Date(dateString), 'MM/dd/yyyy')

export const getSubmittedDateDisplay = (
  claimStatus: ClaimStatus,
  submittedDate?: string | null
) => {
  if (isClaimPresubmit(claimStatus)) {
    return MISSING_VALUE
  } else {
    return submittedDate ? formatDate(submittedDate) : 'Unknown'
  }
}

export const testIds = {
  container: 'claim-header',
  backButton: 'claim-header-back-button',
  deleteButton: 'claim-header-delete-button',
  saveButton: 'claim-header-save-button',
  editClaimButton: 'claim-header-edit-claim-button',
  editPaymentsButton: 'claim-header-edit-claim-payments-button',
  cancelClaimButton: 'claim-header-cancel-claim-button',
  claimTypeValue: 'claim-header-claim-type',
  pcnValue: 'claim-header-patient-control-number',
  createdDateValue: 'claim-header-created-date',
  submittedDateValue: 'claim-header-submitted-date',
  claimStatusValue: 'claim-header-claim-status',
}
type ClaimHeaderProps = {
  claim: ClaimData | undefined
  onGoBack: () => void
  onDeleteClaim: () => void
  onSaveClaim: () => void
  onCancelClaim: () => void
  onEditPayments: () => void
  formStatus: ClaimFormStatus
}

const ClaimHeader: React.FC<ClaimHeaderProps> = ({
  claim,
  onGoBack,
  onDeleteClaim,
  onSaveClaim,
  onCancelClaim,
  onEditPayments,
  formStatus,
}) => {
  const claimStatus = claim?.claimStatus || ClaimStatus.DRAFT
  const title = claim?.unsavedDraft
    ? 'Create new insurance claim'
    : 'Insurance claim'
  const isDisabled = isFormBusy(formStatus)
  let claimOutstanding = 0
  if (claim) {
    ;({ claimOutstanding } = calculateClaimAndPatientOutstanding(claim))
  }

  const getEditClaimOptions = () => {
    const options = [
      {
        text: 'Edit payment values',
        key: '1',
        onClick: onEditPayments,
        testId: testIds.editPaymentsButton,
        disabled: isDisabled,
      },
    ]
    if (claim?.payerControlNumber && isClaimCancellable(claimStatus)) {
      options.push({
        text: 'Cancel/void claim',
        key: '2',
        onClick: onCancelClaim,
        testId: testIds.cancelClaimButton,
        disabled: isDisabled,
      })
    }
    return options
  }

  return (
    <Card testId={testIds.container} bodyStyle={{ paddingTop: 12 }}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <Button
            testId={testIds.backButton}
            shape="circle"
            type="text"
            className={styles.backBtn}
            icon={<ArrowLeftOutlined className={styles.backIcon} />}
            onClick={onGoBack}
            disabled={isDisabled}
          />
          <span className={styles.title}>{title}</span>
        </div>
        <div className={styles.headerRight}>
          {isClaimPresubmit(claimStatus) ? (
            <>
              <Button
                testId={testIds.deleteButton}
                onClick={onDeleteClaim}
                disabled={isDisabled}
              >
                Delete claim draft
              </Button>
              <Button
                testId={testIds.saveButton}
                onClick={onSaveClaim}
                loading={isFormSaving(formStatus)}
                disabled={isDisabled}
              >
                Save as draft
              </Button>
            </>
          ) : (
            <Dropdown
              placement="bottomRight"
              trigger={['click']}
              items={getEditClaimOptions()}
            >
              <Button testId={testIds.editClaimButton}>
                Edit claim
                <DownOutlined
                  style={{ fontSize: 14, verticalAlign: 'middle' }}
                />
              </Button>
            </Dropdown>
          )}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.contentField}>
          <div className={styles.fieldLabel}>Type</div>
          <div
            className={styles.fieldValue}
            data-testid={testIds.claimTypeValue}
          >
            {'Primary'}
          </div>
        </div>

        <div className={styles.contentField}>
          <div className={styles.fieldLabel}>Patient control number</div>
          <div className={styles.fieldValue} data-testid={testIds.pcnValue}>
            {claim?.patientControlNumber || MISSING_VALUE}
          </div>
        </div>

        <div className={styles.contentField}>
          <div className={styles.fieldLabel}>Created date</div>
          <div
            className={styles.fieldValue}
            data-testid={testIds.createdDateValue}
          >
            {claim?.createdAt ? formatDate(claim.createdAt) : MISSING_VALUE}
          </div>
        </div>

        <div className={styles.contentField}>
          <div className={styles.fieldLabel}>Submitted date</div>
          <div
            className={styles.fieldValue}
            data-testid={testIds.submittedDateValue}
          >
            {getSubmittedDateDisplay(
              claimStatus,
              claim?.submittedInfo?.submittedDate
            )}
          </div>
        </div>

        <div className={styles.contentField}>
          <div className={styles.fieldLabel}>Status</div>
          <div
            className={styles.fieldValue}
            data-testid={testIds.claimStatusValue}
          >
            {getClaimStatusDisplay(claimStatus, claimOutstanding)}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default ClaimHeader
