import * as React from 'react'

import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { getUnixTime } from 'date-fns'
import { useHistory } from 'react-router-dom'

import {
  calculateClaimAndPatientOutstanding,
  convertCentsToDollars,
  formatNumberStringToUsdString,
} from '../../../libs/billing'
import {
  CLAIM_STATUS,
  CLAIM_STATUS_DISPLAY,
  CLAIM_TABLE_FILTERS,
  InsuranceClaim,
} from '../../../shared-types'
import { isClaimNotSubmitted } from '../Claims/InsuranceClaim-helper'

import styles from './ClaimsTable.module.scss'

interface ClaimsTableProps {
  claims: Array<InsuranceClaim>
  patientId: string
  providerId: string
}

type ClaimsRow = {
  claimId: string
  patientControlNumber: string
  claimStatus: string
  status: string | React.ReactElement
  submittedAt: string
  totalAdjustmentAmountCents: number
  totalAmountCents: number
  amountCentsDue: number
  patientPaidAmountCents: number
  totalPatientOutstanding: number
  totalClaimOutstanding: number
}

// how to type em-dash on MacOS: shift + option + -
export const MISSING_VALUE = '—'
const NON_DATE_SUBMITTED_AT_VALUES = [MISSING_VALUE, 'Unknown']

const renderPendingIfNaN = (amount: number | string) => {
  if (typeof amount === 'string' && amount === '$NaN') {
    return MISSING_VALUE
  }
  return amount
}

const toUnixTime = (input: string) => {
  switch (input) {
    case MISSING_VALUE:
      return getUnixTime(new Date(0))
    case 'Unknown':
      return getUnixTime(new Date(1))
    default:
      return getUnixTime(new Date(input))
  }
}

const getSubmittedAt = (timestamp: string) => {
  const createdAt = new Date(timestamp)
  return (
    <span>
      {createdAt.getMonth() + 1}/{createdAt.getDate()}/{createdAt.getFullYear()}
    </span>
  )
}

export function formatClaimStatus(status: CLAIM_STATUS): CLAIM_STATUS_DISPLAY {
  return CLAIM_STATUS_DISPLAY[status] || CLAIM_STATUS_DISPLAY.UNKNOWN
}

export const getClaimStatusContent = (
  claim: InsuranceClaim,
  claimsOutstanding?: number
): CLAIM_STATUS_DISPLAY => {
  if (!claim.claimStatus) {
    return CLAIM_STATUS_DISPLAY.UNKNOWN
  }
  if (
    (claim.claimStatus === CLAIM_STATUS.ADJUDICATED ||
      claim.claimStatus === CLAIM_STATUS.MANUALLY_ADJUDICATED) &&
    claimsOutstanding === 0
  ) {
    return CLAIM_STATUS_DISPLAY.SETTLED
  }
  if (claim.claimStatus === CLAIM_STATUS.SUBMITTED) {
    return CLAIM_STATUS_DISPLAY.PENDING
  }
  if (claim.claimStatus === CLAIM_STATUS.MANUALLY_SUBMITTED) {
    return CLAIM_STATUS_DISPLAY.MANUALLY_SUBMITTED
  }

  return formatClaimStatus(claim.claimStatus)
}

export default function ClaimsTable({
  claims,
  patientId,
  providerId,
}: ClaimsTableProps) {
  const history = useHistory()

  function navigateToClaim(
    claimId: string,
    patientId: string,
    providerId: string
  ) {
    const claimRoute = `/patient/billing/claim-create?patientId=${patientId}&providerId=${providerId}&claimId=${claimId}`
    history.push(claimRoute)
  }

  function getClaimStatusColorStyle(status: CLAIM_STATUS_DISPLAY) {
    switch (status) {
      case CLAIM_STATUS_DISPLAY.MANUALLY_SUBMITTED:
      case CLAIM_STATUS_DISPLAY.PENDING:
      case CLAIM_STATUS_DISPLAY.ADJUDICATED: {
        return styles.statusDotBlue
      }
      case CLAIM_STATUS_DISPLAY.SETTLED: {
        return styles.statusDotGreen
      }
      case CLAIM_STATUS_DISPLAY.CHANGE_ERROR: {
        return styles.statusDotRed
      }
      case CLAIM_STATUS_DISPLAY.DRAFT:
      case CLAIM_STATUS_DISPLAY.CANCELED:
      case CLAIM_STATUS_DISPLAY.UNKNOWN: {
        return styles.statusDotGray
      }
    }
  }

  function cleanMoneyRow(status?: CLAIM_STATUS, amount?: number | null) {
    const claimStatus = status ?? CLAIM_STATUS.DRAFT
    if (isClaimNotSubmitted(claimStatus)) {
      return MISSING_VALUE
    } else {
      return amount
    }
  }

  const columns: ColumnsType<ClaimsRow> = [
    {
      title: 'Patient Control #',
      dataIndex: 'patientControlNumber',
      key: 'patientControlNumber',
      width: 152,
      render: (patientControlNumber, row) => {
        return (
          <a
            onClick={() => navigateToClaim(row.claimId, patientId, providerId)}
            style={{ color: '#1890ff' }}
          >
            {patientControlNumber}
          </a>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 156,
      onFilter: (value, record) => {
        // @ts-ignore
        return record.status === value
      },
      filters: Object.keys(CLAIM_TABLE_FILTERS).map((key) => {
        return {
          // @ts-ignore
          value: CLAIM_TABLE_FILTERS[key],
          // @ts-ignore
          text: CLAIM_TABLE_FILTERS[key],
        }
      }),
      render: (status: CLAIM_STATUS_DISPLAY) => {
        return (
          <div
            className={styles.statusContainer}
            data-testid="claim-status-cell"
          >
            <div className={getClaimStatusColorStyle(status)} />
            {status}
          </div>
        )
      },
    },
    {
      title: 'Submitted date',
      dataIndex: 'submittedAt',
      key: 'submittedAt',
      width: 156,
      sorter: (a: ClaimsRow, b: ClaimsRow) =>
        toUnixTime(a.submittedAt) - toUnixTime(b.submittedAt),
      sortDirections: ['ascend', 'descend'],
      defaultSortOrder: 'descend',
      render: (submittedDate: string) => {
        if (NON_DATE_SUBMITTED_AT_VALUES.includes(submittedDate)) {
          return <span>{submittedDate}</span>
        }
        return <span>{getSubmittedAt(submittedDate)}</span>
      },
    },
    {
      title: 'Billed',
      dataIndex: 'totalAmountCents',
      key: 'billedAmount',
      sorter: (a: ClaimsRow, b: ClaimsRow) => {
        return (
          (typeof a.totalAmountCents === 'number'
            ? a.totalAmountCents
              ? a.totalAmountCents
              : 0.01
            : 0) -
          (typeof b.totalAmountCents === 'number'
            ? b.totalAmountCents
              ? b.totalAmountCents
              : 0.01
            : 0)
        )
      },
      render: (totalAmountCents: number | string) => {
        if (typeof totalAmountCents === 'string') {
          return (
            <span data-testid="billed-amount-cell">{totalAmountCents}</span>
          )
        }
        return (
          <span data-testid="billed-amount-cell">
            {renderPendingIfNaN(
              formatNumberStringToUsdString(
                convertCentsToDollars(totalAmountCents as number)
              )
            )}
          </span>
        )
      },
      width: 168,
    },
    {
      title: 'Patient outstanding',
      dataIndex: 'totalPatientOutstanding',
      key: 'totalPatientOutstanding',
      sorter: (a: ClaimsRow, b: ClaimsRow) => {
        return (
          (typeof a.totalPatientOutstanding === 'number'
            ? a.totalPatientOutstanding
              ? a.totalPatientOutstanding
              : 0.01
            : 0) -
          (typeof b.totalPatientOutstanding === 'number'
            ? b.totalPatientOutstanding
              ? b.totalPatientOutstanding
              : 0.01
            : 0)
        )
      },
      render: (totalPatientOutstanding: number | string, row) => {
        if (totalPatientOutstanding === 0 && row.patientPaidAmountCents === 0) {
          return <span data-testid="patient-outstanding-cell">Pending</span>
        }
        if (typeof totalPatientOutstanding === 'string') {
          return (
            <span data-testid="patient-outstanding-cell">
              {totalPatientOutstanding}
            </span>
          )
        }
        return (
          <span data-testid="patient-outstanding-cell">
            {renderPendingIfNaN(
              formatNumberStringToUsdString(
                convertCentsToDollars(totalPatientOutstanding)
              )
            )}
          </span>
        )
      },
      width: 168,
    },
    {
      title: 'Claim outstanding',
      dataIndex: 'totalClaimOutstanding',
      key: 'totalClaimOutstanding',
      sorter: (a: ClaimsRow, b: ClaimsRow) => {
        return (
          (typeof a.totalClaimOutstanding === 'number'
            ? a.totalClaimOutstanding
              ? a.totalClaimOutstanding
              : 0.01
            : 0) -
          (typeof b.totalClaimOutstanding === 'number'
            ? b.totalClaimOutstanding
              ? b.totalClaimOutstanding
              : 0.01
            : 0)
        )
      },
      render: (totalClaimOutstanding: number | string) => {
        if (typeof totalClaimOutstanding === 'string') {
          return (
            <span data-testid="claim-outstanding-cell">
              {totalClaimOutstanding}
            </span>
          )
        }

        const totalClaimOutstandingDollars = formatNumberStringToUsdString(
          convertCentsToDollars(totalClaimOutstanding)
        )
        return (
          <span data-testid="claim-outstanding-cell">
            {renderPendingIfNaN(totalClaimOutstandingDollars)}
          </span>
        )
      },
      width: 168,
    },
  ]

  const formatClaimsAsDataSource = () => {
    const claimDataSource: any = []
    claims.forEach((claimRow: InsuranceClaim, idx: number) => {
      const status = claimRow.claimStatus
      const { patientOutstanding, claimOutstanding } =
        calculateClaimAndPatientOutstanding(claimRow)
      const isMissingBilledAmount =
        claimRow.billableEntity.totalAmountCents === null
      const claimData = {
        key: idx.toString(),
        status: getClaimStatusContent(
          claimRow,
          isMissingBilledAmount ? undefined : claimOutstanding
        ),
        claimStatus: claimRow.claimStatus,
        claimId: claimRow.uuid,
        patientPaidAmountCents: claimRow.patientPaidAmountCents,
        totalClaimOutstanding: isMissingBilledAmount
          ? MISSING_VALUE
          : cleanMoneyRow(status, claimOutstanding),
        totalPatientOutstanding: cleanMoneyRow(status, patientOutstanding),
        patientControlNumber: claimRow.patientControlNumber,
        totalAmountCents: isMissingBilledAmount
          ? MISSING_VALUE
          : cleanMoneyRow(status, claimRow.billableEntity.totalAmountCents),
        totalAdjustmentAmountCents: cleanMoneyRow(
          status,
          claimRow.billableEntity.totalAdjustmentAmountCents
        ),
        submittedAt: isClaimNotSubmitted(
          claimRow.claimStatus ?? CLAIM_STATUS.DRAFT
        )
          ? MISSING_VALUE
          : claimRow.submittedAt ?? 'Unknown',
      }
      claimDataSource.push(claimData)
    })

    const submittedClaims = claimDataSource
      .filter((c: any) => !NON_DATE_SUBMITTED_AT_VALUES.includes(c.submittedAt))
      .sort(
        (objA: any, objB: any) =>
          Number(new Date(objB.submittedAt)) -
          Number(new Date(objA.submittedAt))
      )
    const nonSubmittedClaims = claimDataSource.filter(
      (c: any) => c.submittedAt === MISSING_VALUE
    )
    const untrackedSubmittedClaim = claimDataSource.filter(
      (c: any) => c.submittedAt === 'Unknown'
    )
    return submittedClaims.concat(untrackedSubmittedClaim, nonSubmittedClaims)
  }

  return (
    <>
      <Table
        size="middle"
        scroll={{ x: 1050 }}
        columns={columns}
        dataSource={formatClaimsAsDataSource()}
        className="claims-table"
      />
      <a
        href="https://connectcenter.changehealthcare.com/account/login.fcc"
        target="_blank"
        rel="noreferrer"
      >
        Open Change ConnectCenter
      </a>
    </>
  )
}
