import React, { JSX } from 'react'

import { NodeViewRendererProps, NodeViewWrapper } from '@tiptap/react'
import cx from 'classnames'

import { notification } from '../../../../../libs/notificationLib'
import { useFetchNoteBlock } from '../../../hooks/blocks/useFetchNoteBlock'
import { useUpdateNoteBlock } from '../../../hooks/blocks/useUpdateNoteBlock'
import { Note } from '../../../types'
import {
  DYNAMIC_ALLERGIES_BLOCK,
  DYNAMIC_DIAGNOSIS_BLOCK,
  DYNAMIC_MEDICATIONS_BLOCK,
  DynamicNoteBlock,
  UpdateDynamicBlockOptions,
} from '../Core/Block/DynamicBlockTypes'
import { DragHandle } from '../Core/DragHandle/DragHandle'
import { AllergiesBlock } from './types/allergies/AllergiesBlock'
import { DiagnosesBlock } from './types/diagnoses/DiagnosesBlock'
import { MedicationsBlock } from './types/medications/MedicationsBlock'

import styles from '../Core/Block/Block.module.scss'

/**
 * Returns a dynamic block component based on the block type.
 *
 * @param {object} args - The arguments.
 * @param {DynamicNoteBlock} args.block - The dynamic block.
 * @param {Note} args.note - The note.
 * @param {(opts: UpdateDynamicBlockOptions) => void} args.updateNoteBlock - The function to update the dynamic block.
 * @returns {JSX.Element | null} - The dynamic block component.
 */
export const getDynamicBlockData = (args: {
  block?: DynamicNoteBlock
  note: Note
  updateNoteBlock: (opts: UpdateDynamicBlockOptions) => void
}): JSX.Element | null => {
  const { block, note, updateNoteBlock } = args
  if (!block) {
    return null
  }

  switch (block.blockType) {
    case DYNAMIC_MEDICATIONS_BLOCK:
      return (
        <MedicationsBlock
          block={block}
          note={note}
          onBlockUpdate={updateNoteBlock}
        />
      )
    case DYNAMIC_ALLERGIES_BLOCK:
      return (
        <AllergiesBlock
          block={block}
          note={note}
          onBlockUpdate={updateNoteBlock}
        />
      )
    case DYNAMIC_DIAGNOSIS_BLOCK:
      return (
        <DiagnosesBlock
          block={block}
          note={note}
          onBlockUpdate={updateNoteBlock}
        />
      )
    default:
      return null
  }
}

export const DynamicBlockRouter = ({
  props,
  note,
}: {
  props: NodeViewRendererProps
  note: Note
}) => {
  const { id: blockUuid } = props.node.attrs

  const {
    data: block,
    isLoading,
    isError,
  } = useFetchNoteBlock(note.uuid, blockUuid)

  const { updateNoteBlock } = useUpdateNoteBlock({
    onSuccess: (block) => {
      notification(
        `Active ${
          block.blockType.charAt(0).toUpperCase() +
          block.blockType.slice(1).toLowerCase()
        } table has been refreshed`,
        'success'
      )
    },
  })

  if (isError) {
    return (
      <NodeViewWrapper className="structured-block">
        Unable to load block, please contact Osmind Support
      </NodeViewWrapper>
    )
  }

  if ((isLoading && !isError) || !block) {
    return (
      <NodeViewWrapper className="structured-block">Loading...</NodeViewWrapper>
    )
  }

  const content = getDynamicBlockData({
    block,
    note,
    updateNoteBlock,
  })

  const isSigned = !!note?.firstSignedAt

  return (
    <NodeViewWrapper
      className={!isSigned ? 'structured-block' : 'signed-structured-block'}
      key={block.uuid}
    >
      {!isSigned && <DragHandle />}
      <div className={cx(styles.container)}>{content}</div>
    </NodeViewWrapper>
  )
}
