import { ChangeEvent, useState } from 'react'

import { RadioChangeEvent } from 'antd'
import moment, { Moment } from 'moment'

import { notification } from '../../../libs/notificationLib'
import { Button, Modal, Radio, TextArea } from '../../../stories/BaseComponents'
import DayPicker from '../../../stories/BaseComponents/Daypicker'
import { IcdInput } from '../IcdInput'

import styles from './DiagnosisModal.module.scss'

type AddDiagnosis = {
  isVisible: boolean
  title: string
  initialIcdCode?: never
  initialDiagnosisDate?: never
  initialOnsetDate?: never
  initialComments?: never
  initialDiagnosisStatus?: never
  onCancel: () => void
  onAddDiagnosis: (diagnosis: {
    icdCode: string
    icdDescription: string
    diagnosisDate: string
    onsetDate: string
    comments: string
    diagnosisStatus: 'Active' | 'Inactive' | 'Resolved'
  }) => Promise<void>
  onSaveDiagnosis?: never
  onDeleteDiagnosis?: never
}

type EditDiagnosis = {
  isVisible: boolean
  title: string
  initialIcdCode: {
    code: string
    description: string
  }
  initialDiagnosisDate: string
  initialOnsetDate?: string
  initialComments?: string
  initialDiagnosisStatus: 'Active' | 'Inactive' | 'Resolved'
  onCancel: () => void
  onAddDiagnosis?: never
  onSaveDiagnosis: (diagnosis: {
    icdCode: string
    icdDescription: string
    diagnosisDate: string
    onsetDate: string
    comments: string
    diagnosisStatus: 'Active' | 'Inactive' | 'Resolved'
  }) => Promise<void>
  onDeleteDiagnosis: () => Promise<void>
}

export type DiagnosisModalProps = AddDiagnosis | EditDiagnosis

export const DiagnosisModal = ({
  isVisible,
  title,
  onCancel,
  onAddDiagnosis,
  onDeleteDiagnosis,
  onSaveDiagnosis,
  initialIcdCode,
  initialDiagnosisDate,
  initialOnsetDate,
  initialComments,
  initialDiagnosisStatus,
}: DiagnosisModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [icdCode, setIcdCode] = useState<{
    code: string
    description: string
  } | null>(initialIcdCode || null)
  const [diagnosisDate, setDiagnosisDate] = useState<Moment | null>(
    initialDiagnosisDate
      ? moment(new Date(initialDiagnosisDate))
      : moment(new Date())
  )
  const [onsetDate, setOnsetDate] = useState<Moment | null>(
    initialOnsetDate ? moment(new Date(initialOnsetDate)) : null
  )
  const [comments, setComments] = useState<string>(initialComments || '')
  const [diagnosisStatus, setDiagnosisStatus] = useState<
    'Active' | 'Inactive' | 'Resolved'
  >(initialDiagnosisStatus || 'Active')

  const resetFields = () => {
    setIcdCode(initialIcdCode || null)
    setDiagnosisDate(
      initialDiagnosisDate
        ? moment(new Date(initialDiagnosisDate))
        : moment(new Date())
    )
    setOnsetDate(initialOnsetDate ? moment(new Date(initialOnsetDate)) : null)
    setComments(initialComments || '')
    setDiagnosisStatus(initialDiagnosisStatus || 'Active')
  }

  const handleCancelClick = () => {
    resetFields()
    onCancel()
  }

  const handleCommentsChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setComments(e.target.value)
  }

  const handleStatusChange = (e: RadioChangeEvent) => {
    setDiagnosisStatus(e.target.value)
  }

  const handleDiagnosisDateChange = (date: Moment | null) => {
    setDiagnosisDate(date)
  }

  const handleOnsetDateChange = (date: Moment | null) => {
    setOnsetDate(date)
  }

  const handleSelectIcdCode = (code: string, description: string) => {
    setIcdCode({
      code,
      description,
    })
  }

  const handleOkClick = async () => {
    // Type guard to ensure that the required fields are not empty,
    // however this is not testable as OK is disabled when the required fields are empty
    /* istanbul ignore next */
    if (!diagnosisDate || !icdCode || !onAddDiagnosis) {
      throw new Error(
        'Diagnosis date, icd code and onAddDiagnosis are required'
      )
    }
    setIsSubmitting(true)
    try {
      await onAddDiagnosis({
        icdCode: icdCode.code,
        icdDescription: icdCode.description,
        diagnosisDate: diagnosisDate.toISOString(),
        onsetDate: onsetDate?.toISOString() || '',
        comments,
        diagnosisStatus,
      })
      resetFields()
      notification('Diagnosis saved successfully', 'success')
    } catch (e) {
      console.error(e)
      notification('An error occurred while saving the diagnosis', 'error')
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleSaveClick = async () => {
    // Type guard to ensure that the required fields are not empty,
    // however this is not testable as OK is disabled when the required fields are empty
    /* istanbul ignore next */
    if (!diagnosisDate || !icdCode || !onSaveDiagnosis) {
      throw new Error(
        'Diagnosis date, icd code and onSaveDiagnosis are required'
      )
    }
    setIsSubmitting(true)
    try {
      await onSaveDiagnosis({
        icdCode: icdCode.code,
        icdDescription: icdCode.description,
        diagnosisDate: diagnosisDate.toISOString(),
        onsetDate: onsetDate?.toISOString() || '',
        comments,
        diagnosisStatus,
      })
      resetFields()
      notification('Diagnosis saved successfully', 'success')
    } catch (e) {
      console.error(e)
      notification('An error occurred while saving the diagnosis', 'error')
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleDeleteClick = async () => {
    setShowDeleteConfirmation(true)
  }

  const handleCancelDeleteClick = () => {
    setShowDeleteConfirmation(false)
  }

  const handleDeleteDiagnosisClick = async () => {
    if (!onDeleteDiagnosis) {
      throw new Error('onDeleteDiagnosis is required')
    }
    setIsSubmitting(true)
    try {
      await onDeleteDiagnosis()
      resetFields()
      notification('Diagnosis deleted successfully', 'success')
    } catch (e) {
      console.error(e)
      notification('An error occurred while deleting the diagnosis', 'error')
    } finally {
      setIsSubmitting(false)
      setShowDeleteConfirmation(false)
    }
  }

  const isOkDisabled = !icdCode || !diagnosisDate || isSubmitting
  return (
    <div>
      <Modal
        data-testid="patient-profile-delete-diagnosis-modal"
        visible={showDeleteConfirmation}
        okText="Delete"
        onCancel={handleCancelClick}
        closable={false}
        footer={null}
        style={{ maxWidth: '400px' }}
        maskClosable={false}
      >
        <div className={styles.deleteConfirmationContainer}>
          <p className={styles.deleteConfirmationTitle}>Delete diagnosis</p>
          <p className={styles.deleteConfirmationContent}>
            Are you sure you want to delete{' '}
            <span className={styles.bold}>
              {icdCode?.code} {icdCode?.description}
            </span>
            ? This action cannot be undone.
          </p>
          <div className={styles.deleteConfirmationButtonContainer}>
            <Button
              style={{ position: 'relative', float: 'left' }}
              onClick={handleCancelDeleteClick}
              disabled={isSubmitting}
              testId="delete-diagnosis-modal-cancel-button"
            >
              Cancel
            </Button>
            <Button
              testId="delete-diagnosis-modal-delete-button"
              type="primary"
              onClick={handleDeleteDiagnosisClick}
              disabled={isSubmitting}
              style={{ backgroundColor: '#FF4D4F', borderColor: '#FF4D4F' }}
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        data-testid="patient-profile-diagnosis-modal"
        title={title}
        visible={isVisible}
        okText="Save"
        onCancel={handleCancelClick}
        closable
        maskClosable={false}
        footer={[
          [
            <Button
              style={{ position: 'relative', float: 'left' }}
              onClick={handleCancelClick}
              disabled={isSubmitting}
              testId="diagnosis-modal-cancel-button"
            >
              Cancel
            </Button>,
            onDeleteDiagnosis && (
              <Button
                type="ghost"
                onClick={handleDeleteClick}
                disabled={isSubmitting}
                style={{ borderColor: '#FF4D4F', color: '#FF4D4F' }}
                testId="diagnosis-modal-delete-button"
              >
                Delete
              </Button>
            ),
            onSaveDiagnosis && (
              <Button
                type="primary"
                onClick={handleSaveClick}
                disabled={isOkDisabled}
                style={{ marginLeft: '8px' }}
                testId="diagnosis-modal-save-button"
              >
                Save
              </Button>
            ),
            onAddDiagnosis && (
              <Button
                type="primary"
                onClick={handleOkClick}
                disabled={isOkDisabled}
                testId="diagnosis-modal-ok-button"
              >
                Ok
              </Button>
            ),
          ],
        ]}
      >
        <div className={styles.container}>
          <div className={styles.subContainer}>
            <p className={styles.noMargin}>
              ICD-10 code and description{' '}
              <span className={styles.required}> * </span>
            </p>
            <IcdInput
              value={icdCode?.code}
              onSelectIcdCode={handleSelectIcdCode}
              type={onSaveDiagnosis ? 'Edit' : 'Add'}
            />
          </div>
          <div className={styles.diagnosisContainer}>
            <div className={styles.subContainer}>
              <p className={styles.noMargin}>
                Diagnosis date <span className={styles.required}> * </span>
              </p>
              <DayPicker
                testId="diagnosis-modal-diagnosis-date-date-picker"
                value={diagnosisDate}
                onChange={handleDiagnosisDateChange}
              />
            </div>
            <div className={styles.subContainer}>
              <p className={styles.noMargin}>Onset date</p>
              <DayPicker
                testId="diagnosis-modal-onset-date-date-picker"
                value={onsetDate}
                onChange={handleOnsetDateChange}
              />
            </div>
          </div>
          <div className={styles.subContainer}>
            <p className={styles.noMargin}>Comments</p>
            <TextArea
              value={comments}
              onChange={handleCommentsChange}
              placeholder="Add a comment"
              data-testid="diagnosis-modal-comments"
              rows={1}
            />
          </div>
          <div className={styles.subContainer}>
            <div className={styles.row}>
              <p className={styles.noMargin}>Select the status</p>
              <span className={styles.required}> * </span>
            </div>

            <Radio.Group value={diagnosisStatus} onChange={handleStatusChange}>
              <Radio value="Active">Active</Radio>
              <Radio value="Inactive">Inactive</Radio>
              <Radio value="Resolved">Resolved</Radio>
            </Radio.Group>
          </div>
        </div>
      </Modal>
    </div>
  )
}
