import React, { useCallback, useState } from 'react'

import { ExclamationCircleFilled } from '@ant-design/icons'
import moment from 'moment'
import { Col, Form, Spinner, Table } from 'react-bootstrap'

import { generateRemsPDF, saveNewSignatures } from '../../../../../api/api-lib'
import { onError } from '../../../../../libs/errorLib'
import { useFeatureFlags } from '../../../../../libs/featureFlags'
import { notification } from '../../../../../libs/notificationLib'
import Sentry from '../../../../../libs/sentry'
import { Modal } from '../../../../../stories/BaseComponents'
import AntDButton from '../../../../../stories/BaseComponents/Button'
import TextAreaInput from '../../../../../stories/BaseComponents/TextAreaInput'
import SignatureStroke from '../assets/SignatureStroke.svg'

import '../../NewClinicalNoteForm.scss'
import styles from './signature.module.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function NewSignature(props) {
  const { autosaveDebounceNotev1Seconds } = useFeatureFlags()
  const [toggleSignatureModal, setToggleSignatureModal] = useState(false)
  const [newSignature, setNewSignature] = useState({
    Notes: '',
    SignedBy: props.clinicInfo?.providerName,
    SignedByProviderId: props.clinicInfo?.loggedInProviderId,
  })
  const [isGeneratingREMS, setIsGeneratingREMS] = useState(false)
  const isInvalidSpravato =
    props.fields.NoteType === 'Spravato' &&
    !props.isValid &&
    !props.fields.Signatures?.length
  const isValidSpravato =
    props.fields.NoteType === 'Spravato' &&
    props.isValid &&
    !props.fields.Signatures?.length

  const [isSaving, setIsSaving] = useState(props.isSaving)

  // This method is only triggered when the clinical note is 'opened' and not in
  // edit mode. If this method is called when the note is in edit mode, it may result
  // in lost data
  async function handleNewSignature() {
    setIsSaving(true)
    if (newSignature.SignedBy === '') {
      notification('A Signature is required.', 'failure')
      return
    }
    try {
      if (!props.NoteId || !props.fields.Signatures?.length) {
        props.fields.NewSignature = newSignature
        // If autosave is enabled save new signature without updating note before
        if (autosaveDebounceNotev1Seconds) {
          const data = {
            Signatures: newSignature,
            PatientId: props.PatientId,
            NoteId: props.NoteId,
          }
          await saveNewSignatures(data)
        } else {
          await props.handleSave()
        }

        setToggleSignatureModal(false)
        props.setShowSignedAndValid(isValidSpravato)
        props.setShowSignedAndInvalid(isInvalidSpravato)

        props.updateCache?.(true)
        notification(
          `You have successfully Signed a Note for ${props.patient.PatientName}.`,
          'success'
        )

        if (!isValidSpravato) return // Skip next steps of generating the PDF and faxing

        // if the provider is signing a Spravato note for the first time, trigger the generate REMS PDF call
        const noteId = props.NoteId ?? undefined
        try {
          setIsGeneratingREMS(true)
          // get the local computers current time in same format that patient document upload times are saved
          const currentTime = new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          })
          const data = {
            NoteId: noteId,
            NoteContent: props.fields,
            Patient: props.patient,
            Time: currentTime,
            IsAutoFax: true,
          }
          await generateRemsPDF(data)
        } catch (err) {
          Sentry.captureException(
            new Error(`Error from faxing for note ${noteId}: ${err}`)
          )
        } finally {
          setIsGeneratingREMS(false)
        }

        return
      }

      const data = {
        Signatures: newSignature,
        PatientId: props.PatientId,
        NoteId: props.NoteId,
      }

      await saveNewSignatures(data)
      props.updateCache?.(true)
      setToggleSignatureModal(false)
      notification(
        `You have successfully Signed a Note for ${props.patient.PatientName}.`,
        'success'
      )
    } catch (e) {
      onError(
        e,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
      Sentry.captureException(e)
    } finally {
      props.handleClose()
    }
  }

  const handleSignatureCancel = useCallback(() => {
    /* Hacky legacy code. To be removed this when notes are no longer in modal */
    if (document.getElementsByClassName('modal')[0]) {
      document.getElementsByClassName('modal')[0].style.display = 'block'
    }
    setToggleSignatureModal(false)
  }, [])

  function getInfoBoxText() {
    if (isInvalidSpravato) {
      return `Signing the note will finalize it.
      All users will not be allowed to edit the note after signing.
      There are fields that are required for the Spravato® REMS Patient Monitoring form that are incomplete.
      If you sign this note with incomplete fields,
      the Spravato® REMS Patient Monitoring form will NOT be automatically submitted on your behalf.`
    } else if (isValidSpravato) {
      return `You are about to sign the note and cannot edit it afterwards. The Spravato® REMS Patient Monitoring form will be automatically submitted on your behalf.`
    }
    if (!props.fields.Signatures?.length) {
      return `Signing the note will finalize it.
      All users will not be allowed to edit the note after signing,
      but addendums can be added afterwards.
      Multiple users can sign each note.`
    } else {
      return `This note is finalized.`
    }
  }

  function renderSignature() {
    const signatureTable = (
      <>
        {props.fields.Signatures?.length !== 0 && (
          <Table
            data-testid="note-signatures-table"
            style={{ marginLeft: 0, marginRight: 0 }}
            className="stripe-color remove-padding"
            responsive
            borderless
            striped
          >
            <thead>
              <tr>
                <th style={{ width: 300 }}>Time</th>
                <th style={{ minWidth: 200 }}>Comments</th>
                <th style={{ width: 300 }}>Signee</th>
              </tr>
            </thead>
            <tbody>
              {props.fields.Signatures.map((signature, idx) => {
                const isArray = Array.isArray(signature)
                return (
                  <React.Fragment key={`signature-${idx}`}>
                    <tr
                      key={`signature-row-${idx}`}
                      data-testid={`note-signatures-table-row-${idx}`}
                    >
                      <td>
                        {moment(
                          isArray ? signature[0].CreatedAt : signature.CreatedAt
                        ).format('LLLL')}
                      </td>
                      <td>
                        {isArray
                          ? signature[0].Notes === ''
                            ? 'None'
                            : signature[0].Notes
                          : signature.Notes === ''
                          ? 'None'
                          : signature.Notes}
                      </td>
                      <td>
                        {isArray ? signature[0].SignedBy : signature.SignedBy}
                      </td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </Table>
        )}
      </>
    )

    const signInput = (
      <AntDButton
        variant="outline-primary"
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          marginBottom: '10px',
        }}
        title={
          !props.NoteId
            ? 'Please save the note before you sign'
            : 'Add signature'
        }
        disabled={props.isAddSignatureDisabled || !props.NoteId}
        onClick={() => {
          setToggleSignatureModal(true)
          /*
            This is hacky... but works!
            Used to hide the notes modal when the signature modal is up.
            Need to do this because the nested modal doesn't work with textarea
            causing the user to not be able to type in the comments box.
            Also, gives the user the impression that when they sign the note will be finalized.
            */
          if (document.getElementsByClassName('modal')[0]) {
            document.getElementsByClassName('modal')[0].style.display = 'none'
          }
        }}
      >
        + Add Signature
      </AntDButton>
    )

    return (
      <>
        {signatureTable}
        {!props.fields.Signatures?.length && <br />}
        <Form.Group
          as={Col}
          sm={12}
          md={10}
          lg={12}
          style={{
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          {signInput}
        </Form.Group>
      </>
    )
  }

  return (
    <div>
      <br />
      <div
        className="alert-clinical-notes"
        style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}
      >
        Signature
      </div>
      {renderSignature()}
      <Modal
        visible={toggleSignatureModal}
        zIndex={5000}
        centered={true}
        mask={true}
        title={'Sign note'}
        onCancel={handleSignatureCancel}
        onOk={handleNewSignature}
        width={600}
        okText={'Sign Note'}
        okButtonProps={{
          id: styles.signatureButton,
          disabled: isGeneratingREMS,
          icon: <img src={SignatureStroke} alt="Signature" />,
        }}
        isOkayDisabled={isSaving}
        height={356}
      >
        {isGeneratingREMS && (
          <Spinner
            animation="border"
            variant="danger"
            className="loader-button-spinner"
          />
        )}
        <div
          className={`${styles.infoBox} ${
            isInvalidSpravato && styles.yellowBackground
          }`}
        >
          <div id={styles.infoBoxContents}>
            <div
              className={`${styles.exclamationCircle} ${
                isInvalidSpravato && styles.yellowExclamationCircle
              }`}
            >
              <ExclamationCircleFilled />
            </div>
            <div id={styles.infoText}>{getInfoBoxText()}</div>
          </div>
        </div>
        <div id={styles.signeeSection}>
          Signee:
          <span id={styles.signeeText}>{props.clinicInfo.providerName}</span>
        </div>
        <div className={styles.signatureComments}>
          <div id={styles.commentText}>Comments</div>
          <TextAreaInput
            onChange={(e) => {
              setNewSignature({
                ...newSignature,
                Notes: e.target?.value,
              })
            }}
            allowClear={true}
          />
        </div>
      </Modal>
    </div>
  )
}
