import React, { LegacyRef, useMemo } from 'react'

import cx from 'classnames'
import { Button } from 'react-bootstrap'

import { NoteType } from '../../../../../hooks/useClinicalNoteFields/utils'
import { useClinicalNotesButtonActions } from '../../../../../hooks/useClinicalNotesButtonActions'
import { useFeatureFlags } from '../../../../../libs/featureFlags'
import { ClinicalNote, NoteTypes } from '../../../../../shared-types'
import { Tooltip } from '../../../../../stories/BaseComponents'
import DeleteClinicalNoteButtonModalTop from '../../DeleteClinicalNoteButtonModalTop'
import { DownloadAsPdfButton } from '../../DownloadAsPdfButton'

import '../../CreateClinicalNotePage.scss'

interface EditNoteHeaderV1Props {
  headerRef: LegacyRef<HTMLDivElement>
  clinicalNote: ClinicalNote | null
  collapsedHeader: boolean
  inViewMode: boolean
  isSaving: boolean
  noteRefetchLoading: boolean
  noteId?: string
  hasClaim: boolean
  hasSuperbill: boolean
  hasInvoice: boolean
  fields: NoteType
  patientId: string
  providerId: string
  isAllergiesBeingEdited: boolean
  isFormValid: boolean
  handleSaveNote: () => Promise<void>
  handleClose: () => void
  clickEditButton: () => Promise<void>
  handleCloseEdit: () => Promise<void>
  updateCacheWithNav: () => Promise<void>
  handleCopyToNewNote: () => void
}

export const EditNoteHeaderV1: React.FC<EditNoteHeaderV1Props> = ({
  headerRef,
  clinicalNote,
  collapsedHeader,
  inViewMode,
  isSaving,
  noteRefetchLoading,
  noteId = '',
  hasClaim,
  hasSuperbill,
  hasInvoice,
  fields,
  patientId,
  providerId,
  isAllergiesBeingEdited,
  isFormValid,
  handleSaveNote,
  clickEditButton,
  handleClose,
  handleCloseEdit,
  updateCacheWithNav,
  handleCopyToNewNote,
}) => {
  const { claims } = useFeatureFlags()
  const {
    navigateToInvoice,
    navigateToClaim,
    DeleteNoteModalComponent,
    handleShowDeleteModal,
  } = useClinicalNotesButtonActions(
    hasClaim,
    hasInvoice,
    patientId,
    providerId,
    noteId,
    fields,
    clinicalNote,
    updateCacheWithNav,
    handleClose
  )

  const superbillButton = useMemo(() => {
    return (
      <Button
        className="modal-action-button"
        disabled={noteRefetchLoading}
        id="superbillButton"
        onClick={() => {
          navigateToInvoice(!hasSuperbill)
        }}
      >
        {hasSuperbill ? 'View Superbill' : 'New Superbill'}
      </Button>
    )
  }, [fields.invoice, noteRefetchLoading])

  const invoiceButton = useMemo(() => {
    return (
      <Button
        disabled={noteRefetchLoading}
        className="modal-action-button"
        id="invoiceButton"
        onClick={() => navigateToInvoice()}
      >
        {hasInvoice ? 'View Invoice' : 'New Invoice'}
      </Button>
    )
  }, [fields.invoice, noteRefetchLoading])

  const claimButton = useMemo(() => {
    return (
      <Button
        disabled={noteRefetchLoading}
        className="modal-action-button"
        id="claimButton"
        onClick={() => navigateToClaim()}
      >
        {hasClaim ? 'View Claim' : 'New Claim'}
      </Button>
    )
  }, [fields.InsuranceClaim, noteRefetchLoading])

  return (
    <div className="note-page-header" ref={headerRef}>
      <div>
        <span className="note-title">
          {clinicalNote?.NoteType ?? NoteTypes.CLINICAL_NOTE}
        </span>
        &nbsp;Details
      </div>
      {/** this div/id (action-group) is required so we can target this section for pdf generation */}
      <div
        id="modal-action-group"
        className={cx([
          'edit-note-button-group',
          { topSpacing: collapsedHeader },
        ])}
      >
        {inViewMode ? (
          /** Buttons in view mode */
          <>
            <Button
              disabled={isSaving || noteRefetchLoading}
              onClick={handleCopyToNewNote}
              className="modal-save-button"
            >
              Copy to New Note
            </Button>
            <DownloadAsPdfButton
              disabled={noteRefetchLoading}
              noteId={noteId}
            />
            {invoiceButton}
            {superbillButton}
            {claims && claimButton}

            {!clinicalNote?.Signatures?.length && (
              <Tooltip
                placement="bottom"
                title="Please save or cancel edits to allergies before editing clinical note."
                visible={isAllergiesBeingEdited}
              >
                <Button
                  className={cx('modal-action-button', {
                    disabled: noteRefetchLoading || isAllergiesBeingEdited,
                  })}
                  onClick={clickEditButton}
                  disabled={noteRefetchLoading || isAllergiesBeingEdited}
                >
                  {noteRefetchLoading ? 'Loading...' : 'Edit Note'}
                </Button>
              </Tooltip>
            )}
            {!clinicalNote?.Signatures?.length ? (
              <DeleteClinicalNoteButtonModalTop
                isDisabled={noteRefetchLoading}
                modalComponent={DeleteNoteModalComponent}
                handleModalShow={handleShowDeleteModal}
                hasSuperbill={!!clinicalNote?.invoice?.superbill?.id}
              />
            ) : null}
          </>
        ) : (
          /** Buttons in edit mode */
          <>
            <Button
              onClick={handleSaveNote}
              className="modal-save-button"
              disabled={isSaving || !isFormValid}
            >
              Save Edit
            </Button>
            <Button className="modal-action-button" onClick={handleCloseEdit}>
              Cancel Edit
            </Button>
          </>
        )}
      </div>
    </div>
  )
}
