import cx from 'classnames'

import { Text } from '../../../../stories/BaseComponents'

import styles from './NoteButton.module.scss'

export const CreateNoteButtonV2 = () => {
  return (
    <div className={cx([styles.noteButtonV2])}>
      <Text style={{ paddingRight: '30px' }}>Custom note</Text>
      <Text className={styles.betaLogo}>BETA</Text>
    </div>
  )
}
