import { Button } from 'react-bootstrap'

import { useDownloadClinicalNotePDF } from '../../../hooks/useDownloadClinicalNotePDF'
import { Tooltip } from '../../../stories/BaseComponents'

export const DownloadAsPdfButton = ({
  disabled = false,
  noteId,
}: {
  disabled?: boolean
  noteId?: string
}) => {
  const { handlePrint, isDisabled, isPrinting } = useDownloadClinicalNotePDF(
    noteId,
    disabled
  )

  return (
    <Tooltip
      placement="bottom"
      title="All fields completed in the note will be included in the download."
    >
      <div
        style={{
          display: 'flex',
          cursor: isDisabled ? 'not-allowed' : 'pointer',
        }}
      >
        <Button
          disabled={isDisabled}
          id="print-button"
          onClick={handlePrint}
          className="modal-action-button"
        >
          {isPrinting ? 'Downloading...' : 'Download as PDF'}
        </Button>
      </div>
    </Tooltip>
  )
}
