import cx from 'classnames'
import { Button } from 'react-bootstrap'

type DeleteNoteButtonProps = {
  onClick?(): void
  isDisabled?: boolean
}

export const DeleteNoteButton = ({
  isDisabled,
  onClick,
}: DeleteNoteButtonProps) => {
  return (
    <Button
      data-testid="delete-note-button"
      className={cx('modal-delete-button', {
        disabled: isDisabled,
      })}
      onClick={onClick}
      disabled={isDisabled}
    >
      Delete Note
    </Button>
  )
}
