import React from 'react'

import Icon, { RedoOutlined } from '@ant-design/icons'
import { Button } from 'antd'

import { generateCoreIdDisplayName } from '../../../../../../../hooks/usePatientProfile/utils'
import { useProfileUrlParams } from '../../../../../../../hooks/useProfileUrlParams'
import { DiagnosisHistory } from '../../../../../../../shared-types'
import { Typography } from '../../../../../../../stories/BaseComponents'
import { ExternalLink } from '../../../../Icons/ExternalLink'
import { DynamicBlockRenderOptions } from '../../../Core/Block/DynamicBlockTypes'
import { DynamicBlockHeader } from '../shared/TableHeader'
import { DiagnosesBlockTable } from './DiagnosesBlockTable'

export const DiagnosesBlock = ({
  block,
  note,
  onBlockUpdate,
}: DynamicBlockRenderOptions) => {
  const { Link } = Typography
  const { urlParams } = useProfileUrlParams()

  const actionButtons = [
    <Link
      key={`${block.uuid}_refresh`}
      data-testid={`${block.uuid}_refresh`}
      onClick={() => {
        onBlockUpdate({
          blockConfig: {
            options: {
              limit: Date.now(),
            },
          },
          blockUuid: block.uuid,
          noteUuid: note.uuid,
        })
      }}
    >
      <RedoOutlined /> Refresh Diagnoses
    </Link>,
    <Button
      icon={<Icon component={() => <ExternalLink />} />}
      key={`${block.uuid}_add`}
      data-testid={`${block.uuid}_add`}
      onClick={() => {
        window.open(
          `/patient/profile?${urlParams}&patientProfile=diagnoses`,
          '_blank',
          'noreferrer'
        )
      }}
    >
      Add active diagnosis
    </Button>,
  ]

  return (
    <>
      <DynamicBlockHeader
        title={'Active diagnoses'}
        buttons={actionButtons}
        isSigned={!!note?.firstSignedAt}
      />
      <div>
        <DiagnosesBlockTable
          isSigned={!!note?.firstSignedAt}
          blockData={
            block.data ? (block.data as DiagnosisHistory[]) : undefined
          }
          patientName={
            note?.patient && generateCoreIdDisplayName(note?.patient)
          }
          isLoading={false}
        />
      </div>
    </>
  )
}
