import Address from './Address'
import AddressAutocomplete from './AddressAutocomplete'
import Alert from './Alert'
import AlertIndicator from './AlertIndicator'
import AutoFormatInput from './AutoFormatInput'
import Avatar from './Avatar'
import { Badge } from './Badge'
import Box from './Box'
import Button from './Button'
import CSVExportButton from './CSVExportButton'
import Card from './Card'
import Checkbox, { CheckboxGroup } from './Checkbox'
import Col from './Col'
import Collapse from './Collapse'
import CollapsePanel from './CollapsePanel'
import { CollapsibleCard } from './CollapsibleCard'
import Colorpicker from './Colorpicker'
import ConfirmOrExitInput from './ConfirmOrExitInput'
import DateWarningIcon from './DateWarningIcon'
import Datepicker from './Datepicker'
import Daypicker from './Daypicker'
import Divider from './Divider'
import Drawer from './Drawer'
import Dropdown from './Dropdown'
import DynamicSubForm from './DynamicSubForm'
import { EditableInput, USDInput } from './EditableInputs'
import FilterTags from './FilterTags'
import Form from './Form'
import FormContainer from './FormContainer'
import FormItem from './FormItem'
import Header from './Header'
import { HoverIcon } from './HoverIcon'
import { ImageUpload } from './ImageUpload'
import { InfoGrid } from './InfoGrid'
import InfoPage from './InfoPage'
import InlineEditDate from './InlineEditFields/InlineEditDate'
import InlineEditRadio from './InlineEditFields/InlineEditRadio'
import InlineEditSelect from './InlineEditFields/InlineEditSelect'
import InlineEditText from './InlineEditFields/InlineEditText'
import InlineEditTextarea from './InlineEditFields/InlineEditTextarea'
import InlineEditTime from './InlineEditFields/InlineEditTime'
import Input, { Password, TextArea } from './Input'
import InputNumber from './InputNumber'
import LegacyFormItem from './LegacyFormItem'

/**
 * LineGraph is expensive to import, because antd charts imports a large graphics library
 * Every time a base component is imported like import { Button } from '../../stories/BaseComponents'
 * This large graphics library is imported. To restrict this to be bundled only to pages with graphs,
 * exclude LineGraph from the BaseComponents index and require pages that use it to import by path:
 * import LineGraph from '../../stories/BaseComponents/LineGraph'
 */
// import LineGraph from './LineGraph'
import Link from './Link'
import Menu from './Menu'
import { Message } from './Message'
import Metrics from './Metrics'
import MiniCalendar from './MiniCalendar'
import Modal, { toggleConfirmModal } from './Modal'
import ModalBasic from './ModalBasic'
import MultiSelect from './MultiSelect'
import { generateNotification } from './Notification'
import Option, { OptGroup } from './Option'
import PageHeader from './PageHeader'
import Popconfirm from './Popconfirm'
import PopoverComponent from './Popover'
import Progress from './Progress'
import QRCode from './QRCode'
import QRCodeModal from './QRCodeModal'
import { Radio } from './Radio'
import { RequiredLabel } from './RequiredLabel'
import Row from './Row'
import SearchBox from './SearchBox'
import Select from './Select'
import SignaturePreview from './SignaturePreview'
import { Skeleton } from './Skeleton'
import Slider from './Slider'
import { Space } from './Space'
import DateInput from './SpecialInputFields/DateInput'
import EINInput from './SpecialInputFields/EINInput'
import NPIInput from './SpecialInputFields/NPIInput'
import PhoneNumberInput from './SpecialInputFields/PhoneNumberInput'
import Spinner from './Spinner'
import Steps from './Steps'
import { Switch } from './Switch'
import Table from './Table'
import TableAvatar from './TableAvatar'
import { Tag } from './Tag'
import Text from './Text'
import TextAreaInput from './TextAreaInput'
import TimePicker from './TimePicker'
import { TimeSelector } from './TimeSelector'
import Tooltip from './Tooltip'
import Typography from './Typography'
import { Upload } from './Upload'

const { Link: TypographyLink, Paragraph, Title } = Typography

export {
  Address,
  AddressAutocomplete,
  Alert,
  AlertIndicator,
  AutoFormatInput,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  CheckboxGroup,
  Col,
  Collapse,
  CollapsePanel,
  CollapsibleCard,
  Colorpicker,
  ConfirmOrExitInput,
  CSVExportButton,
  DateInput,
  Datepicker,
  Daypicker,
  DateWarningIcon,
  Divider,
  Drawer,
  Dropdown,
  DynamicSubForm,
  EditableInput,
  FilterTags,
  Form,
  FormContainer,
  FormItem,
  LegacyFormItem,
  EINInput,
  Header,
  HoverIcon,
  ImageUpload,
  InfoGrid,
  InfoPage,
  InlineEditDate,
  InlineEditTime,
  InlineEditRadio,
  InlineEditSelect,
  InlineEditText,
  InlineEditTextarea,
  Input,
  InputNumber,
  Link,
  Menu,
  Message,
  Metrics,
  MiniCalendar,
  Modal,
  ModalBasic,
  toggleConfirmModal,
  MultiSelect,
  NPIInput,
  generateNotification,
  Option,
  OptGroup,
  PageHeader,
  Paragraph,
  Password,
  PhoneNumberInput,
  PopoverComponent,
  Popconfirm,
  Progress,
  QRCode,
  QRCodeModal,
  Radio,
  RequiredLabel,
  Row,
  SearchBox,
  Select,
  Space,
  SignaturePreview,
  Skeleton,
  Slider,
  Spinner,
  Steps,
  Switch,
  Table,
  TableAvatar,
  Tag,
  Text,
  TextArea,
  TextAreaInput,
  TimePicker,
  TimeSelector,
  Title,
  Tooltip,
  TypographyLink,
  Typography,
  Upload,
  USDInput,
}
