import queryString from 'query-string'

import {
  useInvoices,
  useStripeAccountStatus,
  useTransactions,
} from '../../../hooks/useBillingInfo'
import { useGetClaimsByPatientId } from '../../../hooks/useInsuranceClaims'
import {
  AmexIcon,
  DefaultIcon,
  DiscoverIcon,
  MastercardIcon,
  VisaIcon,
} from '../../../images/Icons/PaymentMethodIcons'
import { getDisplayNameForPaymentMethodType } from '../../../libs/billing'
import { CARD_ISSUERS, PAYMENT_METHOD_TYPES } from '../../../libs/constants'
import { useFeatureFlags } from '../../../libs/featureFlags'
import { PaymentMethod } from '../../../shared-types'
import { Spinner } from '../../../stories/BaseComponents'
import { PatientHeader } from '../PatientHeader'
import BillingSummaryCards from './BillingSummaryCards'
import BillingTabs from './BillingTabs'
import ErrorView from './ErrorView'

import sharedStyles from '../../_shared.module.scss'
import styles from './Billing.module.scss'

export interface BillingProps {
  messageCounts: Map<string, unknown>
  providerId: string
  healthGorillaUserName: string
}

export const getPaymentMethodDisplayContent = (
  paymentMethod?: PaymentMethod
) => {
  // don't bork
  if (!paymentMethod) {
    return null
  }

  if (paymentMethod.type !== PAYMENT_METHOD_TYPES.STRIPE_CREDIT_CARD) {
    return <span>{getDisplayNameForPaymentMethodType(paymentMethod.type)}</span>
  }

  const { cardIssuer, lastFour, nickname } = paymentMethod
  let iconComponent
  switch (cardIssuer) {
    case CARD_ISSUERS.VISA:
      iconComponent = <VisaIcon height="17px" style={{ marginRight: '1em' }} />
      break
    case CARD_ISSUERS.MASTERCARD:
      iconComponent = (
        <MastercardIcon height="17px" style={{ marginRight: '1em' }} />
      )
      break
    case CARD_ISSUERS.AMEX:
      iconComponent = <AmexIcon height="17px" style={{ marginRight: '1em' }} />
      break
    case CARD_ISSUERS.DISCOVER:
      iconComponent = (
        <DiscoverIcon height="17px" style={{ marginRight: '1em' }} />
      )
      break
    default:
      iconComponent = (
        <DefaultIcon height="17px" style={{ marginRight: '1em' }} />
      )
      break
  }

  const nicknameContent = nickname ? (
    <span className={styles.nicknameContent}>({nickname})</span>
  ) : null

  return (
    <span>
      {iconComponent}****{lastFour}
      {nicknameContent}
    </span>
  )
}

export default function Billing({
  providerId,
  healthGorillaUserName,
}: BillingProps) {
  const { claims: claimFeatureFlag } = useFeatureFlags()
  const queryParams = queryString.parse(window.location.search)
  const patientId = queryParams.patientId?.toString()

  if (!patientId) {
    return <ErrorView />
  }

  const {
    isInitialLoading: invoiceDataIsLoading,
    isError: invoiceDataIsError,
    data: invoiceData,
    refetch: refetchInvoices,
  } = useInvoices(patientId)

  const {
    isInitialLoading: claimsDataIsLoading,
    isError: claimsDataIsError,
    data: claimData,
  } = useGetClaimsByPatientId(patientId, claimFeatureFlag)

  const {
    isLoading: transactionsIsLoading,
    isError: transactionsIsError,
    data: transactionData,
  } = useTransactions({ patientId })

  const {
    isLoading: stripeAccountStatusIsLoading,
    isError: stripeAccountStatusIsError,
    data: stripeAccountStatusData,
  } = useStripeAccountStatus()

  const isLoadError =
    invoiceDataIsError ||
    transactionsIsError ||
    claimsDataIsError ||
    stripeAccountStatusIsError

  const isLoading =
    invoiceDataIsLoading ||
    claimsDataIsLoading ||
    transactionsIsLoading ||
    stripeAccountStatusIsLoading

  return (
    <div className={sharedStyles.scroll}>
      <PatientHeader
        providerId={providerId}
        patientId={patientId.toString()}
        healthGorillaUserName={healthGorillaUserName}
      />
      {isLoading ? (
        <Spinner />
      ) : isLoadError ? (
        <ErrorView />
      ) : (
        <div className="container">
          <BillingSummaryCards
            invoices={invoiceData ?? []}
            payments={transactionData?.payments ?? []}
            refunds={transactionData?.refunds ?? []}
          />

          <BillingTabs
            invoices={invoiceData || []}
            refetchInvoices={refetchInvoices}
            claims={claimData || []}
            payments={transactionData?.payments ?? []}
            refunds={transactionData?.refunds ?? []}
            patientId={patientId}
            providerId={providerId}
            stripeAccountStatus={stripeAccountStatusData.status}
          />
        </div>
      )}
    </div>
  )
}
