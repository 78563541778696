import React, { useEffect, useState } from 'react'

import moment from 'moment'
import 'moment-timezone'

import {
  FormSectionKey,
  IntakeForm,
  IntakeFormResponseObject,
} from '../../api/intakeForms'
import { normalizedAndFormatDate } from '../../hooks/usePatientProfile/utils'
import { Table } from '../BaseComponents'
import { parseResponsesForFormReview } from './parse-intake-form'
import {
  INTAKE_REVIEW_FORM,
  INTAKE_REVIEW_FORM_ITEM_TYPE,
} from './sections/helpers'

import './ReviewPatientIntake.scss'

interface ReviewProps {
  completedForm: IntakeForm | null
  id?: string
  isPrint?: boolean
}
type Primitives = string | number | boolean
type ArrayVariations = (string | number | boolean | NestedObject)[]
type NestedObject = Record<string, Primitives | ArrayVariations>
type RenderObjectValue =
  | Primitives
  | ArrayVariations
  | Record<string, Primitives | ArrayVariations | NestedObject>

function renderNewTable(value: RenderObjectValue, isPrint = false) {
  const labelColumns = (isPrint = false) => {
    return [
      {
        dataIndex: 'label',
        key: 'label',
        width: isPrint ? '24%' : '40%',
        render: (_text: any, { label }: { label: string }) => {
          const found = INTAKE_REVIEW_FORM.find((value) => value.name === label)
          return <b>{found?.title ?? label}</b>
        },
      },
      {
        dataIndex: 'value',
        key: 'value',
        render: (_text: any, value: any) => {
          if (typeof value.value === 'object' && value.value != null) {
            return renderNewTable(value.value, isPrint)
          } else if (
            isPrint &&
            Array.isArray(value) &&
            typeof value?.[0].value === 'string'
          ) {
            return <>{value.toString()}&nbsp;</>
          } else if (
            isNaN(value.value) &&
            moment(value.value, true).isValid()
          ) {
            const temp = new Date(value.value)
            return temp.toDateString()
          } else if (typeof value.value === 'boolean') {
            return <>{value.value === true ? 'Yes' : 'No'}&nbsp;</>
          }
          return <>{value.value ?? ''}</>
        },
      },
    ]
  }

  const noLabelColumns = (isPrint = false) => {
    return [
      {
        dataIndex: 'value',
        key: 'value',
        render: (_text: any, value: any) => {
          if (
            typeof value.value === 'object' &&
            !Array.isArray(value) &&
            value.value != null
          ) {
            return renderNewTable(value.value, isPrint)
          } else if (value.type === 'date') {
            return moment(new Date(value.value)).format('MMMM d, YYYY')
          } else if (typeof value.value === 'boolean') {
            return <>{value.value === true ? 'Yes' : 'No'}&nbsp;</>
          }
          return <>{value.value ?? value ?? ''}</>
        },
      },
    ]
  }

  const hasLabelColumns =
    //@ts-ignore
    value[0]?.label === '' || value[0]?.label === undefined
      ? noLabelColumns(isPrint)
      : labelColumns(isPrint)

  return (
    <Table
      locale={{ emptyText: ' ' }}
      showHeader={false}
      className="section-table"
      columns={hasLabelColumns}
      //@ts-ignore
      dataSource={value}
      pagination={false}
    />
  )
}

function intakeColumns(isPrint = false) {
  return [
    {
      dataIndex: 'label',
      key: ['label', 'type'],
      width: isPrint ? '28%' : '45%',
      render: (
        _text: any,
        { type, label }: { type: string; label: string }
      ) => {
        if (type === INTAKE_REVIEW_FORM_ITEM_TYPE.section && isPrint)
          return <h3>{label}</h3>
        else if (type === INTAKE_REVIEW_FORM_ITEM_TYPE.section && !isPrint)
          return <h4>{label}</h4>
        return <b>{label}</b>
      },
    },
    {
      dataIndex: 'value',
      key: 'value',
      render: (_text: any, { value }: any) => {
        const firstValue = value[0]?.value
        if (
          !isPrint &&
          Array.isArray(value.value) &&
          typeof value.value[0] === 'string'
        ) {
          return <>{value.value.toString()}&nbsp;</>
        } else if (Array.isArray(value) && typeof firstValue === 'string') {
          return value.map((item) => item?.value).join(', ')
        } else if (Array.isArray(value) && typeof value?.[0] === 'string') {
          return <>{value.toString()}&nbsp;</>
        }
        if (typeof value === 'number') {
          return <>{value}</>
        } else if (isNaN(value) && moment(value, true).isValid()) {
          const temp = new Date(value)
          if (temp.toISOString().includes('T00:00:00.000Z')) {
            // This supports UTC birthdates without using timezone (for new DOB values)
            return normalizedAndFormatDate(
              temp.toISOString(),
              'eee LLL dd yyyy'
            )
          }
          // This uses client timezone (for legacy DOB values)
          return temp.toDateString()
        }
        if (typeof value === 'object' && typeof value?.[0] !== 'string') {
          return renderNewTable(value, isPrint)
        } else if (typeof value === 'boolean') {
          return <>{value === true ? 'Yes' : 'No'}&nbsp;</>
        }
        return <>{value}&nbsp;</>
      },
    },
  ]
}

export default function PatientIntakeResponses({
  completedForm,
  id,
  isPrint = false,
}: ReviewProps) {
  const [formSections, setFormSections] = useState<FormSectionKey[]>([
    FormSectionKey.GENERAL_INFORMATION,
  ])
  const [formResponses, setFormResponses] = useState<
    IntakeFormResponseObject[]
  >([])

  useEffect(() => {
    if (!completedForm?.responses) return
    setFormResponses(completedForm.responses)
    setFormSections(completedForm.sections)
  }, [completedForm])

  const data = React.useMemo(() => {
    // create an indexed form responses, so it can be accessed by index
    const indexedFormResponses = formResponses.reduce<{ [key: string]: any }>(
      (output, item) => ({ ...output, [item.questionKey]: item }),
      {}
    )

    // based on data in INTAKE_REVIEW_FORM to create the review form
    const reviewForm: any[] = INTAKE_REVIEW_FORM.map((item) => {
      if (item.type === INTAKE_REVIEW_FORM_ITEM_TYPE.section) {
        //@ts-ignore
        if (formSections.includes(item.name)) {
          return {
            label: item.title,
            value: '',
            type: item.type,
          }
        }
      }

      const attributeFound = indexedFormResponses[item.name]
      // Ignore array and empty string values
      const validAnswer =
        attributeFound?.answer?.length !== 0 && attributeFound?.answer !== ''
      if (attributeFound && validAnswer) {
        return {
          label: item.title,
          value:
            typeof attributeFound.answer === 'object'
              ? parseResponsesForFormReview(attributeFound.answer)
              : attributeFound.answer,
        }
      }
    })
    return reviewForm.filter((item) => item !== undefined)
  }, [formResponses, formSections])

  return (
    <Table
      className="intake-table"
      id={id}
      locale={{ emptyText: ' ' }}
      showHeader={false}
      rowClassName="intake-table-row"
      //@ts-ignore
      columns={intakeColumns(isPrint)}
      //@ts-ignore
      dataSource={data}
      pagination={false}
    />
  )
}
