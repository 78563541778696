import React, { useMemo } from 'react'

// Import only the necessary functions from date-fns library, which is more modern and tree-shakable
import { format } from 'date-fns-tz'

import { Addendum, Signature } from '../../types'
import { Card, Typography } from './../../../../stories/BaseComponents'
import { Addendums } from './Addendums'

import styles from './Signatures.module.scss'

export type SignaturesProps = {
  signatures: Signature[]
  addendums: Addendum[]
  signatureContainerRef?: React.RefObject<HTMLDivElement>
  addendumContainerRef?: React.RefObject<HTMLDivElement>
}

export const Signatures = ({
  signatures,
  signatureContainerRef,
  addendumContainerRef,
  addendums,
}: SignaturesProps) => {
  const { Text } = Typography
  const dot = <span className={styles.dot}>•</span>

  // Memoize the rendering logic for signatures to prevent unnecessary re-renders
  const renderedSignatures = useMemo(() => {
    if (!Array.isArray(signatures) || signatures.length === 0) {
      return null
    }

    return signatures.map((signature, i) => {
      if (!signature || !signature.signedAt || !signature.providerName) {
        // Log error or handle it as needed
        return null
      }

      const date = format(new Date(signature.signedAt), 'P')
      const time = format(new Date(signature.signedAt), 'p z')

      return (
        <Card
          key={`signature-${i}`}
          className={styles.signatureCard}
          bodyStyle={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
        >
          <Text className={styles.title}>
            {`Signed by ${signature.providerName}`}
          </Text>
          <Text className={styles.text}>
            {date} {dot} {time}
          </Text>
          <Text className={styles.comments}>{signature.comments}</Text>
        </Card>
      )
    })
  }, [signatures])

  if (!renderedSignatures) {
    return null
  }

  return (
    <div className={styles.signatures}>
      <div className={styles.box}>
        <div ref={signatureContainerRef}>
          <Text className={styles.headerTitle}>Signatures</Text>
          {renderedSignatures}
        </div>

        {addendums.length > 0 && (
          <div ref={addendumContainerRef}>
            <Addendums addendums={addendums} />
          </div>
        )}
      </div>
    </div>
  )
}
