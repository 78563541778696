import { useCallback, useMemo, useState } from 'react'

import { ArrowLeftOutlined, DownOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import cn from 'classnames'
import { useHistory } from 'react-router-dom'

import SkeletonLoadingTransition from '../../../../components/Animation/SkeletonLoadingTransition'
import NavigationGuardModal from '../../../../containers/Patient/ClinicalNotes/ClinicalNotesComponents/GuardModals/NavigationGuardModal'
import { getClinicalNotesUrl } from '../../../../containers/Patient/ClinicalNotes/utils'
import { PatientHeader } from '../../../../containers/Patient/PatientHeader'
import useKeyboardShortcut from '../../../../hooks/useKeyboardShortcut'
import { useProfileUrlParams } from '../../../../hooks/useProfileUrlParams'
import { useProviderDetails } from '../../../../hooks/useProviderInfo'
import { useFeatureFlags } from '../../../../libs/featureFlags'
import { notification } from '../../../../libs/notificationLib'
import { Invoice } from '../../../../shared-types'
import {
  Badge,
  Button,
  Dropdown,
  Skeleton,
  Space,
  Tooltip,
} from '../../../../stories/BaseComponents'
import { MenuItemType } from '../../../../stories/BaseComponents/Dropdown'
import { routeParameters } from '../../../../stories/Invoice/InvoiceForm'
import { TITLE_PLACEHOLDER_NAVIGATION } from '../../constants'
import { useNavigate } from '../../hooks'
import { Note, SignatureRequest } from '../../types'
import { AddendumModal } from '../AddendumModal'
import { SignatureModal } from '../SignatureModal/SignatureModal'

import styles from './NavigationBar.module.scss'

const { confirm } = Modal

export type NavigationBarProps = {
  healthGorillaUserName: string
  title?: string
  note: Note
  invoice: Invoice | undefined
  saveError: string | null
  isLoading: boolean
  isSaving: boolean
  canDownload: boolean
  isNoteEmpty: boolean
  pdfTitle?: string
  hasPendingChanges: boolean
  onDelete: () => Promise<void>
  onSign: (body: SignatureRequest) => Promise<Partial<Note>>
  onAddAddendum: (text: string) => Promise<Partial<Note>>
  onCopy: () => void
  onNavigateAway: () => void
}

const getBadgeText = ({
  isLoading,
  isSaving,
  saveError,
  hasPendingChanges,
}: {
  isLoading: boolean
  isSaving: boolean
  saveError: string | null
  hasPendingChanges: boolean
}) => {
  if (isLoading) return 'Loading'
  if (isSaving || hasPendingChanges) return 'Saving...'
  if (saveError) return 'Error saving'
  return 'Saved'
}

const getBadgeColor = ({
  isLoading,
  isSaving,
  saveError,
  hasPendingChanges,
}: {
  isLoading: boolean
  isSaving: boolean
  saveError: string | null
  hasPendingChanges: boolean
}) => {
  if (isLoading || isSaving || hasPendingChanges) return 'gray'
  if (saveError) return 'red'
  return 'green'
}

export const NavigationBar = ({
  healthGorillaUserName,
  note,
  invoice,
  saveError,
  isLoading,
  isSaving,
  onDelete,
  onSign,
  onAddAddendum,
  onCopy,
  canDownload,
  isNoteEmpty,
  hasPendingChanges,
  pdfTitle,
  onNavigateAway,
}: NavigationBarProps) => {
  const { claims } = useFeatureFlags()
  const history = useHistory()
  const { urlParams, providerId, patientId } = useProfileUrlParams()
  const navigate = useNavigate()
  const { data: providerDetails } = useProviderDetails()

  const [toggleSignatureModal, setToggleSignatureModal] = useState(false)
  const [newSignature, setNewSignature] = useState({
    comments: '',
  })
  const [isSignatureSaving, setIsSignatureSaving] = useState(false)
  const [isAddAddendumModalVisible, setIsAddAddendumModalVisible] =
    useState(false)

  const isSigned = note.firstSignedAt !== null
  const noteTitle = note.title ?? TITLE_PLACEHOLDER_NAVIGATION

  function getInfoBoxText() {
    if (!isSigned) {
      return `Signing the note will finalize it.
      All users will not be allowed to edit the note after signing.
      Multiple users can sign each note.`
    }
    return `This note is finalized. You will be adding an additional signature to this note.`
  }

  const downloadPDF = () => {
    if (isLoading || isSaving || hasPendingChanges) {
      return
    }
    const originalTitle = document.title // Store the original document title
    document.title = pdfTitle || '' // Set the document title to the PDF title

    //Put a transparent overlay on the page to prevent user interaction while the print dialog is beign opened
    const overlay = document.createElement('div')
    overlay.style.position = 'fixed'
    overlay.style.top = '0'
    overlay.style.left = '0'
    overlay.style.width = '100%'
    overlay.style.height = '100%'
    overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.0)'
    overlay.style.zIndex = '9999'
    document.body.appendChild(overlay)

    try {
      // Attempt to invoke the print dialog using execCommand, primarily for Safari
      if (navigator.userAgent.indexOf('Safari') !== -1) {
        document.execCommand('print', false)
      } else {
        window.print()
      }
    } finally {
      // Restore the original document title regardless of print success
      document.title = originalTitle
      // Remove the overlay
      document.body.removeChild(overlay)
    }
  }

  useKeyboardShortcut(
    [['mod+p', downloadPDF, { preventDefault: true }]],
    [],
    true
  )

  const goToNotesList = () => {
    navigate(`/patient/clinical-notes`, urlParams)
  }

  const showSignModal = () => {
    setToggleSignatureModal(true)
  }
  const handleSignatureCancel = () => {
    setToggleSignatureModal(false)
  }

  const onChangeSignature = (comments: string) => {
    setNewSignature({
      comments: comments,
    })
  }
  const handleSignNote = async () => {
    try {
      setIsSignatureSaving(true)
      await onSign(newSignature)
      setNewSignature({
        comments: '',
      })
      notification('Note signed', 'success')
    } catch (e) {
      notification('There was a problem saving the signature.', 'failure')
    } finally {
      setIsSignatureSaving(false)
      setToggleSignatureModal(false)
    }
  }

  const showConfirm = () => {
    confirm({
      icon: <></>,
      title: `Delete ${noteTitle}`,
      content: `Are you sure you want to delete this note? This action cannot be undone.`,
      okText: 'Delete',
      okButtonProps: {
        danger: true,
      },
      onOk: async () => {
        try {
          await onDelete()
          history.push(getClinicalNotesUrl({ urlParams }))
        } catch (e) {
          console.error('There was an error deleting this note.', e)
          notification('There was an error deleting this note.', 'failure')
        }
      },
    })
  }

  const [hasSuperbill, hasInvoice] = useMemo(() => {
    return [
      Boolean(!!note.invoiceId && invoice?.superbill?.id),
      Boolean(!!note.invoiceId && !invoice?.deletedAt),
    ]
  }, [note, invoice])

  const navigateToInvoicePath = useCallback(
    ({ createSuperbill }: { createSuperbill?: boolean } = {}) => {
      const options = {
        [routeParameters.invoiceUuid]: hasInvoice ? note.invoiceId : null,
        [routeParameters.noteId]: !hasInvoice ? note.uuid : null,
        [routeParameters.createSuperbill]: createSuperbill ? true : null,
        patientId: note.patientId,
      }

      const urlParams = new URLSearchParams()
      Object.entries(options).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          urlParams.set(key, String(value))
        }
      })
      navigate('/invoice', urlParams.toString())
    },
    [note, hasInvoice]
  )

  const latestClaimId = useMemo(() => {
    return note?.claims?.length
      ? note.claims[note.claims.length - 1].uuid
      : null
  }, [note?.claims])

  const navigateToClaimPath = useCallback(() => {
    const options = {
      [routeParameters.noteId]: !latestClaimId ? note.uuid : null,
      [routeParameters.claimId]: latestClaimId,
      patientId: note.patientId,
      providerId,
    }

    const urlParams = new URLSearchParams()
    Object.entries(options).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        urlParams.set(key, String(value))
      }
    })

    navigate('/patient/billing/claim-create', urlParams.toString())
  }, [note, latestClaimId, providerId])

  const actionsMenu: MenuItemType[] = useMemo(() => {
    const allPotentialItems = [
      {
        text: hasInvoice ? 'View invoice' : 'Create invoice',
        key: 'invoice',
        onClick: () => navigateToInvoicePath(),
        // don't disable if no invoice, disable if still waiting for invoice
        disabled: !note.invoiceId ? false : !invoice,
      },
      {
        text: hasSuperbill ? 'View superbill' : 'Create superbill',
        key: 'superbill',
        onClick: () =>
          navigateToInvoicePath({ createSuperbill: !hasSuperbill }),
        // don't disable if no superbill, disable if still waiting for invoice
        disabled: !note.invoiceId ? false : !invoice,
      },
      {
        text: latestClaimId ? 'View claim' : 'Create claim',
        key: 'claim',
        onClick: navigateToClaimPath,
      },
      {
        text: 'divider',
        key: 'firstDivider',
        type: 'divider',
      },
      {
        text: 'Copy to new note',
        key: 'copy',
        onClick: onCopy,
      },
      {
        text: 'Download as PDF',
        key: 'download',
        onClick: () => downloadPDF(),
        disabled: !canDownload || isLoading,
      },
      {
        text: 'divider',
        key: 'secondDivider',
        type: 'divider',
      },
      {
        text: 'Add addendum',
        key: 'addendum',
        disabled: !isSigned,
        onClick: async () => setIsAddAddendumModalVisible(true),
      },
      { text: 'divider', key: 'thirdDivider', type: 'divider' },
      {
        text: 'Delete',
        key: 'delete',
        disabled: isSigned,
        danger: !isSigned,
        onClick: () => {
          showConfirm()
        },
      },
    ]

    // remove the claims item if it's not feature flag enabled
    return allPotentialItems.filter((item) => item.key !== 'claim' || claims)
  }, [
    isSigned,
    urlParams,
    hasSuperbill,
    hasInvoice,
    claims,
    latestClaimId,
    invoice,
    canDownload,
  ])

  const handleSaveClick = () => {
    onNavigateAway()
  }

  const handleSetIsAddendumModalVisible = (isVisible: boolean) => {
    setIsAddAddendumModalVisible(isVisible)
  }

  const isSigningDisabled =
    isNoteEmpty || hasPendingChanges || isSaving || isLoading
  const areActionsDisabled = isSaving || hasPendingChanges || isLoading
  const badgeColor = getBadgeColor({
    isLoading,
    isSaving,
    saveError,
    hasPendingChanges,
  })
  const badgeText = getBadgeText({
    isLoading,
    isSaving,
    saveError,
    hasPendingChanges,
  })

  if (!providerId) {
    throw new Error('Provider ID is required')
  }

  return (
    <>
      <NavigationGuardModal
        when={areActionsDisabled}
        content="Would you like to save your work before leaving?"
        onSaveClick={handleSaveClick}
        shouldBlockNavigation={() => {
          return areActionsDisabled
        }}
        blockRefreshAndClosing
      />
      <PatientHeader
        healthGorillaUserName={healthGorillaUserName}
        patientId={patientId}
        providerId={providerId}
      />
      <div className={styles.noteNavBar}>
        <div className={styles.leftSideNavBar}>
          <Tooltip
            title={areActionsDisabled ? 'Waiting for saving to complete' : ''}
            placement="bottomLeft"
          >
            <div
              className={cn(styles.allNotesButtonWrapper, {
                [styles.disabled]: areActionsDisabled,
              })}
            >
              <Button
                type="link"
                className={cn(styles.backToNotes, {
                  [styles.disabledButton]: areActionsDisabled,
                })}
                onClick={goToNotesList}
                testId="notes-v2-back-to-note-list"
                icon={<ArrowLeftOutlined />}
                disabled={areActionsDisabled}
              >
                All Notes
              </Button>
            </div>
          </Tooltip>
          <span className={styles.separator}>/</span>
          <Space className={styles.titleBadgeContainer}>
            <span className={styles.title}>
              <SkeletonLoadingTransition
                isLoading={isLoading}
                skeletonComponent={
                  <Skeleton
                    title={{
                      className: styles.skeletonTitle,
                    }}
                    paragraph={{
                      rows: 0,
                      className: styles.skeletonParagraph,
                    }}
                  />
                }
                loadedComponent={noteTitle}
              />
            </span>
            {!isSigned && (
              <Badge
                className={isLoading ? styles.addSkeletonMargin : styles.nowrap}
                color={badgeColor}
                text={badgeText}
              />
            )}
          </Space>
        </div>
        <div className={styles.rightSideNavBar}>
          <Space>
            <Dropdown
              testId="notes-v2-actions"
              items={actionsMenu}
              disabled={areActionsDisabled}
              overlayClassName={styles.actionsDropdown}
            >
              <Button testId="notes-v2-actions-button">
                <Space>
                  Actions
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            <Button
              className={isSigningDisabled ? styles.disabledButton : ''}
              disabled={isSigningDisabled}
              onClick={showSignModal}
            >
              Sign
            </Button>
          </Space>
        </div>
      </div>
      <SignatureModal
        toggleSignatureModal={toggleSignatureModal}
        handleSignatureCancel={handleSignatureCancel}
        handleSignNote={handleSignNote}
        onChangeSignature={onChangeSignature}
        signerName={providerDetails?.providerName ?? ''}
        getInfoBoxText={getInfoBoxText}
        isSaving={isSignatureSaving}
        value={newSignature.comments}
      />
      <AddendumModal
        providerName={providerDetails?.providerName ?? ''}
        isAddAddendumModalVisible={isAddAddendumModalVisible}
        isSaving={isSaving}
        setIsModalVisible={handleSetIsAddendumModalVisible}
        saveAddendum={onAddAddendum}
      />
    </>
  )
}
