import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { QrcodeOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { format, isValid } from 'date-fns'
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Nav,
  NavItem,
  Row,
  Table,
} from 'react-bootstrap'
import Dropzone from 'react-dropzone-uploader'
import * as uuid from 'uuid'

import { submitNewPatientDocument, uploadSuperbillDoc } from '../../api/api-lib'
import {
  FormSectionKey,
  IntakeForm,
  JOTFORM_IDENTIFIER,
  OSMIND_INTAKE_IDENTIFIER,
  resendIntakeFormInvite,
} from '../../api/intakeForms'
import { getLabsResults, saveViewOrder } from '../../api/labs'
import LoaderButton from '../../components/Buttons/LoaderButton'
import FileCard from '../../components/Other/FileInformation'
import PDFModal from '../../components/Other/PDFModal'
import { patientDataTypes } from '../../containers/Patient/patient-data-types'
import { usePatientDiagnoses } from '../../hooks/usePatientDiagnoses'
import { usePatientDocuments } from '../../hooks/usePatientDocuments'
import { usePatientDemographics } from '../../hooks/usePatientInfo'
import { useListPatientIntakeForms } from '../../hooks/usePatientIntakeForm'
import { useProviderDetails } from '../../hooks/useProviderInfo'
import { useProviderSidePatientData } from '../../hooks/useProviderSidePatientData'
import useQueryString from '../../hooks/useQueryString'
import { useReadyForReviewLabs } from '../../hooks/useReadyToReviewLabs'
import { getS3FilePrivate, putS3FilePrivate } from '../../libs/awsLib'
import { onError } from '../../libs/errorLib'
import {
  ProviderSideIntakeFormEvents,
  trackIntakeFormEvent,
} from '../../libs/freshpaint/intakeFormEvents'
import { trackSuperbillEvent } from '../../libs/freshpaint/superbillEvents'
import { notification } from '../../libs/notificationLib'
import { capitalize, compare } from '../../libs/utils'
import { Document } from '../../shared-types'
import {
  InfoPage,
  QRCodeModal,
  Skeleton,
  Spinner,
  Text,
} from '../../stories/BaseComponents'
import { PatientDocumentType } from '../../stories/Invoice/constants'
import LabsResultsTable from '../../stories/Labs/LabsResultsTable'
import ReviewPatientIntake from '../../stories/PatientIntake/ReviewPatientIntake'
import IntakeFormDownloadButton from '../../stories/Patients/IntakeFormDownloadButton'
import SendIntakeFormsModal from '../../stories/Patients/IntakeForms/SendIntakeFormsModal'
import { PatientHeader } from './PatientHeader'

import sharedStyles from '../_shared.module.scss'
import styles from './documents.module.scss'

const MAX_ATTACHMENT_SIZE = 56000000 // max PDF attachment size currently set to: 56 MB
interface FileData {
  signatureRequests: any[]
  documents: any[]
  superbills: ParsedDoc[]
  invoices: any[]
  insurance: any[]
}

interface Sort {
  documents: 'newest' | 'oldest'
}

const errorView = (
  <InfoPage
    status="warning"
    title="Sorry there was a problem loading this page"
    details="Oops something went wrong. Please contact your Osmind representative if this issue persists."
    redirectButtonText="Return to Patient List"
    redirectLink="/"
  />
)

//TODO: Add types, first step in adding typing in component
type ParsedDoc = {
  dateTimeUploaded: any
  name: any
  title: any
  documentReference: any
  status: any
  documentType: any
  noteDate: any
}

export default function Documents({
  primaryProviderId,
  healthGorillaUserName,
}: {
  primaryProviderId: string
  healthGorillaUserName: string
}) {
  const queryClient = useQueryClient()
  const query = useQueryString() // gets it from the URL, not react-query!
  const patientId = query.get('patientId') ?? ''
  const providerId = query.get('providerId') ?? primaryProviderId
  const documentReferenceToOpen = query.get('documentReference') ?? ''
  const refetchIsReadyforReviewLab = useReadyForReviewLabs().refetch
  const [sort, setSort] = useState<Sort>({ documents: 'newest' })
  const [documentTitle, setDocumentTitle] = useState<string>('')
  const [showSuperbillUpload, setShowSuperbillUpload] = useState<boolean>(false)
  const [superbillDocumentReference, setSuperbillDocumentReference] =
    useState<string>('')
  const [showIntakeForms, setShowIntakeForms] = useState<boolean>(false)
  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false)
  const [isSuperbillLoading, setIsSuperbillLoading] = useState<boolean>(false)
  const [activeSignatureRequest, setActiveSignatureRequest] = useState<
    string | undefined
  >(undefined)
  const [intakeForm, setIntakeForm] = useState<{
    file: { data?: string }
  } | null>(null)
  const [showIntakeReview, setShowIntakeReview] = useState<boolean>(false)
  const [resendingForm, setResendingForm] = useState<boolean>(false)
  const [newIntakeForm, setNewIntakeForm] = useState<IntakeForm | null>(null)
  const [show, setShow] = useState(false)
  const [file, setFile] = useState(null)
  const [labResultError, setLabResultError] = useState(false)
  const [labResults, setLabResults] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [isLoadingLabResults, setIsLoadingLabResults] = useState<boolean>(false)
  const [activeResult, setActiveLabResults] = useState()
  const [refetch, setRefetch] = useState(false)

  async function downloadFileFromS3(record: any) {
    const result = await getS3FilePrivate(
      record.labsOrderDocumentReference,
      providerId,
      {
        ResponseCacheControl: 'max-age=0',
      }
    )
    const File: any = {}
    File.file = { data: result }
    setFile(File)
  }

  function setLabToViewed(record: any) {
    const results: any = labResults
    for (const r of results) {
      if (r.key === record.key) {
        r.viewed = new Date().toISOString()
      }
    }
    setLabResults(results)
    refetchIsReadyforReviewLab()
    setRefresh(!refresh)
  }

  async function putViewedOrder(record: any) {
    try {
      await saveViewOrder(record.key)
      setLabToViewed(record)
    } catch (e) {
      return
    }
  }

  function viewResultPDF(record: any) {
    setActiveLabResults(record)
    setShow(true)
    downloadFileFromS3(record)
    if (providerId === record.orderingClinicianId && !record.viewed) {
      putViewedOrder(record)
    }
  }

  if (query.get('invoices')) {
    document.getElementById('invoices')?.scrollIntoView(true)
  }

  const refetchPatientDocs = () => {
    queryClient.invalidateQueries([
      patientDataTypes.PatientDocuments,
      patientId,
    ])
  }

  const refetchProviderSidePatientData = () => {
    queryClient.invalidateQueries([
      patientDataTypes.ProviderSidePatientData,
      patientId,
    ])
  }

  const handleApiChange = () => {
    refetchPatientDocs()
    refetchProviderSidePatientData()
  }

  const { data: providerData } = useProviderDetails()

  const {
    data: patientData,
    isLoading: isHeaderLoading = true,
    isError: isPatientInfoError,
  } = usePatientDemographics(patientId)

  const { data, isError, isLoading } = useProviderSidePatientData(patientId)

  usePatientDiagnoses(patientId)

  const {
    data: patientDocuments = [],
    isError: documentsError,
    isLoading: documentsLoading,
  } = usePatientDocuments(patientId ?? '')

  const {
    data: osmindIntakeForms,
    isError: _intakeFormsError,
    isLoading: intakeFormsLoading,
    refetch: refetchIntakeForms,
  } = useListPatientIntakeForms(patientId)

  const { documents, insurance, invoices, signatureRequests, superbills } =
    useMemo((): FileData => {
      if (
        !patientId ||
        isLoading ||
        documentsLoading ||
        !data ||
        intakeFormsLoading
      ) {
        return {
          documents: [],
          insurance: [],
          signatureRequests: [],
          superbills: [],
          invoices: [],
        }
      }

      const { SignatureRequests: unParsedRequests = [], insurance = [] } = data
      const superbills: ParsedDoc[] = []

      const invoices: any[] = []
      const signatureRequests: any[] = []
      const documents: any[] = []
      patientDocuments.forEach((doc: Document) => {
        if (!doc.Name?.includes('LABORDER#')) {
          const parsedDoc: ParsedDoc = {
            dateTimeUploaded: doc.DateTimeUploaded,
            name: doc.Name,
            title: doc.Title,
            documentReference: doc.DocumentReference,
            status: doc.Status ?? 'Uploaded',
            // TODO: Change this in order to just have documentType for both Invoice and Superbill
            documentType: doc.Superbill
              ? PatientDocumentType.SUPERBILL
              : doc.docType,
            noteDate: doc.Superbill || doc.docType ? doc.NoteDate : undefined,
          }

          switch (parsedDoc.documentType) {
            case PatientDocumentType.SUPERBILL:
              superbills.push(parsedDoc)
              break

            case PatientDocumentType.INVOICE:
              invoices.push(parsedDoc)
              break

            default:
              documents.push(parsedDoc)
          }
        }
      })

      if (osmindIntakeForms && osmindIntakeForms.length) {
        setNewIntakeForm(osmindIntakeForms[0])
      }

      unParsedRequests.forEach((request: any) => {
        const {
          RequestReference: documentReference = '',
          TemplateId = '',
          TemplateName = '',
          SignatureStatus: status = '',
        } = request

        if (
          newIntakeForm &&
          (documentReference.includes(JOTFORM_IDENTIFIER) ||
            documentReference.includes(OSMIND_INTAKE_IDENTIFIER))
        ) {
          // Replace Jotform with Osmind's Intake Forms
          return
        }
        let isCompleted = false

        switch (status) {
          case 'intake form completed':
          case 'signature request all signed':
          case 'signature request downloadable':
            isCompleted = true
        }

        if (osmindIntakeForms && osmindIntakeForms.length) {
          setNewIntakeForm(osmindIntakeForms[0])
        }

        if (!isCompleted) {
          signatureRequests.push({ ...request, isCompleted })
          return
        }

        const file = {
          documentReference,
          name: TemplateId || TemplateName || 'N/A',
          title: TemplateName || 'N/A',
          signatureStatus: status,
        }
        signatureRequests.push({
          ...request,
          downloadData: file,
          isCompleted,
        })
      })
      return { signatureRequests, documents, superbills, insurance, invoices }
    }, [
      data,
      patientDocuments,
      isLoading,
      documentsLoading,
      patientId,
      osmindIntakeForms,
      intakeFormsLoading,
      newIntakeForm,
    ])

  async function handleOpenReviewImportModal(request: any) {
    const result = await getS3FilePrivate(
      `SYSTEM_DEFAULT_JOTFORM#${patientId}`,
      providerId
    )
    setIntakeForm({ file: { data: new TextDecoder().decode(result) } })
    setShowIntakeReview(true)
    setActiveSignatureRequest(request)
  }

  const showLocalTime = (timestamp: string | Date) => {
    const isInvalid = !timestamp || !isValid(new Date(timestamp))
    if (isInvalid) return 'No info avaialble'
    return format(new Date(timestamp), 'Pp')
  }

  async function fileToBase64(file: File) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result)
      }
    })
  }

  function labRefresh() {
    setRefetch(!refetch)
  }

  async function fetchLabResults() {
    const pId = patientId
    if (!pId) {
      throw new Error('No PatientId in url')
    }
    const results = await getLabsResults(patientId)
    if (!results || !results.length) {
      setLabResults([])
      return
    }
    setLabResults(results)
  }

  useEffect(() => {
    setIsLoadingLabResults(true)
    const fetchData = async () => {
      await fetchLabResults()
      setRefresh(!refresh)
    }
    try {
      fetchData()
    } catch (e) {
      setLabResultError(true)
    }
    setIsLoadingLabResults(false)
  }, [refetch])

  // For custom intake forms, we need to detect when Hellosign iframe closes before
  // triggering refetch
  // Note: Hellosign endpoints are only pointed towards staging and prod envs,
  // not remote devs
  const customIntakeCallback = (mutationList: any, observer: any) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'childList') {
        if (
          mutation.removedNodes[0]?.className ===
          'x-hellosign-embedded x-hellosign-embedded--in-modal'
        ) {
          observer.disconnect()
          setTimeout(() => {
            handleApiChange()
          }, 8000)
        }
      }
    }
  }

  const observer = new MutationObserver(customIntakeCallback)

  useEffect(() => {
    const element = document.getElementsByClassName('x-hellosign-embedded')[0]
    if (element !== null) {
      const config = { subtree: false, childList: true }
      observer.observe(document.body, config)
    }
  })

  const handleSubmit = useCallback(
    async ([fileGiven]: any[], isSuperbill = false) => {
      const stagedFile: File | any = fileGiven?.file
      const isFileTooBig = stagedFile?.size > MAX_ATTACHMENT_SIZE

      if (isSuperbill && !superbillDocumentReference) return

      if (!stagedFile || isFileTooBig) {
        notification(
          'Invalid file submitted. Make sure to upload a file under 56 MB.',
          'warning'
        )
        fileGiven.remove()
        return
      }

      if (!isSuperbill && !documentTitle) {
        notification('Please input a title for the file submitted.', 'warning')
        return
      }

      try {
        // follow different submission flow depending on if user is uploading a new patient document or new superbill
        if (!isSuperbill) {
          const s3Key = uuid.v1()
          const dateTimeUploaded = format(new Date(), 'PP hh:mm aaa')
          setIsUploadLoading(true)
          stagedFile.dateTimeUploaded = dateTimeUploaded
          const requestData = {
            PatientId: patientId,
            DateTimeUploaded: dateTimeUploaded,
            DocumentReference: s3Key,
            Title: documentTitle,
            Name: stagedFile.name,
          }
          await putS3FilePrivate(stagedFile, s3Key, providerId)
          await submitNewPatientDocument(requestData)
          notification('You have successfully uploaded a document.', 'success')
          setDocumentTitle('')
        } else {
          setIsSuperbillLoading(true)
          const dateAndTime = new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          })
          const base64 = (await fileToBase64(stagedFile)) as unknown as string
          const base64Image = base64.split(',')[1]
          const data = {
            PatientId: patientId,
            DocumentReference: superbillDocumentReference,
            CreatedOn: dateAndTime,
            File: base64Image,
          }
          await uploadSuperbillDoc(data)
          trackSuperbillEvent('replace', {
            invoiceId: superbillDocumentReference,
          })
          setSuperbillDocumentReference('')
          notification(
            'You have successfully replaced a Superbill file. Please wait a moment for your changes to be reflected.',
            'success'
          )
          setShowSuperbillUpload(false)
        }
        handleApiChange()
      } catch (e) {
        // show more helpful error message if request fails with 413 request too large
        if (e.message.includes('Request failed with status code 413')) {
          notification(
            'This file is too large, please try again with a different file.',
            'error'
          )
          return
        }
        onError(
          e,
          500,
          'There was an internal error processing your request. Please inform your administrator.'
        )
      } finally {
        fileGiven.remove()
        setIsUploadLoading(false)
        setIsSuperbillLoading(false)
      }
    },
    [documentTitle, superbillDocumentReference]
  )

  const patientHeader = (
    <PatientHeader
      patientId={patientId}
      providerId={providerId}
      healthGorillaUserName={healthGorillaUserName}
    />
  )

  const handleResendIntakeInvite = useCallback(async () => {
    if (!newIntakeForm || resendingForm) return

    try {
      setResendingForm(true)
      await resendIntakeFormInvite(newIntakeForm.id)
      trackIntakeFormEvent(ProviderSideIntakeFormEvents.RESENT_FORM, {
        osmindIntakeId: newIntakeForm.id,
      })
      await refetchIntakeForms()
    } catch (err) {
      console.error(err)
    } finally {
      setResendingForm(false)
    }
  }, [resendingForm, newIntakeForm])

  const newIntakeFormRow = useMemo(() => {
    if (intakeFormsLoading)
      return (
        <tr key={`loading-new-intake`}>
          <td>
            <Spinner />
          </td>
        </tr>
      )

    if (!newIntakeForm) return null

    const formUrl = `${window.location.origin}/form/${newIntakeForm.id}`
    let formStatus
    let actionColumn

    if (newIntakeForm.importedAt) {
      formStatus = `Imported on ${showLocalTime(newIntakeForm.importedAt)}`
      actionColumn = (
        <ReviewPatientIntake
          toggleButtonText="View Form"
          formId={newIntakeForm.id}
        />
      )
    } else if (newIntakeForm.reviewedAt) {
      formStatus = `Marked reviewed on ${showLocalTime(
        newIntakeForm.reviewedAt
      )}`
      actionColumn = (
        <ReviewPatientIntake
          toggleButtonText="Import Form"
          formId={newIntakeForm.id}
        />
      )
    } else if (newIntakeForm.completedAt) {
      formStatus = `Patient completed form at ${showLocalTime(
        newIntakeForm.completedAt
      )}`
      actionColumn = (
        <ReviewPatientIntake
          toggleButtonText="Review and Import"
          formId={newIntakeForm.id}
        />
      )
    } else if (newIntakeForm.startedAt) {
      formStatus = `Patient viewed form at ${showLocalTime(
        newIntakeForm.startedAt
      )}`
      actionColumn = !resendingForm ? (
        <Button
          className={styles.btnPrimary}
          onClick={handleResendIntakeInvite}
        >
          Resend Form
        </Button>
      ) : (
        <Spinner />
      )
    } else if (newIntakeForm.lastInvitedAt) {
      formStatus = `Last form sent at ${showLocalTime(
        newIntakeForm.lastInvitedAt
      )}`
      actionColumn = !resendingForm ? (
        <Button
          className={styles.btnPrimary}
          onClick={handleResendIntakeInvite}
        >
          Resend Form
        </Button>
      ) : (
        <Spinner />
      )
    }
    if (newIntakeForm.completedAt) {
      formStatus = (
        <>
          {formStatus}&nbsp;
          <IntakeFormDownloadButton
            formId={newIntakeForm.id}
            publicId={patientData?.PublicId}
          />
        </>
      )
    }
    const sectionsOrdered: string[] = Object.values(FormSectionKey)
    const sectionNames = newIntakeForm.sections
      .sort((a, b) => sectionsOrdered.indexOf(a) - sectionsOrdered.indexOf(b))
      .map(
        (sectionName) =>
          sectionName[0].toUpperCase() +
          sectionName.slice(1).replaceAll('_', ' ').replaceAll('and', '&')
      )
      .join(', ')

    const onQrCodeModalOpen = () =>
      trackIntakeFormEvent(ProviderSideIntakeFormEvents.VIEWED_FORM_QR, {
        osmindIntakeId: newIntakeForm.id,
      })

    return (
      <tr key={`sigrequests-${newIntakeForm.id}`}>
        <td>
          <div>
            {newIntakeForm.title}
            <QRCodeModal
              value={formUrl}
              modalTrigger={
                <>
                  <QrcodeOutlined size={20} />
                  <span>Show QR Code</span>
                </>
              }
              modalDetailsText="Scan this QR code using a smart device to have your patient complete this form during visit:"
              modalFooterText={`Form ID: ${newIntakeForm.id}`}
              modalProps={{
                title: `Access the Osmind intake form`,
              }}
              onModalOpen={onQrCodeModalOpen}
            />
          </div>
          <div className={styles.sectionNameText}>
            <Text>{sectionNames}</Text>
          </div>
        </td>
        <td>{showLocalTime(newIntakeForm.createdAt)}</td>
        <td>{formStatus}</td>
        <td>{actionColumn}</td>
      </tr>
    )
  }, [
    resendingForm,
    newIntakeForm,
    handleResendIntakeInvite,
    intakeFormsLoading,
  ])

  /** SIGNATURE REQUESTS **/
  const populateSignatureRequests = (request: any, index: number) => {
    const {
      downloadData,
      TemplateName: name,
      SentAt: sentAt,
      SignatureStatus: status,
      isCompleted = false,
    } = request
    const isFinalizing =
      status === 'reviewed' || status === 'imported' || isCompleted
    const fileContainer = downloadData ?? {
      documentReference: `SYSTEM_DEFAULT_JOTFORM#${patientId}`,
    }

    let actionsColumn: React.ReactNode | string = <span />

    switch (status) {
      case 'requires review':
        actionsColumn = (
          <Button onClick={() => handleOpenReviewImportModal(request)}>
            Review and Import
          </Button>
        )
        break
      case 'reviewed':
        actionsColumn = (
          <>
            Reviewed
            <Button
              className="pdf-import-button"
              onClick={() => handleOpenReviewImportModal(request)}
            >
              Import
            </Button>
          </>
        )
        break
      case 'imported':
        actionsColumn = 'Imported to Patient Profile'
    }
    return (
      <tr key={`sigrequests-${index}`}>
        <td>{name}</td>
        <td>{showLocalTime(sentAt)}</td>
        <td>
          {capitalize(status)}
          {isFinalizing && (
            <FileCard
              fileContainer={fileContainer}
              patientId={patientId}
              handleApiChange={handleApiChange}
              providerId={providerId}
              isIntakeForm
            />
          )}
        </td>
        <td>{actionsColumn}</td>
      </tr>
    )
  }

  const signatureRequestsTable = useMemo(() => {
    return (
      <Table>
        <thead>
          <tr>
            <th className="table-header">Template Name</th>
            <th className="table-header sent-at">First Sent At</th>
            <th className="table-header signature-status">Status</th>
            <th />
          </tr>
        </thead>
        {!signatureRequests?.length && !newIntakeFormRow ? (
          <tbody>
            <tr>
              <td>No signatures requested yet</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {newIntakeFormRow}
            {signatureRequests.map(populateSignatureRequests)}
          </tbody>
        )}
      </Table>
    )
  }, [signatureRequests, newIntakeFormRow])

  const sendFormButton = useMemo(
    () => (
      <>
        <Button
          bsPrefix="button button-range marginal-light"
          id={styles.sendFormsButton}
          variant="light"
          onClick={() => setShowIntakeForms(true)}
        >
          Send Forms
        </Button>
        {patientData && (
          <SendIntakeFormsModal
            patientEmail={patientData?.PatientEmail}
            patientName={patientData?.PatientName}
            publicId={patientData?.PublicId}
            providerData={providerData}
            show={showIntakeForms}
            setShow={setShowIntakeForms}
            hasDefaultIntakeForm={
              newIntakeForm !== null || signatureRequests?.length > 0
            }
            onSubmit={refetchIntakeForms}
          />
        )}
      </>
    ),
    [showIntakeForms, patientData, providerData]
  )

  const activeIntakeFormView = useMemo(
    () => (
      <PDFModal
        fileContainer={intakeForm}
        show={showIntakeReview}
        attachment={null}
        activeSignatureRequest={activeSignatureRequest}
        handleShow={setShowIntakeReview}
      />
    ),
    [activeSignatureRequest, intakeForm, showIntakeReview]
  )

  const signatureRequestsCard = (
    <>
      {sendFormButton}
      {activeIntakeFormView}
      <Card className={styles.documentsPageCard}>
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            <Row>
              <Col md={6}>
                <span
                  className={`${styles.subheaderTitle} ${styles.responseTitle}`}
                >
                  Status of Electronic Forms
                </span>
              </Col>
            </Row>
            <Row>
              <Col className={styles.signatureRequestsTable}>
                {signatureRequestsTable}
              </Col>
            </Row>
          </>
        )}
      </Card>
    </>
  )

  /** DOCUMENT UPLOADS **/
  const uploadsTable = useMemo(() => {
    if (!documents?.length) return <Col>No uploads available</Col>
    const sortedDocuments = compare(
      documents,
      sort.documents,
      'dateTimeUploaded'
    )
    return (
      <>
        {sortedDocuments.map((file: any, i) => (
          <FileCard
            key={`file-card-${i}`}
            fileContainer={file}
            handleApiChange={handleApiChange}
            patientId={patientId}
            providerId={providerId}
            uploadSuperbill={undefined}
          />
        ))}
      </>
    )
  }, [documents, handleApiChange, patientId, providerId, sort])

  const documentNameField = useMemo(
    () => (
      <Form.Row>
        <Form.Group as={Col} md={4} lg={3} xl={2}>
          <Form.File.Label bsPrefix="info larger">
            Document Name:
          </Form.File.Label>
        </Form.Group>
        <Form.Group as={Col} md={7} lg={8} xl={9}>
          <Form.Control
            id="newDocumentName"
            type="text"
            bsPrefix="form-input"
            onChange={(event) => setDocumentTitle(event.target.value)}
            value={documentTitle}
            placeholder="Annual form, referral, record of treatment, etc"
          />
        </Form.Group>
      </Form.Row>
    ),
    [documentTitle]
  )

  const formUpload = (
    <Form as={Col} noValidate onSubmit={(event) => event.preventDefault()}>
      <Form.File className={styles.documentUpload}>
        {documentNameField}
        <Form.Row>
          <Form.Group as={Col} md={4} lg={3} xl={2}>
            <Form.File.Label
              style={{ marginTop: '8px' }}
              bsPrefix="info larger"
            >
              Document Upload:
            </Form.File.Label>
          </Form.Group>
          <Form.Group as={Col} md={7} lg={8} xl={9}>
            <Dropzone
              accept=".pdf, .tiff, .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .mp3, .aac, .mp4, .mov, .avi, .webp, .csv"
              maxFiles={1}
              onChangeStatus={(file, status) => {
                if (!file?.file.name || status === 'removed') {
                  setDocumentTitle('')
                  return
                }
                setDocumentTitle(file?.file.name)
              }}
              onSubmit={(files) => handleSubmit(files, false)}
              classNames={{
                dropzone: 'dropzone',
                dropzoneActive: 'dropzoneActive',
                dropzoneReject: 'dropzoneReject',
                inputLabel: 'dropzoneLabel',
                inputLabelWithFiles: 'dropzoneLabelWithFiles',
                preview: 'dropzone-preview',
              }}
              inputContent="Add a file"
              SubmitButtonComponent={(props: any) => (
                <LoaderButton
                  id="submitNewDocument"
                  bsPrefix="button-label center button-block"
                  isLoading={isUploadLoading}
                  isDisabled={isUploadLoading || !documentTitle}
                  textInside="Uploading the file"
                  onClick={() => handleSubmit(props.files, false)}
                  {...props}
                >
                  Submit
                </LoaderButton>
              )}
            />
            <Form.Text>
              <a
                target="_blank"
                className="file-link"
                href={`https://support.osmind.org/en/articles/5561807-adding-documents-to-the-patient-record`}
              >
                View the file types and file sizes
              </a>{' '}
              that Osmind supports.
            </Form.Text>
          </Form.Group>
        </Form.Row>
      </Form.File>
    </Form>
  )

  const subheaders = (
    type: 'documents' | 'insurance' | 'superbills' | 'invoices'
  ) => {
    const isDocuments = type === 'documents'
    const isInsurance = type === 'insurance'
    const name = isDocuments
      ? 'Uploads'
      : type.charAt(0).toUpperCase() + type.slice(1)
    return (
      <>
        <Row className={styles.subheaderTitle}>
          <Col>{name}</Col>
        </Row>
        {isDocuments && (
          <Nav>
            <NavItem
              as={Col}
              bsPrefix="nav-items toggle"
              onClick={() => setSort({ ...sort, documents: 'newest' })}
            >
              <i className="fa fa-clock-o" aria-hidden="true" />
              &nbsp;Newest
            </NavItem>
            <NavItem
              as={Col}
              bsPrefix="nav-items toggle"
              onClick={() => setSort({ ...sort, documents: 'oldest' })}
            >
              <i className="fa fa-clock-o" aria-hidden="true" />
              &nbsp;Oldest
            </NavItem>
          </Nav>
        )}
        <Row className={styles.sectionNameRow}>
          <Col sm={12} md={5} className="info emphasis">
            Name
          </Col>
          <Col sm={12} md={3} className="info emphasis">
            Created
          </Col>
          <Col sm={12} md={4} className="info emphasis">
            {isDocuments || isInsurance ? 'Status' : 'Note Date'}
          </Col>
        </Row>
      </>
    )
  }

  // LAB ORDERS
  const labOrdersCard = (
    <>
      <Card className={styles.documentsPageCard}>
        <Row className={styles.subheaderTitle}>
          <Col>Lab Results</Col>
        </Row>
        <PDFModal
          fileContainer={file}
          show={show}
          handleShow={setShow}
          isLabPdf={true}
          labResult={activeResult}
          setLabsRefresh={labRefresh}
        />
        <LabsResultsTable
          results={labResults}
          isLoading={isLoadingLabResults}
          onDocuments={true}
          viewResultPDF={viewResultPDF}
          providerId={providerId}
          refresh={refresh}
        />
      </Card>
    </>
  )

  const uploadsCard = (
    <Card className={styles.documentsPageCard}>
      {subheaders('documents')}
      <Row>
        <Col>{uploadsTable}</Col>
      </Row>
      <Row>
        <Col>{formUpload}</Col>
      </Row>
    </Card>
  )

  /** SUPERBILLS **/
  const uploadSuperbill = (documentReference: string) => {
    setShowSuperbillUpload(true)
    setSuperbillDocumentReference(documentReference)
  }

  const superbillsTable = useMemo(() => {
    if (!superbills?.length) return <Col>No uploads available</Col>
    return (
      <>
        {superbills.map((fileContainer) => {
          if (!fileContainer) return // for the rare instance there is no file
          return (
            <FileCard
              key={fileContainer.documentReference}
              fileContainer={fileContainer}
              patientId={patientId}
              providerId={providerId}
              handleApiChange={handleApiChange}
              openDocument={documentReferenceToOpen}
              uploadSuperbill={uploadSuperbill}
            />
          )
        })}
      </>
    )
  }, [superbills])

  const superbillsCard = (
    <Card className={styles.documentsPageCard}>
      {subheaders('superbills')}
      <Row>
        <Col className={styles.superbillsTable}>{superbillsTable}</Col>
      </Row>
    </Card>
  )

  const superbillReplaceModal = (
    <Modal
      show={showSuperbillUpload}
      onHide={() => setShowSuperbillUpload(false)}
      id={styles.superbillsModal}
      size="lg"
      centered
    >
      <span className={styles.superbillsModalTitle}>
        Replace Superbill file
      </span>
      <Dropzone
        accept=".pdf"
        maxFiles={1}
        onSubmit={(files) => handleSubmit(files, true)}
        classNames={{
          dropzone: 'dropzone',
          dropzoneActive: 'dropzoneActive',
          dropzoneReject: 'dropzoneReject',
          inputLabel: 'superbill-dropzone',
          inputLabelWithFiles: 'dropzoneLabelWithFiles',
          preview: 'superbill-dropzone-preview',
        }}
        inputContent="Choose a PDF file"
        SubmitButtonComponent={(props: any) => (
          <LoaderButton
            containerClassName={styles.overrideWidth}
            id="submitNewDocument"
            bsPrefix="button-label center button"
            isLoading={isSuperbillLoading}
            isDisabled={isSuperbillLoading}
            textInside="Uploading the file"
            onClick={() => handleSubmit(props.files, true)}
            {...props}
          >
            Submit
          </LoaderButton>
        )}
      />
      <br />
    </Modal>
  )

  /** INVOICES **/
  const invoicesTable = useMemo(() => {
    if (!invoices?.length) return <Col>No uploads available</Col>
    return (
      <>
        {invoices.map((fileContainer: any) => {
          if (!fileContainer) return // for the rare instance there is no file
          return (
            <FileCard
              fileContainer={fileContainer}
              patientId={patientId}
              providerId={providerId}
              handleApiChange={handleApiChange}
              openDocument={documentReferenceToOpen}
            />
          )
        })}
      </>
    )
  }, [invoices])

  /** INSURANCE **/
  const insuranceTable = useMemo(() => {
    if (!insurance?.length) return <Col>No uploads available</Col>
    return (
      <>
        {insurance.map((fileContainer: any) => {
          if (!fileContainer) return // for the rare instance there is no file
          return (
            <FileCard
              fileContainer={fileContainer}
              key={fileContainer.documentReference}
              patientId={patientId}
              providerId={providerId}
              handleApiChange={handleApiChange}
              openDocument={documentReferenceToOpen}
            />
          )
        })}
      </>
    )
  }, [insurance, handleApiChange])

  const invoicesCard = (
    <Card id="invoices" className={styles.documentsPageCard}>
      {subheaders('invoices')}
      <Row>
        <Col className={styles.superbillsTable}>{invoicesTable}</Col>
      </Row>
    </Card>
  )

  if (
    !patientId ||
    labResultError ||
    isError ||
    documentsError ||
    isPatientInfoError
  ) {
    return errorView
  }

  const insuranceCard = (
    <Card id="insurance-cards" className={styles.documentsPageCard}>
      {subheaders('insurance')}
      <Row>
        <Col className={styles.superbillsTable}>{insuranceTable}</Col>
      </Row>
    </Card>
  )

  return (
    <div className={sharedStyles.scroll}>
      {patientHeader}
      <div className={styles.patientDocumentsContainer}>
        {superbillReplaceModal}
        {isLoading && isHeaderLoading && patientData ? (
          <Skeleton />
        ) : (
          <>
            {signatureRequestsCard}
            {!!healthGorillaUserName && labOrdersCard}
            {uploadsCard}
            {!!insurance?.length && insuranceCard}
            {!!superbills?.length && superbillsCard}
            {!!invoices?.length && invoicesCard}
          </>
        )}
      </div>
    </div>
  )
}
