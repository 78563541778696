import { Tooltip } from '../../../../../../stories/BaseComponents'
import { Icon } from './Icon'

import styles from './DragHandle.module.scss'

export const DragHandle = () => {
  return (
    <Tooltip placement="right" mouseEnterDelay={2} title="Click & drag to move">
      <div className={styles.dragHandle} data-drag-handle>
        <Icon />
      </div>
    </Tooltip>
  )
}
