import React, { useEffect, useMemo, useState } from 'react'

import { Form, Select, message } from 'antd'

import { getPersonalSettings, updatePersonalSettings } from '../../api/api-lib'
import { useFeatureFlags } from '../../libs/featureFlags'
import { ssnRegExPersonalSettings, tenDigitNumberRegEx } from '../../libs/regex'
import { Button, Card, Input, Spinner } from '../../stories/BaseComponents'
import AddressAutocomplete, {
  Address,
} from '../../stories/BaseComponents/AddressAutocomplete'
import SignaturePreview, {
  FontOptions,
} from '../../stories/BaseComponents/SignaturePreview'
import EINInput, {
  formatSSN,
} from '../../stories/BaseComponents/SpecialInputFields/EINInput'
import PhoneNumberInput from '../../stories/BaseComponents/SpecialInputFields/PhoneNumberInput'
import { extractNumbersFromString } from '../Patient/Claims/InsuranceClaim-helper'

import styles from '../_shared.module.scss'

const { Option } = Select

export interface PersonalSettings {
  ProviderId: string

  ProviderFirstName: string
  ProviderMiddleName: string
  ProviderLastName: string
  ProviderEmail: string
  ProviderPhone: string

  ProviderCredential: string
  ProviderNpiNumber: string
  ProviderEinNumber: string
  ProviderSshNumber: string
  signatureFont?: FontOptions
  AutocompleteAddress?: Address
}

interface SettingsPersonalFormProps {
  UserId: string
  personalSettings: PersonalSettings
}

function formatTelephone(data: string) {
  // This gets rid of any Country Code, then leaves digits only.
  return data.replace(/^\+1 ?/, '').replace(/[^0-9]/g, '')
}

function SettingsPersonalForm({
  UserId,
  personalSettings,
}: SettingsPersonalFormProps) {
  const { claims } = useFeatureFlags()
  const [form] = Form.useForm()
  const [name, setName] = useState<string>('')
  const [font, setFont] = useState<FontOptions | ''>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onFontChange = () => {
    const {
      ProviderFirstName = '',
      ProviderLastName = '',
      signatureFont = '',
    } = form.getFieldsValue()
    setName(`${ProviderFirstName} ${ProviderLastName}`)

    setFont(signatureFont)
  }

  const handleAddressChange = async (values: Address) => {
    const updateItem = form.getFieldsValue()
    if (updateItem.AutocompleteAddress) {
      updateItem.AutocompleteAddress.Address1 = values?.Address1 ?? ''
      updateItem.AutocompleteAddress.Address2 = values?.Address2 ?? ''
      updateItem.AutocompleteAddress.City = values?.City ?? ''
      updateItem.AutocompleteAddress.State = values?.State ?? ''
      updateItem.AutocompleteAddress.Zipcode = values?.Zipcode ?? ''
      form.setFieldsValue(updateItem)
    }
  }

  useEffect(() => {
    onFontChange()
  }, [isLoading])

  const fontOptions = (
    <Select>
      {Object.values(FontOptions).map((font: string) => (
        <Option key={`${font}-font-option`} children={font} value={font} />
      ))}
    </Select>
  )

  const handleFinish = async (values: PersonalSettings) => {
    setIsLoading(true)
    try {
      const requestBody = {
        ...values,
        ProviderCredential: values.ProviderCredential ?? '',
        ProviderNpiNumber: values.ProviderNpiNumber ?? '',
        ProviderPhone: formatTelephone(values.ProviderPhone),
        ProviderId: UserId,
        ProviderMiddleName: values.ProviderMiddleName ?? '',
        addressLine1: values.AutocompleteAddress?.Address1,
        addressLine2: values.AutocompleteAddress?.Address2 ?? '',
        city: values.AutocompleteAddress?.City,
        state: values.AutocompleteAddress?.State,
        zipcode: values.AutocompleteAddress?.Zipcode,
        AutocompleteAddress: undefined,
      }
      await updatePersonalSettings(requestBody)
      message.success('Personal Settings were saved.')
      setIsLoading(false)
    } catch (err) {
      console.error(err)
      message.error(
        'There was an internal error processing your request. Please inform your administrator.'
      )
      setIsLoading(false)
    }
  }

  const columnSpan = {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
    md: {
      span: 20,
    },
    lg: {
      span: 15,
    },
    xl: {
      span: 8,
    },
    xxl: {
      span: 8,
    },
  }

  function transformNPI(input: string) {
    let clean = extractNumbersFromString(input)
    if (!clean || !clean.length) {
      form.setFieldValue('ProviderNpiNumber', clean)
      return clean
    }
    if (clean.length > 10) {
      clean = clean.substring(0, 10)
    }
    form.setFieldValue('ProviderNpiNumber', clean)
    return clean
  }

  const formValues = useMemo(() => form.getFieldsValue(), [])
  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={personalSettings}
      onFinish={handleFinish}
      onValuesChange={onFontChange}
      labelCol={columnSpan}
      wrapperCol={columnSpan}
    >
      <Form.Item
        id="ProviderFirstName"
        name="ProviderFirstName"
        label="First Name"
        rules={[
          {
            required: true,
            message: 'Please input your First Name',
            type: 'string',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="First Name" />
      </Form.Item>
      <Form.Item
        id="ProviderMiddleName"
        name="ProviderMiddleName"
        label="Middle Name"
      >
        <Input placeholder="Middle Name" />
      </Form.Item>
      <Form.Item
        name="ProviderLastName"
        id="ProviderLastName"
        label="Last Name"
        rules={[
          {
            required: true,
            message: 'Please input your Last Name',
            type: 'string',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="Last Name" />
      </Form.Item>
      <Form.Item
        name="ProviderCredential"
        label="Credential"
        id="ProviderCredential"
      >
        <Input placeholder="Credential" />
      </Form.Item>
      <Form.Item
        id="signatureFont"
        name="signatureFont"
        label="Digital signature style"
      >
        {fontOptions}
      </Form.Item>
      <Form.Item>
        <SignaturePreview font={font} name={name} />
      </Form.Item>
      <Form.Item
        name="ProviderEmail"
        label="Email"
        id="ProviderEmail"
        rules={[
          {
            type: 'email',
            message: 'Please input a valid Email',
          },
          {
            required: true,
            message: 'Please input your Email',
          },
        ]}
      >
        <Input placeholder="you@example.com" />
      </Form.Item>
      <PhoneNumberInput form={form} name="ProviderPhone" required />
      <Form.Item
        name="ProviderNpiNumber"
        label="NPI"
        id="ProviderNpiNumber"
        rules={[
          {
            pattern: tenDigitNumberRegEx,
            message: 'Enter a valid 10 digit number.',
            transform: transformNPI,
          },
        ]}
      >
        <Input placeholder="NPI" />
      </Form.Item>
      <EINInput
        data-testid="personal-settings-EIN-input"
        form={form}
        name="EIN"
        formItemProps={{ label: 'EIN' }}
      />
      <EINInput
        data-testid="personal-settings-SSN-input"
        form={form}
        name="SSN"
        formItemProps={{
          label: 'SSN',
          rules: [
            {
              message: 'Enter a valid 9-digit code',
              min: 11,
              pattern: ssnRegExPersonalSettings,
              transform: formatSSN,
              type: 'string',
              whitespace: true,
            },
          ],
        }}
      />
      {claims && (
        <Form.Item
          name="UseEINorSSN"
          label="Do you want your EIN or SSN to be reflected on claims?"
          id="UseEINorSSN"
          data-testid="UseEINorSSN"
        >
          <Select placeholder="Select one">
            <Option children="EIN" key="useEIN" value="EIN" label="EIN" />
            <Option children="SSN" key="useSSH" value="SSN" label="SSN" />
          </Select>
        </Form.Item>
      )}
      <Form.Item label={''} name="AutocompleteAddress">
        <AddressAutocomplete
          value={formValues.AutocompleteAddress}
          onChange={handleAddressChange}
        />
      </Form.Item>
      <Form.Item id="submit">
        <Button
          type="primary"
          htmlType="submit"
          disabled={isLoading}
          loading={isLoading}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}

interface Props {
  UserId: string
  providerData: any
}
export default function SettingsPersonal({ UserId }: Props) {
  const [isFetchDone, setIsFetchDone] = useState(false)
  const [personalSettings, setPersonalSettings] = useState<PersonalSettings>()

  useEffect(() => {
    async function getSettingsFromAPI() {
      try {
        const settings = await getPersonalSettings(UserId)
        setPersonalSettings({
          ...settings,

          AutocompleteAddress: {
            Address1: settings.addressLine1,
            Address2: settings.addressLine2,
            City: settings.city,
            State: settings.state,
            Zipcode: settings.zipcode,
          },
        })
      } catch (err) {
        console.error('Error when fetching PersonalSettings', err)
      } finally {
        setIsFetchDone(true)
      }
    }
    if (UserId) {
      getSettingsFromAPI()
    }
  }, [UserId])

  if (!UserId || !isFetchDone || !personalSettings) {
    return <Spinner />
  }

  return (
    <div className={styles.scroll}>
      <div className={styles.spacedContainer}>
        <Card bordered={false}>
          <div className="headerWrapper">
            <span className="headerWrapper_title">My Settings</span>
            <span className="headerWrapper_description">
              Set your name and contact information. The email address entered
              here is used for your login access.
            </span>
          </div>
          <SettingsPersonalForm
            UserId={UserId}
            personalSettings={personalSettings}
          />
        </Card>
      </div>
    </div>
  )
}
