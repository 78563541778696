import React, { useEffect } from 'react'

import { useFormikContext } from 'formik'

import { Alert, Card } from '../../../../stories/BaseComponents'
import InlineField from '../InlineField'
import { UsePayersResult } from '../hooks'
import { ClaimForm } from '../types'

import styles from './shared.module.scss'

const PAYER = {
  name: 'selectedInsuranceName',
  label: 'Company name',
  placeholder: 'Select',
  type: 'select',
  isRequired: true,
  options: [],
}

export const testIds = {
  container: 'claim-insurance-to-submit',
  alert: 'claim-insurance-to-submit-alert',
}

type PayerProps = {
  payers: UsePayersResult
}

const Payer: React.FC<PayerProps> = ({ payers }) => {
  const { options: payerOptions } = payers
  const { values, touched, setFieldValue } = useFormikContext<ClaimForm>()

  const {
    primaryInsurance: { name },
  } = values

  const nameTouched = !!touched.primaryInsurance?.name

  // dependent fields logic
  // auto fill when primary insurance changes OR when a newly created claim loads
  useEffect(() => {
    if (!nameTouched) {
      return
    }
    setFieldValue('selectedInsuranceName', name)
  }, [name, nameTouched])

  return (
    <Card
      testId={testIds.container}
      style={{ marginTop: 24 }}
      bodyStyle={{ paddingBottom: 16 }}
    >
      <div className={styles.section}>
        <div className={styles.header}>
          <span className={styles.title}>
            {
              'Primary insurance - Select which insurance company to submit this claim to'
            }
          </span>
        </div>
        <Alert
          testId={testIds.alert}
          description={`Submitting to the wrong insurance company is one of the most common claim rejections. Check the patient's insurance card for any instructions on who to submit to, as well as to find the insurance company name.`}
          message=""
          type="info"
          showIcon
          style={{ marginBottom: 16 }}
        />
        <div>
          <InlineField {...PAYER} key={PAYER.name} options={payerOptions} />
        </div>
      </div>
    </Card>
  )
}

export default Payer
