import React, { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'

import { getAllPatients } from '../../../api/api-lib'
import { getPermissionCookie } from '../../../api/permissions'
import OsmindBrandLogo from '../../../images/osmind_logo.png'
import { useAppContext } from '../../../libs/contextLib'
import { onError } from '../../../libs/errorLib'
import { ProviderHomePageData, Teammate } from '../../../shared-types'
import { UnreadMessageCountsContext } from '../../../v2/messaging/UnreadMessageCounts'
import PatientTable from '../PatientTable'
import { PatientResponse, buildPatientTableData } from '../_helpers'
import { CareTeam, PatientTableData } from '../_types'

import '../../../containers/Home.scss'
import '../../../containers/Other/MessagingButton.scss'

export type HomeProps = {
  homePageData: Pick<ProviderHomePageData, 'loggedInProviderId' | 'providerId'>
  teammates: Teammate[]
}

export const Home = ({ homePageData, teammates }: HomeProps) => {
  // Load permissions into state on load
  useQuery(['userPermissions'], getPermissionCookie, { staleTime: 60000 * 5 })
  const {
    data: patients,
    isLoading,
    refetch: refetchPatients,
    isFetching: isPatientsFetching,
    isError,
    error,
  } = useQuery<PatientResponse[]>(['homePatients'], {
    queryFn: getAllPatients,
    refetchInterval: 0,
    refetchOnWindowFocus: false,
  })
  const [patientsTableData, setPatientsTableData] = useState<
    PatientTableData[]
  >([])
  const [patientsTableDataLoading, setPatientsTableDataLoading] =
    useState<boolean>(true)
  const { isAuthenticated } = useAppContext() as any
  const { unreadMessageCounts: patientMessageData } = React.useContext(
    UnreadMessageCountsContext
  )

  const isPatientsLoading = isLoading || isPatientsFetching

  useEffect(() => {
    if (!isPatientsLoading && patientMessageData instanceof Map) {
      const newTableData: PatientTableData[] = buildPatientTableData(
        patients,
        patientMessageData
      )
      setPatientsTableData(newTableData)
      setPatientsTableDataLoading(false)
    }
  }, [patients, isPatientsLoading, patientMessageData])

  //Notify of fetch error
  useEffect(() => {
    if (isError) {
      onError(
        error,
        404,
        'The user could not be found. Please retry login or contact your administrator.'
      )
    }
  }, [isError, error])

  async function updateCareTeam(patientId: string, careTeam: CareTeam[]) {
    const updatedPatientsTableData = patientsTableData
    const index = updatedPatientsTableData.findIndex(
      (pat) => pat.patientId === patientId
    )

    if (index !== -1) {
      updatedPatientsTableData[index].careTeam = careTeam
    }
    // Force a refresh because we're updating a deep object attribute
    setPatientsTableData([])
    setPatientsTableData(updatedPatientsTableData)
  }

  if (!isAuthenticated) {
    return (
      <div className="Home">
        <div className="center summary-card-light lander">
          <img
            src={OsmindBrandLogo}
            style={{ minWidth: '320px' }}
            alt="osmind logo"
            className="img-fluid"
          />
          <h4 id="home-subtext">
            Care Platform for <br /> Mental Health
          </h4>
          <div>
            <Link to="/login" className="button-login">
              Login
            </Link>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="Home" data-testid="Patients--Home">
      <div>
        <PatientTable
          dataSource={patientsTableData}
          handleAddPatient={refetchPatients}
          updateCareTeam={updateCareTeam}
          providerData={homePageData}
          loading={patientsTableDataLoading}
          teammates={teammates}
        />
      </div>
    </div>
  )
}
