import React, { useEffect, useRef, useState } from 'react'

import cx from 'classnames'
import { useHistory, useLocation } from 'react-router-dom'
import { animated, useSpring } from 'react-spring'

import useQueryString from '../../hooks/useQueryString'
import {
  PatientProfileEvents,
  trackPatientProfileEvent,
} from '../../libs/freshpaint/patientProfileEvents'
import HoverScrollview from '../BaseComponents/HoverScrollview'
import NavigatorHeader from './NavigatorHeader'
import NavigatorHeaderDetail from './NavigatorHeaderDetail'

import styles from './Navigator.module.scss'

export interface Element {
  title: string
  profilePanelRoute: string
  content: React.ReactNode
  hasDetail: boolean
}

interface NavigatorProps {
  elements: Array<Element>
  topHeight?: number
  hidden?: boolean
}

const Navigator: React.FC<NavigatorProps> = ({ elements, hidden = false }) => {
  const query = useQueryString()
  const patientId = query.get('patientId') ?? ''
  const providerId = query.get('providerId') ?? ''
  const containerRef: React.Ref<HTMLDivElement> = useRef(null)
  const [container, setContainer] = useState<HTMLDivElement | null>(null)
  const [profilePanel, setProfilePanel] = useState<string | null>('overview')
  const [detailVisible, setDetailVisible] = useState<string | null>('')
  const [scrollTop, setScrollTop] = useState(0)
  const [udpateAffixPositions, setUdpateAffixPositions] =
    useState<boolean>(false)
  const history = useHistory()
  const location = useLocation()

  const detailStyle = useSpring({
    translateX: !profilePanel || profilePanel !== 'overview' ? 0 : 460,
    config: {
      tension: 280,
      friction: 40,
    },
  })

  const overlayStyle = useSpring({
    opacity: !profilePanel || profilePanel !== 'overview' ? 0.4 : 0,
    config: {
      mass: 1,
      friction: 26,
      tension: 170,
    },
  })

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const param = searchParams.get('profilePanel')
    container?.scrollTop
    if (!param) {
      history.replace({
        search: `${location.search}&profilePanel=overview`,
      })
    }
    if (param === 'overview') {
      setTimeout(() => {
        setDetailVisible(null)
        trackPatientProfileEvent(PatientProfileEvents.VIEW_OVERVIEW_PANEL, {
          patientId,
          clinicId: providerId,
          profileSection: param ?? '',
        })
      }, 500)
    } else {
      trackPatientProfileEvent(PatientProfileEvents.VIEW_DETAIL_PANEL, {
        patientId,
        clinicId: providerId,
        profileSection: param ?? '',
      })
      setDetailVisible(param)
    }
    setProfilePanel(param)
  }, [location])

  const renderOverview = () =>
    elements.map(({ title, profilePanelRoute, hasDetail, content }) => {
      if (hasDetail) {
        return (
          <NavigatorHeader
            loading={false}
            profilePanelRoute={profilePanelRoute}
            title={title}
            container={container}
            hasDetail={hasDetail}
          />
        )
      }
      return content
    })

  useEffect(() => {
    if (!containerRef.current) return // wait for the elementRef to be available
    const resizeObserver = new ResizeObserver(() => {
      setUdpateAffixPositions(true)
    })
    resizeObserver.observe(containerRef.current)
    return () => resizeObserver.disconnect() // clean up
  }, [])

  const renderDetail = () => {
    const { title, content } = elements.filter(
      (e) => e.profilePanelRoute === detailVisible
    )[0]
    return (
      <>
        <NavigatorHeaderDetail
          updatePosition={udpateAffixPositions}
          setUpdatePosition={setUdpateAffixPositions}
          backButtonText="Overview"
          title={title}
          container={container}
          scrollTop={scrollTop}
        />
        {content}
      </>
    )
  }

  return (
    <div
      ref={containerRef}
      className={cx(styles.container, hidden && styles.hidden)}
    >
      <div className={styles.overviewContainer}>
        <HoverScrollview
          height={'100%'}
          onRefCreated={setContainer}
          setScrollTop={setScrollTop}
        >
          {detailVisible && (
            <animated.div className={styles.overlay} style={overlayStyle} />
          )}
          {renderOverview()}
          <div className={styles.spacer} />
        </HoverScrollview>
      </div>

      <animated.div className={styles.detailContainer} style={detailStyle}>
        <HoverScrollview
          height={'100%'}
          onRefCreated={setContainer}
          setScrollTop={setScrollTop}
        >
          {detailVisible && renderDetail()}
        </HoverScrollview>
      </animated.div>
    </div>
  )
}

export default Navigator
