import { ReactNode } from 'react'

import { ChainedCommands, Editor } from '@tiptap/react'

import { MarkToggle } from './MarkToggle'

import styles from './EditorToolbar.module.scss'

export const ToggleSection = ({
  items,
  editor,
  isLoading,
}: {
  items: {
    name: string
    icon: ReactNode
    command?: () => ChainedCommands | undefined
  }[]
  editor?: Editor
  isLoading: boolean
}) => {
  return (
    <div className={styles.toggleSection}>
      {items.map(({ name, icon, command }) => (
        <MarkToggle
          key={`${name}-toggle`}
          name={name}
          icon={icon}
          editor={editor}
          isActive={editor?.isActive(name) || false}
          onClick={() => command?.()?.run()}
          isLoading={isLoading || !editor}
        />
      ))}
    </div>
  )
}
