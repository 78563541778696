import { format } from 'date-fns-tz'

import { Card, Text } from '../../../../../stories/BaseComponents'
import { Addendum } from '../../../types'

import styles from './Addendums.module.scss'

type AddendumsProps = {
  addendums: Addendum[]
}

export const Addendums = ({ addendums }: AddendumsProps) => {
  const dot = <span className={styles.dot}>•</span>
  return (
    <div>
      <Text className={styles.headerTitle}>Addendums</Text>
      {addendums.map((addendum) => {
        const date = format(new Date(addendum.createdAt), 'P')
        const time = format(new Date(addendum.createdAt), 'p z')

        return (
          <Card
            key={`addendum-${addendum.id}`}
            className={styles.signatureCard}
            bodyStyle={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <Text className={styles.title}>
              {`Addendum by ${addendum.providerName}`}
            </Text>
            <Text className={styles.text}>
              {date} {dot} {time}
            </Text>
            <Text className={styles.comments}>{addendum.comments}</Text>
          </Card>
        )
      })}
    </div>
  )
}
