import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
} from 'react'

import { Divider, FormInstance } from 'antd'
import get from 'lodash/get'

import { getClaimURL } from '../../../api/hellosign'
import {
  FormSectionKey,
  OsmindIntakeFormTitle,
  sendIntakeForm,
} from '../../../api/intakeForms'
import { HellosignTemplate } from '../../../components/Accordions/SharePatientProgress/attachmentLib'
import { onError } from '../../../libs/errorLib'
import { formatSectionName } from '../../../libs/freshpaint/intakeFormEvents'
import { openHellosignWindow } from '../../../libs/hellosign'
import { notification } from '../../../libs/notificationLib'
import { asyncSleep } from '../../../libs/utils'
import CustomIntakeCheckboxes from './CustomIntakeCheckBoxes'
import DefaultIntakeCheckboxes from './DefaultIntakeCheckboxes'

import styles from '../_shared.module.scss'

type SuccessDefaultIntakeResponse = {
  osmindIntakeId: string
  osmindIntakeName: string
  osmindIntakeFormSections: string[]
}

export type IntakeFormHandler = {
  sendDefaultIntakeForms: (
    publicId: string,
    patientName: string,
    onSuccess: (res: SuccessDefaultIntakeResponse) => void
  ) => Promise<void>
  sendCustomIntakeForms: (
    patientEmail: string,
    patientName: string,
    providerEmail: string,
    practiceName: string,
    publicId: string,
    onSuccess: (res: string[]) => void
  ) => Promise<void>
}

interface Props {
  form: FormInstance
  providerData: unknown
  hasDefaultIntakeForm?: boolean
}

const IntakeForms: ForwardRefRenderFunction<IntakeFormHandler, Props> = (
  { form, providerData, hasDefaultIntakeForm },
  ref
) => {
  useImperativeHandle(ref, () => ({
    sendDefaultIntakeForms: async (publicId, patientName, onSuccess) => {
      const {
        formSectionsSwitch,
        formSections = [FormSectionKey.GENERAL_INFORMATION],
      } = form.getFieldsValue()
      if (formSectionsSwitch) {
        try {
          const { id: osmindIntakeId, title: osmindIntakeName } =
            await sendIntakeForm({
              patientId: publicId,
              formTitle: OsmindIntakeFormTitle,
              formSections,
            })
          notification(`Form successfully sent to ${patientName}.`, 'success')
          onSuccess({
            osmindIntakeId,
            osmindIntakeName,
            osmindIntakeFormSections: formSections.map(formatSectionName),
          })
        } catch (e) {
          onError(
            e,
            500,
            'There was an internal error processing your request. Please inform your administrator.'
          )
        }
      }
    },
    sendCustomIntakeForms: async (
      patientEmail,
      patientName,
      providerEmail,
      practiceName,
      publicId,
      onSuccess
    ) => {
      const { formCustomOneWayIntakes, formCustomTwoWayIntakes } =
        form.getFieldsValue()
      const oneWayArray = Array.isArray(formCustomOneWayIntakes)
        ? formCustomOneWayIntakes
        : []
      const twoWayArray = Array.isArray(formCustomTwoWayIntakes)
        ? formCustomTwoWayIntakes
        : []
      const checkedTemplateIds: string[] = [...oneWayArray, ...twoWayArray]
      if (checkedTemplateIds.length) {
        const templateList: HellosignTemplate[] = get(
          providerData,
          'hellosignTemplates',
          []
        )
        const successfullySentTemplates: string[] = []
        const templateIds: string[] = []
        let templateName = ''
        for (let i = 0; i < checkedTemplateIds.length; i++) {
          const id = checkedTemplateIds[i]
          const template = templateList.find((t) => t.TemplateId === id)

          if (!template) {
            continue
          }

          templateIds.push(template.TemplateId)

          // using the old Hellosign pattern of a new iframe for every template, we sent a freshpaint event with the template name
          // under this new pattern, we'll just use the name of the first template
          if (i === 0) {
            templateName = template.TemplateName
          }
        }
        let urls: string[] = []
        try {
          urls = await getClaimURL({
            templateIds,
            signer: patientEmail,
            patientName,
            email: providerEmail,
            practiceName,
            publicId,
          })
        } catch (e) {
          notification(
            'There was an internal error processing your request. Forms with signatures were not sent. Please inform your administrator.',
            'failure',
            { duration: 10 }
          )
        }
        for (let index = 0; index < urls.length; index++) {
          const url = urls[index]
          try {
            await openHellosignWindow(url)
            if (index < urls.length - 1) {
              notification(
                `Please wait while other forms are being prepared.`,
                'info',
                { duration: 2 }
              )
              await asyncSleep(1000)
            }
            successfullySentTemplates.push(templateName)
          } catch (e) {
            notification(
              `There was an error sending the ${templateName} form. Please try again.`,
              'failure',
              { duration: 10 }
            )
            await asyncSleep(1000)
          }
        }
        onSuccess(successfullySentTemplates)
      }
    },
  }))

  return (
    <>
      <DefaultIntakeCheckboxes
        form={form}
        hasIntakeForm={hasDefaultIntakeForm}
      />
      <Divider className={styles.intakeSectionDivider} />
      <CustomIntakeCheckboxes form={form} providerData={providerData} />
    </>
  )
}

export default forwardRef<IntakeFormHandler, Props>(IntakeForms)
