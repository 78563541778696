import { lazy, useEffect, useState } from 'react'

import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import { EmailNotificationsToggle } from './components/Forms/EmailNotificationsToggle'
import AuthenticatedRoute from './components/Routes/AuthenticatedRoute'
import UnauthenticatedOnlyRoute from './components/Routes/UnauthenticatedOnlyRoute'
import UnauthenticatedRoute from './components/Routes/UnauthenticatedRoute'
import AppointmentSettings from './components/Scheduling/AppointmentSettings'
import { globalConfig } from './config/config'
import ActivatePractice from './containers/Authentication/ActivatePractice'
import ActivateUser from './containers/Authentication/ActivateUser'
import ChangePassword from './containers/Authentication/ChangePassword'
import Locations from './containers/Authentication/Locations'
import Login from './containers/Authentication/Login'
import { PracticeSettings } from './containers/Authentication/PracticeSettings'
import ResetPassword from './containers/Authentication/ResetPassword'
import BillingTemplates from './containers/Billing/BillingTemplates'
import CustomBillingCode from './containers/Billing/CustomBillingCodes'
import PaymentsOnboarding from './containers/Billing/PaymentsOnboarding'
import PaymentsOnboardingComplete from './containers/Billing/PaymentsOnboardingComplete'
import EmailTemplate from './containers/EmailTemplate/EmailTemplate'
import NotAllowedWhileLoggedIn from './containers/Error/NotAllowedWhileLoggedIn'
import NotFound from './containers/Error/NotFound'
import Intake from './containers/Intake'
import MaintenancePage from './containers/MaintenancePage'
import Billing from './containers/Patient/Billing/Billing'
import ClaimCreation from './containers/Patient/ClaimCreation'
import ClaimCreationV2 from './containers/Patient/ClaimCreationV2'
import CreateClinicalNote from './containers/Patient/ClinicalNotes/CreateClinicalNotePage'
import ViewEditNotePage from './containers/Patient/ClinicalNotes/ViewEditNotePage'
import Documents from './containers/Patient/Documents'
import LabOrderFinalStatus from './containers/Patient/LabOrderFinalStatus'
import Labs from './containers/Patient/Labs'
import Medication from './containers/Patient/Medication'
import PatientClinicalNotes from './containers/Patient/PatientClinicalNotes'
import PatientJournal from './containers/Patient/PatientJournal'
import PatientMessages from './containers/Patient/PatientMessages'
import PatientProfile from './containers/Patient/PatientProfile'
import PatientProgress from './containers/Patient/PatientProgress'
import PatientSettingsPage from './containers/Patient/PatientSettingsPage'
import Settings from './containers/Provider/Settings'
import SettingsPersonal from './containers/Provider/SettingsPersonal'
import SurveySettings from './containers/Provider/SurveySettings'
import { AvailabilitySettings } from './containers/Provider/availability/AvailabilitySettings'
import { useAppReload } from './hooks/useAppReload'
import { useAppContext } from './libs/contextLib'
import { useFeatureFlags } from './libs/featureFlags'
import Sentry from './libs/sentry'
import getCognitoUser from './shared/Helpers/getCognitoUser'
import AdvancedMD from './stories/AdvancedMD'
import PageSuspense from './stories/BaseComponents/PageSuspense'
import { ClinicBillingSummary } from './stories/ClinicBillingSummary'
import { InvoiceForm } from './stories/Invoice/InvoiceForm'
import PatientIntake from './stories/PatientIntake/PatientIntake'
import PatientIntakeSuccess from './stories/PatientIntake/sections/PatientIntakeSuccess'
import { Home } from './stories/Patients/Home'
import TeamSettings from './stories/ProviderSettings/TeamSettings'
import ReportLabs from './stories/Reports/ReportLabs'
import { ReportType } from './stories/Reports/types'
import CalendarSettings from './stories/Scheduling/CalendarSettings'
import SchedulingWrapper from './stories/Scheduling/SchedulingWrapper'
import TimezoneSettings from './stories/Scheduling/TimezoneSettings'
import Erx from './v2/erx/views'
import { Editor as NotesV2Editor } from './v2/notes/views/Editor/Editor'

const ReportPage = lazy(() => import('./stories/Reports/ReportPage'))

export default function Routes({
  homepageData,
  loggedInUserCognitoId,
  setHealthGorillaUserNameApp,
  ...props
}) {
  const config = globalConfig.get()
  const [userId, setUserId] = useState(null)
  const [appUserId, setAppUserId] = useState(null) // workaround to problem that userId gets overwritten. e.g. 'offlineContext_cognitoIdentityId'
  // this has to stay in to not break patient.js
  const [healthGorillaUserName, setHealthGorillaUserName] = useState('')
  const { isAuthenticated, shouldReload } = useAppContext()
  const { pathname } = useLocation()

  const { advancedmd, claims, claimCreationV2 } = useFeatureFlags()

  function configureFreshpaint() {
    window?.freshpaint?.addEventProperties({
      production: config.isProd,
      devEnvironment: config.ENV,
    })
    window?.freshpaint?.page()
  }

  function setSentryIdentity(providerId) {
    Sentry.setUser({ id: providerId })
  }

  useEffect(() => {
    ;(async () => {
      configureFreshpaint()

      if (!isAuthenticated) {
        return
      }

      try {
        const provider = await getCognitoUser()
        setSentryIdentity(provider.id)
        if (!provider) {
          console.error('User is not confirmed, provider is null')
        }
        setAppUserId(provider.id)
        setUserId(homepageData.providerId) // localstack sets to 'offlineContext_cognitoIdentityId'
        setHealthGorillaUserName(homepageData.healthGorillaUserName ?? '')
        setHealthGorillaUserNameApp(homepageData.healthGorillaUserName ?? '')
      } catch (e) {
        console.error('error gathering provider ID for sendbird', e)
      }
    })()
  }, [isAuthenticated])

  useAppReload({
    shouldReload,
    currentPathname: pathname,
  })

  return (
    <PageSuspense>
      <Switch>
        <AuthenticatedRoute exact path="/">
          <Home homePageData={homepageData} teammates={props.teammates} />
        </AuthenticatedRoute>
        <Route exact path="/maintenance">
          <MaintenancePage />
        </Route>
        <UnauthenticatedRoute exact path="/login">
          <Login />
        </UnauthenticatedRoute>
        <AuthenticatedRoute exact path="/scheduling">
          <SchedulingWrapper
            isSidebarVisible={props.isSideBarVisible}
            clinicId={appUserId}
          />
        </AuthenticatedRoute>
        <Route exact path="/erx">
          <Erx
            erxMessagesCount={props.erxMessagesCount}
            erxReportsCount={props.erxReportsCount}
            erxNotificationCount={props.erxNotificationCount}
          />
        </Route>
        <UnauthenticatedRoute exact path="/login/reset">
          <ResetPassword />
        </UnauthenticatedRoute>
        <UnauthenticatedOnlyRoute exact path="/activate">
          <ActivateUser />
        </UnauthenticatedOnlyRoute>
        <UnauthenticatedOnlyRoute exact path="/activate/practice">
          <ActivatePractice />
        </UnauthenticatedOnlyRoute>
        <UnauthenticatedRoute exact path="/form/success" forceRedirect={false}>
          <PatientIntakeSuccess />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/form/:formId" forceRedirect={false}>
          <PatientIntake />
        </UnauthenticatedRoute>
        <AuthenticatedRoute exact path="/settings">
          <Settings />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/team">
          <TeamSettings
            masterProviderId={userId}
            loggedInUserCognitoId={loggedInUserCognitoId}
          />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/personal">
          <SettingsPersonal UserId={appUserId} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/availability">
          <AvailabilitySettings providerId={appUserId} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/password">
          <ChangePassword />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/location">
          <Locations />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/practice-settings">
          <PracticeSettings
            providerData={homepageData}
            updateProviderData={props.updateProviderData}
          />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/survey">
          <SurveySettings
            surveyData={homepageData?.surveyData}
            defaultSurveySettings={homepageData?.surveySettings}
          />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/scheduling">
          <CalendarSettings />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/timezone">
          <TimezoneSettings />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/appointment-settings">
          <AppointmentSettings />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/email-template">
          <EmailTemplate />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/email-notifications">
          <EmailNotificationsToggle
            providerEmail={homepageData.providerEmail}
          />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/intake">
          <Intake
            homepageData={homepageData}
            updateProviderData={props.updateProviderData}
          />
        </AuthenticatedRoute>
        <Route exact path="/settings/billing">
          <Redirect to="/settings/billing-templates" />
        </Route>
        <AuthenticatedRoute exact path="/settings/billing-templates">
          <BillingTemplates />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/billing-custom">
          <CustomBillingCode />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/payments">
          <PaymentsOnboarding />
        </AuthenticatedRoute>
        <Route exact path="/settings/payments/success">
          <PaymentsOnboardingComplete />
        </Route>
        {advancedmd && (
          <AuthenticatedRoute exact path="/settings/advanced-md">
            <AdvancedMD />
          </AuthenticatedRoute>
        )}
        <AuthenticatedRoute exact path="/patient/progress">
          <PatientProgress healthGorillaUserName={healthGorillaUserName} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/patient/profile">
          <PatientProfile healthGorillaUserName={healthGorillaUserName} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/billing/clinic">
          <ClinicBillingSummary providerId={userId} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/invoice">
          <InvoiceForm primaryProviderId={userId} userId={userId} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/patient/documents">
          <Documents
            primaryProviderId={userId}
            healthGorillaUserName={healthGorillaUserName}
          />
        </AuthenticatedRoute>

        <AuthenticatedRoute exact path="/patient/clinical-notes/v2/:noteId">
          <NotesV2Editor
            providerId={userId}
            healthGorillaUserName={healthGorillaUserName}
          />
        </AuthenticatedRoute>

        <AuthenticatedRoute exact path="/patient/clinical-notes">
          <PatientClinicalNotes healthGorillaUserName={healthGorillaUserName} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/patient/clinical-notes/new">
          <CreateClinicalNote healthGorillaUserName={healthGorillaUserName} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/patient/clinical-notes/:noteId">
          <ViewEditNotePage healthGorillaUserName={healthGorillaUserName} />
        </AuthenticatedRoute>

        <AuthenticatedRoute exact path="/patient/medication">
          <Medication healthGorillaUserName={healthGorillaUserName} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/patient/journal">
          <PatientJournal healthGorillaUserName={healthGorillaUserName} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/patient/messages">
          <PatientMessages
            providerId={appUserId}
            healthGorillaUserName={healthGorillaUserName}
          />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/patient/settings">
          <PatientSettingsPage healthGorillaUserName={healthGorillaUserName} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/patient/billing">
          <Billing
            providerId={userId}
            healthGorillaUserName={healthGorillaUserName}
          />
        </AuthenticatedRoute>
        {claims && (
          <AuthenticatedRoute exact path="/patient/billing/claim-create">
            {claimCreationV2 ? (
              <ClaimCreationV2
                healthGorillaUserName={healthGorillaUserName}
                practiceData={homepageData.clinicData}
              />
            ) : (
              <ClaimCreation
                healthGorillaUserName={healthGorillaUserName}
                practiceData={homepageData.clinicData}
              />
            )}
          </AuthenticatedRoute>
        )}
        <AuthenticatedRoute exact path="/reports/notes">
          <ReportPage reportType={ReportType.NOTES} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/reports/appointments">
          <ReportPage reportType={ReportType.APPOINTMENTS} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/reports/surveys">
          <ReportPage reportType={ReportType.SURVEYS} />
        </AuthenticatedRoute>
        {healthGorillaUserName && (
          <>
            <AuthenticatedRoute exact path="/reports/labs">
              <ReportLabs providerId={userId} />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/patient/labs">
              <Labs healthGorillaUserName={healthGorillaUserName} />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/laborder/final">
              <LabOrderFinalStatus />
            </AuthenticatedRoute>
          </>
        )}
        <Route exact path="/error/logout">
          <NotAllowedWhileLoggedIn />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </PageSuspense>
  )
}
