import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined'
import { Divider, Form } from 'antd'
import get from 'lodash/get'

import {
  getTeammateData,
  saveCareTeam,
  sendPatientInvite,
} from '../../api/api-lib'
import { FormSectionKey, OsmindIntakeFormTitle } from '../../api/intakeForms'
import { onError } from '../../libs/errorLib'
import {
  ProviderSideIntakeFormEvents,
  SendIntakeFormWithPatientInviteEvent,
  trackIntakeFormEvent,
} from '../../libs/freshpaint/intakeFormEvents'
import { notification } from '../../libs/notificationLib'
import { legalNameRegex } from '../../libs/regex'
import {
  Button as AntDButton,
  Modal as AntDModal,
  MultiSelect as AntDMultiSelect,
  Select as AntDSelect,
  Steps as AntDSteps,
  Checkbox,
  Input,
  Row,
  Typography,
  toggleConfirmModal,
} from '../../stories/BaseComponents'
import FormItem from '../../stories/BaseComponents/FormItem'
import {
  OptionInput,
  OptionOutput,
} from '../../stories/BaseComponents/MultiSelect'
import { DefaultOptionType } from '../../stories/BaseComponents/Select'
import PhoneNumberInput from '../../stories/BaseComponents/SpecialInputFields/PhoneNumberInput'
import IntakeForms, { IntakeFormHandler } from './IntakeForms/IntakeForms'

import './PatientInvite.scss'
import styles from './_shared.module.scss'

const { Title } = Typography

interface FormFields {
  NewPatientFirstName: string
  NewPatientMiddleName: string
  NewPatientLastName: string
  NewPatientEmail: string
  NewPatientEmailConfirm: string
  PhoneNumber: string
  dailyMoodSurvey: boolean
  primaryProvider: string
  formCustomOneWayIntakes?: string[]
  formCustomTwoWayIntakes?: string[]
  formSections?: FormSectionKey[]
  careTeam: string[]
  hasTwilioConsent: boolean
}

interface Props {
  providerData: unknown
  handleUpdatePatientList?: () => void
  storybook?: boolean
}

interface TeammateType {
  name?: string
  email: string
  cognitoId: string
  credential?: string
}

const PatientInvite = (props: Props) => {
  const intakeFormRef = useRef<IntakeFormHandler>(null)
  const [form] = Form.useForm<FormFields>()
  const [practiceName, setPracticeName] = useState<string>('')
  const [providerEmail, setProviderEmail] = useState<string>('')
  const [show, setShow] = useState<boolean>(false)
  const [isSendIntakeForms, setSendIntakeForms] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [teammates, setTeammates] = useState<TeammateType[]>([])
  const [primaryProv, setPrimaryProv] = useState<string | undefined>('')
  const [careTeammates, setCareTeammates] = useState<string[] | undefined>([])
  const [primaryProvOptions, setPrimaryProvOptions] = useState<
    DefaultOptionType[]
  >([])
  const [careTeamOptions, setCareTeamOptions] = useState<OptionInput[]>([])
  const [currentStep, setCurrentStep] = useState<number>(0)

  const initialValues: FormFields = {
    NewPatientFirstName: '',
    NewPatientMiddleName: '',
    NewPatientLastName: '',
    NewPatientEmail: '',
    NewPatientEmailConfirm: '',
    PhoneNumber: '',
    dailyMoodSurvey: false,
    primaryProvider: '',
    careTeam: [],
    hasTwilioConsent: false,
  }

  useEffect(() => {
    if (props.storybook) {
      return
    }
    const practiceName = get(props.providerData, 'clinicData.PracticeName', '')
    const providerEmail = get(
      props.providerData,
      'clinicData.ProviderEmail',
      ''
    )
    if (practiceName && providerEmail) {
      setPracticeName(practiceName)
      setProviderEmail(providerEmail)
    }
  }, [props.providerData])

  useEffect(() => {
    const options = teammates
      .filter((tm) => !careTeammates?.includes(tm.cognitoId))
      .map((tm: any) => {
        let providerFullName
        if (tm.name && tm.credential) {
          providerFullName = `${tm.name}, ${tm.credential}`
        } else if (tm.credential) {
          providerFullName = `${tm.email}, ${tm.credential}`
        } else if (tm.name) {
          providerFullName = `${tm.name}`
        } else {
          providerFullName = tm.email
        }
        return {
          label: providerFullName,
          value: tm.cognitoId,
        }
      })
    setPrimaryProvOptions(options)
  }, [teammates, careTeammates])

  useEffect(() => {
    const options = teammates
      .filter((tm) => tm.cognitoId != primaryProv)
      .map((tm: any) => {
        let providerFullName
        if (tm.name && tm.credential) {
          providerFullName = `${tm.name}, ${tm.credential}`
        } else if (tm.credential) {
          providerFullName = `${tm.email}, ${tm.credential}`
        } else if (tm.name) {
          providerFullName = `${tm.name}`
        } else {
          providerFullName = tm.email
        }
        return {
          label: providerFullName,
          value: tm.cognitoId,
        }
      })
    setCareTeamOptions(options)
  }, [teammates, primaryProv])

  function handleCareTeamChange(value: OptionOutput) {
    form.setFieldsValue({ careTeam: value })
    setCareTeammates(value)
  }

  function handlePrimaryProviderChange(value: any) {
    form.setFieldsValue({ primaryProvider: value })
    setPrimaryProv(value)
  }

  const getTeammateInfo = useCallback(async () => {
    try {
      const allTeam = await getTeammateData()
      const activeTeam = allTeam.filter((at: any) => at.isDeactivated === false)
      setTeammates(activeTeam)
    } catch (e) {
      console.error('Error when feching Teammates', e)
    }
  }, [])

  const activeTeamStorybook = [
    {
      name: 'Charles Lexinton',
      cognitoId: 'storybook-Cognito-ID-1',
      credential: 'Dr',
      email: 'charles.lexinton@amil.com',
    },
    {
      name: 'Susan Herst',
      cognitoId: 'storybook-Cognito-ID-2',
      credential: 'PhD',
      email: 'susan.herst@amil.com',
    },
    {
      name: 'Mary Ferguson',
      cognitoId: 'storybook-Cognito-ID-3',
      credential: 'Dr',
      email: 'mary.ferguson@amil.com',
    },
    {
      name: 'Joseph Grey',
      cognitoId: 'storybook-Cognito-ID',
      credential: 'PhD',
      email: 'joseph.grey@amil.com',
    },
  ]

  useEffect(() => {
    if (props.storybook) {
      setTeammates(activeTeamStorybook)
      return
    }
    getTeammateInfo()
  }, [getTeammateInfo])

  const handleShow = () => {
    setShow(true)
  }

  const handleStepChange = (current: number) => {
    setCurrentStep(current)
  }

  const nextStep = async (next: number) => {
    try {
      await form.validateFields()
      setCurrentStep(next)
    } catch (e) {
      console.debug('validation failed')
    }
  }

  const handleOk = useCallback(async () => {
    if (props.storybook) {
      setCurrentStep(0)
      alert('Validation and then saving new patient...')
      setShow(false)
      form.resetFields()
      return
    }

    let createPatientFrespaintEvent: SendIntakeFormWithPatientInviteEvent = {
      osmindIntakeId: '',
      osmindIntakeName: OsmindIntakeFormTitle,
      osmindIntakeFormSections: [],
      hellosignFormNames: [],
      sentFormTypes: [],
      providerId: get(props.providerData, 'loggedInProviderId', ''),
      patientId: '',
    }
    let values: any

    try {
      values = await form.validateFields()
    } catch (e) {
      console.debug('Form is invalid')
      return setCurrentStep(0)
    }

    setLoading(true)

    const { NewPatientEmail, NewPatientFirstName, NewPatientLastName } =
      form.getFieldsValue()

    const patientName = `${NewPatientFirstName} ${NewPatientLastName}`

    const formattedPhoneNumber = values.PhoneNumber.replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll('-', '')
      .replaceAll(' ', '')
      .trim()

    let cognitoId: string | undefined
    let publicId: string | undefined

    try {
      // 1. Send patient invite
      const {
        InvalidRequestDetails: invalidRequestDetails,
        InvalidRequest: invalidRequest,
        PublicId,
        CognitoId,
      } = await sendPatientInvite({
        NewPatientEmail: values.NewPatientEmail,
        NewPatientFirstName: values.NewPatientFirstName,
        NewPatientMiddleName: values.NewPatientMiddleName,
        NewPatientLastName: values.NewPatientLastName,
        PhoneNumber: formattedPhoneNumber,
        IncludeDailyMoodScores: values.dailyMoodSurvey,
      })
      cognitoId = CognitoId
      publicId = PublicId

      // status 400s from Amplify API are real finicky, so we send as a success
      if (invalidRequestDetails === 'PatientAlreadyExists') {
        notification(
          `The email ${NewPatientEmail} already exists within this same practice. Please try again with another email.`,
          'failure'
        )
        setLoading(false)
        return
      } else if (invalidRequestDetails === 'ProviderAccountNotConfirmed') {
        notification(
          'Provider account must be confirmed to invite patients. Please contact support@osmind.org for assistance.',
          'failure'
        )
        setLoading(false)
        return
      } else if (invalidRequest || !publicId || !cognitoId) {
        onError(
          {},
          500,
          'There was an internal error processing your request. Please email support@osmind.org.'
        )
        setLoading(false)
        return
      }
      // On patient invite success:
      createPatientFrespaintEvent.patientId = CognitoId
      setSendIntakeForms(true)
    } catch (e) {
      onError(
        {},
        500,
        'There was an internal error processing your request. Please email support@osmind.org.'
      )
      setLoading(false)
      return
    }

    if (values.careTeam.length > 0 || values.primaryProvider !== '') {
      const careTeamdata = {
        primaryProvider: values.primaryProvider
          ? values.primaryProvider
          : undefined,
        careTeam: values.careTeam,
        patientId: cognitoId,
      }
      try {
        await saveCareTeam(careTeamdata)
      } catch (e) {
        /* I give more time to the error message because if it happens will be stacked with the success message. */
        notification(
          'There was an error saving the care team. Please try again.',
          'failure',
          {
            duration: 10,
          }
        )
        console.error('Error when saving care team', e)
      }
    }

    if (intakeFormRef.current) {
      await intakeFormRef.current.sendDefaultIntakeForms(
        publicId,
        patientName,
        (res) => {
          const { osmindIntakeId, osmindIntakeName, osmindIntakeFormSections } =
            res
          createPatientFrespaintEvent = {
            ...createPatientFrespaintEvent,
            osmindIntakeId,
            osmindIntakeName,
            osmindIntakeFormSections,
            sentFormTypes: [OsmindIntakeFormTitle],
          }
        }
      )

      await intakeFormRef.current.sendCustomIntakeForms(
        NewPatientEmail,
        patientName,
        providerEmail,
        practiceName,
        publicId,
        (res) => {
          createPatientFrespaintEvent.hellosignFormNames = res
          if (createPatientFrespaintEvent.hellosignFormNames.length) {
            createPatientFrespaintEvent.sentFormTypes.push('HelloSign')
          }
        }
      )
    }

    notification(
      `You have successfully invited ${patientName} to Osmind.`,
      'success'
    )

    trackIntakeFormEvent(
      ProviderSideIntakeFormEvents.CREATED_PATIENT,
      createPatientFrespaintEvent
    )

    if (props.handleUpdatePatientList) {
      props.handleUpdatePatientList()
    }

    setLoading(false)
    setShow(false)
    setCurrentStep(0)
    form.resetFields()
  }, [props, form])

  const checkFormChanged = useCallback(() => {
    const values = form.getFieldsValue()

    if (
      values.NewPatientEmail !== '' ||
      values.NewPatientEmailConfirm !== '' ||
      values.NewPatientFirstName !== '' ||
      values.NewPatientLastName !== '' ||
      values.NewPatientMiddleName !== '' ||
      values.PhoneNumber !== '' ||
      values.dailyMoodSurvey === true ||
      values.hasTwilioConsent === true ||
      (values.formSections?.length ?? 1) > 1
    ) {
      return true
    }

    let flag = false
    Object.entries(values).some(([key, value]) => {
      if (key.includes('pdfforms-') && value === true) {
        flag = true
        return true
      }
    })

    return flag
  }, [form])

  const handleCancel = () => {
    if (!checkFormChanged() && !isSendIntakeForms && !isLoading) {
      form.resetFields()
      setCurrentStep(0)
      setShow(false)
    } else {
      toggleConfirmModal({
        title: 'Unsaved changes',
        content:
          'Are you sure you want to close this window? Any unsaved changes will be lost.',
        okText: 'Ok',
        cancelText: 'Cancel',
        cancelButtonProps: {
          type: 'default',
        },
        centered: true,
        okButtonProps: {
          type: 'primary',
          loading: isLoading,
        },
        onOk: () => {
          form.resetFields()
          setCurrentStep(0)
          setShow(false)
        },
      })
    }
  }

  const validateName = () => ({
    validator(_: any, value: string) {
      if (!value || legalNameRegex.test(value)) return Promise.resolve()

      const errorMessage =
        'Only alphanumeric characters, hyphens, and apostrophes are supported'
      return Promise.reject(new Error(errorMessage))
    },
  })

  const patientInfoContent = (
    <div className="top-margin">
      <div className="light-blue-box">
        The patient&apos;s account will be created, and they will receive an
        email and text message containing information to join Osmind with you as
        their provider.
      </div>
      <FormItem
        id="NewPatientFirstName"
        name="NewPatientFirstName"
        label="Patient first name"
        rules={[
          {
            required: true,
            message: "Please input patient's first name",
            type: 'string',
            whitespace: false,
          },
          validateName,
        ]}
        validateTrigger="onBlur"
      >
        <Input placeholder="Patient first name" />
      </FormItem>

      <FormItem
        id="NewPatientMiddleName"
        name="NewPatientMiddleName"
        label="Patient middle name"
        rules={[
          {
            required: false,
            message: "Please input patient's middle name",
            type: 'string',
            whitespace: false,
          },
          validateName,
        ]}
        validateTrigger="onBlur"
      >
        <Input placeholder="Patient middle name" />
      </FormItem>

      <FormItem
        id="NewPatientLastName"
        name="NewPatientLastName"
        label="Patient last name"
        rules={[
          {
            required: true,
            message: "Please input patient's last name",
            type: 'string',
            whitespace: false,
          },
          validateName,
        ]}
        validateTrigger="onBlur"
      >
        <Input placeholder="Patient last name" />
      </FormItem>

      <FormItem
        id="NewPatientEmail"
        name="NewPatientEmail"
        label="Patient email"
        rules={[
          {
            required: true,
            message: "Please input a valid patient's email",
            type: 'email',
            whitespace: false,
          },
        ]}
        validateTrigger="onBlur"
      >
        <Input placeholder="Patient email" />
      </FormItem>

      <FormItem
        id="NewPatientEmailConfirm"
        name="NewPatientEmailConfirm"
        label="Confirm email"
        rules={[
          {
            required: true,
            message: 'Please input a valid matching email',
            type: 'email',
            whitespace: false,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('NewPatientEmail') === value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error('The two emails that you entered do not match!')
              )
            },
          }),
        ]}
        validateTrigger="onBlur"
      >
        <Input placeholder="Confirm email" />
      </FormItem>

      <PhoneNumberInput form={form} required={true} name="PhoneNumber" />
      <FormItem
        id="hasTwilioConsent"
        name="hasTwilioConsent"
        label="Consent"
        valuePropName="checked"
        rules={[
          {
            required: true,
            message:
              'Please mark that patient has consented to communication from your practice via email and SMS text message',
            type: 'boolean',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || !getFieldValue('hasTwilioConsent')) {
                return Promise.reject(
                  'Please mark that patient has consented to communication from your practice via email and SMS text message'
                )
              }
              return Promise.resolve()
            },
          }),
        ]}
        validateTrigger="onBlur"
      >
        <Checkbox>
          Patient has consented to communication from your practice via email
          and SMS text message
        </Checkbox>
      </FormItem>
    </div>
  )

  const surveysFormsContent = useMemo(
    () => (
      <div className="top-margin">
        <FormItem
          id="dailyMoodSurvey"
          name="dailyMoodSurvey"
          label={<Title level={5}>Surveys</Title>}
          valuePropName="checked"
          extra={
            <Row className={styles.subtextGeneralInfo}>
              By default, patients will not receive daily mood prompts.
            </Row>
          }
        >
          <Checkbox>Enable daily mood surveys</Checkbox>
        </FormItem>
        <Divider className={styles.intakeSectionDivider} />
        <IntakeForms
          form={form}
          providerData={props.providerData}
          ref={intakeFormRef}
        />
      </div>
    ),
    [form, show]
  )

  const careTeamContent = (
    <div className="top-margin">
      <span>
        Select users that you’d like to be included in this care team:
      </span>
      <div>
        <FormItem name="primaryProvider">
          <div className="div-margin-top">
            {'Primary clinician '}{' '}
            <span className="optional-text">(optional)</span>
          </div>
          <AntDSelect
            allowClear={true}
            onChange={(value) => handlePrimaryProviderChange(value)}
            placeholder="Assign primary clinician"
            options={primaryProvOptions}
          />
        </FormItem>
        <FormItem name="careTeam">
          <div className="div-margin-top">
            {'Additional care team members '}
            <span className="optional-text">(optional)</span>
          </div>
          <AntDMultiSelect
            handleChange={(value) => handleCareTeamChange(value)}
            placeholder="Assign care team"
            maxTagCount="responsive"
            options={careTeamOptions}
          />
        </FormItem>
      </div>
    </div>
  )

  const steps = [
    {
      step: 1,
      title: 'Patient Info',
      content: patientInfoContent,
    },
    {
      step: 2,
      title: 'Surveys and Forms',
      content: surveysFormsContent,
    },
    {
      step: 3,
      title: 'Care Team',
      content: careTeamContent,
    },
  ]

  // this form contains fields for inviting a new patient, as well the SendIntakeForms component as one of it's form groups,
  // after a patient is successfully invited and we want to move on to sending intake forms, we want to hide all parts of this modal
  // except for the SendIntakeForms component so that it can then run through it's intake form submission flow
  return (
    <span>
      <AntDButton
        size="middle"
        icon={<PlusOutlined />}
        onClick={handleShow}
        type="primary"
      >
        Add Patient
      </AntDButton>
      {/* dont allow onHide function if intake forms are sending or if patient is being invited */}
      <AntDModal
        title="Add new patient"
        visible={show}
        onCancel={handleCancel}
        bodyStyle={{ height: 1100 }}
        wrapClassName="fixed-height"
        footer={[
          <div key="add-new-patient-footer" className="antd-modal-footer">
            {currentStep > 0 && (
              <AntDButton
                className="ant-button-item"
                onClick={() => setCurrentStep(currentStep - 1)}
              >
                Back
              </AntDButton>
            )}
            <AntDButton
              className="ant-button-item"
              onClick={() => {
                handleCancel()
              }}
            >
              Cancel
            </AntDButton>
            {currentStep < steps.length - 1 && (
              <AntDButton
                type="primary"
                onClick={() => nextStep(currentStep + 1)}
              >
                Next
              </AntDButton>
            )}
            {currentStep === steps.length - 1 && (
              <AntDButton type="primary" onClick={handleOk} loading={isLoading}>
                Done
              </AntDButton>
            )}
          </div>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          className="invite-patient-form"
        >
          <AntDSteps
            steps={steps}
            currentStep={currentStep}
            onChange={handleStepChange}
            handleSubmit={handleOk}
            cancelCallback={handleCancel}
            isLoading={isLoading}
            size="small"
          />
        </Form>
      </AntDModal>
    </span>
  )
}

export default PatientInvite
