// Import react
import React, { useMemo, useState } from 'react'

// Import libs
import moment from 'moment'
// Import surveys
// Import styles
import { Button, Col, Modal, Row } from 'react-bootstrap'

import { deleteQuestionnaireSubmission } from '../../api/api-lib'
import { onError } from '../../libs/errorLib'
import { notification } from '../../libs/notificationLib'
import { toggleConfirmModal } from '../../stories/BaseComponents/Modal'

import '../ChartsLib.scss'

// React component function for the answer table
// returns answer table based on questionnaire given ("phq", "pcl", etc)
export default function Answers({
  handleApiChange,
  type,
  submission,
  onClose,
  loggedInProviderId,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const questionnaireSubmissionAnswers = useMemo(() => {
    return submission.Answers.map((answer, index) => {
      const answerStep = answer.QuestionnaireStep
      const selectedOption = answer.QuestionnaireStepOption

      let submissionAnswer

      if (answerStep.Type === 'SELECT') {
        submissionAnswer = selectedOption?.Title ?? 'Answer not available'
      } else if (answerStep.Type === 'TEXT') {
        submissionAnswer = answer.Text
      } else {
        submissionAnswer = answer.Score ?? submission.Score
      }

      return (
        <div
          key={`question-${index}`}
          className="question"
          data-testid={`answer-container-${answer.Id}`}
        >
          <div
            id={`questionnaireQuestions${type}${index + 1}`}
            className="questionnaire-question"
          >
            {answerStep.Title}
          </div>
          <div
            id={`questionnaireAnswers${type}${index + 1}`}
            className="questionnaire-answer"
          >
            {submissionAnswer}
            {answerStep.AdditionalInputLabel && (
              <div className="questionnaire-answer-additional-text">
                <span className="questionnaire-question">
                  {answerStep.AdditionalInputLabel}
                </span>{' '}
                {answer.Text}
              </div>
            )}
          </div>
        </div>
      )
    })
  }, [submission, type])

  async function handleDeleteQuestionnaireSubmission(id, type) {
    setIsLoading(true)
    try {
      await deleteQuestionnaireSubmission(id, type)
      handleApiChange()
      notification('Successfully deleted questionnaire submission.', 'success')
    } catch (e) {
      console.error('Error when deleting a questionnaire submission', e)
      onError(new Error('Unable to delete questionnaire submission'))
    } finally {
      onClose()
      setIsLoading(false)
    }
  }

  async function showDeleteModal(id, type) {
    toggleConfirmModal({
      title: 'Delete this submission?',
      content:
        'You will not be able to recover this submission after deleting.',
      okText: 'Delete submission',
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'default',
      },
      centered: true,
      zIndex: 2000,
      okButtonProps: {
        type: 'primary',
        loading: isLoading,
      },
      onOk: () => {
        handleDeleteQuestionnaireSubmission(id, type)
      },
    })
  }

  return (
    <Modal size="lg" show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Answer history for {moment.utc(submission.Date).format('LL')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ fontSize: 20, marginRight: 24, textAlign: 'right' }}>
          <span className="info">Score:</span>&nbsp;{submission.Score}
        </div>
        <div className="questionnaire-title">
          {submission.Questionnaire.Name}
        </div>
        {questionnaireSubmissionAnswers}
        <hr />
        <Row>
          {submission.CreatedByProvider === loggedInProviderId && (
            <Col>
              <Button
                className="button-delete"
                id="questionnaireDeleteButton"
                bsPrefix="button-close"
                onClick={() =>
                  showDeleteModal(
                    submission.Id,
                    submission.Questionnaire.ScoreTypeEnum
                  )
                }
              >
                Delete
              </Button>
            </Col>
          )}
          <Col>
            <Button
              id="questionnaireCloseButton"
              bsPrefix="button-close"
              onClick={onClose}
            >
              Close
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}
