import { MutationStatus, QueryStatus } from '@tanstack/react-query'
import z from 'zod'

import { ChangePayersList } from '../../../api/intakeForms'
import {
  CLAIM_INSURANCE_TYPE_TO_FILING_CODE,
  CLAIM_STATUS_DISPLAY,
  MEDICARE_CLAIM_FILING_CODES,
  RELATIONSHIP,
} from './constants'
import { AddressInput, ClaimFormStatus, ClaimStatus } from './types'

export const getClaimStatusDisplay = (
  claimStatus: ClaimStatus | undefined,
  claimsOutstanding?: number
) => {
  if (claimStatus === ClaimStatus.ADJUDICATED && claimsOutstanding === 0) {
    return CLAIM_STATUS_DISPLAY.SETTLED
  }
  if (claimStatus && Object.keys(CLAIM_STATUS_DISPLAY).includes(claimStatus)) {
    return CLAIM_STATUS_DISPLAY[claimStatus]
  }
  return CLAIM_STATUS_DISPLAY.UNKNOWN
}

export const isFormBusy = (formStatus: ClaimFormStatus) =>
  !!formStatus &&
  [
    'INITIAL_LOADING',
    'SAVING_CLAIM',
    'DELETING_CLAIM',
    'CANCELLING_CLAIM',
    'SAVING_CLAIM_MEMO',
  ].includes(formStatus)
export const isFormInitialLoading = (formStatus: ClaimFormStatus) =>
  formStatus === 'INITIAL_LOADING'
export const isFormSaving = (formStatus: ClaimFormStatus) =>
  formStatus === 'SAVING_CLAIM'
export const isFormDeleting = (formStatus: ClaimFormStatus) =>
  formStatus === 'DELETING_CLAIM'
export const isFormSavingMemo = (formStatus: ClaimFormStatus) =>
  formStatus === 'SAVING_CLAIM_MEMO'
export const isLoadingStatus = (
  networkStatus: QueryStatus | MutationStatus
): boolean => networkStatus === 'loading'
export const isErrorStatus = (
  networkStatus: QueryStatus | MutationStatus
): boolean => networkStatus === 'error'
export const isSuccessStatus = (
  networkStatus: QueryStatus | MutationStatus
): boolean => networkStatus === 'success'

export const getClaimFilingCodeForPayer = (
  payersByName: { [name: string]: ChangePayersList },
  payerName: string
) => {
  const type = payersByName[payerName]?.claimInsuranceType
  if (type) {
    return CLAIM_INSURANCE_TYPE_TO_FILING_CODE[type] ?? null
  }
  return null
}

export const isSubscriberSelf = (subscriberRelationship: string | null) =>
  subscriberRelationship === RELATIONSHIP.SELF

export const isMedicare = (claimFilingCode: string | null) =>
  !!(claimFilingCode && MEDICARE_CLAIM_FILING_CODES.includes(claimFilingCode))

// address1 input value -> address1 display
export const getAddressInputDisplay = (addressObj: AddressInput): string => {
  return [
    addressObj.Address1,
    addressObj.City,
    addressObj.State,
    addressObj.Zipcode,
  ]
    .filter((el) => el)
    .join(', ')
}

export const isClaimPresubmit = (claimStatus: ClaimStatus) =>
  [ClaimStatus.DRAFT, ClaimStatus.CHANGE_ERROR].includes(claimStatus)

export const isClaimCancellable = (claimStatus: ClaimStatus) =>
  [ClaimStatus.SUBMITTED, ClaimStatus.ADJUDICATED].includes(claimStatus)

export const isClaimNotSubmitted = (claimStatus: ClaimStatus) =>
  [ClaimStatus.DRAFT, ClaimStatus.CHANGE_ERROR, ClaimStatus.CANCELED].includes(
    claimStatus
  )

export const formatPhoneNumber = (val: string) =>
  val.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
export const formatEin = (val: string) => val.replace(/(\d{2})(\d{7})/, '$1-$2')

export const validatorFactory = (schema: z.Schema) => (val: any) => {
  const { error } = schema.safeParse(val)
  const errorMessage = error?.issues?.[0].message
  return errorMessage
}
