import { API } from 'aws-amplify'

import { queryParamsToQueryStringParams } from '../../libs/utils'
import {
  CreateDynamicBlockOptions,
  DynamicNoteBlock,
  UpdateDynamicBlockOptions,
} from './Components/Blocks/Core/Block/DynamicBlockTypes'
import { Note, NoteDetailFromApi, SignatureRequest } from './types'

const CLINICAL_NOTES = 'clinical-notes'

export const createNote = async ({
  patientId,
  fromLast = false,
  saltNoteId,
}: {
  patientId: string
  fromLast?: boolean
  saltNoteId?: string
}) => {
  return await API.post(CLINICAL_NOTES, '/notes/v2', {
    body: { patientId, copyFromLast: fromLast, saltNoteId },
  })
}

export const createNoteBlock = async ({
  noteUuid,
  blockConfig,
}: CreateDynamicBlockOptions) => {
  return await API.post(
    CLINICAL_NOTES,
    `/clinical-notes/v2/${noteUuid}/blocks`,
    {
      body: blockConfig,
    }
  )
}

export const updateNoteBlock = async ({
  noteUuid,
  blockUuid,
  blockConfig,
}: UpdateDynamicBlockOptions) => {
  return await API.put(
    CLINICAL_NOTES,
    `/clinical-notes/v2/${noteUuid}/blocks/${blockUuid}`,
    {
      body: blockConfig,
    }
  )
}

export const fetchNoteBlock = async ({
  noteUuid,
  blockUuid,
}: {
  noteUuid: string
  blockUuid: string
}): Promise<DynamicNoteBlock> => {
  return await API.get(
    CLINICAL_NOTES,
    `/clinical-notes/v2/${noteUuid}/blocks/${blockUuid}`,
    {}
  )
}

export interface FetchNoteQueryParams {
  includeInsuranceClaims?: boolean
}

export const fetchNote = async (
  noteId: string | number,
  queryParams: FetchNoteQueryParams = {}
) => {
  return await API.get(CLINICAL_NOTES, `/notes/v2/${noteId}`, {
    queryStringParameters: queryParamsToQueryStringParams(queryParams),
  })
}

export const updateNote = async (
  noteId: string | number,
  body: Partial<Note>
) => {
  return await API.put(CLINICAL_NOTES, `/notes/v2/${noteId}`, { body })
}

export const getAllPatientNotes = async (patientId: string) => {
  return await API.get(
    CLINICAL_NOTES,
    `/notes/v2/getAll?patientId=${patientId}`,
    {}
  )
}

export interface GetNoteDetailsQueryParams {
  patientId: string
  hasInvoice?: boolean
}

export const getAllPatientNoteDetails = (
  queryParams: GetNoteDetailsQueryParams
): Promise<{
  data: NoteDetailFromApi[]
}> => {
  return API.get(CLINICAL_NOTES, '/clinical-notes/note-details', {
    queryStringParameters: queryParamsToQueryStringParams(queryParams),
  })
}

export const deleteNote = async (noteId: string | number) => {
  return await API.del(CLINICAL_NOTES, `/notes/v2/${noteId}`, {})
}

export const signNote = async (
  noteId: string | undefined | number,
  body: SignatureRequest
) => {
  return await API.post(CLINICAL_NOTES, `/notes/v2/${noteId}/sign`, { body })
}

export const addAddendum = async (
  noteId: string | number,
  content: string
): Promise<Note> => {
  return await API.post(
    CLINICAL_NOTES,
    `/clinical-notes/v2/${noteId}/addendum`,
    {
      body: { content },
    }
  )
}
