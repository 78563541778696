// Import react
import { useState } from 'react'

// import { useFormFields } from '../../../libs/hooksLib'
import moment from 'moment'
import 'moment-timezone'
// Import components
import { Button, Col, Container, Form } from 'react-bootstrap'
import { SingleDatePicker } from 'react-dates'
// Import libs/other
import 'react-dates/initialize'

import { getCaretakerData } from '../../../api/api-lib'
import { onError } from '../../../libs/errorLib'
import { LegacyNoteTypes, NoteTypes } from '../../../shared-types'
import LegacyFormItem from '../../../stories/BaseComponents/LegacyFormItem'
import EvalNote from './ClinicalNotesComponents/EvalNote'
import IMKetamine from './ClinicalNotesComponents/IMKetamine.js'
import IVKetamine from './ClinicalNotesComponents/IVKetamine.js'
import KAP from './ClinicalNotesComponents/KAP.js'
import MedicalSOAP from './ClinicalNotesComponents/MedicalSOAP.js'
import Memo from './ClinicalNotesComponents/Memo.js'
import Spravato from './ClinicalNotesComponents/NoteType/Spravato/Spravato'
import PatientInfo from './ClinicalNotesComponents/PatientInfo'
import ProviderInfo from './ClinicalNotesComponents/ProvidersInfo.js'
import PsychotherapyNotes from './ClinicalNotesComponents/PsychotherapyNotes.js'
import NewSignature from './ClinicalNotesComponents/Sections/NewSignature'
import NoteTypeSelect from './ClinicalNotesComponents/Sections/NoteTypeSelect'
import SimpleClinicalNote from './ClinicalNotesComponents/SimpleClinicalNote.js'
import TherapySOAP from './ClinicalNotesComponents/TherapySOAP.js'

import './NewClinicalNoteForm.scss'
import 'react-dates/lib/css/_datepicker.css'

export default function NewClinicalNoteForm(props) {
  // TODO: create form validation *******
  const [focused, setFocused] = useState(null)
  const [addVitals, setAddVitals] = useState(false)
  const newNote = true
  const [addAdditionalMedication, setAddAdditionalMedication] = useState(false)
  const [saltNotAcknowledged, setSaltNotAcknowledged] = useState(props.salt)
  const [addDischargeVitals, setAddDischargeVitals] = useState(false)
  const defaultAdministrationRoutes = [
    'IV Infusion',
    'IM',
    'Oral (swallowed)',
    'Sublingual (under the tongue)',
    'Intranasal',
    'Patch',
    'Other (please specify)',
  ]

  const pregnantStatus = ['Negative', 'Positive', 'Declined']
  const catheter = ['20g', '22g', '24g', 'Other (type in)']
  const treatmentTypes = ['Induction', 'Booster']
  const { fields, handleFieldChange } = props
  const isNoteTypeSelected = fields.NoteType !== ''

  const updateSignatureField = (value) => {
    handleFieldChange({ ...fields, NewSignature: value })
  }

  function saveNewDischargeVitals() {
    setAddDischargeVitals(true)
    const newDischargeVitalSigns = {
      VitalSignsType: '',
      Time: moment().format('HH:mm'),
      BP: '',
      HR: '',
      RR: '',
      SPO2: '',
      Comments: '',
      EKG: '',
      RASSscore: '',
    }
    handleFieldChange({
      ...fields,
      SavedDischargeVitalSigns: newDischargeVitalSigns,
    })
  }

  function saveNewVitals() {
    setAddVitals(false)
    const newVitalSigns = [
      {
        VitalSignsType: '',
        Time: moment().format('HH:mm'),
        BP: '',
        HR: '',
        RR: '',
        SPO2: '',
        TemperatureUnits: '',
        Temp: '',
        Comments: '',
        EKG: '',
        RASSscore: '',
      },
    ]
    if (fields.SavedVitalSigns) {
      handleFieldChange({
        ...fields,
        SavedVitalSigns: fields.SavedVitalSigns.concat(newVitalSigns),
      })
    } else {
      handleFieldChange({ ...fields, SavedVitalSigns: newVitalSigns })
    }
  }

  async function saveNewCaretakerVitals() {
    try {
      const caretakerData = await getCaretakerData(
        props.patient.ShortPublicId,
        fields.NoteDate
      )
      setAddVitals(false)
      const newVitalSigns = caretakerData.map((vital) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { Timestamp, UTCOffset, ...rest } = vital
        const isEmpty = Object.values(rest).every((x) => x === null || x === '')
        return {
          VitalSignsType: '',
          Time: moment(vital.Timestamp, 'X')
            .utcOffset(vital.UTCOffset)
            .format('HH:mm'),
          BP:
            vital.Systolic && vital.Diastolic
              ? `${vital.Systolic}/${vital.Diastolic}`
              : '',
          HR: vital.HeartRate,
          RR: vital.RespiratoryRate,
          SPO2: vital.SPO2,
          TemperatureUnits: '',
          Temp: '',
          Comments: isEmpty ? 'No data sent from Caretaker at this time' : '',
          EKG: '',
          RASSscore: '',
        }
      })
      if (fields.SavedVitalSigns) {
        handleFieldChange({
          ...fields,
          SavedVitalSigns: fields.SavedVitalSigns.concat(newVitalSigns),
        })
      } else {
        handleFieldChange({ ...fields, SavedVitalSigns: newVitalSigns })
      }
    } catch (e) {
      onError(
        e,
        e.response.status,
        `${e.response.data.error}. Please inform your administrator.`
      )
    }
  }

  function saveNewEntries() {
    const newEntries = [
      {
        Time: moment().format('HH:mm'),
        Dose: '',
        TotalDose: '',
        Location: '',
        Notes: '',
      },
    ]
    if (fields.SavedEntries) {
      handleFieldChange({
        ...fields,
        SavedEntries: fields.SavedEntries.concat(newEntries),
      })
    } else {
      handleFieldChange({ ...fields, SavedEntries: newEntries })
    }
  }

  function saveNewEntriesKAP() {
    const newEntries = [
      {
        Time: moment().format('HH:mm'),
        Dose: '',
        TotalDose: '',
        Location: '',
        Notes: '',
        Route: '',
        RouteOther: '',
        MedicationType: '',
        MedicationTypeOther: '',
      },
    ]
    if (fields.SavedEntries) {
      handleFieldChange({
        ...fields,
        SavedEntries: fields.SavedEntries.concat(newEntries),
      })
    } else {
      handleFieldChange({ ...fields, SavedEntries: newEntries })
    }
  }

  function saveNewEntriesSpravato() {
    const newEntries = [
      {
        Type: '',
        Date: null,
        Description: '',
        Resolution: '',
      },
    ]
    if (fields.SavedEntries) {
      handleFieldChange({
        ...fields,
        SavedEntries: fields.SavedEntries.concat(newEntries),
      })
    } else {
      handleFieldChange({ ...fields, SavedEntries: newEntries })
    }
  }

  function saveNewAdditionalEntriesSpravato() {
    const newEntries = [
      {
        Type: '',
        Date: null,
        Description: '',
        Resolution: '',
      },
    ]
    if (fields.SavedAdditionalEntries) {
      handleFieldChange({
        ...fields,
        SavedAdditionalEntries:
          fields.SavedAdditionalEntries.concat(newEntries),
      })
    } else {
      handleFieldChange({ ...fields, SavedAdditionalEntries: newEntries })
    }
  }

  function saveNewMedicationSpravato() {
    const newMedication = [
      {
        Name: '',
        Dose: '',
        Time: '',
        Comments: '',
      },
    ]
    if (fields.SavedAdditionalMedications) {
      handleFieldChange({
        ...fields,
        SavedAdditionalMedications:
          fields.SavedAdditionalMedications.concat(newMedication),
      })
    } else {
      handleFieldChange({
        ...fields,
        SavedAdditionalMedications: newMedication,
      })
    }
  }

  function saveAdditionalMedicationsDissociation() {
    const newMedication = [
      {
        Name: '',
        Dose: '',
        Time: '',
        Comments: '',
      },
    ]
    if (fields.SavedAdditionalMedicationsDissociation) {
      handleFieldChange({
        ...fields,
        SavedAdditionalMedicationsDissociation:
          fields.SavedAdditionalMedicationsDissociation.concat(newMedication),
      })
    } else {
      handleFieldChange({
        ...fields,
        SavedAdditionalMedicationsDissocation: newMedication,
      })
    }
  }

  function saveAdditionalMedicationsSedation() {
    const newMedication = [
      {
        Name: '',
        Dose: '',
        Time: '',
        Comments: '',
      },
    ]
    if (fields.SavedAdditionalMedicationsSedation) {
      handleFieldChange({
        ...fields,
        SavedAdditionalMedicationsSedation:
          fields.SavedAdditionalMedicationsSedation.concat(newMedication),
      })
    } else {
      handleFieldChange({
        ...fields,
        SavedAdditionalMedicationsSedation: newMedication,
      })
    }
  }

  function saveNewAdditionalMedication() {
    setAddAdditionalMedication(false)
    const newAditionalMedication = [
      {
        AdditionalMedicationName: '',
        AdditionalMedicationOther: '',
        AdditionalMedicationAdministrationRoute: '',
        AdditionalMedicationOtherAdministrationRoute: '',
        AdditionalMedicationDosage: '',
        AdditionalMedicationUnits: '',
        AdditionalMedicationUnitsOther: '',
        AdditionalMedicationTime: moment().format('HH:mm'),
      },
    ]
    handleFieldChange({
      ...fields,
      SavedAdditionalMedications: fields.SavedAdditionalMedications.concat(
        newAditionalMedication
      ),
    })
  }

  function saveNewMedicationProgress(medName) {
    const newMedProgress = [
      {
        MedicationName: medName,
        Efficacy: '',
        Adherence: '',
        SideEffects: '',
        SideEffectDescription: '',
      },
    ]
    const auxMedProg = fields.SavedAdditionalMedications.concat(newMedProgress)
    handleFieldChange({ ...fields, SavedAdditionalMedications: auxMedProg })
  }

  function deleteVitals(index) {
    const array = fields.SavedVitalSigns.slice()
    array.splice(index, 1)
    handleFieldChange({ ...fields, SavedVitalSigns: array })
  }

  function deleteMedProgress(index) {
    const array = fields.SavedAdditionalMedications.slice()
    array.splice(index, 1)
    handleFieldChange({ ...fields, SavedAdditionalMedications: array })
  }

  function deleteEntries(index) {
    const array = fields.SavedEntries.slice()
    array.splice(index, 1)
    handleFieldChange({ ...fields, SavedEntries: array })
  }

  function deleteAdditionalEntries(index) {
    const array = fields.SavedAdditionalEntries.slice()
    array.splice(index, 1)
    handleFieldChange({ ...fields, SavedAdditionalEntries: array })
  }

  function saveEditDischargeVitalSigns(index, editVitSign) {
    handleFieldChange({ ...fields, SavedDischargeVitalSigns: editVitSign })
  }

  function saveEditEntries(index, editEntry) {
    const addEntry = fields.SavedEntries.slice()
    addEntry[index] = editEntry
    handleFieldChange({ ...fields, SavedEntries: addEntry })
  }

  function saveEditAdditionalEntries(index, editEntry) {
    const addEntry = fields.SavedAdditionalEntries.slice()
    addEntry[index] = editEntry
    handleFieldChange({ ...fields, SavedAdditionalEntries: addEntry })
  }

  const saveEditVitalSigns = (index, editVitSign) => {
    const addVitSign = fields.SavedVitalSigns
    addVitSign[index] = editVitSign
    handleFieldChange({ ...fields, SavedVitalSigns: addVitSign })
  }

  function saveStartSpravatoVitals(editVitSign) {
    let addVitSign = fields.StartSpravatoVitals
    addVitSign = editVitSign
    handleFieldChange({ ...fields, StartSpravatoVitals: addVitSign })
  }

  function saveFortyMinSpravatoVitals(editVitSign) {
    let addVitSign = fields.FortyMinSpravatoVitals
    addVitSign = editVitSign
    handleFieldChange({ ...fields, FortyMinSpravatoVitals: addVitSign })
  }

  function saveEndSpravatoVitals(editVitSign) {
    let addVitSign = fields.EndSpravatoVitals
    addVitSign = editVitSign
    handleFieldChange({ ...fields, EndSpravatoVitals: addVitSign })
  }

  function saveEditMedProgress(index, editMedProgress) {
    const addMedPro = fields.SavedAdditionalMedications
    addMedPro[index] = editMedProgress
    handleFieldChange({ ...fields, SavedAdditionalMedications: addMedPro })
  }

  function deleteAM(index) {
    const array = fields.SavedAdditionalMedications.slice()
    array.splice(index, 1)
    handleFieldChange({ ...fields, SavedAdditionalMedications: array })
  }

  function deleteAMSedation(index) {
    const array = fields.SavedAdditionalMedicationsSedation.slice()
    array.splice(index, 1)
    handleFieldChange({ ...fields, SavedAdditionalMedicationsSedation: array })
  }

  function deleteAMDissociation(index) {
    const array = fields.SavedAdditionalMedicationsDissociation.slice()
    array.splice(index, 1)
    handleFieldChange({
      ...fields,
      SavedAdditionalMedicationsDissociation: array,
    })
  }

  function deleteDischarge() {
    handleFieldChange({ ...fields, SavedDischargeVitalSigns: {} })
  }

  function saveEditAdditionalMedication(index, editAddMed) {
    const addMed = fields.SavedAdditionalMedications
    addMed[index] = editAddMed
    handleFieldChange({ ...fields, SavedAdditionalMedications: addMed })
  }

  function saveEditAdditionalMedicationSedation(index, editAddMed) {
    const addMed = fields.SavedAdditionalMedicationsSedation
    addMed[index] = editAddMed
    handleFieldChange({ ...fields, SavedAdditionalMedicationsSedation: addMed })
  }

  function saveEditAdditionalMedicationDissociation(index, editAddMed) {
    const addMed = fields.SavedAdditionalMedicationsDissociation
    addMed[index] = editAddMed
    handleFieldChange({
      ...fields,
      SavedAdditionalMedicationsDissociation: addMed,
    })
  }
  return (
    <Container className="note-page-body">
      <Form>
        {/* show disclaimer message if this note is being copied over from previous note and the warning hasn't been acknowledged yet */}
        {props.salt && saltNotAcknowledged && (
          <div className="salt-message-container">
            <i
              className="fa fa-exclamation-triangle"
              aria-hidden="true"
              style={{ fontSize: 40 }}
            />
            <div style={{ marginLeft: 24 }}>
              <div style={{ marginBottom: 12 }}>
                Information from a previous note has been imported into this
                note.
              </div>
              <li style={{ marginLeft: 20, marginBottom: 12 }}>
                Sections that have likely changed, such as timestamps, vitals,
                and signatures, are not copied over.
              </li>
              <div style={{ fontWeight: 'bold' }}>
                Please double check all imported information to avoid loss of
                information and to ensure accuracy.
              </div>
            </div>
            <div style={{ flexGrow: 1 }} />
            <Button
              variant="outline-dark"
              className="salt-acknowledge"
              onClick={() => setSaltNotAcknowledged(false)}
            >
              Acknowledge
            </Button>
          </div>
        )}
        <Form.Text className="text-muted">
          <span className="danger">*</span> Indicates required field
        </Form.Text>
        <br />
        <Form.Row>
          <Form.Group as={Col} sm={12} md={8} lg={6} controlId="Title">
            <Form.Label style={{ fontWeight: 'bold' }}>Note Title</Form.Label>
            <LegacyFormItem
              bsPrefix="form-input"
              value={fields.Title}
              onChange={(e) => {
                return handleFieldChange({ ...fields, Title: e.target.value })
              }}
            />
          </Form.Group>
          <Form.Group as={Col} sm={12} md={6} lg={3} controlId="NoteType">
            <NoteTypeSelect
              value={fields.NoteType}
              handleFieldChange={(value) =>
                handleFieldChange({ ...fields, NoteType: value })
              }
            />
          </Form.Group>
          <Form.Group as={Col} md={5} lg={2} controlId="NoteDate">
            <Form.Label style={{ fontWeight: 'bold' }} className="form-label">
              Date <span className="danger">*</span> &nbsp;
            </Form.Label>
            <SingleDatePicker
              date={fields.NoteDate} // momentPropTypes.momentObj or null
              onDateChange={(date) =>
                handleFieldChange({ ...fields, NoteDate: date })
              } // PropTypes.func.isRequired
              id="NoteDate" // PropTypes.string.isRequired,
              focused={focused} // PropTypes.bool
              onFocusChange={(focused) => setFocused(focused)}
              numberOfMonths={1}
              displayFormat="MM/DD/YYYY"
              onClose={() => setFocused(false)}
              isOutsideRange={() => false}
            />
          </Form.Group>

          {(fields.NoteType === NoteTypes.IV_KETAMINE ||
            fields.NoteType === NoteTypes.IM_KETAMINE ||
            fields.NoteType === NoteTypes.KAP) && (
            <Form.Group
              as={Col}
              sm={12}
              md={6}
              lg={3}
              controlId="TreatmentType"
            >
              <Form.Label style={{ fontWeight: 'bold' }}>
                Treatment Type (session)
              </Form.Label>
              <Form.Control
                bsPrefix="form-input"
                as="select"
                onChange={(e) =>
                  handleFieldChange({
                    ...fields,
                    TreatmentType: e.target.value,
                  })
                }
                value={fields.TreatmentType}
              >
                <option key={0} name="Select one" value="">
                  Select one
                </option>
                {treatmentTypes.map((type, index) => (
                  <option key={index + 1} name={type} value={type}>
                    {type}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
          {(fields.NoteType === NoteTypes.IV_KETAMINE ||
            fields.NoteType === NoteTypes.IM_KETAMINE ||
            fields.NoteType === NoteTypes.KAP) &&
          fields.TreatmentType === 'Induction' ? (
            <Form.Group
              as={Col}
              sm={12}
              md={6}
              lg={3}
              xl={3}
              controlId="InductionNumber"
            >
              <Form.Label style={{ fontWeight: 'bold' }}>
                Induction Number
              </Form.Label>
              <Form.Control
                bsPrefix="form-input"
                onChange={(e) =>
                  handleFieldChange({
                    ...fields,
                    InductionNumber: e.target.value,
                  })
                }
                value={fields.InductionNumber}
                as="textarea"
                rows="1"
              />
            </Form.Group>
          ) : null}
          {fields.NoteType === NoteTypes.CLINICAL_NOTE && (
            <Form.Group
              as={Col}
              sm={12}
              md={6}
              lg={3}
              controlId="ReceivedTreatment"
            >
              <Form.Label style={{ fontWeight: 'bold' }}>
                Add a procedure/treatment: <span className="danger">*</span>
              </Form.Label>
              <Form.Group>
                <Form.Check
                  bsPrefix="form-check"
                  checked={fields.ReceivedTreatment}
                  value="true"
                  id="ReceivedTreatmentYes"
                  onChange={() =>
                    handleFieldChange({ ...fields, ReceivedTreatment: true })
                  }
                  type="radio"
                  label="Yes"
                  inline
                />
                <Form.Check
                  bsPrefix="form-check"
                  checked={!fields.ReceivedTreatment}
                  value="false"
                  id="ReceivedTreatmentNo"
                  onChange={() =>
                    handleFieldChange({ ...fields, ReceivedTreatment: false })
                  }
                  type="radio"
                  label="No"
                  inline
                />
              </Form.Group>
            </Form.Group>
          )}
          {isNoteTypeSelected && (
            <ProviderInfo
              fields={fields}
              handleFieldChange={handleFieldChange}
              disabled={props.handleEdit}
            />
          )}
        </Form.Row>
        {isNoteTypeSelected && (
          <>
            <hr />
            <PatientInfo
              noteType={fields.NoteType}
              noteDate={fields.NoteDate}
              startTime={fields.StartTime}
              patient={props.patient}
              Diagnosis={props.Diagnosis}
              activeMeds={props.activeMeds}
              Allergies={props.Allergies}
              handleApiChange={props.updateCache}
            />
            <hr />
          </>
        )}
        {fields.NoteType === NoteTypes.CLINICAL_NOTE ? (
          <SimpleClinicalNote
            fields={fields}
            handleFieldChange={handleFieldChange}
            patient={props.patient}
            addAdditionalMedication={addAdditionalMedication}
            setAddAdditionalMedication={setAddAdditionalMedication}
            defaultAdministrationRoutes={defaultAdministrationRoutes}
            deleteVitals={deleteVitals}
            deleteAM={deleteAM}
            addVitals={addVitals}
            setAddVitals={setAddVitals}
          />
        ) : fields.NoteType === NoteTypes.EVALUATION_NOTE ? (
          <EvalNote
            fields={fields}
            handleFieldChange={handleFieldChange}
            patient={props.patient}
            addAdditionalMedication={addAdditionalMedication}
            setAddAdditionalMedication={setAddAdditionalMedication}
            defaultAdministrationRoutes={defaultAdministrationRoutes}
            deleteVitals={deleteVitals}
            deleteAM={deleteAM}
            addVitals={addVitals}
            setAddVitals={setAddVitals}
          />
        ) : fields.NoteType === NoteTypes.MEMO ? (
          <Memo fields={fields} handleFieldChange={handleFieldChange} />
        ) : fields.NoteType === LegacyNoteTypes.IV_KETAMINE_INFUSION ||
          fields.NoteType === NoteTypes.IV_KETAMINE ? (
          <IVKetamine
            fields={fields}
            handleFieldChange={handleFieldChange}
            handleApiChange={props.updateCache}
            Allergies={props.Allergies}
            patient={props.patient}
            Diagnosis={props.Diagnosis}
            activeMeds={props.activeMeds}
            pregnantStatus={pregnantStatus}
            saveNewEntries={saveNewEntries}
            saveEditEntries={saveEditEntries}
            deleteAM={deleteAM}
            saveEditAdditionalMedication={saveEditAdditionalMedication}
            deleteVitals={deleteVitals}
            setAddAdditionalMedication={setAddAdditionalMedication}
            saveNewAdditionalMedication={saveNewAdditionalMedication}
            saveNewVitals={saveNewVitals}
            saveNewCaretakerVitals={saveNewCaretakerVitals}
            addVitals={addVitals}
            setAddVitals={setAddVitals}
            saveEditVitalSigns={saveEditVitalSigns}
            saveNewDischargeVitals={saveNewDischargeVitals}
            addDischargeVitals={addDischargeVitals}
            setAddDischargeVitals={setAddDischargeVitals}
            saveEditDischargeVitalSigns={saveEditDischargeVitalSigns}
            deleteDischarge={deleteDischarge}
            catheter={catheter}
          />
        ) : fields.NoteType === NoteTypes.IM_KETAMINE ? (
          <IMKetamine
            fields={fields}
            handleFieldChange={handleFieldChange}
            handleApiChange={props.updateCache}
            patient={props.patient}
            Allergies={props.Allergies}
            Diagnosis={props.Diagnosis}
            activeMeds={props.activeMeds}
            pregnantStatus={pregnantStatus}
            saveNewEntries={saveNewEntries}
            saveEditEntries={saveEditEntries}
            deleteAM={deleteAM}
            setAddAdditionalMedication={setAddAdditionalMedication}
            saveEditAdditionalMedication={saveEditAdditionalMedication}
            deleteVitals={deleteVitals}
            saveNewVitals={saveNewVitals}
            saveNewCaretakerVitals={saveNewCaretakerVitals}
            addVitals={addVitals}
            setAddVitals={setAddVitals}
            saveEditVitalSigns={saveEditVitalSigns}
            saveNewDischargeVitals={saveNewDischargeVitals}
            addDischargeVitals={addDischargeVitals}
            setAddDischargeVitals={setAddDischargeVitals}
            saveEditDischargeVitalSigns={saveEditDischargeVitalSigns}
            deleteDischarge={deleteDischarge}
            deleteEntries={deleteEntries}
          />
        ) : fields.NoteType === NoteTypes.KAP ? (
          <KAP
            fields={fields}
            handleFieldChange={handleFieldChange}
            patient={props.patient}
            handleApiChange={props.updateCache}
            Allergies={props.Allergies}
            Diagnosis={props.Diagnosis}
            activeMeds={props.activeMeds}
            pregnantStatus={pregnantStatus}
            saveNewEntriesKAP={saveNewEntriesKAP}
            saveEditEntries={saveEditEntries}
            deleteAM={deleteAM}
            setAddAdditionalMedication={setAddAdditionalMedication}
            saveEditAdditionalMedication={saveEditAdditionalMedication}
            deleteVitals={deleteVitals}
            saveNewVitals={saveNewVitals}
            saveNewCaretakerVitals={saveNewCaretakerVitals}
            addVitals={addVitals}
            setAddVitals={setAddVitals}
            saveEditVitalSigns={saveEditVitalSigns}
            saveNewDischargeVitals={saveNewDischargeVitals}
            addDischargeVitals={addDischargeVitals}
            setAddDischargeVitals={setAddDischargeVitals}
            saveEditDischargeVitalSigns={saveEditDischargeVitalSigns}
            deleteDischarge={deleteDischarge}
            deleteEntries={deleteEntries}
          />
        ) : fields.NoteType === NoteTypes.MEDICAL_SOAP ? (
          <MedicalSOAP
            fields={fields}
            handleFieldChange={handleFieldChange}
            patient={props.patient}
            Diagnosis={props.Diagnosis}
            activeMeds={props.activeMeds}
            pregnantStatus={pregnantStatus}
            deleteVitals={deleteVitals}
            saveNewVitals={saveNewVitals}
            saveNewCaretakerVitals={saveNewCaretakerVitals}
            addVitals={addVitals}
            setAddVitals={setAddVitals}
            previousNotes={props.previousNotes.filter((note) => {
              return note.NoteType === NoteTypes.MEDICAL_SOAP
            })}
            saveEditVitalSigns={saveEditVitalSigns}
            saveNewMedicationProgress={saveNewMedicationProgress}
            saveEditMedProgress={saveEditMedProgress}
            deleteMedProgress={deleteMedProgress}
          />
        ) : fields.NoteType === NoteTypes.THERAPY_SOAP ? (
          <TherapySOAP
            fields={fields}
            handleFieldChange={handleFieldChange}
            patient={props.patient}
            Diagnosis={props.Diagnosis}
            previousNotes={props.previousNotes.filter((note) => {
              return note.NoteType === NoteTypes.MEDICAL_SOAP
            })}
          />
        ) : fields.NoteType === NoteTypes.PSYCHOTHERAPY_NOTE ? (
          <PsychotherapyNotes
            fields={fields}
            handleFieldChange={handleFieldChange}
            patient={props.patient}
            Diagnosis={props.Diagnosis}
            previousKAPSOAPNotes={props.previousNotes}
            previousNotes={props.previousNotes.filter((note) => {
              return note.NoteType === NoteTypes.PSYCHOTHERAPY_NOTE
            })}
          />
        ) : fields.NoteType === NoteTypes.SPRAVATO ? (
          <Spravato
            fields={fields}
            handleFieldChange={handleFieldChange}
            patient={props.patient}
            Allergies={props.Allergies}
            Diagnosis={props.Diagnosis}
            activeMeds={props.activeMeds}
            pregnantStatus={pregnantStatus}
            deleteVitals={deleteVitals}
            saveNewVitals={saveNewVitals}
            saveNewCaretakerVitals={saveNewCaretakerVitals}
            addVitals={addVitals}
            setAddVitals={setAddVitals}
            previousNotes={props.previousNotes.filter((note) => {
              return note.NoteType === NoteTypes.SPRAVATO
            })}
            saveEditVitalSigns={saveEditVitalSigns}
            deleteEntries={deleteEntries}
            deleteAdditionalEntries={deleteAdditionalEntries}
            saveNewEntriesSpravato={saveNewEntriesSpravato}
            saveNewAdditionalEntriesSpravato={saveNewAdditionalEntriesSpravato}
            saveEditEntries={saveEditEntries}
            saveEditAdditionalEntries={saveEditAdditionalEntries}
            saveNewMedicationSpravato={saveNewMedicationSpravato}
            saveAdditionalMedicationsDissociation={
              saveAdditionalMedicationsDissociation
            }
            saveAdditionalMedicationsSedation={
              saveAdditionalMedicationsSedation
            }
            saveEditAdditionalMedication={saveEditAdditionalMedication}
            saveEditAdditionalMedicationDissociation={
              saveEditAdditionalMedicationDissociation
            }
            saveEditAdditionalMedicationSedation={
              saveEditAdditionalMedicationSedation
            }
            deleteAM={deleteAM}
            deleteAMSedation={deleteAMSedation}
            deleteAMDissociation={deleteAMDissociation}
            saveStartSpravatoVitals={saveStartSpravatoVitals}
            saveFortyMinSpravatoVitals={saveFortyMinSpravatoVitals}
            saveEndSpravatoVitals={saveEndSpravatoVitals}
          />
        ) : null}
        {fields.NoteType !== '' ? (
          <NewSignature
            fields={fields}
            handleSave={props.handleSave}
            isValid={props.isValid}
            isSaving={props.isSaving}
            handleFieldChange={handleFieldChange}
            disabled={props.handleEdit}
            clinicInfo={props.clinicInfo}
            handleClose={props.handleClose}
            PatientId={props.patient.PatientId}
            patient={props.patient}
            NewNote={newNote}
            updateSignatureField={updateSignatureField}
            setShowSignedAndValid={props.setShowSignedAndValid}
            setShowSignedAndInvalid={props.setShowSignedAndInvalid}
          />
        ) : null}
        {fields.NoteType === NoteTypes.SPRAVATO &&
          !props.fields.Signatures?.length && (
            <>
              {props.isValid ? (
                <div style={{ width: '100%', padding: 24, marginBottom: 12 }}>
                  <div style={{ marginBottom: 0 }}>
                    {' '}
                    <span>
                      <i
                        className="fa fa-check text-success"
                        aria-hidden="true"
                        style={{ fontSize: 40 }}
                      />
                    </span>
                    The fields required for the REMS Patient Monitoring form
                    have been completed. Signing the note will automatically
                    submit the REMS form.
                  </div>
                </div>
              ) : (
                <div
                  style={{ width: '100%', padding: 20 }}
                  className="salt-message-container"
                >
                  <i
                    className="fa fa-exclamation-triangle"
                    aria-hidden="true"
                    style={{ fontSize: 40 }}
                  />
                  <div style={{ marginLeft: 24 }}>
                    <div style={{ marginBottom: 0 }}>
                      The fields required for the REMS Patient Monitoring form
                      have not been completed. Complete the highlighted fields
                      to automatically submit the REMS form.
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
      </Form>
    </Container>
  )
}
