import React, { useEffect, useMemo, useRef, useState } from 'react'

import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { useHistory } from 'react-router-dom'

import {
  convertTime24to12,
  convertTimeISOto24,
} from '../../../shared/Helpers/utils'
import CustomTimePicker from '../CustomTimePicker'
import InlineEditBase, { focusRef } from './InlineEditBase'
import {
  ActiveComponentProps,
  BaseInterface,
  OnSave,
  Validator,
} from './shared-types'

import styles from './_shared.module.scss'

export interface Props extends BaseInterface {
  onSave: OnSave<string>
  rows?: number
  size?: SizeType
  value?: string | null
  Validators?: Validator<Date | string | null>
}

/**
  Editable inline time
*/
const InlineEditTime = ({
  placeholder,
  requireConfirmation = false,
  size,
  testId,
  onSave,
  value: initialValue,
  autoFocus,
  ...props
}: Props) => {
  const history = useHistory()
  const dateRef: React.Ref<any> = useRef(null)
  const dateContainerRef: React.Ref<HTMLDivElement> = useRef(null)
  const [rendered, setRendered] = useState(false)

  const { hours, minutes, modifier } = convertTime24to12(
    convertTimeISOto24(initialValue ?? null)
  )
  const timezone =
    initialValue == null
      ? null
      : new Date(initialValue)
          .toLocaleString('en', { timeZoneName: 'short' })
          .split(' ')
          .pop()

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRendered(true)
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const ActiveTimePicker = useMemo(
    () =>
      ({ toggleSave, toggleBlur }: ActiveComponentProps) => {
        return (
          <CustomTimePicker
            className={styles.activeTimePick}
            value={convertTimeISOto24(initialValue ?? null)}
            onChange={onSave}
            disabled={false}
            inline={true}
            toggleBlur={toggleBlur}
            toggleSave={toggleSave}
            testId={testId}
          />
        )
      },
    [initialValue]
  )

  return (
    <div className={styles.refContainer} ref={dateContainerRef}>
      <InlineEditBase
        {...props}
        activeComponent={(props) => <ActiveTimePicker {...props} />}
        value={
          initialValue == null
            ? null
            : `${hours}:${minutes} ${modifier} ${timezone}`
        }
        onActive={() =>
          focusRef(autoFocus, dateContainerRef, dateRef, rendered, () => {
            const searchParams = new URLSearchParams(location.search)
            if (searchParams.toString().indexOf('field') !== -1) {
              history.push({
                search: location.search.replace(
                  searchParams
                    .toString()
                    .slice(searchParams.toString().indexOf('field') - 1),
                  ''
                ),
              })
            }
          })
        }
        autoSave={!requireConfirmation}
        toggleActive={autoFocus}
        onSave={onSave}
        size={size}
        placeholder={placeholder}
        testId={testId}
        showActions={requireConfirmation}
      />
    </div>
  )
}

export default InlineEditTime
