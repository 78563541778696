import React from 'react'

import cx from 'classnames'
import { Button } from 'react-bootstrap'

import '../../CreateClinicalNotePage.scss'

interface CreateNoteHeaderV1Props {
  saltNote: boolean
  isSaveButtonDisabled: boolean
  handleSaveNote: () => Promise<void>
}

export const CreateNoteHeaderV1: React.FC<CreateNoteHeaderV1Props> = ({
  saltNote,
  handleSaveNote,
  isSaveButtonDisabled,
}) => {
  return (
    <div className="note-page-header">
      Create a new&nbsp; {!saltNote.toString()}
      <div className="note-title">Note</div>
      <Button
        onClick={handleSaveNote}
        className={cx('modal-save-button', {
          disabled: isSaveButtonDisabled,
        })}
        disabled={isSaveButtonDisabled}
        style={{ marginLeft: 'auto' }}
      >
        Save Note
      </Button>
    </div>
  )
}
