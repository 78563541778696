import React, { useMemo } from 'react'

import { Dropdown as AntDDropdown } from 'antd'

import { TestId } from '../../shared-types'
import Divider from './Divider'
import Menu, { MenuItem } from './Menu'

type MenuClickEventHandler = ({ key }: { key: string }) => void

export type MenuItemType = {
  key: string
  danger?: boolean
  disabled?: boolean
  text: React.ReactNode
  type?: string
  onClick?: MenuClickEventHandler
} & TestId

type DropdownProps = Partial<React.ComponentProps<typeof AntDDropdown>> & {
  items: MenuItemType[]
  onAddClick?: MenuClickEventHandler
} & TestId

const renderMenu = (
  menuItem: MenuItemType[],
  onAddClick?: MenuClickEventHandler
) => {
  return (
    <Menu onClick={(info) => onAddClick && onAddClick(info)}>
      {menuItem.map((item) =>
        item.type === 'divider' ? (
          <Divider style={{ margin: '4px 0px' }} key={item.key} />
        ) : (
          <MenuItem
            danger={item.danger}
            key={item.key}
            disabled={item.disabled}
            onClick={(e) => item.onClick && item.onClick(e)}
            data-testid={item.testId}
          >
            {item.text}
          </MenuItem>
        )
      )}
    </Menu>
  )
}

const Dropdown: React.FC<DropdownProps> = ({
  children,
  items,
  testId,
  ...props
}) => {
  const menu = useMemo(() => renderMenu(items, props.onAddClick), [items])
  return (
    <AntDDropdown data-testid={testId} {...props} overlay={menu}>
      {children}
    </AntDDropdown>
  )
}
export default Dropdown
