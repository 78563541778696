import {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { Editor } from '@tiptap/core'
import BulletList from '@tiptap/extension-bullet-list'
import Focus from '@tiptap/extension-focus'
import Heading from '@tiptap/extension-heading'
import Highlight from '@tiptap/extension-highlight'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import { Transaction } from '@tiptap/pm/state'
import { EditorContent, Extension, JSONContent, useEditor } from '@tiptap/react'
import { StarterKit } from '@tiptap/starter-kit'
import { differenceInYears } from 'date-fns'
import { isEqual } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'

import { removeDiagnosisNoteBlock } from '../../../../api/patients'
import { QUERY_PARAMS } from '../../../../libs/constants'
import { formatDate } from '../../../../libs/utils'
import {
  ClinicDataType,
  Invoice,
  Location,
  Teammate,
} from '../../../../shared-types'
import {
  convertTime24to12,
  convertTimeISOto24,
} from '../../../../shared/Helpers/utils'
import Alert from '../../../../stories/BaseComponents/Alert'
import Button from '../../../../stories/BaseComponents/Button'
import ImageWithFallback from '../../../../stories/BaseComponents/ImageWithFallback'
import { ALLOWED_HEADINGS, HISTORY_DEPTH } from '../../constants'
import { useBlockExtensions, useBlocks } from '../../hooks'
import { useCreateNoteBlock } from '../../hooks/blocks/useCreateNoteBlock'
import { Block, Note, SignatureRequest } from '../../types'
import { DynamicBlock } from '../Blocks/Core/Block/DynamicBlock'
import { BlockConfig, CustomBlockNames } from '../Blocks/types'
import { NavigationBar } from '../NavigationBar'
import { CoreData } from './CoreData'
import { EditorToolbar } from './EditorToolbar/EditorToolbar'
import { insertBlockInEditor } from './EditorToolbar/utils'
import { TrailingNode } from './Plugins/TrailingNode'
import { SignatureHeader } from './SignatureHeader'
import { Signatures } from './Signatures'
import { SignaturesPrintMode } from './SignaturesPrintMode'
import { Title } from './Title'

import './RichTextEditor.global.scss'
import styles from './RichTextEditor.module.scss'

const getDiagnosisBlocks = ({ content }: JSONContent) => {
  return (
    content?.filter(
      (block: Partial<Block>) => block?.type === 'ActiveDiagnoses'
    )?.length || 0
  )
}

export const getNameInitials = (name: string) => {
  return name
    .toUpperCase()
    .split(' ')
    .map((n) => n[0])
    .join('')
}

export type RichTextEditorProps = {
  healthGorillaUserName: string
  note: Note
  invoice: Invoice | undefined
  clinicData: ClinicDataType | undefined
  clinicLogoUrl: string | undefined
  isLoading: boolean
  isSaving: boolean
  saveError: string | null
  onChange: (content: Partial<Note>) => void
  teamOptions: Array<Teammate>
  locationOptions: Array<Location>
  onDelete: () => Promise<void>
  onCopyToNewNote: (patientId: string, sourceNoteId: string) => void
  pendingChanges?: Partial<Note>
  onSign: (body: SignatureRequest) => Promise<Partial<Note>>
  onAddAddendum: (text: string) => Promise<Partial<Note>>
  onNavigateAway: () => void
}

export const PrintableHeader = ({
  note,
  locationName,
  providerName,
}: {
  note: Note
  locationName: string
  providerName: string
}) => {
  const generateTimeText = () => {
    const { hours, minutes, modifier } = convertTime24to12(
      convertTimeISOto24(note?.startTime ?? null)
    )
    const timezone = !note?.startTime
      ? ''
      : new Date(note?.startTime)
          .toLocaleString('en', { timeZoneName: 'short' })
          .split(' ')
          .pop()

    return `${hours}:${minutes} ${modifier} ${timezone}`
  }

  return (
    <div className={styles.containerHeader}>
      <table className="header-table">
        <tr>
          <th>Patient name</th>
          <td>{note.patientName}</td>
        </tr>
        <tr>
          <th>Date of birth</th>
          <td>
            {note.patient.DateOfBirth ? (
              <>
                {formatDate({
                  format: 'MM/dd/yyyy',
                  value: note.patient.DateOfBirth,
                })}
                {note.appointmentDate &&
                  ` (Age at visit: ${differenceInYears(
                    new Date(note.appointmentDate),
                    new Date(note.patient.DateOfBirth)
                  )} years)`}
              </>
            ) : (
              'N/A'
            )}
          </td>
        </tr>
        <tr>
          <th>Date of visit</th>
          <td>
            {note.appointmentDate ? (
              <>
                {formatDate({
                  format: 'MM/dd/yyyy',
                  value: note.appointmentDate,
                })}
              </>
            ) : (
              'N/A'
            )}
          </td>
        </tr>
        <tr>
          <th>Start time</th>
          <td>{note.startTime ? generateTimeText() : 'N/A'}</td>
        </tr>
        {note.locationId ? (
          <tr>
            <th>Location</th>
            <td>{locationName}</td>
          </tr>
        ) : (
          ''
        )}
        {note?.renderingProviderId ? (
          <tr>
            <th>Rendering provider</th>
            <td>{providerName}</td>
          </tr>
        ) : (
          ''
        )}
        <tr>
          <th>Note title</th>
          <td>{note.title}</td>
        </tr>
      </table>
      {note?.firstSignedAt && (
        <SignatureHeader
          signatures={note?.signatures ?? []}
          printMode={true}
          addendums={note?.addendums ?? []}
        />
      )}
    </div>
  )
}

const CUSTOM_BLOCK_NAME_SET: Set<CustomBlockNames> = new Set([
  'ActiveMedications',
  'ActiveDiagnoses',
  'ActiveAllergies',
  'DynamicBlock',
  'TestBlock',
])

export const RichTextEditor = ({
  healthGorillaUserName,
  note,
  invoice,
  clinicData,
  clinicLogoUrl,
  isLoading,
  isSaving,
  saveError,
  onChange,
  pendingChanges,
  teamOptions,
  locationOptions,
  onDelete,
  onCopyToNewNote,
  onSign,
  onAddAddendum,
  onNavigateAway,
}: RichTextEditorProps) => {
  const location = useLocation()
  const history = useHistory()

  const isSigned = !!note?.firstSignedAt
  const [hasDiagnosisBlock, setHasDiagnosisBlock] = useState<boolean>(
    (note.content && getDiagnosisBlocks(note.content) > 0) || false
  )

  //signatures
  const signatureContainerRef: RefObject<HTMLDivElement> = useRef(null)
  const addendumContainerRef: RefObject<HTMLDivElement> = useRef(null)
  //content
  const scrollableContainerRef: RefObject<HTMLTableElement> = useRef(null)

  const [pdfTitle, setPdfTitle] = useState<string>('')

  useEffect(() => {
    const {
      firstName,
      firstNameToUse,
      middleName,
      middleNameToUse,
      lastName,
      lastNameToUse,
    } = note.patient

    const patientName = `${firstNameToUse || firstName} ${
      middleNameToUse || middleName
    } ${lastNameToUse || lastName}`

    const title = `${getNameInitials(patientName)} ${
      note?.title ?? ''
    } ${formatDate({
      format: 'MMMM dd yyyy',
      value: note.appointmentDate,
    })}`

    setPdfTitle(title)
  }, [note])

  const selectedRenderingProvider = teamOptions?.find(
    (teammate) => teammate.cognitoId === note?.renderingProviderId
  )

  const selectedLocation = locationOptions.find(
    (location) => location.LocationId === note?.locationId
  )

  const triggerUpdate = ({
    editor,
    transaction,
  }: {
    editor: Editor
    transaction: Transaction
  }) => {
    const newValue = editor.getJSON()
    // Check if a change has happened in the content to avoid saving the same content
    if (isEqual(transaction.before.content, transaction.doc.content)) {
      return
    }
    // Check if we are removing diagnosisBlock; if so, call removeDiagnosisBlock api
    const diagnosisBlockCount = getDiagnosisBlocks(newValue)

    if (hasDiagnosisBlock && diagnosisBlockCount === 0) {
      removeDiagnosisNoteBlock(note.uuid)
    }
    setHasDiagnosisBlock(diagnosisBlockCount > 0)
    const currentCustomBlocks =
      newValue.content?.reduce<Block[]>((acc, block) => {
        if (
          block.type && // Cast to satisfy TS set type check
          CUSTOM_BLOCK_NAME_SET.has(block.type as BlockConfig['name'])
        ) {
          acc.push({
            type: block.type,
            blockId: block.attrs?.id,
          })
        }

        return acc
      }, []) ?? []

    onChange({ content: newValue, blocks: currentCustomBlocks })
  }

  const blocks = useBlocks()
  const extensions = useBlockExtensions(blocks, isSigned)

  const IntakeBulletList = BulletList.extend({
    addAttributes() {
      return {
        class: {
          default: null,
          renderHTML: (attributes) => {
            return attributes.class ? { class: `${attributes.class}` } : null
          },
        },
      }
    },
  })

  const IntakeHeading = Heading.extend({
    addAttributes() {
      return {
        class: {
          default: null,
          renderHTML: (attributes) => {
            return attributes.class ? { class: `${attributes.class}` } : null
          },
        },
      }
    },
  })

  const DynamicBlockExtension = DynamicBlock({
    note,
  })

  const editor = useEditor(
    {
      extensions: [
        StarterKit.configure({
          heading: { levels: ALLOWED_HEADINGS },
          history: { depth: HISTORY_DEPTH },
          bulletList: { keepMarks: true, keepAttributes: true },
        }),
        Placeholder.configure({
          placeholder: 'Start typing or add a block from the Insert menu above',
        }),
        Highlight.configure({ multicolor: true }),
        IntakeBulletList,
        TrailingNode,
        IntakeHeading,
        Underline,
        Focus,
        DynamicBlockExtension,
        Extension.create({
          addKeyboardShortcuts() {
            return {
              Backspace: ({ editor }) => {
                let cursorStart = editor.state.selection.$from.pos
                let cursorEnd = editor.state.selection.$to.pos
                // Tiptap does not use an input/textarea element for their text editor,
                // and thus we cannot test this functionality. https://github.com/testing-library/user-event/issues/232
                /* istanbul ignore next */
                const isSelectionDelete = cursorStart !== cursorEnd

                // If selection delete, delete the selection presented by the editor.
                // If single deletion, delete the character before the cursor.
                cursorStart = isSelectionDelete ? cursorStart : cursorStart - 1

                let blockCount = 0
                let isDeletingCustomBlock = false
                if (!isSelectionDelete) {
                  // Determine if we are deleting across blocks.
                  editor.state.doc.nodesBetween(
                    // The custom block will start at one character
                    // prior to the current cursor position, which is
                    // two characters prior to the start of the selection.
                    cursorStart - 1,
                    cursorEnd,
                    (node) => {
                      blockCount += 1
                      if (
                        CUSTOM_BLOCK_NAME_SET.has(
                          node.type.name as CustomBlockNames
                        )
                      ) {
                        isDeletingCustomBlock = true
                      }
                      // Never recurse into the blocks in the editor
                      return false
                    }
                  )
                }
                // If we are deleting a custom block, we need to adjust the cursor positions to
                // only delete the custom block.
                if (isDeletingCustomBlock) {
                  cursorStart -= 1
                  cursorEnd -= 1
                  // If we are not deleting a custom block, but we are deleting across blocks,
                  // we need to adjust the cursor start position to delete such that
                  // after deletion our text from the current block is concatenated into
                  // the previous block.
                } else if (blockCount > 1) {
                  cursorStart -= 1
                }
                editor.commands.deleteRange({
                  from: cursorStart,
                  to: cursorEnd,
                })

                return true
              },
            }
          },
        }),
        ...extensions,
      ],
      editorProps: {
        attributes: {
          'data-testid': 'v2-editor-content',
          class:
            'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl focus:outline-none',
        },
      },

      content: note?.content,
      editable: !isLoading && !isSigned,
      onBlur: triggerUpdate,
      onUpdate: triggerUpdate,
    },
    [isLoading, isSigned]
  )

  const { createNewNoteBlock } = useCreateNoteBlock({
    onSuccess: (noteBlock) => {
      insertBlockInEditor(
        editor ?? undefined,
        `<dynamic-block id="${noteBlock.uuid}"></dynamic-block>`
      )
    },
  })

  const scrollToSignatures = () => {
    signatureContainerRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  const scrollToAddendums = () => {
    addendumContainerRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }
  const clinicAddress = useMemo(() => {
    const addressParts = [
      clinicData?.ProviderPracticeAddress,
      clinicData?.ProviderPracticeAddressExtn,
      clinicData?.ProviderPracticeCity,
      clinicData?.ProviderPracticeState,
      clinicData?.ProviderPracticeZipcode,
    ]
    return addressParts.filter((p) => !!p).join(', ')
  }, [clinicData])

  const showSaltAlert = useMemo(() => {
    return (
      new URLSearchParams(location.search).get(QUERY_PARAMS.saltAlert) ===
      'true'
    )
  }, [location.search])

  const onSaltAck = useCallback(() => {
    const newSearchParams = new URLSearchParams(location.search)
    newSearchParams.delete(QUERY_PARAMS.saltAlert)
    history.replace({
      pathname: location.pathname,
      search: newSearchParams.toString(),
    })
  }, [location])

  return (
    <>
      <div className={styles.topToolbars} id="editor-nav">
        <NavigationBar
          healthGorillaUserName={healthGorillaUserName}
          note={note}
          invoice={invoice}
          saveError={saveError}
          isLoading={isLoading}
          isSaving={isSaving}
          onDelete={onDelete}
          canDownload={!!clinicData && !isLoading}
          onSign={onSign}
          onAddAddendum={onAddAddendum}
          onCopy={() => onCopyToNewNote(note.patientId, note.uuid)}
          isNoteEmpty={editor?.isEmpty ?? true}
          hasPendingChanges={
            (pendingChanges && Object.keys(pendingChanges).length > 0) || false
          }
          pdfTitle={pdfTitle}
          onNavigateAway={onNavigateAway}
        />
        {showSaltAlert && (
          <Alert
            testId="alert-note-copied-from"
            message="This note was copied from a previous note."
            description="Please review all the information to ensure accuracy. Note that medications, diagnoses, and allergies will update to reflect the current patient chart."
            type="warning"
            showIcon
            action={
              <Button type="ghost" onClick={onSaltAck}>
                Acknowledge
              </Button>
            }
          />
        )}
        {!isSigned && (
          <EditorToolbar
            editor={editor ?? undefined}
            isLoading={isLoading}
            patientId={note?.patientId}
            noteId={note?.uuid}
            onAddDynamicBlock={createNewNoteBlock}
            onChange={onChange}
          />
        )}
      </div>
      <table
        id="note-content"
        ref={scrollableContainerRef}
        className={styles.container}
      >
        <tbody className={styles.editorWrapper}>
          <div className="print-only pdf-header">
            <div>
              <ImageWithFallback
                className="logo"
                alt=""
                src={clinicLogoUrl}
                title=""
                fallback={<span></span>}
              />
            </div>
            <div>
              <span className="title">{clinicData?.PracticeName}</span>
              &nbsp;
              <span className="address">{clinicAddress}</span>
            </div>
            <PrintableHeader
              note={note}
              locationName={selectedLocation?.LocationName ?? ''}
              providerName={selectedRenderingProvider?.name ?? ''}
            />
          </div>
          <div className={styles.header}>
            <Title
              noteTitle={note?.title}
              onChange={onChange}
              isLoading={isLoading}
              isSigned={isSigned}
            />
            <CoreData
              note={note}
              onChange={onChange}
              teamOptions={teamOptions}
              locationOptions={locationOptions}
              isLoading={isLoading}
              isSigned={isSigned}
            />
            {isSigned && (
              <SignatureHeader
                signatures={note?.signatures ?? []}
                scrollToSignatures={scrollToSignatures}
                scrollToAddendums={scrollToAddendums}
                addendums={note?.addendums ?? []}
              />
            )}
          </div>
          <div className={styles.contentWrapper}>
            {isSigned && editor?.isEmpty && !isLoading && (
              <div className={styles.emptyReadOnlyText}>This note is empty</div>
            )}
            <EditorContent className={styles.editor} editor={editor} />
          </div>
          {isSigned && (
            <div style={{ width: '100%' }}>
              <div className="hide-on-print">
                <Signatures
                  signatures={note?.signatures ?? []}
                  addendums={note?.addendums ?? []}
                  signatureContainerRef={signatureContainerRef}
                  addendumContainerRef={addendumContainerRef}
                />
              </div>
              <div className="print-only">
                <SignaturesPrintMode
                  signatures={note?.signatures ?? []}
                  addendums={note?.addendums ?? []}
                />
              </div>
            </div>
          )}
        </tbody>
        <tfoot className="print-only" style={{ height: '46px' }} />
      </table>
      <div className="print-only pdf-footer">
        <div>
          <p>
            {note.patientName}
            {note.patient.DateOfBirth ? (
              <>
                {' | ' +
                  formatDate({
                    format: 'MM/dd/yyyy',
                    value: note.patient.DateOfBirth,
                  })}
              </>
            ) : (
              ''
            )}
            {' | Visit: ' + formatDate({ value: note.appointmentDate })}
          </p>
        </div>
        <div>
          <img
            src="/osmind-logo.png"
            alt=""
            style={{
              width: '45px',
              height: 'auto',
              overflow: 'visible',
              filter: 'grayscale(100%)',
            }}
          />
        </div>
      </div>
    </>
  )
}
